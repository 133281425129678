import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, message, Modal, Row, Spin } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import APIClient from "../../../helpers/ApiClient";
import "../Bus/busTicketScreen.scss";
// import TicketHotel from "./TicketHotel";
import HotelTicket from "./HotelTicket";
import TicketSidebar from "../TicketSidebar";

const { Panel } = Collapse;
const dateFormat = "DD MMM YYYY";
const HotelTicketScreen = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);

  //  const enums =  {
  //     Failed = 1,
  //     Confirmed = 2,
  //     Cancelled = 3,
  //     Pending = 4,
  //     Rejected = 5,
  //     Hold = 6,
  //     CancellationRequest = 7,
  //     CancellationPending = 8,
  //     CancellationInProgress = 9
  //   }

  const initialState = {
    RefNumber: "",
    ConfirmationNumber: "",
    HotelName: "",
    CityName: "",
    CheckInDate: "",
    CheckOutDate: "",
    StarRating: "",
    BookingStatus: 0,
    NoOfRooms: 0,
    CountryCode: "",
    HotelAddress: {},
    phoneNo: "",
    email: "",
    guests: [],
    PromoData: {},
    ConvienceData: {},
  };

  const [ticketData, setTicketData] = useState(initialState);
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const getTicketDetails = (ref, email) => {
    setLoadingTicket(true);
    APIClient.get("hotels-v2/hotelDetails?RefNumber=" + ref + "&email=" + email)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.BookingStatus != 3) {
            setTicketData(res.data);
            setLoadingTicket(false);
          } else {
            // setTicketData(res.data);
            //message.error("Ticket has cancelled already unable view", 3);
            setLoadingTicket(false);
          }
        }
      })
      .catch((error) => {
        setLoadingTicket(false);
        console.error(error);
      });
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  const submitCancelForm = (val) => {
    setLoading(true);
    if (ticketData.traceId == "" && ticketData.RefNumber == "") {
      return;
    }
    let data = {
      traceId: ticketData.traceId,
      RefNumber: ticketData.RefNumber,
    };

    APIClient.post("hotels-v2/hotelCancel/", data)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (
            [
              "CancellationRequest ",
              "CancellationPending ",
              "CancellationInProgress ",
            ].includes(res.data.bookingStatus)
          ) {
            hotelCancelStatus(data);
          } else {
            message.success(
              `Your total refund amount is Rs. ${res.data.refundAmount} `,
              10
            );
            // setTicketData({ bookingStatus: "Cancelled" });
            getTicketDetails(ticketSearchParams.ref, ticketSearchParams.email);
            setModalVisible(false);
          }
        } else if (res.status === 400) {
          if (res.data.length > 0) {
            res.data.map((err) => {
              if (err.errorCode === "6033") {
                //message.error("Ticket has cancelled already.");
              }
            });
            setModalVisible(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const hotelCancelStatus = (data) => {
    APIClient.post("hotels-v2/hotelCancelStatus/", data)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          message.success(
            `${res.data.Message}. Your total refund amount is Rs. ${res.data.refundAmount}`,
            10
          );
          // setTicketData({ bookingStatus: "Cancelled" });
          getTicketDetails(ticketSearchParams.ref, ticketSearchParams.email);
          setModalVisible(false);
        } else if (res.status === 400) {
          if (res.data.length > 0) {
            res.data.map((err) => {
              if (err.errorCode === "6033") {
                //message.error("Ticket has cancelled already.");
              }
            });
            setModalVisible(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getTicketDetails(ticketSearchParams.ref, ticketSearchParams.email);
  }, [ticketSearchParams.ref]);

  return (
    <div className="flight-ticket-collapse">
      <Card>
        {loadingTicket ? (
          <div className="fligh-ticket-container">
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          </div>
        ) : true ? (
          <div className="fligh-ticket-container">
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum">
                  {/* <TicketHotel
                    ticketData={ticketData}
                    setTotalPrice={setTotalPrice}
                  /> */}
                  <HotelTicket
                    ticketData={ticketData}
                    setTotalPrice={setTotalPrice}
                  />
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Hotel"
                      onCancelTicket={cancelTicket}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={submitCancelForm}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {true ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> : {ticketData.RefNumber}
            </p>
            <p>
              <b>PNR :</b> : {ticketData.ConfirmationNumber}
            </p>
            <p>
              <b>Hotel Name :</b> : {ticketData.HotelName}
            </p>

            <p>
              <b>Check In Date:</b> :{" "}
              {moment(ticketData.CheckInDate).format(dateFormat)}
            </p>
            <p>
              <b> Check Out Date:</b> :{" "}
              {moment(ticketData.CheckOutDate).format(dateFormat)}
            </p>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
export default HotelTicketScreen;
