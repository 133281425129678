import React, { useState, useEffect } from "react";
import "./Sort.scss";
import * as ANTD from "antd";

import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import {
  createDataSourceFromFlightsAndColumns,
  createMatrixColumns,
} from "./AilineMatrixHelper";
const Sort = (props) => {
  const { updateFlightAirSearchRespObj, flightAirSearchResp } = props;

  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const [matrixSelected, setMatrixSelected] = useState({
    visible: false,
    count: "",
  });
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortFlights(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";

      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortFlights(selSorting, dir);
    }
  };

  useEffect(() => {
    sortFlights("price", "asc");
  }, []);

  useEffect(() => {
    if (props.airlineMatrixReset) {
      setMatrixSelected({
        visible: false,
        count: "",
      });
      props.ResetAirlineMatrix(false);
    }
  }, [props.airlineMatrixReset]);
  const getSortIcon = (val) => {
    return val === "asc" ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
  };

  let flightDetails =
    props.selectedTripType === 1
      ? flightAirSearchResp.flightDetails
      : flightAirSearchResp.ibFlightDetails;

  const sortFlights = (selSorting, dir) => {
    if (!flightDetails) return;

    let sortedList = [];

    if (
      flightAirSearchResp.airTravelType == "roundTrip" &&
      flightAirSearchResp.resultsType == "Combined"
    ) {
      sortedList = flightDetails.sort((flightA, flightB) => {
        if (selSorting === "airline") {
          return dir === "asc"
            ? compareFlights(
                flightA.flightDetails[0].airLineName,
                flightB.flightDetails[0].airLineName,
                selSorting
              )
            : compareFlights(
                flightB.flightDetails[0].airLineName,
                flightA.flightDetails[0].airLineName,
                selSorting
              );
        } else if (selSorting === "price") {
          const priceA = flightA.fareFamilies.fareFamilies[0].adultNetFare;
          const priceB = flightB.fareFamilies.fareFamilies[0].adultNetFare;

          return dir === "asc"
            ? compareFlights(priceA, priceB, selSorting)
            : compareFlights(priceB, priceA, selSorting);
        } else if (selSorting === "departure") {
          const depTimeA = new Date(
            flightA.flightDetails[0].flightSegments[0].departureDateTime
          ).getTime();
          const depTimeB = new Date(
            flightB.flightDetails[0].flightSegments[0].departureDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(depTimeA, depTimeB, selSorting)
            : compareFlights(depTimeB, depTimeA, selSorting);
        } else if (selSorting === "arrival") {
          const arrTimeA = new Date(
            flightA.flightDetails[0].flightSegments[
              flightA.flightDetails[0].flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();
          const arrTimeB = new Date(
            flightB.flightDetails[0].flightSegments[
              flightB.flightDetails[0].flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(arrTimeA, arrTimeB, selSorting)
            : compareFlights(arrTimeB, arrTimeA, selSorting);
        }
      });
    } else {
      sortedList = flightDetails.sort((flightA, flightB) => {
        if (selSorting === "airline") {
          return dir === "asc"
            ? compareFlights(
                flightA.airLineName,
                flightB.airLineName,
                selSorting
              )
            : compareFlights(
                flightB.airLineName,
                flightA.airLineName,
                selSorting
              );
        } else if (selSorting === "price") {
          const priceA = flightA.fareFamilies.fareFamilies[0].adultNetFare;
          const priceB = flightB.fareFamilies.fareFamilies[0].adultNetFare;

          return dir === "asc"
            ? compareFlights(priceA, priceB, selSorting)
            : compareFlights(priceB, priceA, selSorting);
        } else if (selSorting === "departure") {
          const depTimeA = new Date(
            flightA.flightSegments[0].departureDateTime
          ).getTime();
          const depTimeB = new Date(
            flightB.flightSegments[0].departureDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(depTimeA, depTimeB, selSorting)
            : compareFlights(depTimeB, depTimeA, selSorting);
        } else if (selSorting === "arrival") {
          const arrTimeA = new Date(
            flightA.flightSegments[
              flightA.flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();
          const arrTimeB = new Date(
            flightB.flightSegments[
              flightB.flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(arrTimeA, arrTimeB, selSorting)
            : compareFlights(arrTimeB, arrTimeA, selSorting);
        }
      });
    }

    if (props.selectedTripType === 1) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: [...sortedList],
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: [...sortedList],
      });
    }
  };

  const compareFlights = (a, b, selSorting) => {
    if (selSorting === "airline" || selSorting === "price") {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };
  let [columns, setColumns] = useState([]);
  let [dataSource, setDataSource] = useState([]);
  const [airlineMatrix, setAirlineMatrix] = useState(false);

  useEffect(() => {
    if (
      flightAirSearchResp.airTravelType == "roundTrip" &&
      flightAirSearchResp.resultsType == "Combined"
    ) {
    } else {
      if (columns.length > 0 && dataSource.length > 0) {
        if (Object.keys(dataSource[0]) > 1) {
          return;
        }
      }
      let temp_columns = createMatrixColumns(flightDetails);
      let temp_dataSource = createDataSourceFromFlightsAndColumns(
        columns,
        flightDetails
      );

      temp_columns = temp_columns.map((item) => {
        return {
          ...item,
          title: (
            <div
              className="cursor_pointer airlineTitle"
              onClick={() => {
                headerRowClicked(item.dataIndex);
                if (item.title === "All Flights") {
                  setMatrixSelected({ visible: false, count: "" });
                } else {
                  setMatrixSelected({ visible: true, count: item.count });
                }
              }}
            >
              {item.title} <i className="fa fa-filter" aria-hidden="true"></i>{" "}
              <span className="matrixCount">({item.count})</span>
            </div>
          ),
        };
      });

      setColumns(temp_columns);
      setDataSource(temp_dataSource);
    }
  }, flightDetails);

  const showModal = () => {
    setAirlineMatrix(true);
  };

  const handleOk = (e) => {
    setAirlineMatrix(false);
  };

  const handleCancel = (e) => {
    setAirlineMatrix(false);
  };

  const filterFLightsByNames = (name) => {
    let new_flights = flightDetails.map((flight) => {
      let visible = false;
      if (name == "type") {
        visible = true;
      } else {
        if (name == flight.airLineName) {
          visible = true;
        }
      }
      return {
        ...flight,
        isVisible: visible,
      };
    });

    if (props.selectedTripType === 1) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: new_flights,
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: new_flights,
      });
    }
  };

  const headerRowClicked = (name) => {
    filterFLightsByNames(name);
    setAirlineMatrix(false);
  };

  return (
    <div className="sort-block">
      <ANTD.Card>
        <div className="radio-btns-sort">
          <div className="sort-text">
            <span>Sort by: </span>
          </div>

          <ANTD.Radio.Group onChange={handleChange} value={sorting}>
            <ANTD.Radio value={"airline"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">
                    Airline{" "}
                    {sorting === "airline" ? getSortIcon(sortDirection) : ""}
                  </p>
                </div>
              </span>
            </ANTD.Radio>
            <ANTD.Radio value={"price"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">
                    Fare
                    {sorting === "price" ? getSortIcon(sortDirection) : ""}
                  </p>
                </div>
              </span>
            </ANTD.Radio>
            <ANTD.Radio value={"departure"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">
                    Departure{" "}
                    {sorting === "departure" ? getSortIcon(sortDirection) : ""}
                  </p>
                </div>
              </span>
            </ANTD.Radio>
            <ANTD.Radio value={"arrival"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">
                    Arrival{" "}
                    {sorting === "arrival" ? getSortIcon(sortDirection) : ""}
                  </p>
                </div>
              </span>
            </ANTD.Radio>
          </ANTD.Radio.Group>

          {/* <div className="matrixWrapper">
            <span onClick={showModal} className="airline-text">
              Airline Matrix{" "}
              {matrixSelected.visible ? (
                <>
                  <i className="fa fa-filter" aria-hidden="true"></i>{" "}
                  <span className="matrixCount">({matrixSelected.count})</span>{" "}
                </>
              ) : null}
            </span>
          </div> */}
        </div>
      </ANTD.Card>

      <ANTD.Modal
        className="air-trix-new"
        title="Airline Matrix"
        visible={airlineMatrix}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={"800px"}
      >
        <ANTD.Table
          scroll={{ x: true }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="airline-matrix-table "
          bordered
        />
      </ANTD.Modal>
    </div>
  );
};

export default Sort;
