import React, { useState, useEffect, useContext } from "react";
import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Spin,
  Skeleton,
  Card,
  message,
} from "antd";
import { GlobalStatesContext } from "../../../common/providers";
import HotelFairBox from "./HotelFairBox";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import HotelBookPayCard from "./HotelBookPayCard";
import CountryList from "../../../common/CountryList";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import ApiClient from "../../../helpers/ApiClient";
import "./HotelCheckout.scss";

const CompanyName = process.env.REACT_APP_COMPANY_NAME;
const { Option } = Select;

const HotelCheckout = () => {
  const { setHotelCheckOutData } = useHotelContext();
  const { RemovePromoConvFee } = useContext(GlobalStatesContext);
  const { user } = useAuthContext();
  const [contact_form] = Form.useForm();
  const [guestDetailsForm] = Form.useForm();
  const [ids, setIds] = useState({
    traceId: null,
    repriceId: null,
  });
  const [loading, setLoading] = useState(true);
  const [hotelPriceData, setHotelPriceData] = useState({});

  const [hotelSearchData, setHotelSearchData] = useState({});

  const [roomGuestInfo, setRoomGuestInfo] = useState([]);

  const [travellersListResp, setTravellersListResp] = useState({
    Travellers: [],
  });

  const fetchTravellerDetails = () => {
    if (!user) return;
    ApiClient.get("admin/user/travellers/" + user.UserID)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setTravellersListResp(resp.data);
        } else {
          console.log("Unable to fetch Travellers List");
        }
      })
      .catch((err) => err);
  };
  const handlePaxField = (e, roomIndex, paxIndex, key) => {
    let temp = [...roomGuestInfo];
    if (paxIndex === 0) {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = true;
    } else {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = false;
    }

    temp[roomIndex].paxInfoList[paxIndex][key] = e;

    setRoomGuestInfo(temp);
  };

  useEffect(() => {
    fetchHotelPrice();
    fetchTravellerDetails();
    RemovePromoConvFee();
  }, []);

  // const handleTravelerDetails = (val, paxIndex) => {
  //   let selectedTraveler = travellersListResp.Travellers.find(
  //     (traveler) => traveler.TravellerID === val
  //   );

  //   guestDetailsForm.setFieldsValue({
  //     [`Title_${paxIndex}`]: selectedTraveler.Gender === 0 ? "MR" : "Ms",
  //     [`lastname_${paxIndex}`]: selectedTraveler.LastName,
  //     [`firstname_${paxIndex}`]: selectedTraveler.FirstName,
  //   });
  // };

  const HotelGuestReqFields = (paxObj, guestRequiredFields) => {
    guestRequiredFields.map((paxReqFieldsObj) => {
      Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
        if (paxReqFieldsObj[paxReqKeys] === true) {
          paxObj[paxReqKeys] = "";
        }
      });
    });
    return paxObj;
  };

  const fetchHotelPrice = () => {
    const hotelParams = queryString.parse(window.location.search);
    setIds({
      traceId: hotelParams.traceId,
      repriceId: null,
    });

    setLoading(true);
    let Req = {
      ...hotelParams,
      roomPlan: JSON.parse(hotelParams.roomPlan),
      userId: user?.UserID ?? 1,
      roleType: user?.Role ?? 4,
      membership: user?.Membership ?? 1,
    };
    setHotelPriceData({});
    setHotelSearchData({});
    setRoomGuestInfo([]);
    ApiClient.post("hotels-v2/hotelprice", Req)
      .then((res) => res)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errors.length === 0) {
            const { traceId, repriceId, hotels, request } = res.data;
            if (
              Object.keys(hotels).length > 0 &&
              Object.keys(request).length > 0
            ) {
              setHotelPriceData({ ...hotels, traceId, repriceId });
              setHotelSearchData(request);
              setHotelCheckOutData({});
              const roomInfoArr = [];

              let roomsInfo = request.roomGuests;

              roomsInfo.map((room, roomIndex) => {
                const paxListArr = [];

                [...Array(room.noOfAdults)].map(() => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mr.",
                    guestType: "Adult",
                    guestInRoom: roomIndex + 1,
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });
                [...Array(room.noOfChilds)].map((_, index) => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mstr",
                    guestType: "Child",
                    guestInRoom: roomIndex + 1,
                    age: parseInt(room.childAge[index]),
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });

                roomInfoArr.push({ paxInfoList: paxListArr });
              });

              setRoomGuestInfo(...roomGuestInfo, roomInfoArr);
            }
          } else {
            res.data.errors.forEach((err) => {
              if (err.errorCode === "SOLDOUT") {
                message.error(err.errorDetail, 5);
              }
            });
          }
        } else if (res.status === 500 && res.data?.errors?.length > 0) {
          res.data.errors.forEach((err) => {
            if (err.errorCode === "SOLDOUT") {
              message.error(err.errorDetail, 5);
            }
          });
        } else {
          if (res.data?.errors?.length > 0) {
            res.data.errors.forEach((err) => {
              if (err.errorCode === "SOLDOUT") {
                message.error(err.errorDetail, 5);
              } else {
                message.error(err.errorDetail, 3);
              }
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let history = useHistory();

  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((val) => {
        guestDetailsForm
          .validateFields()
          .then((passeger_data) => {
            let countryName = "";
            if (val.countryCode) {
              countryName = CountryList.filter(
                (item) => item.code === val.countryCode
              );
              countryName = countryName[0].name;
            }

            let data = {
              hotelPriceData,
              hotelSearchData,
              addressInfo: {
                addressLine1: val.addressLine1,
                addressLine2: val.addressLine2,
                cellCountryCode: val.areaCode,
                countryCode: val.countryCode,
                areaCode: val.areaCode,
                phoneNo: val.phoneNo,
                email: val.email,
                city: val.city,
                state: val.state,
                country: countryName,
                zipCode: val.zipCode,
              },
              guests: roomGuestInfo,
            };

            setHotelCheckOutData(data);

            history.push("/hotel-preview");
          })
          .catch((e) => {
            console.log(e);
            if (e?.errorFields && e?.errorFields.length > 0)
              guestDetailsForm.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  // const getAdultChildCount = (paxInfo) => {
  //   if (paxInfo.length > 0) {
  //     let count = paxInfo.reduce((p, c) => {
  //       let type = c.guestType;
  //       if (!p.hasOwnProperty(type)) {
  //         p[type] = 0;
  //       }
  //       p[type]++;
  //       return p;
  //     }, {});

  //     return `Adult(s) ${count.Adult ? count.Adult : " "} ${
  //       count.Child ? ", Children " + count.Child : " "
  //     }`;
  //   }
  // };

  return (
    <div className="hotel-checkout hotel-div-checkout">
      <div className="checkout-nav e-hide">
        <div className="checkout-container">
          <div className="checkout-header">
            <div className="logo">
              <h4>{CompanyName}</h4>
            </div>
            <div className="checkout-links">
              <ul>
                <li>
                  <i className="fa fa-long-arrow-left"></i> Choose other room
                </li>
                <li>
                  <span>1</span> Guest details
                </li>
                <li>
                  <span>2</span> Summary
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="form-header ">
        <div className="form-header-container cp-container-hotel">
          <div className="form-header-text">
            <h2>Fill out the form below and book your stay now!</h2>
          </div>
        </div>
      </div>

      <div className="form-body">
        <div className="form-body-container cp-container-hotel py-3">
          <Row gutter={[16, 16]}>
            <Col md={17} sm={24} xs={24} className="form-body-left-block">
              <span className="booking-summary-div">
                <i className="fa fa-file-text" aria-hidden="true"></i>&nbsp;
                Review Your Booking{" "}
              </span>
              <div className="hotel-details-box hotel-top-new top-room-data-info">
                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <HotelDetailsBox
                    Ids={ids}
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                  />
                ) : null}
              </div>

              <div className="form-body-left-content">
                <div className="contact-header contact-cp-close">
                  <div className="contact-title">
                    <span className="booking-summary-div-gu">
                      <i className="fa fa-users" aria-hidden="true"></i>
                      &nbsp;Guests Details
                    </span>
                  </div>
                </div>

                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : roomGuestInfo?.length > 0 ? (
                  <Form
                    form={guestDetailsForm}
                    scrollToFirstError={true}
                    layout="vertical"
                  >
                    {roomGuestInfo.map((roomsObj, roomIndex) => (
                      <Card
                        bordered={false}
                        className="guest-details-form hotel-card-wrapper mb-2 "
                        key={roomIndex}
                      >
                        <p className="room-title mb-0">Room {1 + roomIndex}</p>

                        {roomsObj.paxInfoList.map((pax, paxIndex) => (
                          <div
                            key={roomIndex + "detials" + paxIndex}
                            className="guest-input-wrapper"
                          >
                            <p className="guestsType">
                              {pax.guestType === "Adult" ? "Adult" : "Child"}{" "}
                            </p>
                            <Row gutter={[16, 16]}>
                              <Col md={3} sm={12} xs={24}>
                                {pax.guestType === "Adult" ? (
                                  <Form.Item
                                    name={`Title_${roomIndex}_${paxIndex}`}
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "title"
                                        );
                                      }}
                                    >
                                      <Option value="Mr">Mr</Option>
                                      <Option value="Ms">Ms</Option>
                                      <Option value="Mrs">Mrs</Option>
                                    </Select>
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name={`Title_${roomIndex}_${paxIndex}`}
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "title"
                                        );
                                      }}
                                    >
                                      <Option value="Mstr">Mstr</Option>
                                    </Select>
                                  </Form.Item>
                                )}
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="First Name"
                                  name={`firstname_${roomIndex}_${paxIndex}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={(e) => {
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "firstName"
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Last Name"
                                  name={`lastname_${roomIndex}_${paxIndex}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    onChange={(e) => {
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "lastName"
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              {pax.guestType === "Child" ? (
                                <Col md={5} sm={12} xs={24}>
                                  <Form.Item
                                    name={`childAge_${roomIndex}_${paxIndex}`}
                                    label="Child Age"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "age"
                                        );
                                      }}
                                    >
                                      {[...Array(12)].map((_, i) => (
                                        <Option key={i + "age"} value={i}>
                                          {i} Years
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              ) : (
                                <Col md={5} sm={12} xs={24}>
                                  <Form.Item
                                    name={`adultage_${roomIndex}_${paxIndex}`}
                                    label="Age"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "age"
                                        );
                                      }}
                                    >
                                      {[...Array(83)].map((_, i) => (
                                        <Option
                                          key={i + 12 + "age"}
                                          value={i + 12}
                                        >
                                          {i + 12} Years
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("pan") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="PAN Number"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`pan_${roomIndex}_${paxIndex}`}
                                  >
                                    <Input
                                      className="inputbg"
                                      placeholder="PAN Number"
                                      onChange={(e) =>
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "pan"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}

                              {pax.hasOwnProperty("passportNo") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport Number"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportNo_${roomIndex}_${paxIndex}`}
                                  >
                                    <Input
                                      className="inputbg"
                                      placeholder="Passport Number"
                                      onChange={(e) =>
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "passportNo"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("passportDOI") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport DOI"
                                    className="passport-dates"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportDOI_${roomIndex}_${paxIndex}`}
                                  >
                                    <DatePicker
                                      onChange={(date, dateString) =>
                                        handlePaxField(
                                          dateString,
                                          paxIndex,
                                          "passportDOI"
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("passportDOE") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport DOE"
                                    className="passport-dates"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportDOE_${roomIndex}_${paxIndex}`}
                                  >
                                    <DatePicker
                                      onChange={(_, dateString) =>
                                        handlePaxField(
                                          dateString,
                                          paxIndex,
                                          "passportDOE"
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </div>
                        ))}
                      </Card>
                    ))}
                  </Form>
                ) : null}

                <div className="form-body-contact contact-spin-details ">
                  <div className="contact-header">
                    <div className="contact-title">
                      <h1 className="booking-summary-div-gu">
                        <i className="fa fa-user" aria-hidden="true"></i>
                        &nbsp;Contact Details
                      </h1>
                    </div>
                  </div>
                  {loading ? (
                    <Card bordered={false} className="hotel-card-wrapper">
                      <Skeleton active />
                    </Card>
                  ) : (
                    <div className="contact-internal-form">
                      <div className="contact-body">
                        <div className="contact-form">
                          <Form
                            layout="vertical"
                            name="contactForm"
                            form={contact_form}
                            scrollToFirstError={true}
                            initialValues={{
                              countryCode: "IN",
                            }}
                          >
                            <Row gutter={16}>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Email"
                                  name="email"
                                  rules={[
                                    { required: true, message: "Required" },
                                    { type: "email", message: "Invalid Email" },
                                  ]}
                                >
                                  <Input
                                  // onBlur={(e) => {
                                  //   handleContactField(e, "email");
                                  // }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Phone number"
                                  name="phoneNo"
                                  className="phno"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                    {
                                      minLength: 10,
                                      maxLength: 10,
                                      pattern: "^[0-9]{10}$",
                                      message: "Must be 10 digits",
                                    },
                                  ]}
                                >
                                  <Input
                                    addonBefore={
                                      <Form.Item
                                        style={{ width: "35%" }}
                                        name="areaCode"
                                        className="phno"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Phone Number Code Required",
                                          },
                                        ]}
                                        noStyle
                                      >
                                        <Select
                                          showSearch
                                          // placeholder="Select Country"
                                          style={{ width: "100%" }}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {CountryList.map((item) => (
                                            <Option
                                              key={item.dial_code}
                                              value={item.dial_code}
                                            >
                                              {item.dial_code}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    }
                                    // onBlur={(e) => {
                                    //   handleContactField(e, "phnumber");
                                    // }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  name="addressLine1"
                                  label="Address"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                  // onBlur={(e) => {
                                  //   handleContactField(e, "name");
                                  // }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  name="addressLine2"
                                  label="Address"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                  // onBlur={(e) => {
                                  //   handleContactField(e, "name");
                                  // }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  name="city"
                                  label="City"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                  // onBlur={(e) => {
                                  //   handleContactField(e, "name");
                                  // }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  name="state"
                                  label="State"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                  // onBlur={(e) => {
                                  //   handleContactField(e, "name");
                                  // }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  name="zipCode"
                                  label="ZIP/Postal code"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                  // onBlur={(e) => {
                                  //   handleContactField(e, "name");
                                  // }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  name="countryCode"
                                  label="Country"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select Country"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {CountryList.map((item) => (
                                      <Option key={item.code} value={item.code}>
                                        {item.name}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>{" "}
                        </div>
                        {/* <div className="contact-disclaimer">
                          <p>
                            Fill in contact details. It will alow us to contact
                            you in case of any problems with your booking.
                            <br /> After completing booking process, you will
                            receive voucher via e-mail.
                          </p>
                        </div> */}
                      </div>

                      {/* <div className="contact-footer">
                        <Form.Item
                          name="regaAgreement"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject("Should accept agreement"),
                            },
                          ]}
                        >
                          <Checkbox>
                            I want to register an account to be able to{" "}
                            <a href="#" target="_blank">
                              {"  check the status of my booking"}
                            </a>{" "}
                            and view my purchase history
                          </Checkbox>
                        </Form.Item>
                      </div> */}
                    </div>
                  )}
                </div>

                {/* 
                  <div className="form-body-contact">
                    <div className="contact-internal-form">
                      <div className="contact-footer">
                        <Form.Item
                          name="agreement"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value
                                  ? Promise.resolve()
                                  : Promise.reject("Should accept agreement"),
                            },
                          ]}
                        >
                          <Checkbox
                          // onChange={(e) => {
                          //   setPassengersDetails({
                          //     ...passengersDetails,
                          //     termsAccepted: e.target.checked,
                          //   });
                          // }}
                          >
                            I Agree To All The{" "}
                            <a href="/termsofconditions" target="_blank">
                              {"  Terms & Conditions"}
                            </a>{" "}
                            and{" "}
                            <a href="/privacypolicy" target="_blank">
                              {" "}
                              Privacy Policy.
                            </a>
                          </Checkbox>
                        </Form.Item>

                        <div className="bookandpay-btn">
                          <Button disabled={loading} htmlType="submit">
                            Pay Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>
            </Col>

            <Col
              md={7}
              sm={24}
              xs={24}
              className="form-body-right-block hotel-top-new right-block-cp"
            >
              <div className="price-form-right price-hotel-main">
                <div className="form-body-right-content fare-details-box">
                  {loading ? (
                    <Card bordered={false} className="hotel-card-wrapper">
                      <Skeleton active />
                    </Card>
                  ) : Object.keys(hotelPriceData).length > 0 ? (
                    <HotelFairBox
                      hotelDetailsObj={hotelPriceData}
                      hotelSearchData={hotelSearchData}
                      isPromoVisible={true}
                    />
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={17}>
              <HotelBookPayCard
                isLoading={loading}
                bookpaycardinfo={"hotel-checkout"}
                redirectToPreview={redirectToPreview}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default HotelCheckout;
