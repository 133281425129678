import { ArrowRightOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, Modal, Row, Tabs } from "antd";
import dateFormat from "dateformat";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Flightticketsuccess.scss";
const { Content } = Layout;
const { TabPane } = Tabs;
const Flightticketsuccess = ({ flightTicketsDetails }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [detailsModal, setDetailsModal] = useState({});

  const handleDetailsModal = (flightDetails) => {
    setDetailsModal(flightDetails);
    setIsVisible(true);
  };

  const handleModalClose = () => {
    setIsVisible(false);
    setDetailsModal({});
  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };
  const calculateDuration = (from, to) => {
    let fromTime = from.split("T")[0] + " " + from.split("T")[1];
    let toTime = to.split("T")[0] + " " + to.split("T")[1];

    let diffInMilliSeconds =
      Math.abs(new Date(fromTime) - new Date(toTime)) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} d, ` : `${days} ds, `;
    }

    difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

    difference +=
      minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

    return difference;
    // return days;
  };
  const cityCode = (cityCode) => {
    try {
      let cityArray = cityCode.split(" ");
      if (cityArray.length > 0) {
        return cityArray[cityArray.length - 1].slice(1, -1);
      } else {
        return cityCode;
      }
    } catch (error) {
      return cityCode;
    }
  };

  return (
    <div className="promo providerapi">
      <Layout className="ourteam-bg">
        <div className="">
          <Content className="admin-container cms-pages-width retrieve-linebar">
            <Row className="fst-book">
              <Col md={24} xs={24}></Col>
            </Row>

            <Row>
              <Col md={24} xs={24}>
                <Card className="retrieve-booking">
                  <Row>
                    <Col md={12} xs={24}>
                      <h4 className="ticket-lti">Flight ltinerary</h4>
                      {/* sub--row--start */}
                      {Object.keys(flightTicketsDetails.ticketAllData).length >
                      0 ? (
                        <>
                          <h6 className="type">Onward</h6>
                          <div className="fic-tickets-data">
                            <Row gutter={8}>
                              <Col md={6} xs={24}>
                                <div className="airline-dte-tic">
                                  <h5>
                                    {
                                      flightTicketsDetails.ticketAllData
                                        .oneWaySegment[0].flightCode
                                    }
                                  </h5>
                                </div>
                              </Col>

                              <Col md={12} xs={24}>
                                <div className="tic-indi-details">
                                  <p>
                                    {flightTicketsDetails.ticketAllData
                                      .oneWaySegment.length > 0
                                      ? cityCode(
                                          flightTicketsDetails.ticketAllData
                                            .oneWaySegment[0].origin
                                        )
                                      : ""}
                                  </p>

                                  <span className="airline-tic-files"></span>
                                  {flightTicketsDetails.ticketAllData.oneWaySegment.map(
                                    (segment, index) =>
                                      index !== 0 ? (
                                        <>
                                          <p>{cityCode(segment.origin)}</p>
                                          <span className="airline-tic-files"></span>
                                        </>
                                      ) : null
                                  )}

                                  <p>
                                    {flightTicketsDetails.ticketAllData
                                      .oneWaySegment.length > 0
                                      ? cityCode(
                                          flightTicketsDetails.ticketAllData
                                            .oneWaySegment[
                                            flightTicketsDetails.ticketAllData
                                              .oneWaySegment.length - 1
                                          ].destination
                                        )
                                      : ""}
                                  </p>
                                </div>
                              </Col>
                              <Col md={6} xs={24}>
                                <div className="plane-retrive-data">
                                  <ul>
                                    <li>
                                      <p>
                                        {flightTicketsDetails.ticketAllData
                                          .oneWaySegment.length > 0
                                          ? formatFlightTime(
                                              flightTicketsDetails.ticketAllData
                                                .oneWaySegment[0]
                                                .departureDateTime
                                            )
                                          : ""}
                                      </p>
                                    </li>

                                    <li>
                                      <ArrowRightOutlined />
                                    </li>

                                    <li>
                                      {flightTicketsDetails.ticketAllData
                                        .oneWaySegment.length > 0 ? (
                                        <p>
                                          {formatFlightTime(
                                            flightTicketsDetails.ticketAllData
                                              .oneWaySegment[
                                              flightTicketsDetails.ticketAllData
                                                .oneWaySegment.length - 1
                                            ].arrivalDateTime
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          {flightTicketsDetails.ticketAllData.returnSegment
                            .length > 0 ? (
                            <>
                              <h6 className="type">Return</h6>
                              <div className="fic-tickets-data">
                                <Row gutter={8}>
                                  <Col md={6} xs={24}>
                                    <div className="airline-dte-tic">
                                      <h5>
                                        {
                                          flightTicketsDetails.ticketAllData
                                            .returnSegment[0].flightCode
                                        }
                                      </h5>
                                    </div>
                                  </Col>

                                  <Col md={12} xs={24}>
                                    <div className="tic-indi-details">
                                      <p>
                                        {flightTicketsDetails.ticketAllData
                                          .returnSegment.length > 0
                                          ? cityCode(
                                              flightTicketsDetails.ticketAllData
                                                .returnSegment[0].origin
                                            )
                                          : ""}
                                      </p>

                                      <span className="airline-tic-files"></span>
                                      {flightTicketsDetails.ticketAllData.returnSegment.map(
                                        (segment, index) =>
                                          index !== 0 ? (
                                            <>
                                              <p>{cityCode(segment.origin)}</p>
                                              <span className="airline-tic-files"></span>
                                            </>
                                          ) : null
                                      )}

                                      <p>
                                        {flightTicketsDetails.ticketAllData
                                          .returnSegment.length > 0
                                          ? cityCode(
                                              flightTicketsDetails.ticketAllData
                                                .returnSegment[
                                                flightTicketsDetails
                                                  .ticketAllData.returnSegment
                                                  .length - 1
                                              ].destination
                                            )
                                          : ""}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col md={6} xs={24}>
                                    <div className="plane-retrive-data">
                                      <ul>
                                        <li>
                                          <p>
                                            {flightTicketsDetails.ticketAllData
                                              .returnSegment.length > 0
                                              ? formatFlightTime(
                                                  flightTicketsDetails
                                                    .ticketAllData
                                                    .returnSegment[0]
                                                    .departureDateTime
                                                )
                                              : ""}
                                          </p>
                                        </li>

                                        <li>
                                          <ArrowRightOutlined />
                                        </li>

                                        <li>
                                          {flightTicketsDetails.ticketAllData
                                            .returnSegment.length > 0 ? (
                                            <p>
                                              {formatFlightTime(
                                                flightTicketsDetails
                                                  .ticketAllData.returnSegment[
                                                  flightTicketsDetails
                                                    .ticketAllData.returnSegment
                                                    .length - 1
                                                ].arrivalDateTime
                                              )}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <p>{flightTicketsDetails.error}</p>
                      )}
                    </Col>

                    <Col md={12} xs={24} className="flight-email-dd">
                      {flightTicketsDetails.flightDetails.length > 0 ? (
                        <>
                          <div className="paymet-booking-tic">
                            <h4>Ticket Booking Payment Successful</h4>
                            <p>
                              A confirmation email has been sent your provided
                              email address
                            </p>
                            <p>Ref-No : {flightTicketsDetails.BookingRefNo}</p>
                            <p>
                              Status :{" "}
                              <span className="succes-pay-value">Success</span>
                            </p>
                          </div>

                          <Row>
                            <Col md={12} xs={24}>
                              <Button
                                className="tic-retrive-book"
                                htmlType="submit"
                              >
                                <Link
                                  target="_blank"
                                  to={`flight_ticket?ref=${flightTicketsDetails.BookingRefNo}&email=${flightTicketsDetails?.ticketAllData?.guestEmaiId}`}
                                >
                                  Retrieve Booking
                                </Link>
                              </Button>
                            </Col>
                            <Col md={12} xs={24}></Col>
                          </Row>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  {/* iiner--row--close-- */}
                </Card>
              </Col>
            </Row>

            <Modal
              className="modal-fl-heading"
              title="Flight Details"
              visible={isVisible}
              footer={[null]}
              onOk={handleModalClose}
              onCancel={handleModalClose}
              className="flight-details-modal"
            >
              <div className="all-times-local">
                <Tabs
                  defaultActiveKey="1"
                  // onTabClick={(str, e) => fetchFlightFares(str, e)}
                >
                  {Object.keys(detailsModal).length > 0 ? (
                    <TabPane tab="Segments" key="1">
                      <div className="from-to-modal-details">
                        <Row gutter={16} className="more-details-row">
                          <Col span={14}>
                            <Row gutter={16}>
                              <Col span={8} className="more-details-list">
                                <p>{detailsModal.flightSegments[0].origin}</p>
                                <p>
                                  <strong>
                                    {dateFormat(
                                      detailsModal.flightSegments[0]
                                        .departureDateTime,
                                      "HH:MM"
                                    )}
                                    ,
                                  </strong>
                                  <span>
                                    {dateFormat(
                                      detailsModal.flightSegments[0]
                                        .departureDateTime,
                                      "dd mmm (ddd)"
                                    )}
                                  </span>
                                </p>
                              </Col>
                              <Col span={8} className="more-details-line"></Col>
                              <Col span={8} className="more-details-list">
                                <p>
                                  {
                                    detailsModal.flightSegments[
                                      detailsModal.flightSegments.length - 1
                                    ].destination
                                  }
                                </p>
                                <p>
                                  <strong>
                                    {dateFormat(
                                      detailsModal.flightSegments[
                                        detailsModal.flightSegments.length - 1
                                      ].arrivalDateTime,
                                      "HH:MM"
                                    )}
                                    ,
                                  </strong>
                                  <span>
                                    {dateFormat(
                                      detailsModal.flightSegments[
                                        detailsModal.flightSegments.length - 1
                                      ].arrivalDateTime,
                                      "dd mmm (ddd)"
                                    )}
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} className="trip-duration-time">
                            <p>Total trip duration:</p>
                            <p>
                              {detailsModal.flightSegments.length > 0
                                ? calculateDuration(
                                    detailsModal.flightSegments[0]
                                      .departureDateTime,
                                    detailsModal.flightSegments[
                                      detailsModal.flightSegments.length - 1
                                    ].arrivalDateTime
                                  )
                                : calculateDuration(
                                    detailsModal.flightSegments[0]
                                      .departureDateTime,
                                    detailsModal.flightSegments[0]
                                      .arrivalDateTime
                                  )}{" "}
                              |
                              {detailsModal.flightSegments.length === 0 ? (
                                <>Direct</>
                              ) : (
                                <>
                                  {detailsModal.flightSegments.length - 1} stops{" "}
                                </>
                              )}
                            </p>
                          </Col>
                          <Col span={4} className="trip-duration-time">
                            <p>class:</p>
                            <p>Economy</p>
                          </Col>
                        </Row>
                      </div>

                      {detailsModal.flightSegments.map((flightSegment) => (
                        <div className="total-flight-details">
                          <div className="to-fro-flight-details">
                            <div className="from-city-flight-details">
                              <span>{flightSegment.origin}</span>
                            </div>
                            <div>
                              <span>
                                departure:{" "}
                                <strong>
                                  {dateFormat(
                                    flightSegment.departureDateTime,
                                    "HH:MM"
                                  )}
                                  ,
                                </strong>
                              </span>
                              <span>
                                {dateFormat(
                                  flightSegment.departureDateTime,
                                  "dd mmm (ddd)"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="collapsed-card"></div>
                          <div className="to-fro-flight-details">
                            <div className="from-city-flight-details">
                              <span>{flightSegment.destination}</span>
                            </div>
                            <div>
                              <span>
                                arrival:
                                <strong>
                                  {formatFlightTime(
                                    flightSegment.arrivalDateTime
                                  )}
                                </strong>
                              </span>
                              <span>
                                {dateFormat(
                                  flightSegment.arrivalDateTime,
                                  "dd mmm (ddd)"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </TabPane>
                  ) : null}
                </Tabs>
              </div>
            </Modal>
          </Content>
        </div>
      </Layout>
    </div>
  );
};

export default Flightticketsuccess;
