import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Layout, message, Modal, Popconfirm, Radio, Row, Select, Table, Upload } from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../exportExcelCity";

import { EditOutlined, PlusCircleFilled, PlusOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const CityHotel = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/multipleImages";
  const { Option } = Select;
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [countryLists, setCountryList] = useState([]);
  const [cityLists, setCityList] = useState([
    {
      cityId: 1,
      cityName: "Hyderabad",
    },
    {
      cityId: 2,
      cityName: "Dubai",
    },
    {
      cityId: 3,
      cityName: "Singapore",
    },
  ]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cityHotelId, setCityHotelId] = useState(null);
  const [form] = Form.useForm();
  const [serviceType, setServiceType] = useState(2);

  const initialImages = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  };
  const [cityImages, setCityImages] = useState(initialImages);

  const [hotelImages, setHotelImages] = useState(initialImages);

  const showModal = () => {
    if (isEdit) {
      setServiceType(2);
      setIsEdit(false);
      form.resetFields();
      setCityImages(initialImages);
      setHotelImages(initialImages);
    }
    setModalVisible({
      visible: true,
    });
  };

  const EditCityHotel = (record) => {
    setIsEdit(true);

    setServiceType(record.Servicetype);
    setCityHotelId(record.CityHotelID);
    let cityImages = [
      {
        uid: "1",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.CityImage1.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.CityImage1],
          },
        },
      },
      {
        uid: "2",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.CityImage2.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.CityImage2],
          },
        },
      },
      {
        uid: "3",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.CityImage3.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.CityImage3],
          },
        },
      },
    ];
    let hotelImages = [
      {
        uid: "1",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.HotelImage1.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.HotelImage1],
          },
        },
      },
      {
        uid: "2",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.HotelImage2.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.HotelImage2],
          },
        },
      },
      {
        uid: "3",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.HotelImage3.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.HotelImage3],
          },
        },
      },
    ];
    let val = {
      children: record.CountryName,
    };

    countryCityList(val, "");
    form.setFieldsValue({
      Servicetype: record.Servicetype,
      CountryID: record.CountryID,
      CityID: record.CityName,
      cityImages: { fileList: cityImages },
      hotelName: record.HotelName,
      hotelImages: { fileList: hotelImages },
      Title: record.Title,
      SearchURL: record.SearchURL,
      AnyOffer: record.AnyOffer,
      HotelDetailsUrl: record.HotelDetailsUrl,
      ShortDescription: record.ShortDescription,
      Status: record.Status,
    });

    setCityImages((prev) => ({ ...prev, fileList: cityImages }));
    setHotelImages((prev) => ({ ...prev, fileList: hotelImages }));
    setModalVisible(true);
  };

  /********** Uploading Image **********/
  const handleCancel = (type) => {
    if (type === "city") {
      setCityImages((prev) => ({ ...prev, previewVisible: false }));
    } else if (type === "hotel") {
      setHotelImages((prev) => ({ ...prev, previewVisible: false }));
    }
  };

  const handlePreview = async (file, type) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (type === "city") {
      setCityImages((prev) => ({
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      }));
    } else if (type === "hotel") {
      setHotelImages((prev) => ({
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      }));
    }
  };

  const handleChange = (data, type) => {
    if (type === "city") {
      setCityImages((prev) => ({ ...prev, fileList: data.fileList }));
    } else if (type === "hotel") {
      setHotelImages((prev) => ({ ...prev, fileList: data.fileList }));
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              EditCityHotel(rec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              deleteCityHotel(rec.CityHotelID);
            }}
          >
            <DeleteOutlined style={{ color: "red", fontSize: "20px", textAlign: "center" }} />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "Sno",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "name",
      render: (status) => getStatus(status),
    },
    {
      title: "Actions",
      render: (currentDeal) => tableActions(currentDeal),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceName",
      sorter: (a, b) => a.ServiceName.localeCompare(b.ServiceName),
    },
    {
      title: "Name",
      dataIndex: "HotelName",
      sorter: (a, b) => a.HotelName.localeCompare(b.HotelName),
    },
    {
      title: "City Name",
      dataIndex: "CityName",
      sorter: (a, b) => a.CityName.localeCompare(b.CityName),
    },
    {
      title: "Country Name",
      dataIndex: "CountryName",
      sorter: (a, b) => a.CountryName.localeCompare(b.CountryName),
    },

    {
      title: "Title",
      dataIndex: "Title",
    },
    {
      title: "Short Description",
      dataIndex: "ShortDescription",
    },
    {
      title: "Details Url",
      dataIndex: "HotelDetailsUrl",
    },
    {
      title: "Search URL",
      dataIndex: "SearchURL",
    },
  ];

  const getStatus = (status) => {
    return (
      <div style={!status ? { color: "#008000" } : { color: "#ff0000" }}>
        <b>{!status ? "Active" : "Inactive"}</b>
      </div>
    );
  };

  const getName = (id) => {
    switch (id) {
      case 2:
        return "Hotel";
      case 1:
        return "Activity";

      default:
        break;
    }
  };

  const selectCustomFilter = (input, option) => {
    let istext = option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };
  /*==========Api Calls=====*/

  useEffect(() => {
    getCityHotels();
    getCountriesLists();
  }, []);

  const getCityHotels = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status == 200) {
          const data = res.data.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
              ServiceName: getName(item.Servicetype),
            };
          });
          setDataSource(data);
        } else {
          message("Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const countryCityList = (val, _) => {
    let countryName = {
      country: val.children,
    };
    ApiClient.post("admin/citiesbycountry", countryName)
      .then((res) => {
        if (res.status == 200) {
          setCityList(res.data);
        } else {
          message(res.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteCityHotel = (id) => {
    ApiClient.delete("admin/cityHotel/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("City Hotel Deleted  successfully", 3);
          getCityHotels();
        } else {
          message.error("Error Deleting", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    if (values.cityImages.fileList.length === 3) {
      if (values.cityImages.fileList[0].response.status !== 200 && values.cityImages.fileList[1].response.status !== 200 && values.cityImages.fileList[2].response.status !== 200) {
        form.setFields([
          {
            name: "cityImages",
            errors: ["Image Error. Change Image"],
          },
        ]);
        return;
      }
    } else {
      form.setFields([
        {
          name: "cityImages",
          errors: ["3 Images Required"],
        },
      ]);
      return;
    }

    if (values.hotelImages.fileList.length === 3) {
      if (values.hotelImages.fileList[0].response.status !== 200 && values.hotelImages.fileList[1].response.status !== 200 && values.hotelImages.fileList[2].response.status !== 200) {
        form.setFields([
          {
            name: "hotelImages",
            errors: ["Image Error. Change Image"],
          },
        ]);
        return;
      }
    } else {
      form.setFields([
        {
          name: "hotelImages",
          errors: ["3 Images Required"],
        },
      ]);
      return;
    }

    let countryData = countryLists.filter((item) => item.CountryID === values.CountryID);
    let cityData = cityLists.filter((item) => item.cityId === values.CityID);

    let formData = {
      Servicetype: values.Servicetype,
      CountryID: values.CountryID,
      CountryName: countryData[0].Country,
      CityID: 0,
      CityName: values.CityID,
      CityImage1: values.cityImages.fileList[0].response.data.filepaths[0],
      CityImage2: values.cityImages.fileList[1].response.data.filepaths[0],
      CityImage3: values.cityImages.fileList[2].response.data.filepaths[0],
      HotelName: values.hotelName,
      HotelDetailsUrl: values.HotelDetailsUrl,
      HotelImage1: values.hotelImages.fileList[0].response.data.filepaths[0],
      HotelImage2: values.hotelImages.fileList[1].response.data.filepaths[0],
      HotelImage3: values.hotelImages.fileList[2].response.data.filepaths[0],
      Title: values.Title,
      ShortDescription: values.ShortDescription,
      SearchURL: values.SearchURL,
      Status: values.Status,
      AnyOffer: values.AnyOffer,
      CreatedBy: 0,
      DeviceOS: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 0,
      FirBaseToken: "string",
    };

    isEdit ? putDealData(formData) : postCityHotel(formData);
  };

  const putDealData = (formData) => {
    ApiClient.put("admin/cityHotel/" + cityHotelId, {}, formData).then((res) => {
      if (res.status == 200) {
        message.success("City Hotel Updated  successfully", 3);
        setModalVisible(false);
        form.resetFields();
        setCityImages(initialImages);
        setHotelImages(initialImages);
        getCityHotels();
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const postCityHotel = (formData) => {
    ApiClient.post("admin/cityHotel", formData).then((res) => {
      if (res.status == 200) {
        message.success("City Hotel added successfully", 3);
        getCityHotels();
        setModalVisible(false);
        form.resetFields();
        setCityImages(initialImages);
        setHotelImages(initialImages);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getCityHotels();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = (e) => {
    const excelCustomData = dataSource.map((item) => {
      return {
        From_Country: item.FromCountry,
        To_Country: item.ToCountry,
        From_Airport: item.FromAirportCode,
        To_Airport: item.ToAirportCode,
        From_Amount: item.FromAmount,
      };
    });

    props.exportExcel(excelCustomData, "dealsExcel");
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>View City Hotels</h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                      &nbsp;&nbsp;
                      <img src={search} alt="search" onClick={(e) => searchData(dataSource)} />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img src={exchange} alt="exchange" onClick={() => getCityHotels()} />
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={isEdit ? "Update City Hotel" : "Add City Hotel"}
        className="promo-modal-header admin-bc-level trip-modal-admin"
        visible={modalVisible}
        onCancel={(e) => setModalVisible(false)}
        width={"1100px"}
        footer={[
          <Button key="submit" type="primary" onClick={form.submit}>
            {isEdit ? "Update" : "Add"}
          </Button>,
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{
            Servicetype: 2,
          }}
          layout="vertical"
          onFinish={submitForm}
        >
          <Row gutter={[16, 16]}>
            <Col md={6} xs={24}>
              <Form.Item label="Service Type" rules={[{ required: true }]} name="Servicetype">
                <Select
                  placeholder="Please Select"
                  onSelect={(e) => {
                    setServiceType(e);
                  }}
                  value={serviceType}
                >
                  <Option value={2}>Hotel</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Country Name" name="CountryID" rules={[{ required: true, message: "From Country is required" }]}>
                <Select
                  showSearch
                  placeholder="Select country"
                  onSelect={(val, _) => {
                    countryCityList((val, _));
                  }}
                  filterOption={(val, op) => selectCustomFilter(val, op)}
                >
                  {countryLists.map((i) => (
                    <Option value={i.CountryID}>{i.Country}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="City Name" name="CityID" rules={[{ required: true, message: "City is required" }]}>
                <Select showSearch placeholder="Select City" filterOption={(val, op) => selectCustomFilter(val, op)}>
                  {cityLists.map((i) => (
                    <Option value={i}>{i}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label={"Hotel Name"}
                name="hotelName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label={"Hotel Title"}
                name="Title"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                label="Details Url"
                name="HotelDetailsUrl"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                label="Search URL"
                name="SearchURL"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Any Offer"
                name="AnyOffer"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                label="Short Description"
                name="ShortDescription"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>Active</Radio>
                  <Radio value={1}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item label="City Images" name="cityImages" rules={[{ required: true, message: "Images are required" }]}>
                <Upload
                  name="image"
                  action={uploadActionUrl}
                  listType="picture-card"
                  fileList={cityImages.fileList}
                  onPreview={(data) => handlePreview(data, "city")}
                  data={{ category: "cityimages" }}
                  onChange={(data) => handleChange(data, "city")}
                >
                  {cityImages.fileList.length >= 3 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Modal visible={cityImages.previewVisible} title={cityImages.previewTitle} footer={null} onCancel={() => handleCancel("city")}>
                <img alt="example" style={{ width: "100%" }} src={cityImages.previewImage} />
              </Modal>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label={"Hotel Images"} name="hotelImages" rules={[{ required: true, message: "Images are required" }]}>
                <Upload
                  name="image"
                  action={uploadActionUrl}
                  listType="picture-card"
                  fileList={hotelImages.fileList}
                  onPreview={(data) => handlePreview(data, "hotel")}
                  data={{ category: "hotelimages" }}
                  onChange={(data) => handleChange(data, "hotel")}
                >
                  {hotelImages.fileList.length >= 3 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Modal visible={hotelImages.previewVisible} title={hotelImages.previewTitle} footer={null} onCancel={() => handleCancel("hotel")}>
                <img alt="example" style={{ width: "100%" }} src={hotelImages.previewImage} />
              </Modal>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(CityHotel);
