import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const UpdatedComponent = (OriginalComponent) => {
  /* =========== EXCEL START ============ */
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportExcel = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  /* =========== EXCEL  END ============ */

  /* =========== PDF start =============== */

  function exportPDF(fileTitle, fileName, dataHeaders, pdfData) {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = fileTitle;
    const headers = dataHeaders;
    const data = pdfData;

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(fileName);
  }

  /* =========== PDF END =============== */

  const NewComponent = () => {
    return <OriginalComponent exportExcel={exportExcel} exportPdf={exportPDF} />;
  };

  return NewComponent;
};

export default UpdatedComponent;
