import React from "react";
import * as ANTD from "antd";
import "./ContactForm.scss";
import { RightOutlined } from "@ant-design/icons";

const ContactForm = () => {
  const { Option } = ANTD.Select;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  };
  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const [showModal, setShowModal] = React.useState(false);

  const contactFormAlert =
    "Most of the airlines do not allow correcting and changing passenger data. You will find detailed rules and costs in the air fare terms and conditions. Attention! Lack of the special characters on your ticket is not an error, airlines deliberately do not use them.";
  return (
    <div>
      {/* <h3>Are u in contact form pageeeeeeee</h3> */}
      <section className="contact-us-section">
        <div className="container">
          <div className="contact-heading">
            <h2>Contact form - booking service</h2>
            <p>
              Due to the exceptional situation connected with Coronavirus,
              contact us only by using the contact form available below. Thank
              you!
            </p>
          </div>
          <div className="contact-form-section">
            <ANTD.Card bordered={false}>
              <div className="acc-head-section">
                <div className="acc-heading">
                  <div>
                    <h6>How can we help you?</h6>
                  </div>
                </div>
                <div className="hr-block">
                  <hr />
                </div>
              </div>
              <div className="contact-form">
                <ANTD.Form {...layout}>
                  <ANTD.Form.Item label="Category" rules={[{ required: true }]}>
                    <ANTD.Select placeholder="choose">
                      <Option>Choose</Option>
                      <Option>Covid-19</Option>
                      <Option>My booking</Option>
                      <Option>Payment and invoice</Option>
                      <Option>GDPR request</Option>
                      <Option>Complaints</Option>
                      <Option>Rate us</Option>
                    </ANTD.Select>
                  </ANTD.Form.Item>
                  <ANTD.Form.Item
                    label="Message topic:"
                    rules={[{ required: true }]}
                  >
                    <ANTD.Select placeholder="choose">
                      <Option>Choose</Option>
                      <Option>Adding luggage</Option>
                      <Option>Priority boarding</Option>
                      <Option>Seat selection on the plane</Option>
                      <Option>Flight change date</Option>
                      <Option>Correcting or changing passenger data</Option>
                      <Option>Check-in and boarding passes</Option>
                      <Option>Help - I'm on airport</Option>
                      <Option>Ticket return</Option>
                      <Option>Special assistance</Option>
                    </ANTD.Select>
                  </ANTD.Form.Item>
                  <hr />
                  <div>
                    <ANTD.Alert
                      description={contactFormAlert}
                      type="warning"
                      showIcon
                    />
                    <div>
                      <div className="click-modal">
                        <RightOutlined />
                        <a onClick={(e) => setShowModal(true)}>
                          How can I buy a seat selection service?
                        </a>
                        <ANTD.Modal
                          title="How can I buy a seat selection service?"
                          visible={showModal}
                          onCancel={(e) => setShowModal(false)}
                          footer={false}
                        >
                          <p>
                            Airlines booking systems assign seats on the plane
                            randomly. In case of a flight with co-passengers,
                            carriers split up people travelling together (the
                            only exception to this rule is a situation in which
                            a child travels with a guardian). To avoid such
                            inconvenience, it is worth to buy a seat selection
                            service.
                          </p>
                          <br />
                          <p>
                            The service can be bought during booking the ticket
                            (option available only for selected airlines) or by
                            contacting us through the contact form (link below).
                          </p>
                          <br />
                          <p>
                            Booking a selected seat is a paid service and its
                            price depends on the airline and the type of the
                            chosen seat. In response to your message, our
                            consultants will send information about seats
                            availability and prices.
                          </p>
                          <br />
                          <p>
                            Price of the service varies from 5 USD to 28 USD per
                            person (for every flight leg).*
                          </p>
                          <br />
                          <p>
                            Attention! The number of free seats on the plane
                            changes dynamically and the selected seats might be
                            unavailable.
                          </p>
                          <br />
                          <p>
                            * Prices are set by the airlines and might change.
                            Provided values are only indicative.
                          </p>
                        </ANTD.Modal>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="acc-head-section">
                    <div className="acc-heading">
                      <div>
                        <h6>How can we help you?</h6>
                      </div>
                    </div>
                  </div>
                  <ANTD.Form.Item label="First and Last name">
                    <ANTD.Input autoComplete="newpassword" autoComplete="off" />
                  </ANTD.Form.Item>
                  <ANTD.Form.Item label="Phone number">
                    <div className="phone-number">
                      <ANTD.Select
                        placeholder="+1"
                        className="phone-select-prefix"
                      >
                        <Option value="">
                          <div
                            className="phone-select-options"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>Afghanisthan</p>
                            <p>+93</p>
                          </div>
                        </Option>
                        <Option value="">
                          <div
                            className="phone-select-options"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>Canada</p>
                            <p>+1</p>
                          </div>
                        </Option>
                        <Option value="">
                          <div
                            className="phone-select-options"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>Cape Verde</p>
                            <p>+238</p>
                          </div>
                        </Option>
                      </ANTD.Select>
                      <ANTD.Input
                        autoComplete="newpassword"
                        autoComplete="off"
                        placeholder="Number"
                        className="phone-select-suffix"
                      />
                    </div>
                  </ANTD.Form.Item>
                  <ANTD.Form.Item label="E-mail">
                    <ANTD.Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      placeholder="Used to make booking"
                    />
                  </ANTD.Form.Item>
                  <ANTD.Form.Item label="Order number">
                    <ANTD.Input autoComplete="newpassword" autoComplete="off" />
                  </ANTD.Form.Item>
                  <ANTD.Form.Item label="Departure date">
                    <ANTD.DatePicker onChange={onChange} />
                  </ANTD.Form.Item>
                  <ANTD.Form.Item label="Your message">
                    <ANTD.Input.TextArea />
                  </ANTD.Form.Item>
                  <ANTD.Form.Item {...tailLayout}>
                    <ANTD.Checkbox>
                      I agree to the processing of personal data provided in the
                      form.*
                    </ANTD.Checkbox>
                  </ANTD.Form.Item>
                  <ANTD.Form.Item {...tailLayout}>
                    <ANTD.Button className="send-msg-btn">
                      Send message
                    </ANTD.Button>
                  </ANTD.Form.Item>
                  <ANTD.Form.Item {...tailLayout}>
                    <p className="contact-form-text">
                      The administrator of personal data is Travels Providing
                      personal data is voluntary, but necessary to send, receive
                      and archive messages. For more information on the
                      principles of data processing, please refer to the
                      <a href="#!"> Privacy Policy</a>
                    </p>
                  </ANTD.Form.Item>
                </ANTD.Form>
              </div>
            </ANTD.Card>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
