import React, { useState, useEffect } from "react";
import "./Bookingreports.scss";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { Button, Card, Layout, message, Spin, Tabs, Table } from "antd";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import { Link } from "react-router-dom";
const { TabPane } = Tabs;

const { Content } = Layout;

const Bookingreports = () => {
  const { user } = useAuthContext();
  let dateFormat = "DD-MM-YYYY";
  let oriDateFormat = "YYYY-MM-DD";

  const [upBookings, setUpBookings] = useState({
    data: [],
    isloading: false,
  });

  const [comBookings, setComBookings] = useState({
    data: [],
    isloading: false,
  });
  const userId = user ? user.UserID : null;
  const [columns, setColumns] = useState({
    upColumns: [],
    upType: "",
    comColums: [],
    comType: "",
  });

  const renderReffrence = (ref, rec) => {
    const type = rec.type;
    let url = "bus_ticket";
    switch (type) {
      case "Flight": {
        url = "flight_ticket";
        break;
      }
      case "Bus": {
        url = "bus_ticket";
        break;
      }
      case "Hotel": {
        url = "hotel_ticket";
        break;
      }
    }

    return (
      <Link to={url + "?ref=" + ref + "&email=" + rec.guestEmaiId}>
        {ref}
      </Link>
    );
  };

  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "BOOKED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "PARTIALLY CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      default:
        return;
    }
  };

  const hotelColumns = [
    {
      title: "ReferenceNumber",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "	Guest Name",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    { title: "Hotel Name", dataIndex: "HotelName" },
    { title: "City", dataIndex: "City" },
    { title: "NoOfRooms", dataIndex: "NoOfRooms" },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      render: (CheckInDate) => moment(CheckInDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.CheckInDate?.localeCompare(b.CheckInDate),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      render: (CheckOutDate) => moment(CheckOutDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.CheckOutDate?.localeCompare(b.CheckOutDate),
    },
    {
      title: "BookingStatus",
      dataIndex: "BookingStatus",
      // render: (record) => hotelBookingStatus(record.BookingStatus),
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];

  const flightColumns = [
    {
      title: "Reference number",
      dataIndex: "id",
      render: (text, record) => renderReffrence(text, record),
    },

    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight" ? record.oneWaySegment[0].origin : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record.oneWaySegment[length - 1].destination;
        } else {
          return text;
        }
      },
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },

    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },

    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1].departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getFlightStatus(status),
    },
    {
      title: "RefundAmount",
      dataIndex: "RefundAmount",
      key: "RefundAmount",
      render: (refund) => (refund !== "0.00" ? refund : null),
    },
    {
      title: "Guest Name ",
      dataIndex: "guestName",
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => text.adults,
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => text.childCount,
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => text.infantCount,
    },
  ];

  const busColumns = [
    {
      title: "Reference number",
      dataIndex: "id",
      render: (text, record) => renderReffrence(text, record),
    },

    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
    },
    {
      title: "Source",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Destination",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Operator Name",
      dataIndex: "operatorName",
      key: "operatorName",
    },
    {
      title: "Pickup Location",
      dataIndex: "pickUpLocation",
      key: "pickUpLocation",
    },
    {
      title: "Drop Location",
      dataIndex: "dropLocation",
      key: "dropLocation",
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "BookingStatus",
      render: (status) => getBusStatus(status),
    },
    {
      title: "RefundAmount",
      dataIndex: "RefundAmount",
      key: "RefundAmount",
      render: (refund) => (refund !== "0.00" ? refund : null),
    },
    {
      title: "Guest Name ",
      dataIndex: "guestName",
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
    },
  ];

  const allColumns = [
    {
      title: "Reference number",
      dataIndex: "id",
      render: (text, record) => renderReffrence(text, record),
    },
    {
      title: "Service Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight" ? record.oneWaySegment[0].origin : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record.oneWaySegment[length - 1].destination;
        } else {
          return text;
        }
      },
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text, oriDateFormat).format(dateFormat)
          : text,
    },

    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },

    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1].departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },

    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => text.adults,
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => text.childCount,
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => text.infantCount,
    },
    {
      title: "Operator Name",
      dataIndex: "operatorName",
      key: "operatorName",
    },

    {
      title: "Pickup Location",
      dataIndex: "pickUpLocation",
      key: "pickUpLocation",
    },
    {
      title: "Drop Location",
      dataIndex: "dropLocation",
      key: "dropLocation",
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",

      render: (status, record) => {
        return record.type == "Flight"
          ? getFlightStatus(status)
          : getBusStatus(status);
      },
    },
    {
      title: "RefundAmount",
      dataIndex: "RefundAmount",
      key: "RefundAmount",
      render: (refund) => (refund !== "0.00" ? refund : null),
    },
    {
      title: "Guest Name ",
      dataIndex: "guestName",
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
    },
  ];
  const getBusStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "PARTIALLY_CANCELLED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CANCEL_FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }

      default:
        return;
    }
  };

  const getFlightStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "PARTIALLY_CANCELLED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CANCELLATIONREQUESTED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }

      default:
        return;
    }
  };

  const setTableHeaders = (travelType, completed) => {
    switch (travelType) {
      case 1:
        completed
          ? setColumns((prev) => ({
              ...prev,
              comColums: flightColumns,
              comType: "Flight",
            }))
          : setColumns((prev) => ({
              ...prev,
              upColumns: flightColumns,
              upType: "Flight",
            }));
        break;
      case 2:
        completed
          ? setColumns((prev) => ({
              ...prev,
              comColums: hotelColumns,
              comType: "Hotel",
            }))
          : setColumns((prev) => ({
              ...prev,
              upColumns: hotelColumns,
              upType: "Hotel",
            }));
        break;
      case 3:
        completed
          ? setColumns((prev) => ({
              ...prev,
              comColums: busColumns,
              comType: "Bus",
            }))
          : setColumns((prev) => ({
              ...prev,
              upColumns: busColumns,
              upType: "Bus",
            }));

        break;
      default:
        completed
          ? setColumns((prev) => ({
              ...prev,
              comColums: allColumns,
              comType: "All",
            }))
          : setColumns((prev) => ({
              ...prev,
              upColumns: allColumns,
              upType: "All",
            }));
    }
  };

  const fetchBookings = (completed, travelType) => {
    if (completed) {
      setComBookings({ isloading: true, data: [] });
    } else {
      setUpBookings({ isloading: true, data: [] });
    }
    if (!userId) {
      setComBookings({ isloading: false, data: [] });

      setUpBookings({ isloading: false, data: [] });
      return;
    }
    let params = {
      userId: userId,
      travelType: travelType,
      completed: completed,
    };
    setTableHeaders(travelType, completed);
    ApiClient.get("admin/sepratedBookings", params)
      .then((res) => {
        if (res.statusCode == 200) {
          if (completed) {
            setComBookings({ data: res.data, isloading: false });
          } else {
            setUpBookings({ data: res.data, isloading: false });
          }
        } else {
          message.error("Error  From Server");
        }
      })
      .catch((error) => {
        if (completed) {
          setComBookings({ data: [], isloading: false });
        } else {
          setUpBookings({ data: [], isloading: false });
        }
        console.error(error);
      });
  };

  useEffect(() => {
    fetchBookings(0, 0);
    fetchBookings(1, 0);
  }, []);

  return (
    <Layout className="ourteam-bg">
      <div className="manage-markup-section ourteam-bg">
        <Content className="container-fluid cms-pages-width">
          <div className="tabs_Container">
            <Tabs defaultActiveKey="1" type="card" size="small">
              <TabPane tab="Upcoming Trips" key="1">
                <div className="buttons_wrapper">
                  <Button
                    className={columns.upType === "All" ? "active" : ""}
                    onClick={() => fetchBookings(0, 0)}
                  >
                    ALL
                  </Button>
                  <Button
                    className={columns.upType === "Flight" ? "active" : ""}
                    onClick={() => fetchBookings(0, 1)}
                  >
                    Flight
                  </Button>
                  <Button
                    className={columns.upType === "Bus" ? "active" : ""}
                    onClick={() => fetchBookings(0, 3)}
                  >
                    Bus
                  </Button>
                  <Button
                    className={columns.upType === "Hotel" ? "active" : ""}
                    onClick={() => fetchBookings(0, 2)}
                  >
                    Hotel
                  </Button>
                </div>
                <div className="tabs_body">
                  <div className="results_wrapper">
                    <Card bordered={false}>
                      {upBookings.isloading ? (
                        <Spin tip="Loading..." />
                      ) : upBookings.data.length > 0 ? (
                        <>
                          <div className="card-add-heading">
                            <div className="rows-count">
                              <h5>{columns.upType} Reports</h5>
                            </div>
                            <div className="action-images"></div>
                          </div>

                          <Table
                            bordered
                            scroll={{ x: true }}
                            dataSource={upBookings.data}
                            columns={columns.upColumns}
                            pagination={{
                              defaultPageSize: 25,
                              showSizeChanger: true,
                              pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                          />
                        </>
                      ) : (
                        <b>You have no upcoming booking !!</b>
                      )}
                    </Card>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Completed Trips" key="2">
                <div className="buttons_wrapper">
                  <Button
                    className={columns.comType === "All" ? "active" : ""}
                    onClick={() => fetchBookings(1, 0)}
                  >
                    ALL
                  </Button>
                  <Button
                    className={columns.comType === "Flight" ? "active" : ""}
                    onClick={() => fetchBookings(1, 1)}
                  >
                    Flight
                  </Button>
                  <Button
                    className={columns.comType === "Bus" ? "active" : ""}
                    onClick={() => fetchBookings(1, 3)}
                  >
                    Bus
                  </Button>
                  <Button
                    className={columns.comType === "Hotel" ? "active" : ""}
                    onClick={() => fetchBookings(1, 2)}
                  >
                    Hotel
                  </Button>
                </div>
                <div className="tabs_body">
                  <div className="results_wrapper">
                    <Card bordered={false}>
                      {comBookings.isloading ? (
                        <Spin tip="Loading..." />
                      ) : comBookings.data.length > 0 ? (
                        <>
                          <div className="card-add-heading">
                            <div className="rows-count">
                              <h5>{columns.comType} Reports</h5>
                            </div>
                            <div className="action-images"></div>
                          </div>

                          <Table
                            bordered
                            scroll={{ x: true }}
                            dataSource={comBookings.data}
                            columns={columns.comColums}
                            pagination={{
                              defaultPageSize: 25,
                              showSizeChanger: true,
                              pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                          />
                        </>
                      ) : (
                        <b>You have no completed booking !!</b>
                      )}
                    </Card>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Bookingreports;
