import React, { useContext, useState } from "react";
import { Menu, Dropdown, Select, Modal, Drawer } from "antd";
import { Link, useHistory, NavLink } from "react-router-dom";
import { TgContext } from "../providers/TgProvider";
import { useAuthContext } from "../providers/AuthProvider";
import {
  DownOutlined,
  UserOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import "../CombinedNavbar/CombinedNavbar.scss";
import Logo from "../../assets/images/trippozo_bg_overlay.png";
import Login from "../../components/Login/Login";

const CombinedNavbar = (props) => {
  const { logo } = useContext(TgContext);
  const BASE = process.env.REACT_APP_BASE_URL;
  const { setUser } = useAuthContext();
  let history = useHistory();
  let { flag } = props.gscontext.state.isLogin;
  const { updateIsLogin } = props.gscontext;
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);

  const Logout = () => {
    setChangeLoginFlag(false);
    localStorage.clear();
    setUser(null);
    history.push("/");
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const showModal = () => {
    setLoginModalVisible(true);
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profile" onClick={closeDrawer}>
          <i className="fa fa-user-circle v2-pkg1" aria-hidden="true"></i> My
          Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="bookingreports">
        <Link to="/bookingreports" onClick={closeDrawer}>
          <i className="fa fa-ticket v2-pkg1" aria-hidden="true"></i> My
          Bookings
        </Link>
      </Menu.Item>
      <Menu.Item key="traveller-details">
        <Link to="/traveller-details" onClick={closeDrawer}>
          <i className="fa fa-users v2-pkg1" aria-hidden="true"></i> Saved
          Passenger
        </Link>
      </Menu.Item>
      <Menu.Item key="/">
        <Link
          to=""
          onClick={() => {
            closeDrawer();
            Logout();
          }}
        >
          <i className="fa fa-sign-out v2-pkg1" aria-hidden="true"></i> Sign Out
        </Link>
      </Menu.Item>
    </Menu>
  );

  const mobileMenu = (
    <Menu mode="inline">
      <Menu.Item key="/">
        <Link to="/" onClick={closeDrawer}>
          <i className="fa fa-plane v2-pkg1" aria-hidden="true"></i> Flights
        </Link>
      </Menu.Item>
      <Menu.Item key="hotels">
        <Link to="/hotels" onClick={closeDrawer}>
          <i className="fa fa-bed v2-pkg1" aria-hidden="true"></i> Hotels
        </Link>
      </Menu.Item>
      <Menu.Item key="bus">
        <Link to="/bus" onClick={closeDrawer}>
          <i className="fa fa-bus v2-pkg1" aria-hidden="true"></i> Buses
        </Link>
      </Menu.Item>
      <Menu.Item key="offers">
        <Link to="/offers" onClick={closeDrawer}>
          <i className="fa fa-percent v2-pkg1" aria-hidden="true"></i> Offers
        </Link>
      </Menu.Item>
      <Menu.Item key="Webcheckin">
        <Link to="/Webcheckin" onClick={closeDrawer}>
          <i className="fa fa-check-square-o v2-pkg1" aria-hidden="true"></i>{" "}
          Web Checkin
        </Link>
      </Menu.Item>
      {flag
        ? userMenu
        : [
            <Menu.Item key="mytrips">
              <Link to="/mytrips" onClick={closeDrawer}>
                <i className="fa fa-ticket v2-pkg1" aria-hidden="true"></i> My
                Bookings
              </Link>
            </Menu.Item>,
            <Menu.Item
              onClick={() => {
                closeDrawer();
                showModal();
              }}
              key="login"
            >
              <i className="fa fa-sign-in v2-pkg1" aria-hidden="true"></i> Log
              In
            </Menu.Item>,
          ]}
    </Menu>
  );
  return (
    <div>
      <div className="combined-navbar-header">
        <div className="combined-navbar-container">
          <div className="logo">
            <Link to="/">
              <img
                className="desk-top-logo"
                src={logo ? `${BASE + logo.replace("/", "")}` : Logo}
                alt="trippozo-logo"
              />
            </Link>
          </div>

          <Menu className="menu-items head-menu">
            <Menu.Item className="first-mwnu-item md-hide" key="/">
              <Link to="/">
                <i className="fa fa-plane v2-pkg1" aria-hidden="true"></i>
                Flights
              </Link>
            </Menu.Item>
            <Menu.Item className="md-hide" key="hotels">
              <Link to="/hotels">
                <i className="fa fa-bed v2-pkg1" aria-hidden="true"></i>
                Hotels
              </Link>
            </Menu.Item>
            <Menu.Item className="md-hide" key="bus">
              <Link to="/bus">
                <i className="fa fa-bus v2-pkg1" aria-hidden="true"></i>
                Buses
              </Link>
            </Menu.Item>
            <Menu.Item className="md-hide" key="offers">
              <Link to="/offers">
                <i className="fa fa-percent v2-pkg1" aria-hidden="true"></i>
                Offers
              </Link>
            </Menu.Item>
            <Menu.Item className="md-hide" key="bookingreports">
              <Link to={flag ? "/bookingreports" : "/mytrips"}>
                <i className="fa fa-ticket v2-pkg1" aria-hidden="true"></i>
                My Bookings
              </Link>
            </Menu.Item>
            {/* <Menu.Item className="md-hide">
              <Link to="/webcheckin">
                <i className="fa fa-check-square-o v2-pkg1" aria-hidden="true"></i>
                Web Checkin
              </Link>
            </Menu.Item> */}

            <Menu.Item key="account" className="md-show mobile-3line">
              <a
                className="ant-dropdown-link"
                onClick={(e) => {
                  e.preventDefault();
                  setDrawerOpen(true);
                }}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
                <span>MENU</span>
              </a>
              <Drawer
                title={
                  <img
                    src={logo ? `${BASE + logo.replace("/", "")}` : Logo}
                    alt="trippozo-logo"
                  />
                }
                visible={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                closable={false}
                className="tpz-menu-drawer"
              >
                {mobileMenu}
              </Drawer>
            </Menu.Item>

            {!flag ? (
              <Menu.Item
                className="menu-login-item md-hide"
                key="login"
                onClick={showModal}
              >
                <i className="fa fa-sign-in v2-pkg1" aria-hidden="true"></i>
                &nbsp; LOGIN
              </Menu.Item>
            ) : (
              <Menu.Item key="login" className="menu-login-item md-hide">
                <Dropdown overlay={userMenu} trigger={["click"]}>
                  <a
                    className="ant-dropdown-link"
                    style={{ textTransform: "initial" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <UserOutlined />
                    My Account &nbsp;
                    <DownOutlined />
                  </a>
                </Dropdown>
              </Menu.Item>
            )}
          </Menu>

          <ul className="top-menu-support" style={{ display: "none" }}>
            <li>
              <a href="contact_us">Customer Support</a>
            </li>
            <li>
              <a href="mytrips">Track Credit/Refund</a>
            </li>

            <li>
              <div className="mobile-callv3">
                <div className="phone-icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="call-para">
                  <span>+91-11 4221 3221</span>
                  <span>Customer Support</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="second-header-one first-menu-none">
        <div className="inner-wdh-v3">
          <Menu className="menu-items icons-menu-v3">
            <Menu.Item className="v3-menu-mob" key="/">
              <Link to="/">Flight</Link>
            </Menu.Item>

            <Menu.Item className="v3-menu-mob" key="/hotels">
              <Link to="/hotels">Hotel</Link>
            </Menu.Item>
            <Menu.Item className="v3-menu-mob" key="/bus">
              <Link to="/bus">Bus</Link>
            </Menu.Item>
            <Menu.Item className="v3-menu-mob" key="/offers">
              <Link to="/offers">Offers</Link>
            </Menu.Item>
            {!flag ? (
              <Menu.Item className="v3-menu-mob" key="/mytrips">
                <Link to="/mytrips">Manage Booking</Link>
              </Menu.Item>
            ) : null}

            <Menu.Item className="v3-menu-mob" key="/Webcheckin">
              <Link to="/Webcheckin">Web Checkin</Link>
            </Menu.Item>

            <Menu.Item key="account" className="md-v3-account">
              <Dropdown
                // overlay={flag ? userMenu : notloggedMenu}
                overlay={userMenu}
                trigger={["click"]}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  My Account <DownOutlined />
                </a>
              </Dropdown>
            </Menu.Item>
          </Menu>

          <div>
            <Menu className="menu-items icons-menu-v3">
              {!flag ? (
                <Menu.Item
                  className="login-bdr"
                  key="login"
                  onClick={showModal}
                >
                  <a>
                    <i className="fa fa-user" aria-hidden="true"></i>&nbsp;Login{" "}
                  </a>
                </Menu.Item>
              ) : (
                <Menu.Item className="first-mwnu-item v3-menu-mob" key="login">
                  <Dropdown overlay={userMenu} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      <UserOutlined /> My Account{" "}
                      <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </Menu.Item>
              )}
            </Menu>
          </div>
        </div>
      </div>

      <Modal
        visible={loginModalVisible}
        onOk={() => setLoginModalVisible(false)}
        onCancel={() => setLoginModalVisible(false)}
        footer={false}
        width={700}
        className="login-modal"
      >
        <Login
          updateIsLogin={updateIsLogin}
          setLoginModalVisible={setLoginModalVisible}
        />
      </Modal>
    </div>
  );
};
export default CombinedNavbar;
