import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Webcheckin.scss";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import ReactHtmlParser from "react-html-parser";
const Webcheckin = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi web-check-in-page">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container">
              {/*  <ANTD.Row>
                                <ANTD.Col span={24}>
                                    <div className="">

                                        <div className="">
                                            <h2 className="common-headings">Our Team</h2>
                                            <p className="common-paras">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type 
                                                specimen book. It has survived not only five centuries, but also the leap into 
                                                electronic typesetting, remaining essentially unchanged. It was popularised in 
                                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                                and more recently with desktop publishing software like Aldus PageMaker including 
                                                versions of Lorem Ipsum.</p>

                                        </div>

                                    </div>
                                </ANTD.Col>
                            </ANTD.Row> */}
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <ANTD.Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <div className="">
                <div className="">
                  <h2 className="web-heading-check">Web Check In</h2>
                  <span className="span-web-check">
                    Choose any of the Airline for web Check In
                  </span>
                </div>
              </div>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row className="panel-webcheckin">
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-1.png")}
                  alt="Second slide"
                />
                <span>Indigo</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-2.png")}
                  alt="Second slide"
                />
                <span>Spice Jet</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-3.png")}
                  alt="Second slide"
                />
                <span>GoAir</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-4.png")}
                  alt="Second slide"
                />
                <span>Air Vistara</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-5.png")}
                  alt="Second slide"
                />
                <span>Air India</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-7.png")}
                  alt="Second slide"
                />
                <span>Air India Ex</span>
              </div>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-8.png")}
                  alt="Second slide"
                />
                <span>Air Asia</span>
              </div>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-9.png")}
                  alt="Second slide"
                />
                <span>Singapore Air</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-6.png")}
                  alt="Second slide"
                />
                <span>Thai</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-10.png")}
                  alt="Second slide"
                />
                <span>Etihad Airways</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-11.png")}
                  alt="Second slide"
                />
                <span>Malasysia Air</span>
              </div>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <div className="list-web-checkin">
                <img
                  className="list-cc-img-web"
                  src={require("../../assets/images/web-list-12.png")}
                  alt="Second slide"
                />
                <span>Emirates</span>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};

export default Webcheckin;
