import React from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import dateFormat from "dateformat";
import {
  getFlightPrice,
  calculateDuration,
} from "../../AllTickets/NewflightHelper";
import bg from "../../../assets/images/bg/ticketbg.png";
import arrow from "../../../assets/images/bg/rightArrow.png";
// import shield from "../../../assets/images/bg/shield.png";
import baggage from "../../../assets/images/bg/baggage.png";
import moment from "moment";

let uidateFormat = "DD MMM YYYY";

const csssheet = {
  body: {
    margin: 0,
    fontSize: 11,
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid #000",
    padding: 2,
  },

  p: {
    margin: 0,
    fontSize: 11,
  },
};
const styles = StyleSheet.create({
  textDanger: {
    color: "#dc3545",
  },

  mb: {
    marginBottom: 10,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexGrow: 1,
  },

  wHalf: {
    flex: 0.5,
    marginBottom: 10,
  },
  tw: {
    flex: 0.2,
  },
  fourthHalf: {
    flex: 0.25,
    paddingRight: 5,
  },

  width32: {
    flex: 0.32,
  },
  trw: {
    flex: 0.3,
  },
  fw: {
    flex: 0.4,
  },
  fw1: {
    flex: 0.6,
    paddingRight: "5px",
  },
  sw1: {
    flex: 0.4,
  },
  sw: {
    flex: 0.6,
  },
  sew: {
    flex: 0.7,
  },

  mb8: {
    marginBottom: 8,
  },
  description: {
    paddingLeft: 5,
  },

  hr: {
    borderBottom: "1px solid #e2e2e2",
    flex: 1,
    margin: "10px 0",
  },

  page: {
    flexDirection: "row",
    padding: "15px 10px",
  },
  section: {
    flex: 1,
  },
  innerSection: {
    flex: 1,
    position: "relative",
  },
  pageBg: {
    position: "absolute",
    flex: 1,
    width: "100%",
    height: 100,
  },
  busWrapper: {
    padding: "25px 10px 10px 10px",
  },

  whiteText: { color: "#fff", fontSize: 12, marginBottom: 10, fontWeight: 600 },
  blackText: { color: "#000", fontSize: 12, marginBottom: 10, fontWeight: 600 },
  title: { fontSize: 12, fontWeight: 600, wordBreak: "break-all" },
  fadetitle: { color: "#a8a8a8", fontSize: 9 },
  smallText: { fontSize: 10 },
  xsmallText: {
    fontSize: 9,
    marginBottom: 3,
  },
  greytext: { color: "#666", fontSize: 9 },
  card: {
    padding: "10px 5px",
    marginBottom: 10,
    border: "1px solid #e2e2e2",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
  },

  insuranceCard: {
    border: "1px solid #02cb66",
    borderRadius: 6,
    textAlign: "center",
    padding: "10px 5px",
  },

  insurancetitle: {
    color: "#18a160",
    fontSize: 10,
  },

  dottedBorder: {
    borderTop: "1px dotted #a8a8a8",
    width: "100%",
  },

  coverage: {
    color: "#294782",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px 0",
  },

  coveragetitle: {
    color: "#294782",
    fontSize: 9,
  },
});

const getFlightTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <Text style={{ color: "#f9e218" }}>CREATED</Text>;

    case 2:
      return <Text style={{ color: "#FFA500" }}>BLOCKED</Text>;

    case 3:
      return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;

    case 4:
      return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;

    case 5:
      return <Text style={{ color: "#008000" }}>PARTIALLY CANCELLED</Text>;

    case 6:
      return <Text style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</Text>;
    case "CREATED":
      return <Text style={{ color: "#f9e218" }}>CREATED</Text>;

    case "BLOCKED":
      return <Text style={{ color: "#FFA500" }}>BLOCKED</Text>;

    case "CONFIRMED":
      return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;

    case "CANCELLED":
      return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;

    case "PARTIALLYCANCELLED":
      return <Text style={{ color: "#008000" }}>PARTIALLY CANCELLED</Text>;

    case "CANCELLATIONREQUESTED":
      return <Text style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</Text>;
    default:
      return "";
  }
};

const parser = (str) => {
  if (str) {
    let doc = new DOMParser().parseFromString(str, "text/html");

    return doc.firstChild.innerHTML;
  }
};

const FlightDoc = ({ ticketData, fareRulesResp }) => {
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    RefundAmount,
    grandTotal,
    insuranceTotal,
  } = getFlightPrice(ticketData);

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.section}>
          <View style={styles.innerSection}>
            <Image src={bg} style={styles.pageBg} />
            <View style={styles.busWrapper}>
              <Text style={styles.whiteText}>
                {/* {ticketData?.source} To {ticketData?.destination} */}
                {ticketData?.tripType === "roundTrip"
                  ? "Onward Deatils"
                  : `${ticketData?.source} To ${ticketData?.destination}`}
              </Text>

              <View style={styles.card} wrap={false}>
                <View style={styles.row}>
                  <View style={styles.fw1}>
                    <View style={styles.row}>
                      <View style={styles.fw}>
                        <Text style={styles.title}>{ticketData?.source}</Text>
                        <Text style={styles.fadetitle}>
                          {ticketData?.oneWaySegment[0]?.flightCode} -
                          {ticketData?.oneWaySegment[0]?.flightNumber}
                        </Text>
                      </View>
                      <View style={styles.tw}>
                        <Image
                          src={arrow}
                          style={{ width: 16, height: 10, marginTop: 1 }}
                        />
                      </View>

                      <View style={styles.fw}>
                        <Text style={styles.title}>
                          {ticketData?.destination}
                        </Text>
                        <Text style={styles.fadetitle}>
                          {moment(ticketData?.journeyDate).format(uidateFormat)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.sw1}>
                    <View style={styles.row}>
                      {/* <View style={styles.trw}>
                        <Text style={styles.title}>
                          {moment(ticketData?.journeyDate).format(uidateFormat)}
                        </Text>
                        <Text style={styles.fadetitle}>Journey Date</Text>
                      </View> */}
                      <View style={styles.sew}>
                        <Text style={styles.title}>
                          {ticketData.tripType === "roundTrip"
                            ? ticketData.pnr.includes("~")
                              ? ticketData.pnr.split("~")[0]
                              : ticketData.pnr
                            : ticketData.pnr}
                        </Text>
                        <Text style={styles.fadetitle}>PNR/Booking No</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.hr}></View>

                <View style={styles.row}>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>{ticketData?.id}</Text>
                    <Text style={styles.fadetitle}>Ticket Ref Number</Text>
                  </View>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>
                      {getFlightTicketStatus(ticketData?.BookingStatus)}
                    </Text>
                    <Text style={styles.fadetitle}>Status</Text>
                  </View>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>
                      {ticketData?.bookingDate
                        ? moment(ticketData?.bookingDate).format(uidateFormat)
                        : ""}
                    </Text>
                    <Text style={styles.fadetitle}>Booking Date</Text>
                  </View>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>Paid</Text>
                    <Text style={styles.fadetitle}>Payment Status</Text>
                  </View>
                </View>
              </View>

              {/* bus info */}

              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Travel Infromation</Text>
                  <Text style={styles.fadetitle}>
                    Here are the details of your flight from{" "}
                    {ticketData?.oneWaySegment[0]?.origin} to{" "}
                    {
                      ticketData?.oneWaySegment[
                        ticketData?.oneWaySegment.length - 1
                      ].destination
                    }{" "}
                    on {moment(ticketData?.journeyDate).format(uidateFormat)}
                  </Text>
                </View>
                {ticketData?.oneWaySegment?.length > 0
                  ? ticketData?.oneWaySegment.map((flightSegment, key) => (
                      <View
                        key={key + "seg"}
                        style={{ ...styles.row, ...styles.mb }}
                      >
                        <View style={styles.fw}>
                          <Text style={styles.smallText}>
                            {flightSegment.flightCode}
                            {" - "}
                            {flightSegment.flightNumber}
                          </Text>
                        </View>

                        <View style={styles.sw}>
                          <View
                            style={{
                              ...styles.row,
                              alignItems: "flex-start",
                            }}
                          >
                            <View
                              style={{
                                ...styles.width32,
                                textAlign: "center",
                              }}
                            >
                              <Text style={styles.title}>
                                {flightSegment.origin}
                              </Text>

                              <Text style={styles.fadetitle}>
                                {dateFormat(
                                  flightSegment.departureDateTime,
                                  "HH:MM"
                                )}
                              </Text>
                              {flightSegment.departureTerminal ? (
                                <Text style={styles.fadetitle}>
                                  Terminal- {flightSegment.departureTerminal}
                                </Text>
                              ) : null}
                            </View>
                            <View
                              style={{
                                ...styles.width32,
                                margin: "5px 2px 0 0",
                                paddingRight: 5,
                              }}
                            >
                              <View style={styles.dottedBorder}>
                                {/* <Text
                                  style={{
                                    ...styles.fadetitle,
                                    display: "inline",
                                    paddingTop: 3,
                                    textAlign: "center",
                                  }}
                                >
                                  {calculateDuration(
                                    flightSegment.departureDateTime,
                                    flightSegment.arrivalDateTime
                                  )}
                                </Text> */}
                                <Text
                                  style={{
                                    ...styles.fadetitle,
                                    display: "inline",
                                    paddingTop: 3,
                                    textAlign: "center",
                                  }}
                                >
                                  {flightSegment.journeyDuration}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                ...styles.width32,
                                textAlign: "center",
                              }}
                            >
                              <Text style={styles.title}>
                                {flightSegment.destination}
                              </Text>
                              <Text style={styles.fadetitle}>
                                {dateFormat(
                                  flightSegment.arrivalDateTime,
                                  "HH:MM"
                                )}
                              </Text>
                              {flightSegment.arrivalTerminal ? (
                                <Text style={styles.fadetitle}>
                                  Terminal- {flightSegment.arrivalTerminal}
                                </Text>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    ))
                  : null}

                <View style={styles.row}>
                  <View style={styles.width32}>
                    <View style={styles.row}>
                      <View style={{ width: 15 }}>
                        <Image
                          style={{ width: 15, height: 15, marginRight: 5 }}
                          src={baggage}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.smallText}>
                          Baggage Information
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.width32}>
                    <View style={styles.row}>
                      <View style={{ width: 15 }}>
                        <Image
                          style={{ width: 15, height: 15, marginRight: 5 }}
                          src={baggage}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.smallText}>Check-in</Text>
                        <Text
                          style={{
                            ...styles.fadetitle,
                            ...styles.textDanger,
                          }}
                        >
                          {ticketData?.oneWayBaggageInfo?.length > 0
                            ? ticketData?.oneWayBaggageInfo[0]?.BaggageInfo
                            : null}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.width32}>
                    <View style={styles.row}>
                      <View style={{ width: 15 }}>
                        <Image
                          style={{ width: 15, height: 15, marginRight: 5 }}
                          src={baggage}
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.smallText}>Hand Baggage</Text>
                        <Text
                          style={{
                            ...styles.fadetitle,
                            ...styles.textDanger,
                          }}
                        >
                          {ticketData?.oneWayBaggageInfo?.length > 0
                            ? ticketData?.oneWayBaggageInfo[0]?.cabinBaggageInfo
                            : null}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              {ticketData.returnSegment.length > 0 &&
              ticketData.tripType === "roundTrip" ? (
                <>
                  <Text style={styles.blackText}>Return Deatils</Text>
                  <View style={styles.card} wrap={false}>
                    <View style={styles.row}>
                      <View style={styles.fw1}>
                        <View style={styles.row}>
                          <View style={styles.fw}>
                            <Text style={styles.title}>
                              {ticketData?.destination}
                            </Text>
                            <Text style={styles.fadetitle}>
                              {ticketData?.returnSegment[0]?.flightCode} -
                              {ticketData?.returnSegment[0]?.flightNumber}
                            </Text>
                          </View>
                          <View style={styles.tw}>
                            <Image
                              src={arrow}
                              style={{ width: 16, height: 10, marginTop: 1 }}
                            />
                          </View>

                          <View style={styles.fw}>
                            <Text style={styles.title}>
                              {ticketData?.source}
                            </Text>
                            <Text style={styles.fadetitle}>
                              {moment(
                                ticketData.returnSegment[0].departureDateTime
                              ).format(uidateFormat)}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.sw1}>
                        <View style={styles.row}>
                          <View style={styles.sew}>
                            <Text style={styles.title}>
                              {ticketData?.returnPnr
                                ? ticketData?.returnPnr
                                : ticketData?.pnr.includes("~")
                                ? ticketData?.pnr.split("~")[1]
                                : ticketData?.pnr}
                            </Text>
                            <Text style={styles.fadetitle}>PNR/Booking No</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={styles.hr}></View>

                    <View style={styles.row}>
                      <View style={styles.fourthHalf}>
                        <Text style={styles.title}>{ticketData?.id}</Text>
                        <Text style={styles.fadetitle}>Ticket Ref Number</Text>
                      </View>
                      <View style={styles.fourthHalf}>
                        <Text style={styles.title}>
                          {getFlightTicketStatus(ticketData?.BookingStatus)}
                        </Text>
                        <Text style={styles.fadetitle}>Status</Text>
                      </View>
                      <View style={styles.fourthHalf}>
                        <Text style={styles.title}>
                          {ticketData?.bookingDate
                            ? moment(ticketData?.bookingDate).format(
                                uidateFormat
                              )
                            : ""}
                        </Text>
                        <Text style={styles.fadetitle}>Booking Date</Text>
                      </View>
                      <View style={styles.fourthHalf}>
                        <Text style={styles.title}>Paid</Text>
                        <Text style={styles.fadetitle}>Payment Status</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.card} wrap={false}>
                    <View style={styles.mb}>
                      <Text style={styles.title}>Travel Infromation</Text>
                      <Text style={styles.fadetitle}>
                        Here are the details of your flight from{" "}
                        {ticketData?.returnSegment[0]?.origin} to{" "}
                        {
                          ticketData?.returnSegment[
                            ticketData?.returnSegment.length - 1
                          ].destination
                        }{" "}
                        on{" "}
                        {moment(
                          ticketData.returnSegment[0].departureDateTime
                        ).format(uidateFormat)}
                      </Text>
                    </View>
                    {ticketData?.returnSegment?.length > 0
                      ? ticketData?.returnSegment.map((flightSegment, key) => (
                          <View
                            key={key + "seg"}
                            style={{ ...styles.row, ...styles.mb }}
                          >
                            <View style={styles.fw}>
                              <Text style={styles.smallText}>
                                {flightSegment.flightCode}
                                {" - "}
                                {flightSegment.flightNumber}
                              </Text>
                            </View>

                            <View style={styles.sw}>
                              <View
                                style={{
                                  ...styles.row,
                                  alignItems: "flex-start",
                                }}
                              >
                                <View
                                  style={{
                                    ...styles.width32,
                                    textAlign: "center",
                                  }}
                                >
                                  <Text style={styles.title}>
                                    {flightSegment.origin}
                                  </Text>

                                  <Text style={styles.fadetitle}>
                                    {dateFormat(
                                      flightSegment.departureDateTime,
                                      "HH:MM"
                                    )}
                                  </Text>
                                  {flightSegment.departureTerminal ? (
                                    <Text style={styles.fadetitle}>
                                      Terminal-{" "}
                                      {flightSegment.departureTerminal}
                                    </Text>
                                  ) : null}
                                </View>
                                <View
                                  style={{
                                    ...styles.width32,
                                    margin: "5px 2px 0 0",
                                    paddingRight: 5,
                                  }}
                                >
                                  <View style={styles.dottedBorder}>
                                    {/* <Text
                                      style={{
                                        ...styles.fadetitle,
                                        display: "inline",
                                        paddingTop: 3,
                                        textAlign: "center",
                                      }}
                                    >
                                      {calculateDuration(
                                        flightSegment.departureDateTime,
                                        flightSegment.arrivalDateTime
                                      )}
                                    </Text> */}
                                    <Text
                                      style={{
                                        ...styles.fadetitle,
                                        display: "inline",
                                        paddingTop: 3,
                                        textAlign: "center",
                                      }}
                                    >
                                      {flightSegment.journeyDuration}
                                    </Text>
                                  </View>
                                </View>

                                <View
                                  style={{
                                    ...styles.width32,
                                    textAlign: "center",
                                  }}
                                >
                                  <Text style={styles.title}>
                                    {flightSegment.destination}
                                  </Text>
                                  <Text style={styles.fadetitle}>
                                    {dateFormat(
                                      flightSegment.arrivalDateTime,
                                      "HH:MM"
                                    )}
                                  </Text>
                                  {flightSegment.arrivalTerminal ? (
                                    <Text style={styles.fadetitle}>
                                      Terminal- {flightSegment.arrivalTerminal}
                                    </Text>
                                  ) : null}
                                </View>
                              </View>
                            </View>
                          </View>
                        ))
                      : null}

                    <View style={styles.row}>
                      <View style={styles.width32}>
                        <View style={styles.row}>
                          <View style={{ width: 15 }}>
                            <Image
                              style={{ width: 15, height: 15, marginRight: 5 }}
                              src={baggage}
                            />
                          </View>
                          <View style={{ flex: 1 }}>
                            <Text style={styles.smallText}>
                              Baggage Information
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.width32}>
                        <View style={styles.row}>
                          <View style={{ width: 15 }}>
                            <Image
                              style={{ width: 15, height: 15, marginRight: 5 }}
                              src={baggage}
                            />
                          </View>
                          <View style={{ flex: 1 }}>
                            <Text style={styles.smallText}>Check-in</Text>
                            <Text
                              style={{
                                ...styles.fadetitle,
                                ...styles.textDanger,
                              }}
                            >
                              {ticketData?.returnBaggageInfo?.length > 0
                                ? ticketData?.returnBaggageInfo[0]?.BaggageInfo
                                : null}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.width32}>
                        <View style={styles.row}>
                          <View style={{ width: 15 }}>
                            <Image
                              style={{ width: 15, height: 15, marginRight: 5 }}
                              src={baggage}
                            />
                          </View>
                          <View style={{ flex: 1 }}>
                            <Text style={styles.smallText}>Hand Baggage</Text>
                            <Text
                              style={{
                                ...styles.fadetitle,
                                ...styles.textDanger,
                              }}
                            >
                              {ticketData?.returnBaggageInfo?.length > 0
                                ? ticketData?.returnBaggageInfo[0]
                                    ?.cabinBaggageInfo
                                : null}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : null}

              {/* Contact Details */}

              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Contact Details</Text>
                  <Text style={styles.fadetitle}>
                    Any Communication by airlines will be sent these details
                  </Text>
                </View>

                <View style={styles.row}>
                  <View style={styles.wHalf}>
                    <Text style={styles.fadetitle}>Name</Text>
                    <Text style={styles.smallText}>
                      {ticketData?.guestName}
                    </Text>
                  </View>

                  <View style={styles.wHalf}>
                    <Text style={styles.fadetitle}>Mobile Number</Text>
                    <Text style={styles.smallText}>
                      {ticketData?.guestMobileNo}
                    </Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.wHalf}>
                    <Text style={styles.fadetitle}>Email</Text>
                    <Text style={styles.smallText}>
                      {ticketData?.guestEmaiId}
                    </Text>
                  </View>

                  <View style={styles.wHalf}>
                    <Text style={styles.fadetitle}>Home Address</Text>
                    {ticketData?.passengers?.length > 0 ? (
                      <Text style={styles.smallText}>
                        {ticketData?.passengers[0]?.address},{" "}
                        {ticketData?.passengers[0]?.passengerNationality}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </View>

              {/* pax details */}

              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Passengers Details</Text>
                </View>
                {ticketData?.passengers?.length > 0
                  ? ticketData?.passengers.map((item, i) => (
                      <View style={styles.row} key={i + "pax"}>
                        <View style={styles.width32}>
                          <Text style={styles.fadetitle}>Name</Text>
                          <Text style={styles.smallText}>
                            {item.firstName} {item.lastName}
                          </Text>
                        </View>

                        <View style={styles.width32}>
                          <Text style={styles.fadetitle}>DOB</Text>
                          <Text style={styles.smallText}>
                            {moment(item.dob).format(uidateFormat)}
                          </Text>
                        </View>
                        <View style={styles.width32}>
                          <Text style={styles.fadetitle}>Passenger Type</Text>
                          <Text style={styles.smallText}>
                            {item.paxType === "ADT"
                              ? "Adult"
                              : item.paxType === "CHD"
                              ? "Child"
                              : "Infant"}
                          </Text>
                        </View>
                      </View>
                    ))
                  : null}
              </View>

              {/* fare */}

              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Fare Details</Text>
                </View>

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Base Fare</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {baseAmount}</Text>
                  </View>
                </View>

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Surge and Taxes:YQ:</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {taxAmount}</Text>
                  </View>
                </View>

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Convenience Fee</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {convienenceFee}</Text>
                  </View>
                </View>

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Discount</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {discount}</Text>
                  </View>
                </View>

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Total</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {grandTotal}</Text>
                  </View>
                </View>

                {ticketData.BookingStatus !== "CONFIRMED" ? (
                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>RefundAmount</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {RefundAmount}</Text>
                    </View>
                  </View>
                ) : null}
              </View>
              {/* terms */}
              <View style={styles.card} wrap={true}>
                <View style={styles.mb}>
                  <Text style={styles.title}>{"Terms & Conditions"}</Text>
                </View>

                {!fareRulesResp ? null : (
                  <Html stylesheet={csssheet}>
                    {parser(fareRulesResp?.data?.fareRules[0]?.ruleDetails)}
                  </Html>
                )}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FlightDoc;
