import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Spin,
  Select,
  Table,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import "../../components/Bookingreports/Bookingreports.scss";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import exchange from "../assets/vector-icons/exchange.png";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import { exportExcel } from "../ExportExcel";
import queryString from "query-string";
import {
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;
let uiDateFormat = "DD-MM-YYYY";

const SuperBookingreports = () => {
  const [form] = Form.useForm();
  let dateFormat = "DD-MM-YYYY";
  let oriDateFormat = "YYYY-MM-DD";
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [travelType, setTravelType] = useState(0);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [toDisableDate, setToDisableDate] = useState(moment());
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cardInfo, setCardInfo] = useState({
    cardType: "",
    cardNumber: "",
    expirationDate: "",
    securityCode: "",
  });

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    setParamValue(params);
  }, []);

  const setParamValue = (params) => {
    if (params && params.edit === "edit") {
      if (params.dateType === "1") {
        handleTimeAndDate(params.dateType);
        form.setFieldsValue({
          dateType: params.dateType,
          travelType: Number(params.travelType),
          fromDate: moment(params.fromDate, oriDateFormat),
          toDate: moment(params.toDate, oriDateFormat),
        });
        setShowTable(true);
        setDataSource([]);
        setTableHeaders(Number(params.travelType));
        getBookingListData(params);
      }
    }
  };

  const renderReffrence = (ref, rec) => {
    const type = rec.type;
    let url = "bus_ticket";
    switch (type) {
      case "Flight": {
        url = "super_flight_ticket";
        break;
      }
      case "Bus": {
        url = "super_bus_ticket";
        break;
      }
      case "Hotel": {
        url = "super_hotel_ticket";
        break;
      }
    }

    return rec.guestEmaiId ? (
      <Link
        to={url + "?ref=" + ref + "&email=" + rec.guestEmaiId}
        target="_blank"
      >
        {ref}
      </Link>
    ) : (
      { ref }
    );
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    const excelData = dataSource;
    exportExcel(excelData, "Booking_Reports");
  };

  const getHotelStatus = (status) => {
    switch (status) {
      case 1: {
        return <p style={{ color: "#FFA500" }}>FAILED</p>;
      }
      case 2: {
        return <p style={{ color: "#008000" }}>CONFIRMED</p>;
      }
      case 3: {
        return <p style={{ color: "#FF0000" }}>CANCELED</p>;
      }
      case 4: {
        return <p style={{ color: "#FF0000" }}>PENDING</p>;
      }
      case 5: {
        return <p style={{ color: "#f9e218" }}>REJECTED</p>;
      }
      case 6: {
        return <p style={{ color: "#FF0000" }}>HOLD</p>;
      }
      case 7: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
      }
      case 8: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
      }
      case 9: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FFA500" }}>FAILED</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>CONFIRMED</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "#FF0000" }}>CANCELED</p>;
      }
      case "PENDING": {
        return <p style={{ color: "#FF0000" }}>PENDING</p>;
      }
      case "REJECTED": {
        return <p style={{ color: "#f9e218" }}>REJECTED</p>;
      }
      case "HOLD": {
        return <p style={{ color: "#FF0000" }}>HOLD</p>;
      }
      case "CANCELLATIONREQUESTED": {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
      }
      case "CANCELLATIONPENDING": {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
      }
      case "CANCELLATIONPROGRESS": {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
      }

      default:
        return;
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "BOOKED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "HOLD": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "PARTIALLY CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "ALREADYCANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "PENDING": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "REJECTED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CancellationRequest": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CancellationPending": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CancellationInProgress": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      default:
        return;
    }
  };

  const getBusStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "PARTIALLY_CANCELLED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CANCEL_FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      default:
        return;
    }
  };

  const getFlightStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "PARTIALLY_CANCELLED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CANCELLATIONREQUESTED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }

      default:
        return;
    }
  };

  const flightColumns = [
    {
      title: "Reference number",
      dataIndex: "id",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
      sorter: (a, b) => a.bookingDate.localeCompare(b.bookingDate),
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      sorter: (a, b) => a.provider.localeCompare(b.provider),
    },
    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight" ? record.oneWaySegment[0].origin : text;
      },
      sorter: (a, b) => {
        let valA = a.type == "Flight" ? a.oneWaySegment[0].origin : "";
        let valB = b.type == "Flight" ? b.oneWaySegment[0].origin : "";

        return valA.localeCompare(valB);
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record.oneWaySegment[length - 1].destination;
        } else {
          return text;
        }
      },
      sorter: (a, b) => {
        let valA =
          a.type == "Flight"
            ? a.oneWaySegment[a.oneWaySegment.length - 1].destination
            : "";
        let valB =
          b.type == "Flight"
            ? b.oneWaySegment[b.oneWaySegment.length - 1].destination
            : "";

        return valA.localeCompare(valB);
      },
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
      sorter: (a, b) => a.journeyDate.localeCompare(b.journeyDate),
    },

    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },

    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1].departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getFlightStatus(status),
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName.localeCompare(b.guestName),
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId.localeCompare(b.guestEmaiId),
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo.localeCompare(b.guestMobileNo),
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => text.adults,
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => text.childCount,
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => text.infantCount,
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "CollectedAmount",
      dataIndex: "TotalPrice",
    },
    {
      title: "MarkUp",
      dataIndex: "markup",
    },
  ];

  const hotelColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      key: "CheckInDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      key: "CheckOutDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "HotelName",
      dataIndex: "HotelName",
      key: "HotelName",
    },

    // {
    //   title: "Operator Name",
    //   dataIndex: "operatorName",
    //   key: "operatorName",
    // },

    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "BookingStatus",
      render: (status) => getHotelStatus(status),
    },
    {
      title: "Rooms",
      dataIndex: "NoOfRooms",
    },
    {
      title: "Adult Count",
      dataIndex: "pax",
      render: (value) => value.adults,
    },
    {
      title: "Child Count",
      dataIndex: "pax",
      render: (value) => value.childCount,
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Star Rating",
      dataIndex: "StarRating",
    },
    {
      title: "Fare",
      dataIndex: "Fare",
    },
  ];
  const busColumns = [
    {
      title: "Reference number",
      dataIndex: "id",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
      sorter: (a, b) => a.bookingDate.localeCompare(b.bookingDate),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      sorter: (a, b) => a.journeyDate.localeCompare(b.journeyDate),
    },
    {
      title: "Source",
      dataIndex: "from",
      key: "from",
      sorter: (a, b) => a.from.localeCompare(b.from),
    },
    {
      title: "Destination",
      dataIndex: "to",
      key: "to",
      sorter: (a, b) => a.to.localeCompare(b.to),
    },
    {
      title: "Operator Name",
      dataIndex: "operatorName",
      key: "operatorName",
      sorter: (a, b) => a.operatorName.localeCompare(b.operatorName),
    },
    {
      title: "Pickup Location",
      dataIndex: "pickUpLocation",
      key: "pickUpLocation",
      sorter: (a, b) => a.pickUpLocation.localeCompare(b.pickUpLocation),
    },
    {
      title: "Drop Location",
      dataIndex: "dropLocation",
      key: "dropLocation",
      sorter: (a, b) => a.dropLocation.localeCompare(b.dropLocation),
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "BookingStatus",
      render: (status) => getBusStatus(status),
    },
    {
      title: "RefundAmount",
      dataIndex: "RefundAmount",
      key: "RefundAmount",
    },
    {
      title: "Guest Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName.localeCompare(b.guestName),
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId.localeCompare(b.guestEmaiId),
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo.localeCompare(b.guestMobileNo),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "CollectedAmount",
      dataIndex: "TotalPrice",
    },
    {
      title: "MarkUp",
      dataIndex: "markup",
      sorter: (a, b) => a.markup.localeCompare(b.markup),
    },
  ];

  const allColumns = [
    {
      title: "Reference number",
      dataIndex: "id",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    // {
    //   title: "Reference number",
    //   render: (record) => {
    //     return record.type == "Flight"
    //       ? renderReffrence(record.id, record)
    //       : renderReffrence(record.RefNumber, record);
    //   },
    // },
    {
      title: "Service Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight"
          ? record?.oneWaySegment[0]?.origin
          : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record?.oneWaySegment[length - 1]?.destination;
        } else {
          return "";
        }
      },
    },
    {
      title: "HotelName",
      dataIndex: "HotelName",
      key: "HotelName",
      render: (text, rec) => (rec.type == "Hotel" ? rec.HotelName : text),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : null),
    },
    {
      title: "Arrival",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight" ? text[0]?.arrivalTerminal : "",
    },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0]?.arrivalDateTime, oriDateFormat).format(dateFormat)
          : "",
    },

    {
      title: "Departure",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return text[length - 1]?.departureTerminal;
        } else {
          return "";
        }
      },
    },
    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1]?.departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return "";
        }
      },
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      key: "CheckInDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      key: "CheckOutDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },

    {
      title: "Adults",
      // dataIndex: "pax",
      // render: (text) => text.adults,
      render: (record) =>
        record.type == "Flight" ? record.pax.adults : record.AdultCount,
    },
    {
      title: "Childs",
      //dataIndex: "pax",
      // render: (text) => text.childCount,
      render: (text, record) =>
        record.type == "Flight" ? record.pax.childCount : record.ChildCount,
    },
    {
      title: "Infant",
      dataIndex: "pax",
      //render: (text) => text.infantCount,
      render: (text, record) =>
        record.type == "Flight" ? text.infantCount : 0,
    },
    {
      title: "Operator Name",
      dataIndex: "operatorName",
      key: "operatorName",
    },

    // {
    //   title: "Pickup Location",
    //   dataIndex: "pickUpLocation",
    //   key: "pickUpLocation",
    // },
    // {
    //   title: "Drop Location",
    //   dataIndex: "dropLocation",
    //   key: "dropLocation",
    // },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",
      // render: (status) => getStatus(status),
      render: (status, record) => {
        return record.type == "Flight"
          ? getStatus(status)
          : getHotelStatus(status);
      },
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Payment Mode",
      dataIndex: "PartPayment",
      render: (PartPayment) =>
        PartPayment?.isPartPayment ? "Part Payment" : "Full Payment",
    },
    {
      title: "Due Amount",
      dataIndex: "PartPayment",
      render: (PartPayment) =>
        PartPayment?.dueAmount ? (
          <p style={{ color: "#FF0000", fontWeight: "600" }}>
            {Number(PartPayment?.dueAmount).toFixed(2)}
          </p>
        ) : (
          ""
        ),
    },
    {
      title: "Part Payment Amount",
      dataIndex: "PartPayment",
      render: (PartPayment) => PartPayment?.partPaymentAmount ?? "",
    },
    {
      title: "Total Amount",
      dataIndex: "serviceTotal",
    },
  ];

  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 3:
        setColumns(busColumns);
        break;
      case 2:
        setColumns(hotelColumns);
        break;
      default:
        setColumns(allColumns);
    }
  };

  const getBookingListData = (values) => {
    setLoading(true);

    ApiClient.get("admin/myBookings/report", values)
      .then((res) => {
        setLoading(false);

        if (res.statusCode == 200) {
          setDataSource(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    let prevData = dataSource;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(prevData);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {
    setShowTable(true);
    if (values.dateType == "0") {
      values.fromDate = moment(new Date().getTime() - 604800000).format(
        oriDateFormat
      );
      values.toDate = moment().format(oriDateFormat);
    } else if (values.dateType == "1") {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    if (values.journeyDate) {
      values.journeyDate = moment(values.journeyDate).format(oriDateFormat);
    } else {
      values.journeyDate = "";
    }

    setDataSource([]);
    setTableHeaders(values.travelType);
    delete values.dateType;

    getBookingListData(values);
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };
  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                >
                  <Panel header="Booking Reports" key="1">
                    <Form
                      form={form}
                      {...formItemLayout}
                      layout={formLayout}
                      initialValues={{
                        options: 1,
                        bookingStatus: "",
                        emailId: "",
                        journeyDate: "",
                        paxName: "",
                        phoneNumber: "",
                        referenceNo: "",
                        fareType: "",
                        travelType: 0,
                        Role: 0,
                        tripType: "",
                      }}
                      onFinish={onFormSubmit}
                    >
                      <Row gutter={16}>
                        <Col className="gutter-row" md={8} xs={24}>
                          <Form.Item label="Time and Date" name="options">
                            <Select
                              autoComplete="newpassword"
                              autoComplete="off"
                              placeholder="Please select"
                              onChange={handleTimeAndDate}
                            >
                              <Option value={1}>Last One Hour</Option>
                              <Option value={2}>Yesterday</Option>

                              <Option value={5}>Custom Dates</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {isDateDisplay ? (
                          <>
                            <Col className="gutter-row" md={8} xs={24}>
                              <Form.Item label="From Date" name="fromDate">
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  format={uiDateFormat}
                                  disabledDate={disabledFutureDate}
                                  onChange={(date, dateString) =>
                                    onChangeFromDate(date, dateString)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={8} xs={24}>
                              <Form.Item label="To Date" name="toDate">
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  disabledDate={disabledSelectedDate}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : null}
                        {/* <Col md={8} xs={24}>
                              <Form.Item label="Pax Name" name="paxName">
                                <Input
 autoComplete="newpassword" autoComplete="off"  />
                              </Form.Item>
                            </Col> */}
                        <Col md={8} xs={24}>
                          <Form.Item label="Reports of" name="Role">
                            <Select
                              autoComplete="newpassword"
                              autoComplete="off"
                              placeholder="Select"
                            >
                              <Option value={0}>All</Option>
                              <Option value={2}>Users</Option>
                              <Option value={4}>Guest</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" md={8} xs={24}>
                          <Form.Item label="Service" name="travelType">
                            <Select
                              autoComplete="newpassword"
                              autoComplete="off"
                              placeholder="Select"
                              onSelect={(val) => setTravelType(val)}
                            >
                              <Option value={0}>All</Option>
                              <Option value={1}>Flights</Option>
                              <Option value={2}>Hotels</Option>
                              <Option value={3}>Buses</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {/* <Col md={8} xs={24}>
                              <Form.Item
                                label="Service Type"
                                name="serviceType"
                              >
                                <Select
 autoComplete="newpassword" autoComplete="off"  placeholder="Select Service Type">
                                  <Option value={0}>All</Option>
                                  <Option value={1}>Flights</Option>
                                  <Option value={2}>Buses</Option>
                                </Select>
                              </Form.Item>
                            </Col> */}
                        {travelType === 1 ? (
                          <>
                            <Col md={8} xs={24}>
                              <Form.Item label="Coupan Type" name="fareType">
                                <Select placeholder="Select Travel Type">
                                  <Option value="PUBLISH">Publish</Option>
                                  <Option value="GENERAL">General </Option>
                                  <Option value="COUPONFARE">
                                    Coupon Fare
                                  </Option>
                                  <Option value="CORPORATEFARE">
                                    Corporate Fare
                                  </Option>
                                  <Option value="SMEFARE">SME Fare</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item label="Trip Type" name="tripType">
                                <Select placeholder="Select">
                                  <Option value="oneWay">One way</Option>
                                  <Option value="roundTrip">Round Trip</Option>
                                </Select>
                              </Form.Item>
                            </Col>{" "}
                          </>
                        ) : null}

                        <Col md={8} xs={24}>
                          <Form.Item
                            label="Reference number"
                            name="referenceNo"
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              placeholder="Enter Reference Number"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Journey Date" name="journeyDate">
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select Journey date"
                              format={dateFormat}
                            />
                          </Form.Item>
                        </Col>

                        {/* <Col md={8} xs={24}>
                              <Form.Item label="PNR Number" name="pnr">
                                <Input
 autoComplete="newpassword" autoComplete="off"  />
                              </Form.Item>
                            </Col> */}
                        <Col md={8} xs={24}>
                          <Form.Item
                            label="Pax Email"
                            name="emailId"
                            rules={[{ type: "email" }]}
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item
                            label="Pax Mobile"
                            name="phoneNumber"
                            rules={[
                              {
                                minLength: 10,
                                maxLength: 10,
                                pattern: "^[0-9]{10}$",
                                message: "Must be 10 digits",
                              },
                            ]}
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              placeholder=""
                            />
                          </Form.Item>
                        </Col>

                        <Col md={8} xs={24}>
                          <Form.Item
                            label="Booking Status"
                            name="bookingStatus"
                          >
                            <Select
                              autoComplete="newpassword"
                              autoComplete="off"
                              placeholder="Please Select"
                            >
                              {/* <Option value={1}>Created </Option>
                              <Option value={2}>Blocked</Option> */}
                              <Option value={3}>Booked</Option>
                              <Option value={5}>Canceled</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="reports-btns">
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={() => {
                            form.resetFields();
                            setTravelType(0);
                          }}
                        >
                          Reset
                        </Button>
                      </Row>
                    </Form>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {dataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <h5>View Reports {dataSource.length}</h5>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                autoComplete="newpassword"
                                autoComplete="off"
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>

                        <Table
                          scroll={{ x: true }}
                          bordered
                          dataSource={dataSource}
                          columns={columns}
                          rowKey={keyGenerate}
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "25", "50", "100", "125"],
                          }}
                        />
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>

          <Modal
            className="admin_modal"
            visible={showCardInfo}
            title="Card Details"
            style={{ textAlign: "center" }}
            onCancel={(e) => {
              setShowCardInfo(false);
            }}
            footer={false}
          >
            <div style={{ textAlign: "left" }}>
              <p>
                <b>Card Type : </b> {cardInfo.cardType ? cardInfo.cardType : ""}
              </p>
              <p>
                <b>Card Number : </b>{" "}
                {cardInfo.cardNumber ? cardInfo.cardNumber : ""}
              </p>
              <p>
                <b>Expiration Date : </b>{" "}
                {cardInfo.expirationDate ? cardInfo.expirationDate : ""}
              </p>
              <p>
                <b>Security Code : </b>{" "}
                {cardInfo.securityCode ? cardInfo.securityCode : ""}
              </p>
            </div>
          </Modal>
        </Content>
      </div>
    </Layout>
  );
};

export default SuperBookingreports;
