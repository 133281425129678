import React, { useContext, useEffect } from "react";
import { Card, Carousel, Col, Collapse, Form, Row } from "antd";

import { useHistory } from "react-router-dom";

import { TgContext } from "../../common/providers/TgProvider";
import CombinedBanner from "../CombinedBanner/CombinedBanner";

import "./FlightHome.scss";
import FlightSearch from "../FlightSearch/FlightSearch";
import queryString from "query-string";

import Deals from "./Deals";
import Greatdeals from "../Greatdeals/Greatdeals";
import TopFlights from "./TopFlights/TopFlights";
import Offers from "../PromoOffers/Offers";

const { Panel } = Collapse;

const FlightHome = (props) => {
  const [subsribeForm] = Form.useForm();
  let history = useHistory();
  const { updateTabKey } = useContext(TgContext);
  const oriDateString = "YYYY-MM-DD";

  // const {
  //   updataCombineSearchObj,
  //   RemovePromo,
  //   state: { combineSearchData },
  // } = props.gscontext;

  // const { combineSearchData } = props.gscontext.state;
  const subsribeData = {
    DeviceOS: 3,
    ApplicationType: 1,
    Email: "string",
    Status: 0,
    CreatedBy: 0,
    DeviceOSVersion: "string",
    DeviceToken: "string",
    FirBaseToken: "string",
  };

  // const handleSubscibe = (value) => {
  //   let data = {
  //     ...subsribeData,
  //     ...value,
  //   };
  //   APIClient.post("admin/subscriberReport", data)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         message.success("Subscibed SuccessFully", 3);
  //         subsribeForm.resetFields();
  //       } else if (response.status == 400) {
  //         message.error("This Email Address Has Already Subscribed.", 3);
  //       } else if (response.status == 404) {
  //         message.error("Failed", 3);
  //       } else {
  //         message.error("Something Went Wrong", 3);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const searchTopCities = (data, tabKey) => {
  //   let tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  //   updataCombineSearchObj("origin", data.origin);
  //   updataCombineSearchObj("destination", data.destination);
  //   updataCombineSearchObj("fromDate", tomorrow);
  //   updataCombineSearchObj("toDate", "");

  //   let query = {
  //     adult: 1,
  //     child: 0,
  //     class: "Economy",
  //     from: tomorrow,
  //     infant: 0,
  //     to: "",
  //     tripType: "oneWay",
  //     origin: `${data.origin.name}-${data.origin.airports.airportCodeIATA}`,
  //     destination: `${data.destination.name}-${data.destination.airports.airportCodeIATA}`,
  //   };
  //   updateTabKey(tabKey);

  //   let queryParams = queryString.stringify(query);
  //   history.push("/flightresults?" + queryParams);
  // };

  // useEffect(() => {
  //   RemovePromo();
  //   updataCombineSearchObj("origin", []);
  //   updataCombineSearchObj("destination", []);
  //   updataCombineSearchObj("tripType", "oneWay");
  //   updataCombineSearchObj("classType", "Economy");

  //   if (combineSearchData)
  //     if (combineSearchData.fromDate) {
  //       if (
  //         moment(combineSearchData.fromDate, oriDateString) <
  //         moment().startOf("day")
  //       )
  //         updataCombineSearchObj("fromDate", moment().format(oriDateString));
  //     }
  // }, []);

  return (
    <div className="combined-home">
      <CombinedBanner />

      <div className="wrapper-one-1">
        <div className="search-wrapper search-wrap-two">
          <FlightSearch
            gscontext={props.gscontext}
            flightContext={props.flightContext}
            reset={true}
          />
        </div>
      </div>

      <div className="content-wrapper v3-wrapper-22">
        <div className="content-cards">
          <div className="site-card-wrapper">
            <Row>
              <Col lg={8} xs={24} className="cards-flex tarvel-22">
                <Card bordered={false} className="home-cards">
                  {/* <img
                    src={require("../../assets/images/pkg-mv-1.png")}
                    alt="search-img"
                  /> */}
                  <h3>Search</h3>
                  <p>
                    Search with our easy to use interface & get diverse search
                    results to suit your travel needs
                  </p>
                </Card>
              </Col>
              <Col lg={8} xs={24} className="cards-flex tarvel-22">
                <Card bordered={false} className="home-cards">
                  {/* <img
                    src={require("../../assets/images/pkg-mv-2.png")}
                    alt="compare-img"
                  /> */}
                  <h3>Compare</h3>
                  <p>
                    Compare between different travel modes & different service
                    providers with a range of price & convenience options
                  </p>
                </Card>
              </Col>
              <Col lg={8} xs={24} className="cards-flex tarvel-22">
                <Card bordered={false} className="home-cards">
                  {/* <img
                    src={require("../../assets/images/pkg-mv-3.png")}
                    alt="book-img"
                  /> */}
                  <h3>Book</h3>
                  <p>
                    Easy to book in few clicks with different modes of payment
                    which are completely safe & 100% secure
                  </p>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className="planning-block">
          <Row className="planning-block-row">
            <Col lg={10} xs={24} className="image-container"></Col>
            <Col lg={14} xs={24} className="planning-text planning-text-mobile">
              <h1>The APML App Makes Travel Planning Easy</h1>
              <p>
                We’ll get you where you want to go. Our innovative app is the
                ideal way to plan and keep track of your travel.
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <div className="adv-banner">
        <div className="adv-banner-container">
          <Row gutter={16} className="adv-row">
            <Col md={12}>
              <div className="adv-text">
                <h3>The APML App Makes Travel Planning Easy</h3>
                <p>
                  Compare your options: Buses, Flights all in one search. We’ll
                  get you where you want to go. Find the best way to get from
                  anywhere in India, on your web or mobile. We bring larger
                  inventory from all sources to your finger tips.
                </p>
              </div>
              <div className="play-store-btns">
                <a href="#">
                  <img
                    src={require("../../../src/assets/images/playstore.png")}
                    alt="playstore Icon"
                  />
                </a>
              </div>
            </Col>
            <Col md={12} className="image-carousel">
              <Carousel autoplay dots={false}>
                {/* <div>
                  <img
                    src={require("../../../src/assets/images/apml-suvidha.png")}
                  />
                </div>
                <div>
                  <img
                    src={require("../../../src/assets/images/apml-suvidha.png")}
                  />
                </div>
                <div>
                  <img
                    src={require("../../../src/assets/images/apml-suvidha.png")}
                  />
                </div> */}
              </Carousel>
            </Col>
          </Row>
        </div>
      </div>

      {/* <div className="directly-flight-ofc-v3">
        <Row>
          <Col md={24} xs={24}>
            <div>
              <img
                className="ofc-panel-offers"
                src={require("../../assets/images/map-view-img.png")}
              />
            </div>
          </Col>
        </Row>
      </div> */}

      <div className="directly-flight-ofc-v3">
        <div className="position-delas-1">
          <div className="position-delas-2">
            <Row>
              <Col md={24} xs={24}>
                <Greatdeals />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="directly-flight-ofc-v3">
        <Row>
          <Col md={24} xs={24}>
            <Deals
            //  updataCombineSearchObj={updataCombineSearchObj}
            />
          </Col>
        </Row>
      </div>

      <div className="offers-promo-one">
        <Row>
          <Col md={24} xs={24}>
            <Offers promoType={1} />
          </Col>
        </Row>
      </div>

      {/* top-routes--open */}

      <div className="directly-flight-ofc-line">
        <Row>
          <Col md={24} xs={24}>
            <div className="fli-v1">
              <h5>Book tickets directly with us</h5>
              <p>
                Make your travel companion to not only fly on-time but to also
                enjoy hassle-free and courteous service on board and beyond. On
                making a flight booking directly with us, you can enjoy the
                lowest booking and cancellation fee, exclusive flight offers and
                add-ons, along with a personalised and smooth experience. You
                can also manage your bookings on the go.
              </p>
            </div>
          </Col>
          <Col md={24} xs={24}>
            <div className="fli-v1">
              <h5>Largest Network of global Airfares</h5>
              <p>
                Find fares from Asia, Middle East, SouthEast Asia, USA and Uk
                markets for best flight deals. You can choose from our domestic
                flights to all destinations in India or book discounted air
                tickets to all international destinations around the world. Not
                only this, we push offers frequently to for sale on our website.
                With a vast experience in industry, we can support on all your
                queries.
              </p>
            </div>
          </Col>
          <Col md={24} xs={24}>
            <div className="fli-v1">
              <h5>What's Exciting?</h5>
              <p>
                We offer various discounts on festival seasons, peak days to
                make your journey more comfortable. Stay up to date with our
                latest flight offers and enjoy additional savings with exclusive
                cashback and discounts on booking directly with us.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="directly-flight-ofc-line">
        <Row>
          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>Domestic Airlines</h5>
              <p className="dm-flight">
                <a href="#">Flights Discount Coupons |</a>&nbsp;
                <a href="#">Domestic Airlines |</a>&nbsp;
                <a href="#">Indigo Airlines |</a>&nbsp;
                <a href="#">Air Asia |</a>&nbsp;
                <a href="#">SpiceJet, GoAir |</a>&nbsp;
                <a href="#">Sita Airlines |</a>&nbsp;
                <a href="#">Air India |</a>&nbsp;
                <a href="#">Air India Express |</a>&nbsp;
                <a href="#">New Delhi Mumbai Flights |</a>&nbsp;
                <a href="#">Delhi Flights |</a>&nbsp;
                <a href="#">Delhi Chennai Flights |</a>&nbsp;
                <a href="#">Delhi Guwahati Flights |</a>&nbsp;
                <a href="#">Mumbai Varanasi Flights |</a>&nbsp;
                <a href="#">Delhi Guwahati Flights |</a>&nbsp;
                <a href="#">Mumbai Varanasi Flights |</a>&nbsp;
                <a href="#">Guwahati Delhi Flights |</a>&nbsp;
                <a href="#">Goa Delhi Flights |</a>&nbsp;
                <a href="#">Delhi Goa Flights |</a>&nbsp;
                <a href="#">Delhi Chennai Flights |</a>&nbsp;
                <a href="#">Delhi to Khajuraho flight |</a>&nbsp;
                <a href="#">Pasighat to Guwahati Flight |</a>&nbsp;
                <a href="#">Rupsi to Guwahati Flight |</a>&nbsp;
                <a href="#">Kolkata to Rupsi Flight |</a>&nbsp;
                <a href="#">Indore to Mumbai Flight |</a>&nbsp;
                <a href="#">Delhi to Tirupati Flight |</a>&nbsp;
                <a href="#">Mumbai Shirdi Flights |</a>&nbsp;
                <a href="#">Jaipur Mumbai flights |</a>&nbsp;
                <a href="#">Chandigarh Bangalore flights |</a>&nbsp;
                <a href="#">Chennai Mumbai flights |</a>&nbsp;
                <a href="#">Mumbai Hyderabad flights |</a>&nbsp;
                <a href="#">Hyderabad Bangalore flights |</a>&nbsp;
                <a href="#">Pune Bangalore flights |</a>&nbsp;
                <a href="#">Mumbai Jaipur flights |</a>&nbsp;
                <a href="#">Mumbai Chandigarh flights |</a>&nbsp;
                <a href="#">Bangalore Kolkata flights |</a>&nbsp;
                <a href="#">Hyderabad Visakhapatnam flights |</a>&nbsp;
                <a href="#">Cheap Flights |</a>&nbsp;
                <a href="#">Flight Status |</a>&nbsp;
              </p>
            </div>
          </Col>
          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>International Airlines</h5>
              <p className="dm-flight">
                <a href="#">Qatar Airways |</a>&nbsp;
                <a href="#">Air Arabia |</a>&nbsp;
                <a href="#">Malaysia Airlines |</a>&nbsp;
                <a href="#">Malindo Air |</a>&nbsp;
                <a href="#">Emirates Airline |</a>&nbsp;
                <a href="#">Srilankan Airlines |</a>&nbsp;
                <a href="#">Oman Air |</a>&nbsp;
                <a href="#">FlyDubai |</a>&nbsp;
                <a href="#">Singapore Airlines |</a>&nbsp;
                <a href="#">KLM Airlines |</a>&nbsp;
                <a href="#">Tiger Airways |</a>&nbsp;
                <a href="#">Saudi Arabian Airlines |</a>&nbsp;
                <a href="#">Etihad Airways |</a>&nbsp;
                <a href="#">Philippine Airlines |</a>&nbsp;
                <a href="#">Gulf Air |</a>&nbsp;
                <a href="#">Mumbai Dubai Flights |</a>&nbsp;
                <a href="#">Royal Nepal Airlines |</a>&nbsp;
                <a href="#">Buddha Air |</a>&nbsp;
                <a href="#">Air India |</a>&nbsp;
                <a href="#">Indigo |</a>&nbsp;
                <a href="#">Bhutan Airlines |</a>&nbsp;
                <a href="#">Etihad Airways |</a>&nbsp;
                <a href="#">Biman Bangladesh Airlines |</a>&nbsp;
                <a href="#">Thai Airways |</a>&nbsp;
                <a href="#">Malindo Air |</a>&nbsp;
                <a href="#">Druk Air |</a>&nbsp;
                <a href="#">Malaysia Airline |</a>&nbsp;
                <a href="#">Emirates |</a>&nbsp;
                <a href="#">Cathay Pacific |</a>&nbsp;
                <a href="#">Sichuan Airlines |</a>&nbsp;
                <a href="#">Korean Air |</a>&nbsp;
                <a href="#">Air China |</a>&nbsp;
                <a href="#">China Southern Airlines |</a>&nbsp;
                <a href="#">China Eastern Airlines |</a>&nbsp;
                <a href="#">Vistara |</a>&nbsp;
                <a href="#">Srilankan Airlines |</a>&nbsp;
                <a href="#">Air Arabia |</a>&nbsp;
                <a href="#">Turkish Airlines |</a>&nbsp;
              </p>
            </div>
          </Col>
          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>International Routes</h5>
              <p className="dm-flight">
                <a href="#">Mumbai Singapore Flights |</a>&nbsp;
                <a href="#">Delhi London Flights |</a>&nbsp;
                <a href="#">Mumbai Abu Dhabi Flights |</a>&nbsp;
                <a href="#">London Delhi Flights |</a>&nbsp;
                <a href="#">Mumbai Frankfurt Flights |</a>&nbsp;
                <a href="#">Etihad Airways |</a>&nbsp;
                <a href="#">Mumbai Kuala Lumpur Flights |</a>&nbsp;
                <a href="#">Jaipur Dubai Flights |</a>&nbsp;
                <a href="#">Ahmedabad Dubai Flights |</a>&nbsp;
                <a href="#">Kuala Lumpur Bangalore Flights |</a>&nbsp;
                <a href="#">Bangkok Koh Samui Flights |</a>&nbsp;
              </p>
            </div>
          </Col>

          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>Domestic Hotels</h5>
              <p className="dm-flight">
                <a href="#">Hotels In Goa |</a>&nbsp;
                <a href="#">Hotels In Mumbai |</a>&nbsp;
                <a href="#">Hotels In Mahabaleshwar |</a>&nbsp;
                <a href="#">Hotels In Matheran |</a>&nbsp;
                <a href="#">Hotels In Lonavala &amp; Khandala |</a>&nbsp;
                <a href="#">Hotels In Delhi |</a>&nbsp;
                <a href="#">Hotels In Shimla |</a>&nbsp;
                <a href="#">Hotels In Lansdowne |</a>&nbsp;
                <a href="#">Hotels In Digha |</a>&nbsp;
                <a href="#">Hotels In Puri |</a>&nbsp;
                <a href="#">Hotels In Nainital |</a>&nbsp;
                <a href="#">Hotels In Shirdi |</a>&nbsp;
                <a href="#">Hotels In Bangalore |</a>&nbsp;
                <a href="#">Hotels In Mussoorie |</a>&nbsp;
                <a href="#">Hotels In Manali |</a>&nbsp;
                <a href="#">Hotels Near Me |</a>&nbsp;
                <a href="#">Cheap Hotels |</a>&nbsp;
                <a href="#">Hotels In Jaipur |</a>&nbsp;
                <a href="#">Hotels In Udaipur |</a>&nbsp;
                <a href="#">Hotels In Pune |</a>&nbsp;
                <a href="#">Hotels In Pondicherry |</a>&nbsp;
                <a href="#">Hotels In Ooty |</a>&nbsp;
                <a href="#">Hotels In Kodaikanal |</a>&nbsp;
                <a href="#">Hotels In Darjeeling |</a>&nbsp;
                <a href="#">Hotels In Chandigarh |</a>&nbsp;
                <a href="#">Hotels In Mount abu |</a>&nbsp;
                <a href="#">Hotels In Ahmedabad |</a>&nbsp;
                <a href="#">Hotels In Kolkata |</a>&nbsp;
              </p>
            </div>
          </Col>

          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>International Hotels</h5>
              <p className="dm-flight">
                <a href="#">Hotels In Maldives |</a>&nbsp;
                <a href="#">Hotels In Langkawi |</a>&nbsp;
                <a href="#">Hotels In Thailand |</a>&nbsp;
                <a href="#">Hotels In Malaysia |</a>&nbsp;
                <a href="#">Hotels In Istanbul |</a>&nbsp;
                <a href="#">Hotels In Rome |</a>&nbsp;
                <a href="#">Hotels In Sri Lanka |</a>&nbsp;
                <a href="#">Hotels In Switzerland |</a>&nbsp;
                <a href="#">Hotels In Los Angeles |</a>&nbsp;
                <a href="#">Hotels In Tokyo |</a>&nbsp;
                <a href="#">Hotels In Shanghai |</a>&nbsp;
                <a href="#">Hotels In Venice |</a>&nbsp;
                <a href="#">Hotels In Manila |</a>&nbsp;
                <a href="#">Hotels In Australia |</a>&nbsp;
                <a href="#">Hotels In Canada |</a>&nbsp;
                <a href="#">Hotels In Melbourne |</a>&nbsp;
                <a href="#">Hotels In Guangzhou |</a>&nbsp;
                <a href="#">Hotels In Phi Phi Island |</a>&nbsp;
                <a href="#">Hotels In Doha |</a>&nbsp;
                <a href="#">Hotels In Krabi |</a>&nbsp;
                <a href="#">Hotels In Amsterdam |</a>&nbsp;
                <a href="#">Hotels In Vietnam |</a>&nbsp;
                <a href="#">Hotels In Singapore |</a>&nbsp;
                <a href="#">Hotels In Dubai |</a>&nbsp;
                <a href="#">Hotels In Bangkok |</a>&nbsp;
                <a href="#">Hotels In Pattaya |</a>&nbsp;
                <a href="#">Hotels In London |</a>&nbsp;
                <a href="#">Hotels In Phuket |</a>&nbsp;
                <a href="#">Hotels In Bali |</a>&nbsp;
                <a href="#">Hotels In Hong Kong |</a>&nbsp;
                <a href="#">Hotels In Paris |</a>&nbsp;
                <a href="#">Hotels In Koh Samui |</a>&nbsp;
                <a href="#">Hotels In New York |</a>&nbsp;
                <a href="#">Hotels In Las Vegas |</a>&nbsp;
                <a href="#">Hotels In Colombo |</a>&nbsp;
                <a href="#">Hotels In Macau |</a>&nbsp;
                <a href="#">Hotels In Kuala Lumpur |</a>&nbsp;
                <a href="#">Hotels In Sydney |</a>&nbsp;
                <a href="#">Hotels In Kathmandu |</a>&nbsp;
                <a href="#">Cheap Hotels |</a>&nbsp;
              </p>
            </div>
          </Col>

          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>Top Bus Routes</h5>
              <p className="dm-flight">
                <a href="#">Hyderabad to Bangalore Bus |</a>&nbsp;
                <a href="#">Bangalore to Chennai Bus |</a>&nbsp;
                <a href="#">Pune to Bangalore Bus |</a>&nbsp;
                <a href="#">Mumbai to Bangalore Bus |</a>&nbsp;
                <a href="#">Mumbai to Hyderabad Bus |</a>&nbsp;
                <a href="#">Pune to Hyderabad Bus |</a>&nbsp;
                <a href="#">Pune to Hyderabad Bus |</a>&nbsp;
              </p>
            </div>
          </Col>

          <Col md={24} xs={24}>
            <div className="fli-v12">
              <h5>Top Bus Operators</h5>
              <p className="dm-flight">
                <a href="#">SRS Travels |</a>&nbsp;
                <a href="#">Evacay Bus |</a>&nbsp;
                <a href="#">Kallada Travels |</a>&nbsp;
                <a href="#">KPN Travels |</a>&nbsp;
                <a href="#">Orange Travels |</a>&nbsp;
                <a href="#">Parveen Travels |</a>&nbsp;
                <a href="#">Rajdhani Express |</a>&nbsp;
                <a href="#">VRL Travels |</a>&nbsp;
                <a href="#">Bengal Tiger |</a>&nbsp;
                <a href="#">SRM Travels |</a>&nbsp;
                <a href="#">Infant Jesus |</a>&nbsp;
                <a href="#">Patel Travels |</a>&nbsp;
                <a href="#">JBT Travels |</a>&nbsp;
                <a href="#">Shatabdi Travels |</a>&nbsp;
                <a href="#">Eagle Travels |</a>&nbsp;
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className="top-routes-mobile-1">
        <div className="top-routes-container-mobile">
          <Collapse className="bus-routes-collaspe" accordion>
            <Panel header="Domestic Airlines" key="1">
              <Row>
                <Col md={24} xs={24}>
                  <p className="dm-flight">
                    <a href="#">Flights Discount Coupons |</a>&nbsp;
                    <a href="#">Domestic Airlines |</a>&nbsp;
                    <a href="#">Indigo Airlines |</a>&nbsp;
                    <a href="#">Air Asia |</a>&nbsp;
                    <a href="#">SpiceJet, GoAir |</a>&nbsp;
                    <a href="#">Sita Airlines |</a>&nbsp;
                    <a href="#">Air India |</a>&nbsp;
                    <a href="#">Air India Express |</a>&nbsp;
                    <a href="#">New Delhi Mumbai Flights |</a>&nbsp;
                    <a href="#">Delhi Flights |</a>&nbsp;
                    <a href="#">Delhi Chennai Flights |</a>&nbsp;
                    <a href="#">Delhi Guwahati Flights |</a>&nbsp;
                    <a href="#">Mumbai Varanasi Flights |</a>&nbsp;
                    <a href="#">Delhi Guwahati Flights |</a>&nbsp;
                    <a href="#">Mumbai Varanasi Flights |</a>&nbsp;
                    <a href="#">Guwahati Delhi Flights |</a>&nbsp;
                    <a href="#">Goa Delhi Flights |</a>&nbsp;
                    <a href="#">Delhi Goa Flights |</a>&nbsp;
                    <a href="#">Delhi Chennai Flights |</a>&nbsp;
                    <a href="#">Delhi to Khajuraho flight |</a>&nbsp;
                    <a href="#">Pasighat to Guwahati Flight |</a>&nbsp;
                    <a href="#">Rupsi to Guwahati Flight |</a>&nbsp;
                    <a href="#">Kolkata to Rupsi Flight |</a>&nbsp;
                    <a href="#">Indore to Mumbai Flight |</a>&nbsp;
                    <a href="#">Delhi to Tirupati Flight |</a>&nbsp;
                    <a href="#">Mumbai Shirdi Flights |</a>&nbsp;
                    <a href="#">Jaipur Mumbai flights |</a>&nbsp;
                    <a href="#">Chandigarh Bangalore flights |</a>&nbsp;
                    <a href="#">Chennai Mumbai flights |</a>&nbsp;
                    <a href="#">Mumbai Hyderabad flights |</a>&nbsp;
                    <a href="#">Hyderabad Bangalore flights |</a>&nbsp;
                    <a href="#">Pune Bangalore flights |</a>&nbsp;
                    <a href="#">Mumbai Jaipur flights |</a>&nbsp;
                    <a href="#">Mumbai Chandigarh flights |</a>&nbsp;
                    <a href="#">Bangalore Kolkata flights |</a>&nbsp;
                    <a href="#">Hyderabad Visakhapatnam flights |</a>&nbsp;
                    <a href="#">Cheap Flights |</a>&nbsp;
                    <a href="#">Flight Status |</a>&nbsp;
                  </p>
                </Col>
              </Row>
            </Panel>

            <Panel header="International Airlines" key="3">
              <Row>
                <Col md={24} xs={24}>
                  <p className="dm-flight">
                    <a href="#">Qatar Airways |</a>&nbsp;
                    <a href="#">Air Arabia |</a>&nbsp;
                    <a href="#">Malaysia Airlines |</a>&nbsp;
                    <a href="#">Malindo Air |</a>&nbsp;
                    <a href="#">Emirates Airline |</a>&nbsp;
                    <a href="#">Srilankan Airlines |</a>&nbsp;
                    <a href="#">Oman Air |</a>&nbsp;<a href="#">FlyDubai |</a>
                    &nbsp;<a href="#">Singapore Airlines |</a>&nbsp;
                    <a href="#">KLM Airlines |</a>&nbsp;
                    <a href="#">Tiger Airways |</a>&nbsp;
                    <a href="#">Saudi Arabian Airlines |</a>&nbsp;
                    <a href="#">Etihad Airways |</a>&nbsp;
                    <a href="#">Philippine Airlines |</a>&nbsp;
                    <a href="#">Gulf Air |</a>&nbsp;
                    <a href="#">Mumbai Dubai Flights |</a>&nbsp;
                    <a href="#">Royal Nepal Airlines |</a>&nbsp;
                    <a href="#">Buddha Air |</a>&nbsp;
                    <a href="#">Air India |</a>&nbsp;<a href="#">Indigo |</a>
                    &nbsp;<a href="#">Bhutan Airlines |</a>&nbsp;
                    <a href="#">Etihad Airways |</a>&nbsp;
                    <a href="#">Biman Bangladesh Airlines |</a>&nbsp;
                    <a href="#">Thai Airways |</a>&nbsp;
                    <a href="#">Malindo Air |</a>&nbsp;
                    <a href="#">Druk Air |</a>&nbsp;
                    <a href="#">Malaysia Airline |</a>&nbsp;
                    <a href="#">Emirates |</a>&nbsp;
                    <a href="#">Cathay Pacific |</a>&nbsp;
                    <a href="#">Sichuan Airlines |</a>&nbsp;
                    <a href="#">Korean Air |</a>&nbsp;
                    <a href="#">Air China |</a>&nbsp;
                    <a href="#">China Southern Airlines |</a>&nbsp;
                    <a href="#">China Eastern Airlines |</a>&nbsp;
                    <a href="#">Vistara |</a>&nbsp;
                    <a href="#">Srilankan Airlines |</a>&nbsp;
                    <a href="#">Air Arabia |</a>&nbsp;
                    <a href="#">Turkish Airlines |</a>&nbsp;
                  </p>
                </Col>
              </Row>
            </Panel>

            <Panel header="International Routes" key="2">
              <Row>
                <Col md={24} xs={24}>
                  <p className="dm-flight">
                    <a href="#">Mumbai Singapore Flights |</a>&nbsp;
                    <a href="#">Delhi London Flights |</a>&nbsp;
                    <a href="#">Mumbai Abu Dhabi Flights |</a>&nbsp;
                    <a href="#">London Delhi Flights |</a>&nbsp;
                    <a href="#">Mumbai Frankfurt Flights |</a>&nbsp;
                    <a href="#">Etihad Airways |</a>&nbsp;
                    <a href="#">Mumbai Kuala Lumpur Flights |</a>&nbsp;
                    <a href="#">Jaipur Dubai Flights |</a>&nbsp;
                    <a href="#">Ahmedabad Dubai Flights |</a>&nbsp;
                    <a href="#">Kuala Lumpur Bangalore Flights |</a>&nbsp;
                    <a href="#">Bangkok Koh Samui Flights |</a>&nbsp;
                  </p>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="top-routes-container mb-3 directly-flight-ofc-line">
        <TopFlights />
      </div>
      {/* <div className="stay-tuned-block tuned-v3">
        <div className="stay-tuned-wrapper">
          <h2>Stay Tuned</h2>
          <p>Get travel offers, trip reminders and other updates by email.</p>

          <div className="staytuned-input">
            <Form form={subsribeForm} onFinish={handleSubscibe}>
              <Row gutter={16} justify="center">
                <Col md={6} sm={8}>
                  <Form.Item
                    name="Email"
                    rules={[
                      { required: true, message: "Required" },
                      { type: "email", message: "Please enter valid email" },
                    ]}
                  >
                    <Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      placeholder="Your Email Address"
                      prefix={<MailOutlined />}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col md={2} sm={4}>
                  <Button
                    type="primary"
                    size={"large"}
                    className="tg-primary-btn"
                    htmlType="submit"
                  >
                    Subscribe
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default FlightHome;
