import React, { useContext, useEffect } from "react";
import "./BusTicket.scss";
import moment from "moment";
import { TgContext } from "../../providers/TgProvider";
import getBusTicketPrice from "../busHelper";
import ApiClient from "../../../helpers/ApiClient";
import { useState } from "react";
import Logo from "../../../assets/images/trippozo_bg_overlay.png";
const BusTicket = ({ ticketData }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const CompanyName = process.env.REACT_APP_COMPANY_NAME;
  const [address, setAddress] = useState([]);
  const { logo } = useContext(TgContext);
  let {
    baseFare,
    taxes,
    serviceCharge,
    convamount,
    grandTotal,
    insuranceTotal,
  } = getBusTicketPrice(ticketData);
  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <span style={{ color: "#FFA500" }}> {status} </span>;
      }
      case "CONFIRMED": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Booked": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "PartiallyCancelled": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "CREATED": {
        return (
          <span style={{ color: "#f9e218" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "FAILED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Cancelled": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      default:
        return;
    }
  };

  useEffect(() => {
    getAddress();
  }, []);
  const getAddress = () => {
    ApiClient.get("admin/settings/" + 1)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setAddress(data);
        }
      })
      .catch((error) => {});
  };
  const CancellationPolicy = (data) => {
    const policyStringArray = data.split(";");
    return (
      <div className="cancellation-wrapper">
        <p className="policytitle">Cancellation Policy</p>
        <ul>
          <li>
            <p>
              <strong> Cancellation time </strong>
            </p>
            <p>
              <strong>Cancellation charges</strong>
            </p>
          </li>
          {policyStringArray.map((data, index) => {
            let val = data.split(":");
            if (index === 0) {
              return (
                <li>
                  <p>{`With in ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else if (index === policyStringArray.length - 1) {
              return (
                <li>
                  <p>{`Above ${val[0]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else {
              return (
                <li>
                  <p>{`Between ${val[0]} to ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  };
  return (
    <div className="bus_Ticket">
      {ticketData.BookingRefNo ? (
        <table
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
          className="Container"
        >
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2" className="align-c">
              <img
                src={logo ? `${BASE + logo.replace("/", "")}` : Logo}
                width="150"
              />
            </td>
          </tr>

          <tr>
            <td width="60%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                    >
                      <tr>
                        <td className="font-family fW600 fS12p">Tripozo.com</td>
                      </tr>
                      <tr>
                        <td>
                          {address?.settings?.BusinessAddress[0]?.Address ??
                            null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {address?.settings?.BusinessAddress[0]?.PostalCode ??
                            null}
                        </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td width="40%" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td className="font-family fW300 fS13p align-r"></td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">&nbsp;</td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">
                    <b>Reference Number:</b>
                    {ticketData?.BookingRefNo}
                  </td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">
                    <b>Journey Date:</b> {ticketData?.JourneyDate}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border "
              >
                <tr className="background-color">
                  <td
                    colspan="4"
                    className="font-family fW600 fS11p align-c"
                    width="34%"
                    height="32"
                  >
                    {ticketData?.operator}
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="4"
                    className="font-family fW300 fS11p align-r pd"
                    height="32"
                  >
                    <b>PNR : {ticketData?.pnr}</b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="40%"
                    height="32"
                  >
                    Passenger Detail
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Gender
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Age
                  </td>
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Seat no.
                  </td>
                </tr>
                {ticketData?.passengerInfo.length > 0
                  ? ticketData.passengerInfo.map((passenger, key) => (
                      <tr>
                        <td
                          className="font-family fW600 fS11p pd align-l"
                          width="40%"
                          height="32"
                        >
                          <p className="mb0 mt0 fW600 ">
                            {`${key + 1}${"."}${passenger.titles}${"."}${
                              passenger.names
                            }`}
                          </p>
                        </td>
                        <td
                          className="font-family fW300 fS11p pd align-l"
                          width="30%"
                        >
                          {passenger.genders === "M" ? "Male" : "Female"}
                        </td>

                        <td
                          className="font-family fW300 fS11p pd align-l"
                          width="30%"
                        >
                          {passenger?.ages}
                        </td>
                        <td
                          className="font-family fW300 fS11p pd align-l"
                          width="20%"
                        >
                          {passenger?.seatNos}
                        </td>
                      </tr>
                    ))
                  : null}
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Email
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Mobile No.
                  </td>
                </tr>
                <tr>
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="30%"
                  >
                    {ticketData.EmailId}
                  </td>
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    {ticketData.mobileNo}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="30%"
                    height="32"
                  >
                    Bus Details
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="20%"
                  >
                    Departure
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="20%"
                  >
                    Arrival
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l  "
                    width="30%"
                  >
                    Status
                  </td>
                </tr>

                <tr>
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    height="32"
                  >
                    <p className="mb0 mt0">{ticketData?.operator}</p>

                    <p className="mt0">{ticketData.busTypeName}</p>
                  </td>
                  <td className="font-family fW300 fS11p pd align-l  ">
                    <p className="mb0 mt0">{ticketData.sourceName}</p>
                    <p>
                      {`${ticketData?.departureTime},${moment(
                        ticketData?.JourneyDate,
                        "DD-MM-YYYY"
                      ).format("ddd DD MMM YYYY")}`}
                    </p>
                  </td>
                  <td className="font-family fW300 fS11p pd align-l ">
                    <p className="mb0 mt0">{ticketData?.destinationName}</p>
                    <p className="mb0 mt0">{`${ticketData.ArrivalTime},${moment(
                      ticketData.JourneyDate,
                      "DD-MM-YYYY"
                    ).format("ddd DD MMM YYYY")}`}</p>
                  </td>
                  <td className="font-family fW300 fS11p pd align-l ">
                    <p className="mt0 mb0 blueColor">
                      {getStatus(ticketData.bookingStatus)}
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    colspan="4"
                    className="font-family fW600 fS11p pd align-l  "
                    width="34%"
                    height="32"
                  >
                    <p className="mb0 mt0">Payment Details</p>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    className="font-family fW300 fS11p pd align-l "
                    width="75%"
                    height="32"
                  >
                    {" "}
                    <p className="mb0 mt0">This is an electronic ticket.</p>
                    <p className="mb0 mt0">
                      Please carry a positive identification for check in.
                    </p>
                  </td>
                  <td
                    colspan="2"
                    className="font-family fW300 fS11p pd align-r  "
                    width="25%"
                    height="32"
                  >
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      className="border-left"
                    >
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="font-family fW300 fS11p ">
                            <b>Base Fare:</b>
                          </td>
                          <td className="font-family fW300 fS11p">
                            <b>{baseFare}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p ">Taxes</td>
                          <td className="font-family fW300 fS11p">{taxes}</td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p pd-b">
                            <b>Convenience Fee:</b>
                          </td>
                          <td className="font-family fW300 fS11p pd-b">
                            <b>{convamount}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p pd-b">
                            <b>Service Charges:</b>
                          </td>
                          <td className="font-family fW300 fS11p pd-b">
                            <b>{serviceCharge}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p border-bottom pd-b">
                            <b>Discount :</b>
                          </td>
                          <td className="font-family fW300 fS11p border-bottom pd-b">
                            <b>
                              {" "}
                              {!ticketData.promoData.Discount
                                ? "0.00"
                                : ticketData.promoData.tripType === "twoWay"
                                ? Number(
                                    Number(ticketData.promoData.Discount) / 2
                                  ).toFixed(2)
                                : Number(ticketData.promoData.Discount).toFixed(
                                    2
                                  )}
                            </b>
                          </td>
                        </tr>

                        <tr rowspan="2">
                          <td className="ptb6p fW600">
                            <b>Total</b>
                          </td>
                          <td className="ptb6p fW600" colspan="2">
                            <b>{grandTotal}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="100%"
                    height="32"
                  >
                    Terms and Conditions
                  </td>
                </tr>

                <tr>
                  <td width="100%">
                    {" "}
                    <div className="termCon_wrapper">
                      <p>
                        1. {CompanyName}* is ONLY a bus ticket agent. It does
                        not operate bus services of its own. In order to provide
                        a comprehensive choice of bus operators, departure times
                        and prices to customers, it has tied up with many bus
                        operators.
                        {CompanyName} advice to customers is to choose bus
                        operators they are aware of and whose service they are
                        comfortable with{" "}
                      </p>
                      <div className="description">
                        <p className="title">
                          {CompanyName} responsibilities include:
                        </p>
                        <p>
                          (1) Issuing a valid ticket (a ticket that will be
                          accepted by the bus operator) for its network of bus
                          operators
                        </p>

                        <p>
                          (2) Providing refund and support in the event of
                          cancellation
                        </p>
                        <p>
                          (3) Providing customer support and information in case
                          of any delays / inconvenience
                        </p>
                        <p className="title">
                          {" "}
                          {CompanyName} responsibilities do NOT include:
                        </p>
                        <p>
                          (1) The bus operator's bus not departing / reaching on
                          time.
                        </p>
                        <p> (2) The bus operator's employees being rude. </p>
                        <p>
                          (3) The bus operator's bus seats etc not being up to
                          the customer's expectation.
                        </p>
                        <p>
                          (4) The bus operator canceling the trip due to
                          unavoidable reasons.
                        </p>
                        <p>
                          (5) The baggage of the customer getting lost / stolen
                          / damaged.
                        </p>
                      </div>
                      <p>
                        2. The departure time mentioned on the ticket are only
                        tentative timings. However the bus will not leave the
                        source before the time that is mentioned on the ticket.
                      </p>
                      <p>
                        3. Passengers are required to furnish the following at
                        the time of boarding the bus:
                      </p>
                      <div className="description">
                        <p>
                          Passengers are required to furnish the following at
                          the time of boarding the bus:
                        </p>

                        <p>
                          (1) A copy of the ticket (A print out of the ticket or
                          the print out of the ticket e-mail).
                        </p>

                        <p> (2) A valid identity proof </p>
                        <p>
                          Failing to do so, they may not be allowed to board the
                          bus.
                        </p>
                      </div>
                      <p>
                        4. In case one needs the refund to be credited back to
                        his/her bank account, please write your cash coupon
                        details to * The discount
                      </p>

                      <p>
                        5. Delivery charges (if any), will not be refunded in
                        the event of ticket cancellation.
                      </p>

                      <p>
                        6. In case a booking confirmation e-mail and sms gets
                        delayed or fails because of technical reasons or as a
                        result of incorrect e-mail ID / phone number provided by
                        the user etc, a ticket will be considered 'booked' as
                        long as the ticket shows up on the confirmation page of{" "}
                        {CompanyName}
                      </p>
                      <p>
                        7. Grievances and claims related to the bus journey
                        should be reported to {CompanyName} support team within
                        2 days of your travel date.
                      </p>
                      {CancellationPolicy(ticketData.cancellationPolicy)}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
        </table>
      ) : null}
    </div>
  );
};
export default BusTicket;
