import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Collapse, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import APIClient from "../../helpers/ApiClient";
import "./cmsStyle.scss";

const CmsPage = ({ id }) => {
  const { Content } = Layout;
  const [description, setDescription] = useState("");
  const [collapseDecription, setCollapseDecription] = useState([]);
  const { Panel } = Collapse;
  const [cmsName, setCmsName] = useState("");
  const checkifDescription = (data) => {
    if (!data.title) {
      return true;
    }
    if (data.title == "") {
      return true;
    }
    if (data.title == " ") {
      return true;
    }
    if (data.title == null) {
      return true;
    }
    return false;
  };
  const getCMSList = () => {
    APIClient.get("admin/cms/" + `${id}`)
      .then((response) => {
        if (response.status == 200) {
          /**
           * Extract such array where title is null to show at top
           * and all Collapseible
           */
          let temp_decription = "";
          let collapsable = [];
          response.data.pages.forEach((page) => {
            setCmsName(page.CMSName);
            page.Description.forEach((data) => {
              if (checkifDescription(data)) {
                temp_decription += data.description;
              } else {
                collapsable.push(data);
              }
            });
          });

          setCollapseDecription(collapsable);
          setDescription(temp_decription);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCMSList();
  }, []);

  return (
    <div className="collapseCustomWhite cms_pagelisting">
      <div className="promo providerapi ourteam-values">
        <Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container"></Content>
          </div>
        </Layout>
      </div>

      <Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <Row>
            <Col span={24}>
              <h2 className="common-headings">{cmsName}</h2>

              <div className="paras">{ReactHtmlParser(description)}</div>
            </Col>
          </Row>

          <Row className="cms-pages-collaspe">
            <Col span={24}>
              <div className="check-accordions">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  expandIconPosition="right"
                >
                  {collapseDecription.map((res, i) => (
                    <Panel
                      header={res.title}
                      key={i}
                      className="site-collapse-custom-panel"
                    >
                      <div className="text-wrapper">
                        <p>{ReactHtmlParser(res.description)}</p>
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default CmsPage;
