import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Radio, Row, Select, Switch, Spin, Popconfirm, Table, Modal, message } from "antd";

import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";

import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../exportExcelCity";
import { CloseOutlined, PlusCircleFilled } from "@ant-design/icons";

import { useAuthContext } from "../../common/providers/AuthProvider";
const { Option } = Select;

const BookingLimit = (props) => {
  const [form] = Form.useForm();
  const { user } = useAuthContext();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const [commissionList, setCommissionList] = useState([]);
  const [tableList, setTableList] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);

  const toggleModal = () => {
    if (isEdit) {
      setIsEdit(false);
    }
    form.resetFields();

    setModalVisible((prev) => !prev);
  };

  const handleExcel = () => {
    let copyData = tableList;
    const excelData = copyData.map((item) => {
      return {
        ServiceType: item.ServiceTypeName,
        RoleType: item.RoleTypeName,
        DayLimit: item.DayLimit,
        Status: item.Status === 1 ? "Active" : "Inactive",
      };
    });
    props.exportExcel(excelData, "bookingLimit");
  };

  const closeSearch = () => {
    getBookingLimit();
    setShowSearchBox(false);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(commissionList);
    }
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const getServiceType = (key) => {
    switch (key) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      case 3:
        return "Bus";
      case 6:
        return "All";
      default:
        return;
    }
  };

  const setUpdateForm = (curRec) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(curRec.ID);

    form.setFieldsValue({
      Servicetype: curRec.Servicetype,
      RoleType: curRec.RoleType,
      DayLimit: curRec.DayLimit,
      Status: curRec.Status,
    });
  };

  const deleteRecord = (id) => {
    ApiClient.delete("admin/bookingLimit/" + id)
      .then((res) => {
        if (res?.status == 200) {
          if (res?.message) message.success(res.message, 3);
          getBookingLimit();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch(() => {});
  };

  const getRoleType = (num) => {
    if (num === 2) {
      return "Users";
    } else if (num === 4) {
      return "Guest";
    } else if (num === 0) {
      return "All";
    }
  };

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked onChange={() => changeStatus(data, 0)} />;
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={() => changeStatus(data, 1)} />;
    }
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
      sorter: (a, b) => Number(a.SNo) - Number(b.SNo),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceTypeName",
    },
    {
      title: "Role Type",
      dataIndex: "RoleTypeName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.RoleTypeName),
    },
    {
      title: "DayLimit",
      dataIndex: "DayLimit",
    },
    {
      title: "Status",
      render: (Status) => handelStatus(Status),
    },
    { title: "Actions", render: (_, rec) => tableActions(rec) },
  ];

  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon mr-2" onClick={() => setUpdateForm(rec)}>
          <i className="fa fa-edit"></i>
        </span>

        <Popconfirm title="Are u sure to delete?" onConfirm={() => deleteRecord(rec.ID)}>
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };
  const onFormSubmit = (values) => {
    if (isEdit) {
      let req = {
        ...values,
        ModifiedBy: user?.UserID,
      };
      updateCommApi(req);
    } else {
      let req = {
        ...values,
        CreatedBy: user?.UserID,
        ModifiedBy: 0,
      };

      createCommApi(req);
    }
  };

  const createCommApi = (req) => {
    ApiClient.post("admin/bookingLimit", req)
      .then((res) => {
        if (res.statusCode == 200) {
          if (res?.message) message.success(res.message, 3);
          toggleModal();
          form.resetFields();
          getBookingLimit();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch(() => {});
  };

  const updateCommApi = (req) => {
    ApiClient.put(`admin/bookingLimit/${currID}`, {}, req).then((res) => {
      if (res?.status == 200) {
        if (res?.message) message.success(res.message, 3);
        setIsEdit(false);
        setCurrID(-1);
        toggleModal();
        getBookingLimit();
      } else {
        if (res?.message) message.error(res.message, 3);
        else message.error("Failed", 3);
      }
    });
  };

  const changeStatus = (data, status) => {
    let req = {
      ModifiedBy: user?.UserID,
      Status: status,
    };
    ApiClient.put(`admin/bookingLimit/${data.ID}`, {}, req).then((res) => {
      if (res?.status == 200) {
        if (res?.message) message.success(res.message, 3);

        getBookingLimit();
      } else {
        if (res?.message) message.error(res.message, 3);
        else message.error("Failed", 3);
      }
    });
  };

  useEffect(() => {
    getBookingLimit();
  }, []);

  const getBookingLimit = () => {
    setLoading(true);
    setCommissionList([]);
    setTableList([]);
    ApiClient.get("admin/AllbookingLimit")
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ServiceTypeName: getServiceType(item.Servicetype),
              RoleTypeName: getRoleType(item.RoleType),
            };
          });
          setCommissionList(data);
          console.log(data, "test");
          setTableList(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false}>
            <div className="card-add-heading">
              <div className="rows-count d-block">
                <p>{tableList.length} rows found !</p>
              </div>
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    autocomplete="newpassword"
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{
                      padding: "0px 12px",
                      maxWidth: "250px",
                    }}
                  />
                )}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={() => searchData()} />
                <img src={excel} alt="excel" onClick={() => handleExcel()} />
                <p className="add-deposit-icon" onClick={toggleModal}>
                  <PlusCircleFilled />
                </p>
              </div>
            </div>

            {loading ? (
              <Spin />
            ) : commissionList.length > 0 ? (
              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            ) : (
              <b>No Results Found!</b>
            )}
          </Card>
        </div>
      </div>
      <Modal
        title={isEdit ? "Update Booking Limit" : "Add Booking Limit"}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={[
          <div>
            <Button type="primary" htmlType="submit" onClick={form.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger onClick={() => toggleModal()}>
              Cancel
            </Button>
          </div>,
        ]}
        width={"700px"}
      >
        <Form form={form} layout={"vertical"} onFinish={onFormSubmit}>
          <Row gutter={16}>
            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item label="Role Type" name="RoleType" rules={[{ required: true }]}>
                <Select placeholder="Please Select">
                  <Option value={0}>All</Option>
                  <Option value={2}>Users</Option>
                  <Option value={4}>Guest</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item label="Service Type" name="Servicetype" rules={[{ required: true, message: "Required" }]}>
                <Select placeholder="Please Select">
                  <Option value={1}>Flights</Option>
                  <Option value={2}>Hotels</Option>
                  <Option value={3}>Buses</Option>

                  <Option value={6}>All</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item
                label="Day Limit"
                name="DayLimit"
                rules={[
                  { required: true, message: "Required" },
                  {
                    maxLength: 5,
                    pattern: "^[0-9]{0,5}$",
                    message: "Must be  0-5 digit(s)",
                  },
                  {
                    validator: (_, value) => {
                      if (value > -1) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Please Enter valid Amount");
                      }
                    },
                  },
                ]}
              >
                <Input autocomplete="newpassword" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item label="Status" name="Status" rules={[{ required: true, message: "Required" }]}>
                <Radio.Group rules={[{ required: true, message: "Please choose Status!" }]}>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(BookingLimit);
