import React from "react";
import { Button, Card, Checkbox, Form, Spin } from "antd";
import PaymentGateway from "../../../helpers/PaymentGateway";
import "./HotelCheckout.scss";
import { LoadingOutlined } from "@ant-design/icons";

const HotelBookPayCard = (props) => {
  const [form] = Form.useForm();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  const goTo = () => {
    if (props.bookpaycardinfo === "hotel-review") {
      props.navigateToTicket();
    } else if (props.bookpaycardinfo === "hotel-checkout") {
      props.redirectToPreview();
    }
  };
  return (
    <div className="book-pay-section">
      <Card className="book-pay-card ">
        {props.pgDisplay ? (
          <PaymentGateway data={props.pgData} />
        ) : (
          <Form form={form} onFinish={goTo}>
            <div className="book-pay-tc">
              {props.bookpaycardinfo === "hotel-checkout" ? (
                <Form.Item
                  name="remember"
                  rules={[
                    {
                      validator: (rule, value) => {
                        return value
                          ? Promise.resolve()
                          : Promise.reject("Accept Terms & Conditions");
                      },
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    I Agree To All The{" "}
                    <a href="/termsofconditions">{" Terms & Conditions"} </a>{" "}
                    and <a href="/privacypolicy"> Privacy Policy.</a>
                  </Checkbox>
                </Form.Item>
              ) : null}
            </div>
            <div className="book-pay-btn">
              <Button
                disabled={props.isLoading}
                className="btn btn-md"
                htmlType="submit"
              >
                {props.pgIsLoading ? (
                  <Spin indicator={antIcon} />
                ) : props.bookpaycardinfo === "hotel-checkout" ? (
                  "Continue"
                ) : (
                  "Pay Now"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Card>
    </div>
  );
};

export default HotelBookPayCard;
