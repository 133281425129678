import React, { useState, useEffect } from "react";
import { Col, Row, Skeleton, Popconfirm, Tabs } from "antd";
import moment from "moment";
import "./Offers.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import APIClient from "../../helpers/ApiClient";
import img1 from "../../assets/images/Offers/img1.jpg";
import img2 from "../../assets/images/Offers/img2.jpg";
import img3 from "../../assets/images/Offers/img3.jpg";

function copyToClipboard(text) {
  const elem = document.createElement("textarea");
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
}

const ImagesSlider = ({ image }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      <div>
        <img
          className="slide-img"
          src={image ? BASE + image : img1}
          alt="img1"
        />
      </div>
      <div>
        <img className="slide-img" src={img2} alt="img2" />
      </div>
      <div>
        <img className="slide-img" src={img3} alt="img3" />
      </div>
    </Slider>
  );
};
const OfferSkeleton = () => {
  return (
    <Col className="gutter-row" lg={7} md={12} sm={12} xs={24}>
      <div className="coupon-card">
        <div className="images-slider">
          <div
            style={{
              height: 200,
              borderRadius: "35px",
              backgroundColor: "#f2f2f2",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Skeleton.Image />
          </div>
        </div>
        <div className="coupon-content">
          <div className="coupon-code">
            <div className="codes">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>

            <div className="copy-code">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>
          </div>
          <hr />
          <Skeleton active paragraph={{ rows: 1 }} />
          <hr />
          <div className="coupon-expiry-details">
            <div className="countdown">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>
            <div className="expiry-date">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

const OffersBox = ({ id, promo, getDaysRemaining }) => {
  return (
    <Col key={id} className="gutter-row" lg={7} md={12} sm={12} xs={24}>
      <div className="coupon-card">
        <div className="images-slider">
          <ImagesSlider image={promo.Image ? promo.Image.substring(1) : ""} />
        </div>
        <div className="coupon-content">
          <div className="coupon-code">
            <div className="codes">
              <p>{promo.Name}</p>
              <h2>{promo.Code}</h2>
            </div>
            <Popconfirm
              title="Code Copied, Click on OK"
              onConfirm={() => copyToClipboard(promo.Code)}
              onCancel={false}
              okText="Ok"
              cancelText="No"
            >
              <div className="copy-code">
                <p>COPY CODE</p>
              </div>
            </Popconfirm>
          </div>
          <hr />
          {promo.Description}
          <hr />
          <div className="coupon-expiry-details">
            <div className="countdown">
              <p>
                {Math.max(0, getDaysRemaining(promo.ValidTill))} Day(s) <br />
                Remaining
              </p>
            </div>
            <div className="expiry-date">
              <p>Book by {moment(promo.ValidTill).format("DD MMM YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

const Offers = () => {
  let dateFormat = "YYYY-MM-DD";
  let initialState = {
    busPromo: [],
    flightPromo: [],
    hotelPromo: [],
  };

  const { TabPane } = Tabs;
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState(initialState);

  useEffect(() => {
    getpromo();
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          // let data = res.data.filter((item) =>
          //   moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          // );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 3 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );
            let flightPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );
            let hotelPromo = data.filter(
              (promo) =>
                promo.ServiceType === 2 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );
            setPromoCode({
              busPromo: busPromo,
              flightPromo: flightPromo,
              hotelPromo: hotelPromo,
            });
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setPromoCode(initialState);
        setIsLoading(false);
        console.error(error);
      });
  };

  function getDaysRemaining(ValidTill) {
    let today = new moment();
    let ValidTillDate = moment(ValidTill, dateFormat);
    return ValidTillDate.diff(today, "days");
  }

  return (
    <div className="promocodes">
      <div className="promocode-wrapper">
        <div className="offercontainer">
          <div className="promocodes-section">
            <div className="service-type-tabs">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Flights" key="1">
                  <div className="tab1-content">
                    <div className="deals-details-wrapper mb-2">
                      <div className="offercontainer">
                        <div className="main-text">
                          <h2>Flight Offers</h2>
                          <p>
                            Discover the best airline booking offers for cheap
                            air tickets on domestic and international flights,
                            using exclusive promocodes and deals for additional
                            discount.
                          </p>
                        </div>
                      </div>
                    </div>
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : promoCode.flightPromo.length > 0 ? (
                        promoCode.flightPromo.map((promo, i) => (
                          <OffersBox
                            key={i + "flight"}
                            id={"flight" + i}
                            promo={promo}
                            getDaysRemaining={getDaysRemaining}
                          />
                        ))
                      ) : null}
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Buses" key="2">
                  <div className="tab2-content">
                    <div className="deals-details-wrapper mb-2">
                      <div className="offercontainer">
                        <div className="main-text">
                          <h2>Buses Offers</h2>
                          <p>
                            Discover the best bus booking offers for cheap bus
                            tickets on traveling, using exclusive promocodes and
                            deals for additional discount.
                          </p>
                        </div>
                      </div>
                    </div>
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : promoCode.busPromo.length > 0 ? (
                        promoCode.busPromo.map((promo, i) => (
                          <OffersBox
                            key={"bus" + i}
                            id={i + "bus"}
                            promo={promo}
                            getDaysRemaining={getDaysRemaining}
                          />
                        ))
                      ) : null}
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Hotels" key="3">
                  <div className="tab2-content">
                    <div className="deals-details-wrapper mb-2">
                      <div className="offercontainer">
                        <div className="main-text">
                          <h2>Hotel Offers</h2>
                          <p>
                            Discover the best hotel offers for cheap hotels,
                            using exclusive promocodes and deals for additional
                            discount.
                          </p>
                        </div>
                      </div>
                    </div>
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : promoCode.hotelPromo.length > 0 ? (
                        promoCode.hotelPromo.map((promo, i) => (
                          <OffersBox
                            key={"hotel" + i}
                            id={i + "hotel"}
                            promo={promo}
                            getDaysRemaining={getDaysRemaining}
                          />
                        ))
                      ) : null}
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Offers;
