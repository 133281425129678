import React, { useState, useRef, useEffect } from "react";
import { Button, Col, DatePicker, Form, Row } from "antd";
import { useHistory } from "react-router-dom";

import BusesAutoSelect from "../../common/AutoCompleteSelect/BusesAutoSelect";

import moment from "moment";
import queryString from "query-string";
// import arrowImg from "../../assets/images/flight-arrow.png";
import { SearchOutlined } from "@ant-design/icons";
import "../buses-search/Buses.scss";

const Buses = ({ modifySearch, busesSearchAPI }) => {
  const city = useRef(null);
  const searchBtn = useRef();

  let history = useHistory();
  const tocityBox = useRef(null);
  const fromDateBox = useRef(null);

  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();

  const validateMessages = {
    required: "",
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const [showDate, setShowDate] = useState(false);
  const [dataFlip, setDataFlip] = useState(false);
  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === fromDateBox) {
      setShowDate(true);
    }
  };

  const submitForm = (values) => {
    let source = queryString.parse(values.sourceId);
    let destination = queryString.parse(values.destinationId);
    let formData = {
      sourceId: source.cityId,
      destinationId: destination.cityId,
      journeyDate: moment(values.journeyDate).format("DD-MM-YYYY"),
      sourceName: source.cityName,
      destinationName: destination.cityName,
    };
    const query = queryString.stringify(formData);

    history.push("/busresults?" + query);

    if (modifySearch) {
      busesSearchAPI();
    }
  };
  useEffect(() => {
    if (modifySearch) {
      let param = queryString.parse(document.location.search);
      form.setFieldsValue({
        sourceId: `cityName=${param.sourceName}&cityId=${param.sourceId}`,
        destinationId: `cityName=${param.destinationName}&cityId=${param.destinationId}`,
        journeyDate: moment(param.journeyDate, "DD-MM-YYYY"),
      });

      setDataFlip((prev) => !prev);
    }
  }, []);

  // const handleSwap = () => {
  //   const sourceId = form.getFieldValue("sourceId");
  //   const destinationId = form.getFieldValue("destinationId");

  //   if (sourceId && destinationId) {
  //     form.setFieldsValue({
  //       sourceId: destinationId,
  //       destinationId: sourceId,
  //     });
  //     setDataFlip((prev) => !prev);
  //   }
  // };

  return (
    <div>
      <div className="hotels_search_box buses-panel-align-bd overall-tabs-v3">
        <div className="hotels-wrapper">
          <Row className="pdr-bus-bottom" gutter={8}>
            <Col md={24} xs={24} className=""></Col>
          </Row>
          <Form
            form={form}
            className="hotel-search-form  bus-panel-bg"
            name="busForm"
            validateMessages={validateMessages}
            onFinish={submitForm}
          >
            <Row className="search-row bus-alert-sec bus-cr-search" gutter={8}>
              <Col
                md={12}
                lg={8}
                xs={24}
                className="from-to-inputs bus-cols-info firstbusinfo"
              >
                <div className="postive-v3-hotel">
                  <div className="location-name-code">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <BusesAutoSelect
                    formItemProps={{
                      label: "From City",
                      name: "sourceId",
                      rules: [
                        {
                          required: true,
                          message: "Please Specify Origin City",
                        },
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Enter Origin Name",
                    }}
                    api={"buses/cities/search/"}
                    refName={city}
                    focusRef={tocityBox}
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={modifySearch ? modifySearch : false}
                    dataFlip={dataFlip}
                  />
                </div>
                {/* <img
                  className="arrow-bus-inr"
                  src={arrowImg}
                  alt="Arrow img"
                  onClick={handleSwap}
                /> */}
              </Col>
              <Col
                md={12}
                lg={8}
                xs={24}
                className="from-to-inputs bus-cols-info"
              >
                <div className="postive-v3-hotel">
                  <div className="location-name-code">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <BusesAutoSelect
                    formItemProps={{
                      label: "To City",
                      name: "destinationId",
                      rules: [
                        {
                          required: true,
                          message: "Please Specify Destination City",
                        },
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Enter Destination Name",
                    }}
                    api={"buses/cities/search/"}
                    refName={tocityBox}
                    focusRef={fromDateBox}
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={modifySearch ? modifySearch : false}
                    dataFlip={dataFlip}
                  />
                </div>
              </Col>
              <Col
                md={12}
                lg={4}
                xs={24}
                className="from-to-inputs bus-cols-info"
              >
                <Form.Item
                  name="journeyDate"
                  rules={[
                    { required: true, message: "Please Specify Journey Date" },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    size="large"
                    className="search-inputs from-return-date"
                    placeholder="Journey Date"
                    ref={fromDateBox}
                    format={dateFormat}
                    open={showDate}
                    disabledDate={disabledOriginDate}
                    onOpenChange={() => {
                      setShowDate((prev) => !prev);
                    }}
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={4} xs={24} className="bus-cols-info">
                <Form.Item className="mr-none">
                  <Button
                    size="large"
                    className="primary-btn train-search-btn tg-primary-btn-h3 bus-btn-bottom"
                    ref={searchBtn}
                    htmlType="submit"
                  >
                    <SearchOutlined /> Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Buses;
