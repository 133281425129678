import React, { useEffect, useState } from "react";
import APIClient from "../../helpers/ApiClient";

export const TgContext = React.createContext();

export function TgProvider({ children }) {
  const [tabKey, setTabKey] = useState("2");
  const [logo, setLogo] = useState("");
  const [banners, setBanners] = useState([]);
  const [businessAddress, setbusinessAddress] = useState([]);
  const [businessData, setbusinessData] = useState({});
  const [socialLinks, setSocialLinks] = useState({
    facebook: "https://www.facebook.com/",
    GoogleUrl: "https://www.google.com/",
    youtube: "https://www.youtube.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    linkedin: "https://www.linkedin.com/",
  });

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let logo = res.data.filter((item) => item.BannerType === 1);
          if (logo.length > 0 && logo[0].images.length > 0) {
            setLogo(logo[0].images[0].path);
          }
          let homeList = res.data.filter((item) => item.BannerType === 4);
          if (homeList.length > 0 && homeList[0].images.length > 0) {
            setBanners(homeList[0].images);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getbusinessData = () => {
    setbusinessData({
      CompanyName: "Trippozo Technologies Private Limited",
      Email: "info@trippozo.com",
      MobileNumber: "11 4221 3221",
      CountryCode: "+91",
    });
    setbusinessAddress([
      {
        BusinessAddressID: 1,
        Address: "1606, Durga Vihar, Naubasta, Kanpur, UP",
        PostalCode: 208021,
        StateID: 0,
        CityID: 0,
        CountryID: 0,
      },
    ]);
    // APIClient.get("admin/settings/1")

    //   .then((res) => {
    //     if (res.status === 200) {
    //       console.log(res.data);
    //       const { settings } = res.data;
    //       setbusinessData(settings.userBusinessDetails);
    //       setbusinessAddress(settings.BusinessAddress);
    //       setSocialLinks((prev) => ({
    //         ...prev,
    //         facebook: settings.userSocialDetails.FacebookUrl,
    //         GoogleUrl: settings.userSocialDetails.GoogleUrl,
    //         youtube: settings.userSocialDetails.Youtube,
    //         twitter: settings.userSocialDetails.Twitter,
    //         instagram: settings.userSocialDetails.Instagram,
    //         linkedin: settings.userSocialDetails.Linkedin,
    //       }));
    //     }
    //   })
    //   .catch((e) => {
    //     console.log("error", e);
    //   });
  };

  const updateTabKey = (val) => {
    setTabKey(val);
  };

  useEffect(() => {
    getBannersList();
    getbusinessData();
  }, []);
  return (
    <TgContext.Provider
      value={{
        tabKey,
        logo,
        banners,
        businessAddress,
        businessData,
        socialLinks,
        updateTabKey,
      }}
    >
      {children}
    </TgContext.Provider>
  );
}
