import React, { useContext } from "react";
import { Col, Row, Input } from "antd";
import { Link } from "react-router-dom";
import { TgContext } from "../providers/TgProvider";
import * as ANTD from "antd";
import "../CombinedFooter/CombinedFooter.scss";
import { PhoneOutlined, MailOutlined, SendOutlined } from "@ant-design/icons";
const CompanyName = process.env.REACT_APP_COMPANY_NAME;
const CombinedFooter = () => {
  const { businessData, socialLinks, businessAddress } = useContext(TgContext);
  const { Search } = Input;
  return (
    <div className="footer-block">
      <div className="before-ft-bg">
        <div className="footer-container tpz-container">
          {/* <Row>
            <Col md={12} sm={12} xs={24}>
              <div className="ft-v3-1">
                <Row>
                  <Col md={20} sm={12} xs={24}>
                    <h5 className="dwn-heading">
                      {CompanyName} Android & Ios App (Coming Soon)
                    </h5>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <div className="store-icons">
                      <p>
                        <img
                          className=""
                          src={require("../../assets/images/store-v3-1.png")}
                        />
                      </p>
                      <p className="play-store-1">
                        <img
                          className=""
                          src={require("../../assets/images/store-v3-2.png")}
                        />
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <p className="text-msg-1">Send website link to your phone:</p>
                <ANTD.Form.Item>
                  <Search
                    placeholder="999-9999-99"
                    allowClear
                    enterButton="Send SMS"
                    size="large"
                  />
                </ANTD.Form.Item>
              </div>
            </Col>

            <Col md={12} sm={12} xs={24} className="left-line-ft">
              <div className="ft-v3-1">
                <Row>
                  <Col md={12} sm={12} xs={24}>
                    <h5 className="dwn-heading">Connect with Us</h5>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    <div>
                      <ul className="media-icons-store">
                        <a
                          href={socialLinks.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>

                        <a
                          href={socialLinks.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>

                        <a
                          href={socialLinks.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-twitter"></i>
                        </a>

                        <a
                          href={socialLinks.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-youtube"></i>
                        </a>

                        <a
                          href={socialLinks.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <p className="text-msg-1">
                  Sign up today and never miss another deal again!
                </p>
                <ANTD.Form.Item>
                  <Search
                    placeholder="Get Trippozo Emails"
                    allowClear
                    enterButton="Sign Up"
                    size="large"
                  />
                </ANTD.Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="ft-award-1">
            <Col md={24} sm={24} xs={24}>
              <div className="ft-v3-1">
                <h5 className="dwn-heading">Easy Access</h5>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col md={5} sm={8} xs={12}>
              <div className="in-ofc">
                <p>Quick Links</p>
                <ul className="footer-lines-new">
                  <li>
                    <Link to="/">Cheap Flights</Link>
                  </li>
                  <li>
                    <Link to="/hotels">Cheap Hotels</Link>
                  </li>
                  <li>
                    <Link to="/bus">Cheap Buses</Link>
                  </li>
                  <li>
                    <Link to="/offers">Best Offers and Deals</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={5} sm={8} xs={12}>
              <div className="in-ofc">
                <p>On the go</p>
                <ul className="footer-lines-new">
                  <li>
                    <Link to="/aboutus"> About Us </Link>
                  </li>

                  <li>
                    <Link to="/contact_us">Contact Us</Link>
                  </li>

                  <li>
                    <Link to="/faqs">FAQ's </Link>
                  </li>
                  <li>
                    <Link to="/webcheckin">Web Check-In</Link>
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col md={4} sm={12} xs={12}>
              <div className="in-ofc">
                <p>ABOUT {CompanyName}</p>
                <ul className="footer-lines-new">
                  <li>
                    <Link to="/ourteam">Our Team </Link>
                  </li>

                  <li>
                    <Link to="/testimonials">Testimonials </Link>
                  </li>

                  <li>
                    <Link to="/Investorrelations">Investor Relations </Link>
                  </li>

                  <li>
                    <Link to="/careers">Careers </Link>
                  </li>

                  <li>
                    <Link to="/Socialresponsibility">
                      Social Responsibility{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col> */}
            <Col md={5} sm={8} xs={24}>
              <div className="in-ofc">
                <p>Legal</p>
                <ul className="footer-lines-new">
                  <li>
                    <Link to="privacypolicy">Privacy Policy </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">Disclaimer </Link>
                  </li>
                  <li>
                    <Link to="/refundpolicy">Refund Policy </Link>
                  </li>
                  <li>
                    <Link to="/termsofconditions">Terms and Conditions </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={9} sm={24} xs={24}>
              <div className="in-ofc" style={{marginBottom:"10px"}}>
                <div className="media-icons-store">
                  <span className="we-accept">Connect With Us</span>
                  <a
                    href={socialLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>

                  {/* <a
                    href={socialLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a> */}

                  <a
                    href={socialLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>

                  <a
                    href={socialLinks.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>

                  <a
                    href={socialLinks.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="in-ofc">
                <p>Security and Payment Methods</p>
                <div className="media-icons-store">
                  <span className="payicons nb"/>&nbsp;&nbsp;
                  <span className="payicons upi"/>&nbsp;&nbsp;
                  <span className="payicons rupay"/>&nbsp;&nbsp;
                  <span className="payicons visa"/>&nbsp;&nbsp;
                  <span className="payicons master"/>
                  
                </div>
              </div>
              <div className="copyright">©2021 Trippozo Technologies Private Limited.</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default CombinedFooter;
