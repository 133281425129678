import {
  getConvieneceFeeValue,
  getPromoDiscount,
} from "../../components/FlightsCheckout/flightPriceHelper";

export function getFlightPrice(ticketData) {
  let flightFares = ticketData.oneWayflightFare.fares || [];

  let baseAmount = 0;
  let taxAmount = 0;
  let convienenceFee = 0;
  let discount = 0;
  let RefundAmount = 0;
  let grandTotal = 0;
  let markup = 0;

  if (
    ticketData.tripType == "roundTrip" &&
    ticketData.mappingType === "Separate"
  ) {
    flightFares = [
      ...ticketData.oneWayflightFare.fares,
      ...ticketData.returnflightFare.fares,
    ];
  }

  let base = flightFares
    .filter((item) => item.FareCode == "Base")
    .reduce((total, item) => {
      if (item.PaxType == "ADT") {
        return total + Number(Number(item.Amount) * ticketData.pax.adults);
      } else if (item.PaxType == "CHD") {
        return total + Number(Number(item.Amount) * ticketData.pax.childCount);
      } else if (item.PaxType == "INF") {
        return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
      }
    }, 0);

  if (ticketData?.markup) {
    markup = Number(ticketData?.markup);
  }
  baseAmount = Number(base + markup);

  let tax = flightFares
    .filter((item) => item.FareCode != "Base")
    .reduce((total, item) => {
      if (item.PaxType == "ADT") {
        return total + parseFloat(item.Amount * ticketData.pax.adults);
      } else if (item.PaxType == "CHD") {
        return total + parseFloat(item.Amount * ticketData.pax.childCount);
      } else {
        return total + parseFloat(item.Amount * ticketData.pax.infantCount);
      }
    }, 0);
  taxAmount = Number(tax);

  let totalwithtax = parseFloat(baseAmount) + parseFloat(taxAmount);

  if (ticketData.convienenceData) {
    convienenceFee = getConvieneceFeeValue(
      ticketData.convienenceData,
      totalwithtax
    );
  }

  if (ticketData.promoData) {
    let discountValue = getPromoDiscount(
      ticketData.promoData,
      ticketData.totalwithtax
    );
    discount = Number(discountValue).toFixed(2);
  }
  RefundAmount = ticketData.RefundAmount ?? 0;

  grandTotal = Number(
    baseAmount + taxAmount + Number(convienenceFee) - discount
  ).toFixed(2);

  return {
    baseAmount: Number(baseAmount).toFixed(2),
    taxAmount: Number(taxAmount).toFixed(2),
    convienenceFee: Number(convienenceFee).toFixed(2),
    discount: Number(discount).toFixed(2),
    RefundAmount: Number(RefundAmount).toFixed(2),
    grandTotal,
  };
}

export const calculateDuration = (from, to) => {
  let fromTime = from.split("T")[0] + " " + from.split("T")[1];
  let toTime = to.split("T")[0] + " " + to.split("T")[1];

  let diffInMilliSeconds =
    Math.abs(new Date(fromTime) - new Date(toTime)) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} d, ` : `${days} ds, `;
  }

  difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

  return difference;
  // return days;
};
