import React from "react";

const CancellationPolicy = ({ data }) => {
  const policyStringArray = data.split(";");

  return (
    <div className="cancellation-block-body">
      <ul>
        <li>
          <span className="title">Time before Departure </span>{" "}
          <span className="title last">Cancellation Charges</span>
        </li>
        {policyStringArray.map((data, index) => {
          let val = data.split(":");
          if (index === 0) {
            return (
              <li key={index}>
                <span>{`With in ${val[1].replace("-", "")} Hrs`} </span>
                <span className="last">{`${val[2]}%`}</span>
              </li>
            );
          } else if (index === policyStringArray.length - 1) {
            return val[2] ? (
              <li key={index}>
                <span>{`Above ${val[0].replace("-", "")} Hrs`} </span>
                <span className="last">{`${val[2]}%`}</span>
              </li>
            ) : null;
          } else {
            return (
              <li key={index}>
                <span>
                  {`Between ${val[0].replace("-", "")} to ${val[1].replace(
                    "-",
                    ""
                  )} Hrs`}{" "}
                </span>
                <span className="last">{`${val[2]}%`}</span>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default CancellationPolicy;
