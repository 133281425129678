import React, { useContext, useState } from "react";
import Flight, { FlightSelection } from "../Flight/Flight";
import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";

import { Col, Row, Button } from "antd";

const CombinedView = ({
  combinedTwowayFlightList,
  combinedOnewayFlightList,

  flightSearchObj,
  setFlightFareRules,
  changeFlightResultsView,
  selectedFlight,
}) => {
  const [pagination, SetPagination] = useState(51);
  const showMore = () => {
    SetPagination((prev) => prev + 51);
  };

  return flightSearchObj.airTravelType === "oneWay" ? (
    combinedOnewayFlightList.length > 0 ? (
      combinedOnewayFlightList.map((flight, i) => {
        return i < pagination ? (
          <div className="flightCardWrapper" key={i}>
            <Flight
              flightInfo={flight}
              key={flight.flightId}
              flightSearchData={flightSearchObj}
              setFlightFareRules={setFlightFareRules}
              fareFamilies={flight.fareFamilies}
              showFare={true}
            />

            <div className="choose-btn-wrapper flights-btn-view">
              <FlightSelection
                flightInfo={flight}
                changeFlightResultsView={changeFlightResultsView}
                flightSearchData={flightSearchObj}
                setSelectedJourneyType={() => {}}
                selectedFlight={selectedFlight}
                setShowFlightsDetail={() => {}}
              />
            </div>
          </div>
        ) : i === pagination ? (
          <div className="showmore-wrapper" key="key">
            <Button onClick={showMore}>Show More</Button>
          </div>
        ) : null;
      })
    ) : (
      <CustomNoResultFound title={"No Flights Available"} />
    )
  ) : combinedTwowayFlightList.length > 0 ? (
    combinedTwowayFlightList.map((flights, i) => {
      return i < pagination ? (
        <div key={i} className="combine-roundtrip-view">
          <div className="flightCardWrapper">
            {flights.flightDetails.map((flight, i) => (
              <Flight
                flightInfo={flight}
                key={flight.flightId}
                flightSearchData={flightSearchObj}
                setFlightFareRules={setFlightFareRules}
                fareFamilies={flights.fareFamilies}
                showFare={i === 0}
              />
            ))}

            <div className="choose-btn-wrapper flights-btn-view">
              <FlightSelection
                flightInfo={flights}
                changeFlightResultsView={changeFlightResultsView}
                flightSearchData={flightSearchObj}
                selectedFlight={selectedFlight}
                setShowFlightsDetail={() => {}}
              />
            </div>
          </div>
        </div>
      ) : i === pagination ? (
        <div className="showmore-wrapper" key="key">
          <Button onClick={showMore}>Show More</Button>
        </div>
      ) : null;
    })
  ) : (
    <CustomNoResultFound title={"No Flights Available"} />
  );
};

export default CombinedView;
