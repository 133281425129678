import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Popover, Radio } from "antd";

import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";

import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import APIClient from "../../../helpers/ApiClient";
import { CloseOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../../common/providers/AuthProvider";

export const getPromoDiscount = (promoData, total) => {
  if (promoData.DiscountType == 1) {
    return Number((total / 100) * promoData.Discount);
  } else {
    return Number(promoData.Discount);
  }
};
export const getHotelGrandTotal = (
  hotelDetailsObj,
  hotelSearchData,
  ConvFee,
  promoData
) => {
  let totalRoomBaseFare = 0;
  let totalRoomTax = 0;
  let totalOtherCharges = 0;
  let totalExtraGuestCharges = 0;
  let noOfNights = 0;
  let convamount = 0;
  let discount = 0;
  let noOfRooms = 0;
  let adminCommission = 0;
  let markup = 0;
  let totalCommission = 0;
  let roomDetails = {
    roomCount: 0,
    roomNightPrice: 0,
    totalTax: 0,
  };

  if (Object.keys(hotelSearchData).length > 0) {
    let checkin = new Date(hotelSearchData.checkInDate);
    let checkout = new Date(hotelSearchData.checkOutDate);
    let diffTime = checkout - checkin;
    let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));
    noOfNights = Number(diffDays);
  } else return;

  if (hotelDetailsObj?.rooms?.length > 0) {
    noOfRooms = hotelDetailsObj?.rooms.length;
    hotelDetailsObj.rooms.forEach((roomDetail) => {
      if (roomDetail?.ratePlans?.length > 0) {
        const priceObj = roomDetail?.ratePlans[0].price;
        totalRoomBaseFare += Number(priceObj.base);
        totalRoomTax += Number(priceObj.tax);
        totalOtherCharges += Number(priceObj.otherCharges);
        totalExtraGuestCharges += Number(priceObj.extraGuestCharges);
        adminCommission += Number(priceObj.adminCommission);
        markup += Number(priceObj.markup);
        totalCommission += Number(priceObj.commission);
      }
    });
  }

  let grandBaseFare = Number(totalRoomBaseFare) * noOfNights;
  let grandTax = Number(totalRoomTax) * noOfNights;
  let grandOtherCharges = Number(totalOtherCharges) * noOfNights;
  let grandExtraGuestCharges = Number(totalExtraGuestCharges) * noOfNights;
  // let grandBaseFare = Number(totalRoomBaseFare);
  // let grandTax = Number(totalRoomTax);
  // let grandOtherCharges = Number(totalOtherCharges);
  // let grandExtraGuestCharges = Number(totalExtraGuestCharges);
  let totalTax = Number(grandTax) + Number(grandOtherCharges);

  roomDetails = {
    roomCount: noOfRooms,
    roomNightPrice: grandBaseFare,
    totalTax: totalTax,
    tax: grandTax,
    otherCharges: grandOtherCharges,
    extraGuestCharges: grandExtraGuestCharges,
  };

  let total =
    Number(grandBaseFare) + Number(totalTax) + Number(grandExtraGuestCharges);

  if (ConvFee && ConvFee?.type) {
    if (ConvFee.type == 1) {
      convamount = Number(ConvFee.amount);
    } else {
      convamount = Number(
        (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
      );
    }
  }

  let totalAmount = Number(total) + Number(convamount);
  discount = getPromoDiscount(promoData, totalAmount);
  totalAmount = Number(totalAmount) - Number(discount);

  return {
    roomDetails,
    noOfNights,
    discount,
    totalAmount: Number(totalAmount).toFixed(2),
    convamount,
    totalCommission,
    adminCommission,
    markup,
  };
};

const HotelFairBox = ({ hotelDetailsObj, hotelSearchData, isPromoVisible }) => {
  const [form] = Form.useForm();
  let dateFormat = "YYYY-MM-DD";
  const { user } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData },
    },
    AddConvFee,
    RemovePromo,
    validatePromoCode,
  } = useContext(GlobalStatesContext);

  const [promoCode, setPromoCode] = useState([]);
  const { roomDetails, noOfNights, discount, totalAmount, convamount } =
    getHotelGrandTotal(hotelDetailsObj, hotelSearchData, ConvFee, promoData);

  useEffect(() => {
    AddConvFee(2);
    getpromo();
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let hotelPromo = data.filter(
              (promo) =>
                promo.ServiceType === 2 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(hotelPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });
    form.submit();
  };

  const roomFare = () => {
    return (
      <div className="pax-count-acc-body">
        <p>({`${roomDetails.roomCount} Rooms x ${noOfNights} Nights`})</p>
        <p>
          <i className="fa fa-inr" /> {roomDetails.roomNightPrice}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="sticky-card-container">
        <Card className="pax-count-card flight-cards-details">
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Room Price</p>
            </Col>
            <Col className="d-flex">
              <p>
                <i className="fa fa-inr" />{" "}
                {Number(roomDetails.roomNightPrice).toFixed(2)}
              </p>

              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={roomFare()}
                title="Room Price"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Taxes and Fees</p>
            </Col>
            <Col className="d-flex">
              <p>
                <i className="fa fa-inr" />{" "}
                {Number(roomDetails.totalTax).toFixed(2)}
              </p>
              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={
                  <div className="pax-count-acc-body">
                    <div className="pax-type">
                      <p>Service fee</p>
                    </div>

                    <div className="service-price">
                      <p>
                        <i className="fa fa-inr" />{" "}
                        {Number(roomDetails.totalTax).toFixed(2)}
                      </p>
                    </div>
                  </div>
                }
                title="Taxes and Fees"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          {promoData.status ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Discount</p>
              </Col>
              <Col className="d-flex">
                <p>
                  <i className="fa fa-inr" /> {Number(discount).toFixed(2)}
                </p>
              </Col>
            </Row>
          ) : null}

          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Convenience Fee</p>
            </Col>
            <Col className="d-flex">
              <p>
                <i className="fa fa-inr" /> {Number(convamount).toFixed(2)}
              </p>
            </Col>
          </Row>

          <div className="pax-total-price">
            <div className="pax-type">
              <p>Total:</p>
            </div>
            <div className="total">
              <p>
                {" "}
                <i className="fa fa-inr" />{" "}
              </p>
              <p className="amount ml-1"> {totalAmount}</p>
            </div>
          </div>
          <p className="all-taxes">Including all taxes and fees</p>
        </Card>
      </div>

      {isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <Card bordered={false} className="hotel-card-wrapper">
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  validatePromoCode({
                    ServiceType: 2,
                    Amount: Number(totalAmount),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item
                      name="promo"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" onClick={() => form.submit()}>
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <div key={key}>
                      <div className="promo-cp-coupons">
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save <i className="fa fa-inr" />
                              &nbsp;
                              {item.Discount}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card bordered={false} className="hotel-card-wrapper">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
    </>
  );
};

export default HotelFairBox;
