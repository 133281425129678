import React from "react";
import { Card, Col, Row, Skeleton } from "antd";

const FlightSalesCountSkeleton = () => {
  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <h4 className="flights-sales-card">Flight Sales</h4>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8} lg={4}>
          <Card>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Card>
        </Col>
      </Row>

      <div className="order-count-cards">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card className="side-bar-graph-1">
              <Skeleton active paragraph={{ rows: 4 }} />
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card className="side-bar-graph-new-flight">
              <Skeleton active paragraph={{ rows: 4 }} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default FlightSalesCountSkeleton;
