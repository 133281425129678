import React, { useState, useCallback, useEffect } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";

const API_URL = "flights/updatedAirPort/search/";
const AirportAutoComplete = (props) => {
  useEffect(() => {
    let value = "";
    if (props.isfieldKey) {
      value = props.refName.current[props.fieldKey].props.value;
    } else {
      value = props.refName.current.props.value;
    }

    if (!value || value == "" || value == " ") {
      return;
    }

    fetchData(value);
  }, [
    props.isfieldKey
      ? props.refName.current[props.fieldKey]
      : props.refName.current,
    props.dataFlip,
  ]);

  // const check = () => {
  //   if (props.focusRef) {
  //     if (props.isfieldKey)
  //       props.handleOnSubmit(props.focusRef, props.isfieldKey, props.fieldKey);
  //     else props.handleOnSubmit(props.focusRef);
  //   }
  // };
  const { Option } = Select;
  const [options, setOptions] = useState({
    data: [],
    value: [],
    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results) => {
    let arr = [];

    results.forEach((result) => {
      arr.push({
        value: result._source.code,
        display: result._source.suggestionWithTags,
        _class: "parent",
        label: result.suggestion,
      });

      if (result._type == "multiport") {
        result._source.airports_data.forEach((result_2) => {
          arr.push({
            value: result_2._source.code,
            display: result_2._source.suggestionWithTags,
            _class: "child",
            label: result_2.suggestion,
          });
        });
      }
    });

    return arr;
  };
  function fetchData(value) {
    if (value == "" || value == " ") {
      return;
    }

    setOptions({ data: [], fetching: true });
    APIClient.get(`${API_URL}${value}`)
      .then((res) => {
        if (res.statusCode == 200 && res) {
          setOptions({ data: res.data, fetching: false });
          return;
        }
      })
      .catch((error) => {
        console.error("error", error);
        setOptions({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  return (
    <Form.Item {...props.formItemProps} className="airport_auto_complete">
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={(el) => {
          props.isfieldKey
            ? (props.refName.current[props.fieldKey] = el)
            : (props.refName.current = el);
        }}
        // value={details.value}
        notFoundContent={options.fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={debounceOnChange}
        onChange={props.onChange}
        {...props.selectProps}
        dropdownClassName={"airpot_names_dropdown"}
        dropdownStyle={{ width: "100%" }}
      >
        {options.data.map((d, index) => (
          <Option value={d.AirportCode} key={"key" + index}>
            {/* {d.nameAirport} */}
            {`${d.City},${d.Country}- (${d.AirportCode})-${d.AirportDesc}`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default AirportAutoComplete;
