import { Button, Col, Collapse, Grid, Modal, Row, message } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import FlightSearch from "../FlightSearch/FlightSearch";
import CustomNoResultFound from "../ErrorPages/CustomNoResultFound";
import FilterSkeletonLayout from "../FilterSkeleton/FilterSkeleton";
import FlightFilters from "../Flight-Filters/FlightFilters";
import FlightResultsCardSkeleton from "../FlightResultsCardSkeleton/FlightResultsCardSkeleton";
import FlightsList from "../SearchResults/FlightsList";
import "./FlightResults.scss";
import Sort from "./Sort/Sort";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;
const FlightResults = ({ gscontext }) => {
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const { user } = useAuthContext();
  let offsetY = 80;
  const {
    state: { flightPersist, flightAirSearchResp },
    updateFlightSearchObj,
    updateSelectedFlight,
    resetFlightPersist,
    updateFlightAirSearchRespObj,
    updateFlightFares,
  } = useFlightContext();

  const dateFormat = "DD-MM-YYYY";

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const handleScroll = () => {
    let elem = document.querySelector(".results_tab .ant-tabs-nav");
    if (!elem) return;
    let rect = elem.getBoundingClientRect();

    offsetY = rect.top;
    if (rect.top < 10) {
      document.getElementById("stickyLogo").style.display = "block";
    } else {
      document.getElementById("stickyLogo").style.display = "none";
    }
  };

  const debounceOnChange = useCallback(debounce(handleScroll, 100), []);

  useEffect(() => {
    window.addEventListener("scroll", debounceOnChange);
    return () => window.removeEventListener("scroll", debounceOnChange);
  });
  // const [minValues, setMinValues] = useState({
  //   minFlightPrice: 0,
  //   minFlightTime: 0,
  //   minBusPrice: 0,
  //   minBusTime: 0,
  //   minTrainPrice: 0,
  //   minTrainTime: 0,
  // });

  //Bus Variables
  const { airlineMatrixReset } = gscontext.state;
  const { RemovePromo, ResetAirlineMatrix } = gscontext;

  let busInitial = {
    trips: [],
    origin: "",
    destination: "",
  };

  const [selectedTripType, setSelectedTripType] = useState(1);

  const [modalVisible, setModalVisible] = useState(false);

  const [modalVisiblenew, setModalVisiblenew] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [promodataSource, setPromoDataSource] = useState([]);

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  const getPromoCodes = () => {
    setPromoDataSource([]);

    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });
          setPromoDataSource(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    fetchAirSearch();
    RemovePromo();
    getPromoCodes();
  }, []);

  const calculateDuration = (from, to) => {
    let FromDate = moment(from);
    let ToDate = moment(to);

    let numberOfHours = ToDate.diff(FromDate, "hours");
    let numerOfMinutes = ToDate.diff(FromDate, "minutes");

    let days = Math.floor(numberOfHours / 24);
    let Remainder = numberOfHours % 24;
    let hours = Math.floor(Remainder);
    let minutes = Math.floor(numerOfMinutes - 60 * hours);

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} d, ` : `${days} ds, `;
    }

    difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

    difference +=
      minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

    return difference;
  };

  const setUpAirsearchAfterApiResponse = (resp, reqData) => {
    if (resp.statusCode === 200) {
      updateFlightSearchObj({
        ...reqData,
        traceId: resp.data.traceId,
        airTravelType: resp.data.airTravelType,
        resultsType: resp.data.resultsType,
      });
      updateFlightAirSearchRespObj({
        ...resp.data,
        tripType: 1,
        flightDetails: resp.data.flightDetails.map((flight) => {
          return { ...flight, isVisible: true };
        }),
        ibFlightDetails: resp.data.ibFlightDetails.map((flight) => {
          return { ...flight, isVisible: true };
        }),
      });

      // getMinFlightPrice(resp.data.flightDetails);
    }
  };

  const getSearchRequestFromQuery = () => {
    const searchParams = queryString.parse(window.location.search);
    if (!searchParams) {
      document.location.replace("/error?erro='Invalid t'");
      return;
    }
    let requestObj = {
      originDestinations: [
        {
          departureDateTime: searchParams.from + "T00:00:00",
          origin: searchParams.origin,
          destination: searchParams.destination,
          flightDateFlex: 0,
        },
      ],
      adultCount: parseInt(searchParams.adult),
      childCount: parseInt(searchParams.child),
      infantCount: parseInt(searchParams.infant),
      cabinClass: searchParams.class,
      includeCarrier: searchParams.includeCarrier
        ? searchParams.includeCarrier
        : "",
      excludeCarrier: "",
      stopOver: "None",
      airTravelType: searchParams.tripType,
      flightDateFlex: 0,
      itineraryViewType: "1",
    };
    if (searchParams.tripType === "roundTrip") {
      requestObj.originDestinations.push({
        departureDateTime: searchParams.to + "T00:00:00",
        origin: searchParams.destination,
        destination: searchParams.origin,
        flightDateFlex: 0,
      });
    }
    return requestObj;
  };

  /* Flight AirSearch API */
  const fetchAirSearch = () => {
    setIsLoading(true);
    resetFlightPersist();

    const requestData = getSearchRequestFromQuery();

    if (
      requestData.originDestinations[0].destination ===
      requestData.originDestinations[0].origin
    ) {
      message.error("Origin and Destination cannot be same", 3);
      setIsLoading(false);
      return false;
    }

    let reqData = {
      ...requestData,
      userId: user?.UserID ?? 1,
    };

    ApiClient.post("flights/airSearch", reqData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        setUpAirsearchAfterApiResponse(resp, reqData);

        // if (resp.statusCode === 400 || resp.statusCode === 404) {
        //   setMinValues((prev) => ({
        //     ...prev,
        //     minFlightPrice: 0,
        //     minFlightTime: 0,
        //   }));
        // }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="combined-results-container">
        <div className="modify-combined-search-container">
          <div className="modify-combined-block">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={
                  <span className="hotels-hide-search">Modify Search</span>
                }
                key="1"
              >
                <FlightSearch
                  gscontext={gscontext}
                  modify={true}
                  onModify={fetchAirSearch}
                />
              </Panel>
            </Collapse>
          </div>
        </div>

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container">
            <Row gutter={16} className="margins-alignments results_tab">
              {isLoading ? (
                <>
                  <Col md={6} xs={0} className="filter-section-flight">
                    <FilterSkeletonLayout />
                  </Col>
                  <Col md={18} xs={24}>
                    <div className="flight-results-skeleton-container">
                      <div className="flights-skeleton-card">
                        <FlightResultsCardSkeleton />
                        <FlightResultsCardSkeleton />
                        <FlightResultsCardSkeleton />
                        <FlightResultsCardSkeleton />
                      </div>
                    </div>
                  </Col>
                </>
              ) : Object.keys(flightAirSearchResp).length === 0 ||
                flightAirSearchResp.flightDetails.length === 0 ? (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Flights Available"} />
                </Col>
              ) : (
                <>
                  <Col md={6} xs={0} className="filter-section-flight">
                    {flightAirSearchResp.flightDetails && (
                      <FlightFilters
                        updateFlightAirSearchRespObj={
                          updateFlightAirSearchRespObj
                        }
                        ResetAirlineMatrix={ResetAirlineMatrix}
                        selectedTripType={selectedTripType}
                        flightAirSearchResp={flightAirSearchResp}
                      />
                    )}
                  </Col>
                  <Col md={18} xs={24}>
                    <>
                      <div className="flight-sort-container">
                        <Sort
                          updateFlightAirSearchRespObj={
                            updateFlightAirSearchRespObj
                          }
                          flightAirSearchResp={flightAirSearchResp}
                          airlineMatrixReset={airlineMatrixReset}
                          ResetAirlineMatrix={ResetAirlineMatrix}
                          selectedTripType={selectedTripType}
                        />
                      </div>

                      <FlightsList
                        setSelectedTripType={setSelectedTripType}
                        updateSelectedFlight={updateSelectedFlight}
                        flightPersist={flightPersist}
                        updateFlightFares={updateFlightFares}
                        promoData={promodataSource}
                        flightAirSearchResp={flightAirSearchResp}

                        // getMinFlightPrice={getMinFlightPrice}
                      />
                    </>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
      </div>
      {isLoading ? null : Object.keys(flightAirSearchResp).length === 0 ||
        flightAirSearchResp.flightDetails.length === 0 ? null : (
        <Row className="fiters_wrapper">
          <Col md={12} xs={12} className="tc">
            <span className="title" onClick={showModalflight}>
              {" "}
              <i className="fa fa-filter" aria-hidden="true"></i>
              &nbsp;Flight Filters
            </span>
          </Col>
          <Col md={12} xs={12} className="tc">
            <span className="title" onClick={showModalsort}>
              <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
              &nbsp;Sort by
            </span>
          </Col>
        </Row>
      )}

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> Flight Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            {isLoading ? (
              <FilterSkeletonLayout />
            ) : Object.keys(flightAirSearchResp).length === 0 ||
              flightAirSearchResp.flightDetails.length === 0 ? null : (
              flightAirSearchResp.flightDetails && (
                <FlightFilters
                  updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                  ResetAirlineMatrix={ResetAirlineMatrix}
                  selectedTripType={selectedTripType}
                  flightAirSearchResp={flightAirSearchResp}
                />
              )
            )}
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisiblenew(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        {isLoading ? (
          "Loading"
        ) : Object.keys(flightAirSearchResp).length === 0 ||
          flightAirSearchResp.flightDetails.length === 0 ? null : (
          <div className="mobflightsort">
            <Sort
              updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
              flightAirSearchResp={flightAirSearchResp}
              airlineMatrixReset={airlineMatrixReset}
              ResetAirlineMatrix={ResetAirlineMatrix}
              selectedTripType={selectedTripType}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default FlightResults;
