import React, { useEffect } from "react";
import { message } from "antd";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import "./App.scss";

import { useAuthContext } from "./common/providers/AuthProvider";
import { AdminAccessProvider } from "./common/providers/AdminAccessProvider";
import { HotelProvider } from "./common/providers";
import GlobalStatesContext from "./common/providers/GlobalStatesContext";
import CombinedNavbar from "./common/CombinedNavbar/CombinedNavbar";
import Administration from "./admin/Administration";
import UserNavigations from "./common/navbar/userNavigations";
import CombinedFooter from "./common/CombinedFooter/CombinedFooter";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  const { tryLocalSignin, accessList } = useAuthContext();

  useEffect(() => {
    tryLocalSignin();

    message.config({
      duration: 3,
      maxCount: 1,
    });
  }, []);
  return (
    <GlobalStatesContext.Consumer>
      {(gscontext) => (
        <Router>
          <ScrollToTop>
            {gscontext.state.isLogin.role ? (
              <AdminAccessProvider>
                <Administration
                  updateIsLogin={gscontext.updateIsLogin}
                  accessList={accessList}
                />
              </AdminAccessProvider>
            ) : (
              <HotelProvider>
                <div className="main_body">
                  <div className="main_content">
                    <CombinedNavbar gscontext={gscontext} />
                    <UserNavigations gscontext={gscontext} />
                  </div>
                  <div className="footer_content">
                    <CombinedFooter />
                  </div>
                </div>
              </HotelProvider>
            )}
          </ScrollToTop>
        </Router>
      )}
    </GlobalStatesContext.Consumer>
  );
};

export default App;
