import React, { useState } from "react";
import "../HotelPassengers/HotelPassengers.scss";

const HotelPassengers = (props) => {
  // console.log("hotel passengers props", props);
  const [agesList, setAgesList] = useState([
    {
      id: 0,
      childYear: 0,
    },
    {
      id: 1,
      childYear: 1,
    },
    {
      id: 2,
      childYear: 2,
    },
    {
      id: 3,
      childYear: 3,
    },
    {
      id: 4,
      childYear: 4,
    },
    {
      id: 5,
      childYear: 5,
    },
    {
      id: 6,
      childYear: 6,
    },
    {
      id: 7,
      childYear: 7,
    },
    {
      id: 8,
      childYear: 8,
    },
    {
      id: 9,
      childYear: 9,
    },
    {
      id: 10,
      childYear: 10,
    },
    {
      id: 11,
      childYear: 11,
    },
    {
      id: 12,
      childYear: 12,
    },
    {
      id: 13,
      childYear: 13,
    },
    {
      id: 14,
      childYear: 14,
    },
    {
      id: 15,
      childYear: 15,
    },
    {
      id: 16,
      childYear: 16,
    },
    {
      id: 17,
      childYear: 17,
    },
  ]);

  /* on Click of remove room */

  const onRemoveRoom = (i) => {
    props.paxInfo.splice(i, 1);
    /* props.paxInfo.filter((paxInfoObj) => {
      return paxInfoObj.id === i;
    }); */
    props.updatePaxInfoFromChild(props.paxInfo);
  };

  /* On Increase Adult count in room */
  const onIncreaseAdultCount = (e, paxObj, i) => {
    paxObj.noOfAdults = paxObj.noOfAdults + 1;
    props.updatePaxInfoFromChild(props.paxInfo);
  };

  /* On Decrease Adult count in room */
  const onDecreaseAdultCount = (e, paxObj, i) => {
    if (paxObj.noOfAdults > 1) {
      paxObj.noOfAdults = paxObj.noOfAdults - 1;
      props.updatePaxInfoFromChild(props.paxInfo);
    }
  };

  /* on Child Increment */
  const onIncreaseChildCount = (e, paxObj, i) => {
    paxObj.noOfChilds = paxObj.noOfChilds + 1;
    let childAge = {
      age: null,
    };
    paxObj.childAge.push(0);
    props.updatePaxInfoFromChild(props.paxInfo);
  };
  /* on Child Decrement */
  const onDecreaseChildCount = (e, paxObj, i) => {
    if (paxObj.noOfChilds > 0) {
      paxObj.noOfChilds = paxObj.noOfChilds - 1;
      paxObj.childAge.pop();
      props.updatePaxInfoFromChild(props.paxInfo);
    }
  };

  /* Grabbing child age */
  const selectChildAge = (paxIndex, ageIndex, e, paxObj) => {
    paxObj.childAge[ageIndex] = e.target.value;
    props.updatePaxInfoFromChild(props.paxInfo);
  };
  return (
    <div className="hotel-pax-box">
      <li key={props.id}>
        <ul className="child-item">
          <li>
            <div className="lists-wrapper">
              <div>Room {props.index + 1}</div>
              <p
                className="remove-btn"
                onClick={(e) => onRemoveRoom(props.index)}
              >
                Remove
              </p>
            </div>
          </li>
          <li>
            <div className="lists-wrapper">
              <div className="pax-label">
                <p>Adults</p>
                <span>over 18 years old</span>
              </div>
              <div className="pax-count">
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={(e) =>
                    onDecreaseAdultCount(e, props.pax, props.index)
                  }
                ></i>
                <span>{props.pax.noOfAdults}</span>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={(e) =>
                    onIncreaseAdultCount(e, props.pax, props.index)
                  }
                ></i>
              </div>
            </div>
          </li>
          <li>
            <div className="lists-wrapper">
              <div className="pax-label">
                <p>Children</p>
                <span>Upto 18 years old</span>
              </div>
              <div className="pax-count">
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={(e) =>
                    onDecreaseChildCount(e, props.pax, props.index)
                  }
                ></i>
                <span>{props.pax.noOfChilds}</span>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={(e) =>
                    onIncreaseChildCount(e, props.pax, props.index)
                  }
                ></i>
              </div>
            </div>

            <div className="ages-select">
              {props.pax.childAge.map((childAge, index) => (
                <select
                  onChange={(e) => {
                    selectChildAge(props.index, index, e, props.pax);
                  }}
                  key={index}
                  defaultValue={"childValue"}
                >
                  <option disabled value="childValue">
                    Child {index}
                  </option>
                  {agesList.map((ageObj, index) => (
                    <option key={ageObj.id} value={ageObj.childYear}>
                      {ageObj.childYear} years
                    </option>
                  ))}
                </select>
              ))}
            </div>
          </li>
        </ul>
      </li>
    </div>
  );
};

export default HotelPassengers;
