import React from "react";

import "./FlightsCheckout.scss";

import * as ANTD from "antd";
import PaymentGateway from "../../helpers/PaymentGateway";

const BookPayCard = (props) => {
  const [form] = ANTD.Form.useForm();

  const goTo = () => {
    if (props.bookpaycardinfo === "flight-review") {
      props.fetchAirBook();
    } else if (props.bookpaycardinfo === "flight-checkout") {
      props.redirectToPreview();
    }
  };
  return (
    <div className="book-pay-section">
      <ANTD.Card className="book-pay-card flight-cards-details">
        {props.pgDisplay ? (
          <PaymentGateway data={props.pgData} />
        ) : (
          <ANTD.Form form={form} onFinish={goTo}>
            <div className="book-pay-tc">
              {props.bookpaycardinfo === "flight-checkout" ? (
                <ANTD.Form.Item
                  name="remember"
                  rules={[
                    {
                      validator: (rule, value) => {
                        return value
                          ? Promise.resolve()
                          : Promise.reject("Accept Terms & Conditions");
                      },
                    },
                  ]}
                  valuePropName="checked"
                >
                  <ANTD.Checkbox>
                    I Agree To All The{" "}
                    <a href="/termsofconditions" target="_blank">
                      {" Terms & Conditions"}{" "}
                    </a>{" "}
                    and{" "}
                    <a href="/privacypolicy" target="_blank">
                      {" "}
                      Privacy Policy.
                    </a>
                  </ANTD.Checkbox>
                </ANTD.Form.Item>
              ) : null}
            </div>
            <div className="book-pay-btn">
              <ANTD.Button
                disabled={!props.isLoading}
                className="btn btn-md"
                htmlType="submit"
              >
                Continue
              </ANTD.Button>
            </div>
          </ANTD.Form>
        )}
      </ANTD.Card>
    </div>
  );
};

export default BookPayCard;
