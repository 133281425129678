import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import { DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import search from "../assets/vector-icons/search.png";

import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../../src/helpers/ApiClient";
import "./SubscriberReport.scss";

const SubscriberReport = () => {
  const { Content } = ANTD.Layout;
  const [subscriberList, setSubscriberList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    getSubscriberList();
  }, []);

  const getSubscriberList = () => {
    setSubscriberList([]);
    setTableData([]);
    ApiClient.get("admin/subscriberReports")
      .then((response) => {
        if (response.status == 200) {
          let data = response.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setSubscriberList(data);
          setTableData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeleteSubscribtion = (Record) => {
    var Id = Record.ID;
    ApiClient.delete("admin/subscriberReport/" + `${Id}`)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Deleted  successfully", 3);
          getSubscriberList();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const updateSubscribtion = (val, record) => {
    var Id = record.ID;
    let status = 0;
    if (!val) status = 1;

    let data = {
      ...record,
      DeviceOS: 3,
      ApplicationType: 1,
      Status: status,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    ApiClient.put("admin/subscriberReport/" + `${Id}`, {}, data)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Updated  successfully", 3);
          getSubscriberList();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(subscriberList);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    setTableData(subscriberList);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const searchData = () => {
    setShowSearchBox(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Email ID",
      dataIndex: "Email",
      refno: "",
      sorter: {
        compare: (a, b) => a.Email.length - b.Email.length,
      },

      key: "source",
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (status, record) => (
        <ANTD.Switch
          checkedChildren="Subscribe"
          unCheckedChildren="Unsubscribe"
          defaultChecked={!status}
          onChange={(val) => updateSubscribtion(val, record)}
        />
      ),
    },
    {
      title: "Delete",
      render: (text, record) => (
        <ANTD.Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => DeleteSubscribtion(record)}
        >
          <DeleteOutlined
            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
          />
        </ANTD.Popconfirm>
      ),
    },
  ];

  return (
    <ANTD.Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>Subscriber Report</h5>
                      <p>{tableData.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          autoComplete="newpassword"
                          autoComplete="off"
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getSubscriberList()}
                      />
                    </div>
                  </div>

                  <ANTD.Table
                    scroll={{ x: true }}
                    bordered
                    scroll={{ x: true }}
                    rowKey={uniqueKey}
                    dataSource={tableData}
                    columns={columns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
    </ANTD.Layout>
  );
};
export default SubscriberReport;
