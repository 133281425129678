import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
  message,
  Card,
} from "antd";
//import React from "react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import "./AdminLogin.scss";
import ReCAPTCHA from "react-google-recaptcha";
export const AdminLogin = (props) => {
  const [form] = Form.useForm();

  const {
    setExtraUserInfo,
    setUser,
    setAccessToken,
    setAdminAsUser,
    setAccessList,

    resetIsLogin,
  } = useAuthContext();
  console.log(props, "props");
  const history = useHistory();
  const { updateIsLogin } = props.gscontext;
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const setChangeDashboardFlag = (value) => updateIsLogin("role", value);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  // const handleRedirect = () => {
  //   let path = "/";
  //   if (location) {
  //     if (location?.state?.login) path = location.state?.from?.pathname ?? path;
  //   }

  //   history.push(path, {});
  // };

  const onFinish = (values, redirect = true) => {
    let obj = {
      UserName: values.username,
      Password: values.password,
    };

    const handleAdminAsUser = (id) => {
      setAdminAsUser({
        status: true,
        id,
      });
      setExtraUserInfo(null);
    };
    APIClient.post("admin/login", obj)
      .then((resp) => {
        if (resp.status === 200) {
          setUser(resp.data.userDetails);
          console.log(resp, "resp");
          setAccessToken(resp.accessToken);
          history.push("/");

          if (resp.data.userDetails.Role.RoleId == 1) {
            let screens = [];
            resp.data.userDetails.Screens.forEach((element) => {
              screens.push(element.Name);
            });
            setAccessList(screens);

            updateDashboardFlag(true);
          } else {
            resetIsLogin();
          }
        } else if (resp.status == 500) {
          message.error("Unable to Login", 3);
        } else if (resp.status == 503) {
          message.error(resp.message, 3);
        } else if (resp.status == 400 || resp.status == 404) {
          let errorMsg = resp?.message
            ? resp?.message
            : "Wrong Email or Password";
          form.setFields([
            {
              name: "username",
              errors: [" "],
              value: "",
            },
            {
              name: "password",
              errors: [errorMsg],
              value: "",
            },
          ]);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const updateDashboardFlag = (val) => {
    console.log(val, "val");
    setChangeDashboardFlag(val);
  };
  return (
    <Row className="justify-content-center d-flex my-4 admin-shadow ">
      <Col md={8} xs={24}>
        <Card>
          <div>
            <Form form={form} name="basic" onFinish={onFinish} size="large">
              <div className="login-text mb-3">
                <h3 className="text-center"> Admin Login</h3>
              </div>

              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    type: "email",
                    message: "Email is not a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password placeholder="Password " />
              </Form.Item>

              <Row justify="center" className="recapcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
              </Row>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="admin-loginbtn"
                  size={"large"}
                >
                  Login
                </Button>
              </div>
            </Form>

            <div className="forgot-text">
              {/* <p
            className="mb-0 my-2"
            onClick={() => {
              setModalVisible(false);
              forgetForm.resetFields();
              setForgetModal(true);
            }}
          >
            Forgot Your Password
          </p> */}
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminLogin;
