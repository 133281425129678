// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDxSbwvHv78C6lGggqaK61MmIR1g-phgik",
  authDomain: "trusgo-dev.firebaseapp.com",
  databaseURL: "https://trusgo-dev.firebaseio.com",
  projectId: "trusgo-dev",
  storageBucket: "trusgo-dev.appspot.com",
  messagingSenderId: "756005648568",
  appId: "1:756005648568:web:725ee5c1091bdee65cdc4d",
  measurementId: "G-NF0JEBFMGY",
};

// const firebaseConfig = {
//   apiKey: "AIzaXXXXXXXXXXXXXXXXXXXXXXX",
//   authDomain: "test-XXXX.firebaseapp.com",
//   databaseURL: "https://test-XXXXXX.firebaseio.com",
//   projectId: "test-XXXX",
//   storageBucket: "test-XXXX.appspot.com",
//   messagingSenderId: "XXXXXXX",
//   appId: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
// };
