import React from "react";
import { Menu } from "antd";
export const checkRouteAccess = (link, accessList) => {
  if (!accessList) {
    return false;
  }

  if (accessList.indexOf(link) == -1) {
    return false;
  } else {
    return true;
  }
};
const ProtectedMenu = ({ component: Component, accessList, ...rest }) => {
  return checkRouteAccess(rest.children.props.to, accessList) ? (
    <Menu.Item {...rest} />
  ) : null;
};

export default ProtectedMenu;
