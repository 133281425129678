import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PaymentSuccess.scss";
import { Button, Col, message, Row, Spin } from "antd";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import Busticketsuccess from "../../components/Busticketsuccess/Busticketsuccess";
import SuccessSkeleton from "../../common/SuccessSkeleton/SuccessSkeleton";
import BusTicketSuccSkeleton from "../../components/Busticketsuccess/BusTicketSuccSkeleton";
import Flightticketsuccess from "../../components/Flightticketsuccess/Flightticketsuccess";
import FlightTicketSucSkeleton from "../../components/Flightticketsuccess/FlightTicketSucSkeleton";
import HotelTicketSuccess from "../../components/HotelTicketSuccess/HotelTicketSuccess";
// import { res } from "./file";
const PaymentSucces = () => {
  const [type, setType] = useState("");
  const [isBooked, setIsBooked] = useState(false);
  const [actionUrl, setActionUrl] = useState("");
  const [processed, setProcessed] = useState(false);
  const [flightTicketsDetails, setFlightTicketsDetails] = useState({
    BookingRefNo: null,
    flightDetails: [],
    ticketAllData: {},
    loading: true,
  });
  const [busTicketsDetails, setBusTicketsDetails] = useState({
    twoWay: false,
    refNo1: null,
    refNo2: null,
    loading: true,
  });

  const params = queryString.parse(document.location.search);

  const airBook = (value) => {
    // const response = res;
    // console.log(res);
    // setFlightTicketsDetails({
    //   BookingRefNo: response.data.BookingRefNo,
    //   flightDetails: response.data.flightDetails,
    //   ticketAllData: response.charges.ticketAllData,
    //   loading: false,
    // });
    // return;
    ApiClient.post("flights/airBook/" + value)
      .then((response) => {
        if (response.statusCode === 200) {
          setFlightTicketsDetails({
            BookingRefNo: response.data.BookingRefNo,
            flightDetails: response.data.flightDetails,
            ticketAllData: response.charges.ticketAllData,
            loading: false,
          });
          return;
        }
        setFlightTicketsDetails({
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
          error: response?.message ?? "",
        });
      })
      .catch((error) => {
        setFlightTicketsDetails({
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
          error: error,
        });
      });
  };

  const busBookApi = async (preBookingRefId, ticketNo) => {
    setBusTicketsDetails((prev) => ({
      ...prev,
      loading: true,
    }));
    await ApiClient.get("buses/bookTicket?refNo=" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (ticketNo == 2) {
            setBusTicketsDetails((prev) => ({
              ...prev,
              email: resp.data.email,
              refNo2: resp.data.referenceNo,
            }));
          } else {
            setBusTicketsDetails((prev) => ({
              ...prev,
              email: resp.data.email,
              refNo1: resp.data.referenceNo,
            }));
          }
        }
        setBusTicketsDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
  const hotelBookApi = (preBookingRefId) => {
    setHotelTicketsDetails({
      errorMessage: "",
      otherMessage: "",
      ticketAllData: {},
      loading: true,
    });
    ApiClient.post("hotels-v2/hotelBook/" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            ticketAllData: resp.data,
            loading: false,
          });
        } else if (resp.status == 400 && resp.data.length > 0) {
          setHotelTicketsDetails({
            errorMessage: resp.data[0].errorDetail,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        } else {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((err) => {
        setHotelTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          ticketAllData: {},
          loading: false,
        });
      });
  };

  const busBooking = async (ref) => {
    setType("BUS");
    let busResObj = JSON.parse(localStorage.getItem("twoWayBusData"));
    let ticketNo = 1;

    if (busResObj) {
      if (busResObj.length > 1) {
        setBusTicketsDetails({
          twoWay: true,
          refNo1: null,
          refNo2: null,
          loading: true,
        });
        await busBookApi(busResObj[0].BookingReferenceNo, ticketNo);
        ticketNo = 2;
      }
    }

    await busBookApi(ref, ticketNo);
  };

  useEffect(() => {
    if (params.ref && params.ref.indexOf("TPZF") != -1) {
      setType("FLIGHT");
      airBook(params.ref);
    } else if (params.ref && params.ref.indexOf("TPZH") != -1) {
      setType("HOTEL");
      hotelBookApi(params.ref);
    } else if (params.ref && params.ref.indexOf("TPZB") != -1) {
      setType("BUS");
      busBooking(params.ref);
    }

    // if (params && params.Edit === "edit") {
    //   console.log("url params", params);
    //   setIsDateDisplay(false);
    //   form.setFieldsValue({
    //     dateType: params.dateType,
    //     travelType: Number(params.travelType),
    //     fromDate: moment(params.fromDate),
    //     toDate: moment(params.toDate),
    //   });
    // }
  }, []);
  const [hotelTicketsDetails, setHotelTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });

  return (
    <div className="success_area">
      {type === "FLIGHT" ? (
        flightTicketsDetails.loading ? (
          <FlightTicketSucSkeleton />
        ) : (
          <Flightticketsuccess flightTicketsDetails={flightTicketsDetails} />
        )
      ) : null}
      {type === "BUS" ? (
        busTicketsDetails.loading ? (
          <BusTicketSuccSkeleton />
        ) : (
          <Busticketsuccess busTicketsDetails={busTicketsDetails} />
        )
      ) : null}
      {type === "HOTEL" ? (
        hotelTicketsDetails.loading ? (
          <SuccessSkeleton title="Hotel" />
        ) : (
          <HotelTicketSuccess hotelTicketsDetails={hotelTicketsDetails} />
        )
      ) : null}
      {/* {type === "HOTEL" ? (
        <div className="pay_success_area">
          <Row justify={"center"}>
            <Col span={24}>
              <div className="pay_success">
                <h4 className="status-payment-data">Booking Status </h4>
                {processed ? (
                  isBooked ? (
                    <>
                      <div className="pay_success">
                        <h4 className="status-payment-data">
                          Payment Successfull
                        </h4>
                        <span>
                          A confirmation email has been sent to your provided
                          email address
                        </span>
                      </div>

                      <div className="ticket-full-details">
                        <div className="ref-no">
                          <p>
                            Ref-No. : <span>{params.ref}</span>
                          </p>
                        </div>
                        <div className="status">
                          <p>
                            Status :{" "}
                            <span className="booking-status">Success</span>
                          </p>
                        </div>
                      </div>
                      <div className="text-center my-3">
                        <Button type="primary" key="console">
                          <Link to={actionUrl}>View Ticket</Link>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <p style={{ color: "red", fontSize: "18px" }}>
                      Sorry Ticket Not Booked ! Please Contact Admin
                    </p>
                  )
                ) : (
                  <span>
                    Loading...
                    <Spin />
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ) : null} */}
    </div>
  );
};
export default PaymentSucces;
