import React from "react";
import { Button, Col, Collapse, Form, Input, Row, Layout, message } from "antd";
import ApiClient from "../../helpers/ApiClient";

const { Panel } = Collapse;
const { Content } = Layout;
const Createlocation = () => {
  const [form] = Form.useForm();

  const onFormSubmit = (values) => {
    values.cityCodes = values.cityCodes.split(",").filter((item) => item);
    values.aliases = values.aliases.split(",").filter((item) => item);
    createLoc(values);
  };

  const createLoc = (values) => {
    values.countryCode = "IN";

    let data = [values];

    ApiClient.post("mapping/locations/create", data)
      .then((res) => {
        if (res.statusCode == 200) {
          message.success("Location added successfully", 3);
          form.resetFields();
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="mapping-wrapper">
      <Layout>
        <div className="manage-markup-section">
          <Content className="container-fluid">
            <div className="card-bt-gap">
              <Collapse
                bordered={false}
                className="panel_wrapper"
                expandIcon={null}
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Create Location"
                  key="1"
                  showArrow={false}
                  collapsible="disabled"
                >
                  <Form
                    layout="vertical"
                    form={form}
                    initialValues={{ countryCode: "IN" }}
                    onFinish={onFormSubmit}
                  >
                    <Row gutter={16}>
                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Latitude (DMS Format)"
                          name="latitude"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="12° 58' 20.7912'' N"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Longitude (DMS Format)"
                          name="longitude"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="77° 34' 50.3148'' E"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="Bengaluru"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="Karnataka"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Country Code"
                          name="countryCode"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            value="IN"
                            disabled
                            placeholder="IN"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="City Code"
                          name="cityCodes"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="BLR,XYZ"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Aliases"
                          name="aliases"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="Bangalore,Bengaluru"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="reports-btns">
                      <Button htmlType="submit">Submit</Button>
                      <Button type="danger" onClick={() => form.resetFields()}>
                        Reset
                      </Button>
                    </Row>
                  </Form>
                </Panel>
              </Collapse>{" "}
            </div>
          </Content>
        </div>
      </Layout>
    </div>
  );
};

export default Createlocation;
