import React, { useContext, useEffect, useState } from "react";
import "./HotelTicket.scss";
import moment from "moment";
import { TgContext } from "../../providers/TgProvider";
import ApiClient from "../../../helpers/ApiClient";
import getBusTicketPrice from "../busHelper";
import Logo from "../../../assets/images/trippozo_bg_overlay.png";
const HotelTicket = ({ ticketData }) => {
  let dateFormat = "MMM, DD-YYYY";
  const checkin = new Date(ticketData.CheckInDate);
  const checkout = new Date(ticketData.CheckOutDate);
  const diffTime = Math.abs(checkout - checkin);
  const [address, setAddress] = useState([]);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const noOfNights = Number(diffDays);
  const noOfRooms = Number(ticketData.NoOfRooms);
  const BASE = process.env.REACT_APP_BASE_URL;
  const { logo } = useContext(TgContext);

  function getBaseFare() {
    if (ticketData.Rooms.length > 0) {
      let totalBaseFare = ticketData.Rooms.reduce(
        (acc, val) => acc + val.base,
        0
      );

      totalBaseFare = Number(
        noOfRooms * Number(totalBaseFare) * noOfNights
      ).toFixed(2);

      return totalBaseFare;
    }
  }
  function getTaxes() {
    if (ticketData.Rooms.length > 0) {
      let totalTax = ticketData.Rooms.reduce((acc, val) => acc + val.tax, 0);

      return Number(noOfRooms * Number(totalTax)).toFixed(2);
    }
  }
  function getConvFee() {
    let baseFare = getBaseFare();
    let taxes = getTaxes();
    let total = 0;
    total = Number(baseFare) + Number(taxes);
    let convamount = 0;
    if (ticketData.ConvienceData.amount) {
      if (ticketData.ConvienceData.type === 1) {
        convamount = Number(ticketData.ConvienceData.amount).toFixed(2);
      } else {
        convamount = Number(
          (Number(total) / 100) *
            Number(
              ticketData.ConvienceData.amount
                ? ticketData.ConvienceData.amount
                : "0.00"
            )
        ).toFixed(2);
      }
    }
    return convamount;
  }
  const hotelDiscount = () => {
    let baseFare = getBaseFare();
    let taxes = getTaxes();
    let total = 0;
    total = Number(baseFare) + Number(taxes);
    if (ticketData.PromoData.DiscountType == 1) {
      return Number((total / 100) * ticketData.PromoData.Discount).toFixed(2);
    } else {
      return Number(ticketData.PromoData.Discount).toFixed(2);
    }
  };
  const getDicountValue = () => {
    return ticketData.PromoData ? hotelDiscount() : Number("0.00").toFixed(2);
  };
  function getTotal() {
    let base = getBaseFare();
    let tax = getTaxes();
    let convienenceFee = getConvFee();
    let discount = getDicountValue();

    return Number(
      Number(base) + Number(tax) + Number(convienenceFee) - Number(discount)
    ).toFixed(2);
  }
  const getStatus = (status) => {
    switch (status) {
      case 1: {
        return <p style={{ color: "#FFA500" }}>FAILED</p>;
      }
      case 2: {
        return <p style={{ color: "#008000" }}>CONFIRMED</p>;
      }
      case 3: {
        return <p style={{ color: "#FF0000" }}>CANCELED</p>;
      }
      case 4: {
        return <p style={{ color: "#FF0000" }}>PENDING</p>;
      }
      case 5: {
        return <p style={{ color: "#f9e218" }}>REJECTED</p>;
      }
      case 6: {
        return <p style={{ color: "#FF0000" }}>HOLD</p>;
      }
      case 7: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
      }
      case 8: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
      }
      case 9: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
      }
      default:
        return;
    }
  };
  useEffect(() => {
    getAddress();
  }, []);
  const getAddress = () => {
    ApiClient.get("admin/settings/" + 1)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setAddress(data);
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="hotel_Ticket">
      {ticketData.RefNumber ? (
        <table
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
          className="Container"
        >
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2" className="align-c">
              <img
                src={logo ? `${BASE + logo.replace("/", "")}` : Logo}
                width="150"
              />
            </td>
          </tr>

          <tr>
            <td width="60%">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                    >
                      <tr>
                        <td className="font-family fW600 fS12p">Tripozo.com</td>
                      </tr>
                      <tr>
                        <td>
                          {address?.settings?.BusinessAddress[0]?.Address ??
                            null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {address?.settings?.BusinessAddress[0]?.PostalCode ??
                            null}
                        </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td width="40%" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td className="font-family fW300 fS13p align-r"></td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">&nbsp;</td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">
                    <b>Reference Number:</b>
                    {ticketData?.RefNumber}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border "
              >
                <tr className="background-color">
                  <td
                    colspan="4"
                    className="font-family fW600 fS11p align-c"
                    width="34%"
                    height="32"
                  >
                    {ticketData?.HotelName}
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="4"
                    className="font-family fW300 fS11p align-r pd"
                    height="32"
                  >
                    <b>PNR : {ticketData?.ConfirmationNumber}</b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="40%"
                    height="32"
                  >
                    Passenger Detail
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Guest Type
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Age
                  </td>
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    width="20%"
                  >
                    Pan
                  </td>
                </tr>
                {ticketData?.guests.length > 0
                  ? ticketData.guests.map((passenger, key) => (
                      <tr>
                        <td
                          className="font-family fW600 fS11p pd align-l"
                          width="40%"
                          height="32"
                        >
                          <p className="mb0 mt0 fW600 ">
                            {`${key + 1}${"."}${passenger.Title}${"."}${
                              passenger.FirstName
                            }${passenger.LastName}`}
                          </p>
                        </td>
                        <td
                          className="font-family fW300 fS11p pd align-l"
                          width="30%"
                        >
                          {passenger.GuestType}
                        </td>

                        <td
                          className="font-family fW300 fS11p pd align-l"
                          width="30%"
                        >
                          {passenger?.Age}
                        </td>
                      </tr>
                    ))
                  : null}
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l border-top"
                    width="30%"
                    height="32"
                  >
                    Hotel Details
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="20%"
                  >
                    CheckOut Date
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="20%"
                  >
                    CheckIn Date
                  </td>

                  <td
                    className="font-family fW600 fS11p pd align-l  "
                    width="30%"
                  >
                    Status
                  </td>
                </tr>

                <tr>
                  <td
                    className="font-family fW600 fS11p pd align-l"
                    height="32"
                  >
                    <p className="mb0 mt0">{ticketData?.HotelName}</p>

                    <p className="mt0">{`${noOfNights}${"DAYS"}`}</p>
                  </td>
                  <td className="font-family fW300 fS11p pd align-l  ">
                    <p>
                      {`${moment(ticketData?.CheckInDate, "DD-MM-YYYY").format(
                        "hh:mm A"
                      )},${moment(ticketData?.CheckInDate, "DD-MM-YYYY").format(
                        "ddd DD MMM YYYY"
                      )}`}
                    </p>
                  </td>
                  <td className="font-family fW300 fS11p pd align-l ">
                    <p className="mb0 mt0">{`${moment(
                      ticketData.CheckOutDate
                    ).format("hh:mm A")},${moment(
                      ticketData.CheckOutDate,
                      "DD-MM-YYYY"
                    ).format("ddd DD MMM YYYY")}`}</p>
                  </td>
                  <td className="font-family fW300 fS11p pd align-l ">
                    <p className="mt0 mb0 blueColor">
                      {getStatus(ticketData.BookingStatus)}
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    colspan="4"
                    className="font-family fW600 fS11p pd align-l  "
                    width="34%"
                    height="32"
                  >
                    <p className="mb0 mt0">Payment Details</p>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    className="font-family fW300 fS11p pd align-l "
                    width="75%"
                    height="32"
                  >
                    {" "}
                    <p className="mb0 mt0">This is an electronic ticket.</p>
                    <p className="mb0 mt0">
                      Please carry a positive identification for check in.
                    </p>
                  </td>
                  <td
                    colspan="2"
                    className="font-family fW300 fS11p pd align-r  "
                    width="25%"
                    height="32"
                  >
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      className="border-left"
                    >
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="font-family fW300 fS11p ">
                            <b>Base Fare:</b>
                          </td>
                          <td className="font-family fW300 fS11p">
                            <b>{getBaseFare()}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p ">Taxes</td>
                          <td className="font-family fW300 fS11p">
                            {getTaxes()}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p pd-b">
                            <b>Convenience Fee:</b>
                          </td>
                          <td className="font-family fW300 fS11p pd-b">
                            <b>{getConvFee()}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p border-bottom pd-b">
                            <b>Discount :</b>
                          </td>
                          <td className="font-family fW300 fS11p border-bottom pd-b">
                            <b> {getDicountValue()}</b>
                          </td>
                        </tr>

                        <tr rowspan="2">
                          <td className="ptb6p fW600">
                            <b>Total</b>
                          </td>
                          <td className="ptb6p fW600" colspan="2">
                            <b>{getTotal()}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="100%"
                    height="32"
                  >
                    Terms and Conditions
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    {" "}
                    <div className="termCon_wrapper">
                      <p>
                        * Early check in and late check out is subject to
                        availability.
                      </p>

                      <p>
                        * The passenger has to furnish a photo identity and
                        address proof at the time of verification failing to
                        which all passenger on that voucher shall not be
                        entertained with any refunds or restitution.
                      </p>
                      <p>
                        * Standard Check in time is 1400 hrs / check out time
                        1200 hrs unless specified.
                      </p>

                      <p>
                        * The refund for early check out or unutilized night or
                        service is subject to discretion of the hotel & the
                        supplier, we strongly recommend to get a letter from the
                        hotel favoring no charges for early check out.
                      </p>

                      <p>
                        * All additional charges other than the room charges and
                        inclusions as mentioned in the booking voucher are to be
                        borne and paid separately during check-out. Please make
                        sure that you are aware of all such charges that may
                        comes as extras. Some of them can be WiFi costs, Mini
                        Bar, Laundry Expenses, Telephone calls, Room Service,
                        Snacks etc.
                      </p>

                      <p>
                        {" "}
                        * Voucher covers only those taxes which are included in
                        the booking price (if included), all various other
                        applicable taxes needs to be settled directly by guest .{" "}
                      </p>
                      <p>
                        * We are not liable for any loss or damage caused during
                        your stay in the hotel or while availing any service.
                      </p>

                      <p>
                        * We have no relevance with the room service or food
                        menu or any other service as these are all as per the
                        hotel standards.
                      </p>

                      <p>
                        * The hotel reserves the right of admission.
                        Accommodation can be denied to guests posing as a
                        'couple' if suitable proof of identification is not
                        presented at the time of check in. we will not be
                        responsible for any check in denied by the hotel due to
                        aforesaid reason.
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
        </table>
      ) : null}
    </div>
  );
};
export default HotelTicket;
