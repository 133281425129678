import React, { useContext, useState } from "react";
import { Card, Col, Row, Form, Popconfirm, Input, message } from "antd";
import FareSummaryCard from "../FlightsCheckout/FareSummaryCard";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import BookPayCard from "../FlightsCheckout/Book-Pay-Card";
import "./Flights-review.scss";
import ApiClient from "../../helpers/ApiClient";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import moment from "moment";
import { FlightGrandTotalCard } from "../FlightsCheckout/FlightGrandTotalCard";
import FlightDetailsCard from "../FlightsCheckout/Flight-Details-Card";
import { getFlightGrandTotalPrice } from "../FlightsCheckout/flightPriceHelper";
import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";
import { useAuthContext } from "../../common/providers/AuthProvider";

const Review = () => {
  const {
    state: {
      flightPersist: { flightSearchObj, selectedFlight },
      airBookStateObj,
      flightAirPriceResp,
    },
    updateAirBookRespState,
  } = useFlightContext();
  const {
    state: {
      otherData: { promoData, ConvFee },
    },
  } = useContext(GlobalStatesContext);

  const { user } = useAuthContext();
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";

  let history = useHistory();

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const fetchAirBook = () => {
    //add Dicount and Convience Fee

    let allAmount = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj
    );

    // airBookStateObj.promoData = promoData;

    // airBookStateObj.convienenceData = ConvFee;
    airBookStateObj.promoCode = promoData.Code ?? "";
    airBookStateObj.convienenceId = ConvFee.id ?? 0;
    airBookStateObj.totalPrice = Number(allAmount.grandTotal);

    ApiClient.post("flights/airBlock", airBookStateObj)
      .then((resp) => {
        return resp;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          updateAirBookRespState(resp);

          processPayment(
            resp.data.BookingRefNo,
            allAmount,
            resp.data.signature
          );
        } else {
          console.log("airbook feching failed");
        }
      })
      .catch((err) => console.log(err));
  };

  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});
  const processPayment = (oid, allAmount, signature) => {
    const userDetails = {
      ...airBookStateObj.passengers[0],
    };

    let data = {
      OrderId: oid,
      OrderAmount: Number(allAmount.grandTotal),
      OrderCurrency: "INR",
      OrderNote: "FLIGHT TICKET",
      CustomerName: userDetails.firstName + " " + userDetails.lastName,
      CustomerEmail: userDetails.email,
      CustomerPhone: userDetails.mobile,
      msg: signature,
    };

    // set PgData and Display
    setPgData(data);
    setPgDisplay(true);
  };

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyBookingOTP", {
      mobile: airBookStateObj.passengers[0].mobile,
      dialingCode: airBookStateObj.passengers[0].areaCode,
      OTP: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          fetchAirBook();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendBookingOTP", {
      mobile: airBookStateObj.passengers[0].mobile,
      dialingCode: airBookStateObj.passengers[0].areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = () => {
    const grandTotal = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 1,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          if (user) {
            fetchAirBook();
          } else {
            handleSendOTP();
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <section className="flight-wrapper">
      <div className="flight-checkout review-container">
        <div className="checkout-heading">
          <Popconfirm
            placement="right"
            title="Are you sure you want to navigate back from this page you may lose data?"
            onConfirm={() => {
              history.go(-1);
            }}
            overlayClassName="flighttoast"
          >
            <div className="goback">
              <ArrowLeftOutlined />
              <span>Go back </span>
            </div>
          </Popconfirm>
        </div>
        <Row gutter={[16, 16]} className="flight-review-row">
          <Col md={16} xs={24}>
            <>
              <FlightDetailsCard
                selectedFlightData={selectedFlight}
                flightSearchData={flightSearchObj}
              />
              <div className="user-details">
                <p className="heading">Passengers</p>
                <Card className="flight-cards-details">
                  {airBookStateObj?.passengers?.map(
                    (passengerDetailsObj, passengerIndex) => (
                      <div key={passengerIndex}>
                        <div className="card-adult-text">
                          <div className="pax-heading">
                            <div className="pax-heading">
                              <p>
                                {passengerDetailsObj.paxType === "ADT"
                                  ? "Adult"
                                  : passengerDetailsObj.paxType === "CHD"
                                  ? "Child"
                                  : "Infant"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <Form layout="vertical" className="passenger-form">
                          <Row gutter={[16, 8]}>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item label="Name" name="passengerName">
                                <Input
                                  autoComplete="newpassword"
                                  autoComplete="off"
                                  className="contacts-value-de"
                                  size={"large"}
                                  plaintext
                                  readOnly
                                  defaultValue={
                                    passengerDetailsObj.title +
                                    " " +
                                    passengerDetailsObj.firstName +
                                    " " +
                                    passengerDetailsObj.lastName
                                  }
                                />
                              </Form.Item>
                            </Col>

                            {passengerDetailsObj.genderType ? (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item label="Gender" name="gender">
                                  <Input
                                    autoComplete="newpassword"
                                    autoComplete="off"
                                    className="contacts-value-de"
                                    size={"large"}
                                    plaintext
                                    readOnly
                                    defaultValue={
                                      passengerDetailsObj.genderType === "m"
                                        ? "Male"
                                        : "Female"
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {passengerDetailsObj.dob ? (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item label="Date of birth" name="dob">
                                  <Input
                                    autoComplete="newpassword"
                                    autoComplete="off"
                                    className="contacts-value-de"
                                    size={"large"}
                                    plaintext
                                    readOnly
                                    defaultValue={moment(
                                      passengerDetailsObj.dob,
                                      oriDateFormat
                                    ).format(dateFormat)}
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {passengerDetailsObj.passportNumber ? (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport Number"
                                  name="passportNumber"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    autoComplete="off"
                                    className="contacts-value-de"
                                    size={"large"}
                                    plaintext
                                    readOnly
                                    defaultValue={
                                      passengerDetailsObj.passportNumber
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {passengerDetailsObj.passportDOI ? (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport Issue Date"
                                  name="doi"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    className="contacts-value-de"
                                    autoComplete="off"
                                    size={"large"}
                                    plaintext
                                    readOnly
                                    defaultValue={moment(
                                      passengerDetailsObj.passportDOI,
                                      oriDateFormat
                                    ).format(dateFormat)}
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {passengerDetailsObj.passportDOE ? (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport Expiry Date"
                                  name="doe"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    autoComplete="off"
                                    className="contacts-value-de"
                                    size={"large"}
                                    plaintext
                                    readOnly
                                    defaultValue={moment(
                                      passengerDetailsObj.passportDOE,
                                      oriDateFormat
                                    ).format(dateFormat)}
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {passengerDetailsObj.passportIssuedCountry ? (
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport Issue Country"
                                  name="passportIssuedCountry"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    autoComplete="off"
                                    className="contacts-value-de"
                                    size={"large"}
                                    plaintext
                                    readOnly
                                    defaultValue={
                                      passengerDetailsObj.passportIssuedCountry
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                          </Row>
                        </Form>
                      </div>
                    )
                  )}
                </Card>
              </div>

              {airBookStateObj?.gstDetails?.gstNumber === "" ? null : (
                <div className="user-details">
                  <p className="heading">GST Details</p>
                  <Card className="checkout-custom-card">
                    <Form layout="vertical" className="passenger-form">
                      <Row gutter={[16, 8]}>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Company Name"
                            rules={[{ required: true }]}
                            name="companyname"
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              className="contacts-value-de"
                              size={"large"}
                              plaintext
                              readOnly
                              defaultValue={
                                airBookStateObj?.gstDetails?.gstCompanyName
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="GST Number"
                            rules={[{ required: true }]}
                            name="gstNumber"
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              className="contacts-value-de"
                              size={"large"}
                              plaintext
                              readOnly
                              defaultValue={
                                airBookStateObj?.gstDetails?.gstNumber
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Contact Number"
                            rules={[{ required: true }]}
                            name="contactNumber"
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              size={"large"}
                              className="contacts-value-de"
                              plaintext
                              readOnly
                              defaultValue={
                                airBookStateObj?.gstDetails?.gstPhoneNo
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Company Email"
                            rules={[{ required: true }]}
                            name="companyemail"
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              className="contacts-value-de"
                              size={"large"}
                              plaintext
                              readOnly
                              defaultValue={
                                airBookStateObj?.gstDetails?.gstEmailId
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Company Address"
                            rules={[{ required: true }]}
                            name="companyaddress"
                          >
                            <Input
                              autoComplete="newpassword"
                              autoComplete="off"
                              size={"large"}
                              className="contacts-value-de"
                              plaintext
                              readOnly
                              defaultValue={
                                airBookStateObj?.gstDetails?.gstAddressLine1
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </div>
              )}
            </>
          </Col>
          <Col xs={24} md={8}>
            <div className="checkout-sticky-part">
              {flightAirPriceResp?.flightDetails?.map((flightDetObj, i) => (
                <FareSummaryCard
                  flightSearchObj={flightSearchObj}
                  flightDetObj={flightDetObj}
                  currency={flightDetObj.fareFamilies[0].currency}
                  title={i === 0 ? "Onward Trip Fare" : "Return Trip Fare"}
                />
              ))}

              <div className="grand-total-card">
                {flightAirPriceResp?.flightDetails?.length > 0 ? (
                  <FlightGrandTotalCard
                    airPriceResp={flightAirPriceResp}
                    flightSearchObj={flightSearchObj}
                    PromoNotVisible={true}
                  />
                ) : null}
              </div>
              <BookPayCard
                isLoading={true}
                bookpaycardinfo={"flight-review"}
                fetchAirBook={ValidateBookingLimit}
                pgDisplay={pgDisplay}
                pgData={pgData}
              />
            </div>
          </Col>
        </Row>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={airBookStateObj.passengers[0].mobile}
        />
      )}
    </section>
  );
};

export default Review;
