import React from "react";
import { useState } from "react";
import {
  Button,
  Collapse,
  Col,
  Form,
  Modal,
  Input,
  Row,
  Popconfirm,
  Spin,
  Table,
  Layout,
  message,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";

const { Panel } = Collapse;
const { Content } = Layout;

const Updatelocation = () => {
  const [form] = Form.useForm();
  const [searchform] = Form.useForm();
  const [isloading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [locList, setLocList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [locId, setLocId] = useState(-1);

  const showModal = () => {
    setModalVisible(true);
  };

  const onSearch = (values) => {
    setShowTable(false);
    console.log(values);
    getLocByName(values.cityName);
  };

  const getLocByName = (cityName) => {
    setIsLoading(true);
    setShowTable(true);
    ApiClient.get("mapping/locations/findByName/" + cityName)
      .then((res) => {
        console.log(res);
        if (res) {
          if (res.length > 0) {
            let data = res.map((item, index) => {
              return {
                ...item,
                SNo: index + 1,
              };
            });
            setLocList(data);
          } else {
            setLocList([]);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setLocList([]);
        setIsLoading(false);
      });
  };

  const onFormSubmit = (values) => {
    values.cityCodes = values.cityCodes.split(",").filter((item) => item);
    values.aliases = values.aliases.split(",").filter((item) => item);
    updateLoc(values);
  };
  const updateLoc = (values) => {
    values.countryCode = "IN";
    ApiClient.put("mapping/locations/update/" + locId, {}, values)
      .then((res) => {
        console.log(res);
        if (res.statusCode === 200) {
          setShowTable(false);
          message.success("Location Updated successfully", 3);
          setLocId(-1);
          setLocList([]);

          form.resetFields();
          setModalVisible(false);
        }
      })
      .catch((error) => {});
  };

  const updateLocModel = (id) => {
    setShowTable(true);
    setIsLoading(false);
    setIsLoading(true);
    ApiClient.get("mapping/locations/findById/" + id)
      .then((res) => {
        if (res.statusCode === 200) {
          setLocId(id);
          let { data } = res;
          form.setFieldsValue({
            latitude: data.geoLocation.coordinates[1],
            longitude: data.geoLocation.coordinates[0],
            city: data.city,
            state: data.state,
            countryCode: data.countryCode,
            cityCodes: data.cityCodes.toString(),
            aliases: data.aliases.toString(),
          });
          showModal();
        }
        setIsLoading(false);
        setShowTable(true);
      })
      .catch(() => {
        setShowTable(true);
        setLocList([]);
        setIsLoading(false);
      });
  };

  const deleteLoc = (id) => {
    console.log(id);

    ApiClient.delete("mapping/locations/delete/" + id)
      .then((res) => {
        if (res.statusCode === 200) {
          message.success("Location Deleted  successfully", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableActions = (id) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateLocModel(id)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteLoc(id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },
    {
      title: "City",
      dataIndex: "city",

      sorter: (a, b) => a.city.localeCompare(b.city),
      key: "City",
    },
    {
      title: "State",
      dataIndex: "state",

      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "geoLocation",
      dataIndex: "geoLocation",
      key: "geoLocation",
      render: (text) => (text ? text.coordinates.toString() : ""),
    },

    {
      title: "Country Code",
      dataIndex: "countryCode",
      key: "countryCode",
      sorter: (a, b) => a.countryCode.localeCompare(b.countryCode),
    },
    // {
    //   title: "cityCodes",
    //   dataIndex: "cityCodes",
    //   key: "cityCodes",
    //   render: (text) => (text ? JSON.stringify(text) : ""),
    // },
    {
      title: "Aliases",
      dataIndex: "aliases",
      key: "aliases",
      render: (text) => (text ? text.toString() : ""),
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (text) => {
        return tableActions(text);
      },
    },
  ];

  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <div className="mapping-wrapper">
      <Layout>
        <div className="manage-markup-section">
          <Content className="container-fluid">
            <div className="card-bt-gap">
              <Collapse
                bordered={false}
                className="panel_wrapper"
                expandIcon={null}
                defaultActiveKey={["1"]}
              >
                <Panel
                  header="Update Location"
                  key="1"
                  showArrow={false}
                  collapsible="disabled"
                >
                  <Form form={searchform} layout="vertical" onFinish={onSearch}>
                    <Row gutter={16} align="middle">
                      <Col lg={6} md={12} xs={24}>
                        <Form.Item
                          label="City Name"
                          name="cityName"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} md={12} xs={24}>
                        <Button style={{ marginTop: "4px" }} htmlType="submit">
                          Search
                        </Button>
                        <Button
                          style={{ marginTop: "4px", marginLeft: "5px" }}
                          onClick={() => {
                            searchform.resetFields();
                            setShowTable(false);
                            setLocList([]);
                          }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {showTable ? (
                    locList.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <h5>View Locations </h5>
                            <p>{locList.length} rows found !</p>
                          </div>
                        </div>

                        <Table
                          scroll={{ x: true }}
                          bordered
                          dataSource={locList}
                          columns={columns}
                          rowKey={keyGenerate}
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "25", "50", "100", "125"],
                          }}
                        />
                      </>
                    ) : isloading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )
                  ) : (
                    ""
                  )}
                </Panel>
              </Collapse>
            </div>
          </Content>
        </div>
      </Layout>
      <Modal
        title="Update Location"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={"800px"}
        footer={[
          <>
            <Button key="add" type="primary" onClick={form.submit}>
              Update
            </Button>
          </>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{ countryCode: "IN" }}
          onFinish={onFormSubmit}
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Latitude (DMS Format)"
                name="latitude"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  placeholder="12° 58' 20.7912'' N"
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Longitude (DMS Format)"
                name="longitude"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  placeholder="77° 34' 50.3148'' E"
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  placeholder="Bengaluru"
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  placeholder="Karnataka"
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Country Code"
                name="countryCode"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  value="IN"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="City Code"
                name="cityCodes"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  placeholder="BLR,XYZ"
                />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Aliases"
                name="aliases"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  autoComplete="newpassword"
                  autoComplete="off"
                  placeholder="Bangalore,Bengaluru"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Updatelocation;
