import React from "react";

import { Card, Col, Row, Table } from "antd";

const Bussalescount = ({ busData }) => {
  let key = 0;

  const columnsBuscount = [
    {
      title: "ReferenceID",
      dataIndex: "BookingRefNo",
      key: "BookingRefNo",
    },
    {
      title: "Source",
      dataIndex: "SourceName",
      key: "SourceName",
    },
    {
      title: "Journey Date",
      dataIndex: "JourneyDate",
      key: "JourneyDate",
    },

    {
      title: "Amount ",
      dataIndex: "CollectedFare",
      render: (p) => (p ? Math.round(Number(p)) : ""),
      key: "Fares",
    },

    // {
    //   title: "Status",
    //   dataIndex: "BookingStatus",
    //   key: "BookingStatus",
    // },

  ];

  const keyGenerate = () => {
    return key++;
  };
  return (
    <>
      <div className="order-count-cards ">
        <Row>
          <Col xs={24} md={24}>
            <h4 className="flights-sales-card">Bus Sales</h4>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <span className="ad-span-col bg-1">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Booking Count</p>
                <h5>{Number(busData.booking_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <span className="ad-span-col bg-1">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Cancelled Count</p>
                <h5>{Number(busData.cancelled_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <span className="ad-span-col bg-6">
                <i className="fa fa-bar-chart"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Day Sales </p>
                <h5>{Number(busData.day_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <span className="ad-span-col bg-7">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Yesterday Sales </p>
                <h5>{Number(busData.yesterday_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <span className="ad-span-col bg-8">
                <i className="fa fa-calendar-o" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Week Sales </p>
                <h5>{Number(busData.week_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <span className="ad-span-col bg-9">
                <i className="fa fa-calendar-o" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>month Sales </p>
                <h5>{Number(busData.month_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="order-count-cards ">
        <Row
          gutter={[
            { xs: 0, md: 16 },
            { xs: 8, md: 16 },
          ]}
          className="charts-bars card-count-balance"
        >
          <Col xs={24} md={12}>
            <Card className="side-bar-graph-1">
              <p className="month-wise-cart">Upcoming Bookings</p>

              <Table
                className=" flight-new-pagenation"
                scroll={{ x: true }}
                bordered
                dataSource={busData.upcoming_bookings}
                columns={columnsBuscount}
                rowKey={keyGenerate}
              />
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card className="side-bar-graph-new-flight">
              <p className="month-wise-cart">Completed Trips</p>

              <Table
                className=" flight-new-pagenation"
                scroll={{ x: true }}
                bordered
                dataSource={busData.recent_bookings}
                columns={columnsBuscount}
                rowKey={keyGenerate}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Bussalescount;
