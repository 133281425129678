import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row, Tooltip } from "antd";
import "../SeperatedView/SeperatedView.scss";
import Flight, { FlightSelection, FlightTimings } from "../Flight/Flight";
import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";
import moment from "moment";
const SeperatedView = ({
  selectedFlight,
  journeyType,
  flightDetails,
  changeFlightResultsView,
  setOnwardFlights,
  flightSearchObj,
  setFlightFareRules,
  changeReturnFlights,
}) => {
  const flightInfo = selectedFlight;
  const history = useHistory();
  const [pagination, SetPagination] = useState(51);
  const showMore = () => {
    SetPagination((prev) => prev + 51);
  };

  const [flightList, setFlightList] = useState([]);

  useEffect(() => {
    let visibleData = flightDetails.filter((item) => item.isVisible);
    setFlightList(visibleData);
  }, [flightDetails]);

  const editOnclick = (type) => {
    if (type == 1) {
      setOnwardFlights();
    } else {
      changeReturnFlights();
    }
  };
  const toolBreakCity = (breakCityDest) => {
    return (
      <div className="tooltip-data">
        <h4>Change</h4>
        <p>{breakCityDest}</p>
      </div>
    );
  };

  const goToCheckoutPage = () => {
    history.push("/flight-checkout");
  };
  const getSelectedTotalFare = (selectedflightsArray) => {
    let totalAmount = 0;
    if (selectedflightsArray.length > 1) {
      totalAmount = selectedflightsArray.reduce((prev, cur) => {
        return prev + Number(cur.fareFamilies.fareFamilies[0].adultNetFare);
      }, 0);
    }
    return Number(totalAmount).toFixed(2);
  };
  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };
  return (
    <div className="flights-seperated-view">
      {selectedFlight.length > 0 ? (
        <>
          <div className="edit-onward">
            <div className="card-results">
              <Row gutter={[16, 8]}>
                <Col md={10} sm={10} xs={24} className="flight-border-right">
                  <div className="title-box ">
                    <p className="title mb-0">
                      {" "}
                      <strong>Onward Selection</strong>
                    </p>
                    {/* <p className="travel_date">
                      {" "}
                      {moment(
                        selectedFlight[0].flightSegments[0].departureDateTime
                      ).format("MMM, DD-YYYY")}
                    </p> */}
                    <p
                      onClick={() => editOnclick(1)}
                      // className="tg-primary-btn"
                      className="change-text"
                    >
                      Change
                    </p>
                  </div>
                  <div className="card-results-travel-details">
                    <div className="itinary_wrapper">
                      <div className="itinary-details">
                        <div className="itinary-flight-name">
                          <img
                            className="airline-img"
                            src={selectedFlight[0].airLineLogo}
                            alt={selectedFlight[0].airLineLogo}
                          />
                          <p className="name">
                            {selectedFlight[0].airLineName}
                          </p>
                        </div>

                        <div className="fromTo">
                          <div className="cities-from-to">
                            <div className="loc_wrapper">
                              <span className="name">
                                {
                                  selectedFlight[0].flightSegments[0].originName.split(
                                    " "
                                  )[0]
                                }
                              </span>
                              <span className="code">
                                ({selectedFlight[0].flightSegments[0].origin})
                              </span>

                              <div className="break-journey-city">
                                <div className="inner_wrapper">
                                  {selectedFlight[0].flightSegments.map(
                                    (flightSegInfo, index) =>
                                      index !== 0 ? (
                                        <div
                                          className="stop_points"
                                          key={flightSegInfo.segId}
                                        >
                                          <Tooltip
                                            placement="top"
                                            title={toolBreakCity(
                                              flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.originName
                                            )}
                                            className="citynames-tooltip"
                                          >
                                            <span className="break-city-name pointer_cursor">
                                              {flightSegInfo.origin}
                                            </span>
                                          </Tooltip>
                                        </div>
                                      ) : null
                                  )}
                                </div>
                              </div>

                              <span className="name">
                                {
                                  selectedFlight[0].flightSegments[
                                    selectedFlight[0].flightSegments.length - 1
                                  ].destiantionName.split(" ")[0]
                                }
                              </span>
                              <span className="code">
                                (
                                {
                                  selectedFlight[0].flightSegments[
                                    selectedFlight[0].flightSegments.length - 1
                                  ].destination
                                }
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="slectedtiming">
                        <p className="price">
                          <span>₹</span>
                          {selectedFlight[0].fareFamilies.fareFamilies[0].adultNetFare.toFixed(
                            2
                          )}{" "}
                        </p>
                        {/* <FlightTimings
                          flightDetails={flightInfo[0]}
                          flightSearchData={flightSearchObj}
                        /> */}
                        <div className="flight-class-details">
                          <span className="stopsType">
                            {selectedFlight[0].flightSegments.length - 1 ===
                            0 ? (
                              <>Direct</>
                            ) : (
                              <>
                                {selectedFlight[0].flightSegments.length - 1}{" "}
                                stops{" "}
                              </>
                            )}{" "}
                            | {flightSearchObj.cabinClass}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="btncontainer">
                    <p
                      onClick={() => editOnclick(1)}
                      className="tg-primary-btn"
                    >
                      Change
                    </p>
                  </div> */}
                </Col>
                {selectedFlight.length > 1 ? (
                  <>
                    <Col
                      md={10}
                      sm={10}
                      xs={24}
                      className="flight-border-right"
                    >
                      <div className="title-box ">
                        <p className="title mb-0">
                          {" "}
                          <strong>Return Selection</strong>
                        </p>
                        {/* <p className="travel_date">
                          {" "}
                          {moment(
                            selectedFlight[1].flightSegments[0]
                              .departureDateTime
                          ).format("MMM, DD-YYYY")}
                        </p> */}
                        <p
                          onClick={() => editOnclick(2)}
                          // className="tg-primary-btn"
                          className="change-text"
                        >
                          Change
                        </p>
                      </div>
                      <div className="card-results-travel-details">
                        <div className="itinary_wrapper">
                          <div className="itinary-details">
                            <div className="itinary-flight-name">
                              <img
                                className="airline-img"
                                src={selectedFlight[1].airLineLogo}
                                alt={selectedFlight[1].airLineLogo}
                              />
                              <p className="name">
                                {selectedFlight[1].airLineName}
                              </p>
                            </div>
                            <div className="fromTo">
                              <div className="cities-from-to">
                                <div className="loc_wrapper">
                                  <span className="name">
                                    {
                                      selectedFlight[1].flightSegments[0].originName.split(
                                        " "
                                      )[0]
                                    }
                                  </span>
                                  <span className="code">
                                    (
                                    {selectedFlight[1].flightSegments[0].origin}
                                    )
                                  </span>

                                  <div className="break-journey-city">
                                    <div className="inner_wrapper">
                                      {selectedFlight[1].flightSegments.map(
                                        (flightSegInfo, index) =>
                                          index !== 0 ? (
                                            <div
                                              className="stop_points"
                                              key={flightSegInfo.segId}
                                            >
                                              <Tooltip
                                                placement="top"
                                                title={toolBreakCity(
                                                  flightSegInfo.origin +
                                                    " " +
                                                    flightSegInfo.originName
                                                )}
                                                className="citynames-tooltip"
                                              >
                                                <span className="break-city-name pointer_cursor">
                                                  {flightSegInfo.origin}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          ) : null
                                      )}
                                    </div>
                                  </div>

                                  <span className="name">
                                    {
                                      selectedFlight[1].flightSegments[
                                        selectedFlight[1].flightSegments
                                          .length - 1
                                      ].destiantionName.split(" ")[0]
                                    }
                                  </span>
                                  <span className="code">
                                    (
                                    {
                                      selectedFlight[1].flightSegments[
                                        selectedFlight[1].flightSegments
                                          .length - 1
                                      ].destination
                                    }
                                    )
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="slectedtiming">
                            {/* <FlightTimings
                          flightDetails={flightInfo[0]}
                          flightSearchData={flightSearchObj}
                        /> */}
                            <p className="price">
                              <span>₹</span>
                              {selectedFlight[1].fareFamilies.fareFamilies[0].adultNetFare.toFixed(
                                2
                              )}{" "}
                            </p>
                            <div className="flight-class-details">
                              <span className="stopsType">
                                {selectedFlight[1].flightSegments.length - 1 ===
                                0 ? (
                                  <>Direct</>
                                ) : (
                                  <>
                                    {selectedFlight[1].flightSegments.length -
                                      1}{" "}
                                    stops{" "}
                                  </>
                                )}{" "}
                                | {flightSearchObj.cabinClass}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="btncontainer">
                        <Button
                          onClick={() => editOnclick(2)}
                          className="tg-primary-btn"
                        >
                          Change
                        </Button>
                      </div> */}
                    </Col>
                    <Col md={4} sm={4} xs={24}>
                      <div className="selected-flights-price-wrapper">
                        <div className="selected-flights-price">
                          <div className="price-box">
                            <p className="totalFare">
                              <strong>
                                {" "}
                                ₹ {getSelectedTotalFare(selectedFlight)}
                              </strong>
                            </p>
                          </div>

                          <Button
                            type="primary"
                            className="tg-primary-btn goto-btn"
                            onClick={goToCheckoutPage}
                          >
                            Book
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>
          <div className="edit-onward">
            <p className="returntext">
              Select For {journeyType === "return" ? "Return" : "Onward"}{" "}
            </p>
          </div>
        </>
      ) : null}

      {flightList.length > 0 ? (
        <div className="resultsScrollWrapper">
          {flightList.map((flight, index) => {
            return index < pagination ? (
              <div className="flightCardWrapper" key={index}>
                <Flight
                  flightInfo={flight}
                  key={flight.flightId}
                  // changeFlightResultsView={changeFlightResultsView}
                  flightSearchData={flightSearchObj}
                  setFlightFareRules={setFlightFareRules}
                  // promoData={filteredPromoData}
                  fareFamilies={flight.fareFamilies}
                  showFare={true}
                />

                <div className="choose-btn-wrapper flights-btn-view">
                  <FlightSelection
                    flightInfo={flight}
                    changeFlightResultsView={changeFlightResultsView}
                    flightSearchData={flightSearchObj}
                    flightDetails={flightDetails}
                    selectedFlight={selectedFlight}
                    journeyType={journeyType}
                  />
                </div>
              </div>
            ) : index === pagination ? (
              <div className="showmore-wrapper">
                <Button onClick={showMore}>Show More</Button>
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <CustomNoResultFound title={"No Flights Available"} />
      )}
    </div>
  );
};

export default SeperatedView;
