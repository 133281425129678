//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import { Button, Card, Col, Form, Input, Row, Radio, message } from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";

import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getBusGrandTotal = (selectedBusData, ConvFee, promoData) => {
  let agentMarkup = 0;

  let fareInfoData = selectedBusData.length > 0 ? selectedBusData.map((selectBusObj) => selectBusObj.fareInfo) : null;

  fareInfoData = fareInfoData.filter((i) => i != undefined);

  let totalBaseFare = fareInfoData.reduce((acc, value) => Number(value.baseFare) + Number(acc), 0);

  let totalGst = fareInfoData.reduce((acc, value) => Number(value.gst) + Number(acc), 0);

  let totalCommission = fareInfoData.reduce((acc, value) => Number(value.commission) + Number(acc), 0);

  let total = Number(totalBaseFare) + Number(totalGst) + Number(totalCommission);
  let convamount = 0;

  let discount = Number(promoData.Discount);

  if (ConvFee.type == 0) {
    convamount = Number(ConvFee.amount);
  } else {
    convamount = Number((total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0));
  }

  total = total + Number(convamount);

  let totalFareCalculations = Number(total);
  totalFareCalculations = Number(totalFareCalculations - Number(discount)).toFixed(2);

  agentMarkup = fareInfoData.reduce((acc, value) => Number(value.agentMarkup) + Number(acc), 0);
  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount,
    totalFareCalculations,
    convamount: Number(convamount).toFixed(2),
    totalCommission: totalCommission ? Number(totalCommission) : 0,
    agentMarkup,
  };
};

const FareDetails = ({ selectedBusData, isPromoVisible }) => {
  const [form] = Form.useForm();
  const { user } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData },
    },
    validatePromoCode,
    AddConvFee,
    RemovePromo,
  } = useContext(GlobalStatesContext);
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  let { totalBaseFare, totalGst, discount, convamount, totalFareCalculations, insuranceTotal } = getBusGrandTotal(selectedBusData, ConvFee, promoData);

  useEffect(() => {
    AddConvFee(3);
    getpromo();
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) => moment(item.ValidTill, dateFormat).isSameOrAfter(moment()));
          if (data.length > 0) {
            let busPromo = data.filter((promo) => promo.ServiceType === 3 && (promo.ApplicationType === 1 || promo.ApplicationType === 3));

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });

    form.submit();
  };

  return (
    <>
      <div className="busFareDetail_wrapper">
        <Card>
          <div className="price_wrapper">
            <p className="text">Base Fare</p>
            <p className="price">
              <i className="fa fa-inr" aria-hidden="true" />
              {totalBaseFare ? totalBaseFare : ""}
            </p>
          </div>

          <div className="price_wrapper">
            <p className="text">GST Amount</p>
            <p className="price">Rs {totalGst ? totalGst : ""}</p>
          </div>

          <>
            <div className="price_wrapper">
              <p className="text">Discount</p>
              <p className="price">
                <i className="fa fa-inr" aria-hidden="true" />
                {promoData.status ? discount : " 0.00"}
              </p>
            </div>

            <div className="price_wrapper">
              <p className="text">Convenience Fee</p>
              <p className="price">Rs {convamount}</p>
            </div>
          </>

          {/* {selectedInsuranceData.status === 1 &&
          selectedInsuranceData?.serviceType === 3 ? (
            <div className="price_wrapper">
              <p className="text">Insurance Amount</p>
              <p className="price">Rs {insuranceTotal}</p>
            </div>
          ) : null} */}

          <div className="price_wrapper total_price">
            <p className="text">Total:</p>
            <p className="price">
              Rs
              {totalFareCalculations ? totalFareCalculations : ""}
            </p>
          </div>
        </Card>
      </div>
      {isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <Card>
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  validatePromoCode({
                    ServiceType: 3,
                    Amount: Number(totalFareCalculations),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item name="promo" rules={[{ required: true, message: "Required" }]}>
                      <Input className="inputbg" placeholder="Enter Your Promo code" autoComplete="off" />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" onClick={() => form.submit()}>
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio onClick={() => handleApply(item.Code)}></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer mx-1">Save {item.Discount + " %"}</p>
                          ) : (
                            <p className="save-cp-offer">
                              Save <i className="fa fa-inr" aria-hidden="true" /> &nbsp;
                              {item.Discount}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
    </>
  );
};
export default FareDetails;
