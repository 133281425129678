import React from "react";
import * as ANTD from "antd";
import { Link } from "react-router-dom";
import "./unauth.scss";
//import img from "../../assets/images/Ap_Logo.png";
const NoResultFound = () => {
  return (
    <div className="error-404 ">
      <img
        className="no-results-img"
        src={require("../../assets/images/no-results-v1.png")}
        alt="search-img"
        srcSet=""
      />
      <p className="text-results-found">No Results Found</p>
      <Link to="/">
        <ANTD.Button className="backto-homebtn" type="primary">
          Back Home
        </ANTD.Button>
      </Link>
      {/* <ANTD.Result
        status="500"
        title="No Results Found"
        subTitle=""
        extra={
          <Link to="/">
            <ANTD.Button type="primary">Back Home</ANTD.Button>
          </Link>
        }
      /> */}
    </div>
  );
};

export default NoResultFound;
