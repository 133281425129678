import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Input,
  Layout,
  Modal,
  Row,
  Table,
  Button,
  message,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import { CloseOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { exportExcel } from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";

const { Content } = Layout;

function ContactForm() {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [contactForms, setContactForms] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showRequestModal, setRequestModal] = useState(false);
  const [bulkDelete, setBulkDelete] = useState([]);
  const [msg, setMsg] = useState("");
  const getContactList = () => {
    setContactForms([]);
    setTableData([]);
    ApiClient.get("admin/contacts")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              key: index,
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setContactForms(data);
          setTableData(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const BulckDelete = () => {
    let deleteContact = {
      deleteContact: bulkDelete,
    };
    ApiClient.post("admin/contactsbulkdelete", deleteContact)
      .then((result) => result)
      .then((resp) => {
        if (resp.status == 200) {
          message.success(
            `${bulkDelete.length} Record Deleted  successfully`,
            3
          );
          getContactList();
        }
        if (resp.status == 400) {
          message.error(`please select Record to Delete`, 3);
          getContactList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBulkDelete(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };
  useEffect(() => {
    getContactList();
  }, []);
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  const MsgReq = (msg) => {
    return (
      <div className="eyewrapper">
        <EyeOutlined
          onClick={() => {
            setMsg(msg);

            setRequestModal(true);
          }}
        />
      </div>
    );
  };

  const tableColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
      key: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Category",
      dataIndex: "CategoryName",
      key: "Category",
      sorter: (a, b) => a.CategoryName.localeCompare(b.CategoryName),
    },

    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
      sorter: (a, b) => a.FirstName.localeCompare(b.FirstName),
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
      sorter: (a, b) => a.LastName.localeCompare(b.LastName),
    },
    {
      title: "ISD Code",
      dataIndex: "ISDCode",
      key: "ISDCode",
      sorter: (a, b) =>
        a.ISDCode && b.ISDCode ? a.ISDCode.localeCompare(b.ISDCode) : "",
    },
    {
      title: "Phone",
      dataIndex: "MobileNumber",
      key: "MobileNumber",
      sorter: (a, b) => a.MobileNumber.localeCompare(b.MobileNumber),
    },
    {
      title: "Email",
      dataIndex: "EmailAddress",
      key: "Email",
      sorter: (a, b) => a.EmailAddress.localeCompare(b.EmailAddress),
    },
    {
      title: "Order ID",
      dataIndex: "OrderNumber",
      key: "OrderNumber",
      sorter: (a, b) => a.OrderNumber.localeCompare(b.OrderNumber),
    },
    {
      title: "Departure Date",
      dataIndex: "DepartureDate",
      key: "DepartureDate",
      sorter: (a, b) => a.DepartureDate.localeCompare(b.DepartureDate),
    },
    {
      title: "Message",
      dataIndex: "YourMessage",
      key: "YourMessage",
      render: (text) => {
        return MsgReq(text);
      },
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    },
  ];

  /* === === === === Search Button Starts === === === === */

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(contactForms);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    setTableData(contactForms);
  };
  const searchData = () => {
    setShowSearchBox(true);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    const excelData = tableData;

    exportExcel(excelData, "ContactFormDetails");
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Contact Us Forms</h5>
                      <p>{tableData.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      <DeleteOutlined
                        onClick={() => BulckDelete()}
                        style={{ fontSize: "35px", color: "#08c" }}
                      />
                      {showSearchBox && (
                        <Input
                          autoComplete="newpassword"
                          autoComplete="off"
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getContactList()}
                      />
                    </div>
                  </div>

                  <Table
                    scroll={{ x: true }}
                    bordered
                    dataSource={tableData}
                    columns={tableColumns}
                    rowSelection={{
                      ...rowSelection,
                    }}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                    // rowKey={keyGenerate}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
      <Modal
        visible={showRequestModal}
        title="Message"
        onCancel={(e) => {
          setRequestModal(false);
        }}
        footer={false}
      >
        <div>
          <p>{msg}</p>
        </div>
      </Modal>
    </Layout>
  );
}

export default ContactForm;
