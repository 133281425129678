import React, { useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  message,
  Modal,
  Statistic,
  Result,
} from "antd";
import {
  DingdingOutlined,
  DollarCircleOutlined,
  TaobaoCircleOutlined,
  RedEnvelopeOutlined,
  QqOutlined,
  TeamOutlined,
  BookOutlined,
  BlockOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import globe from "../../assets/images/globe-trus-1.png";
import otpMob from "../../assets/images/mobile-otp-v3-1.png";
import sendImg from "../../assets/images/send-otp-2.png";
import successImg from "../../assets/images/success-otp.png";
import sendImg2 from "../../assets/images/send-otp-1.png";
import gmailLogo from "../../assets/images/mail-new-img.png";
import "./Login.scss";
import EncHelper from "../../helpers/EncHelper";
import { firebaseConfig } from "./FireBaseConfig";

firebase.initializeApp(firebaseConfig);
const { Countdown } = Statistic;

const Login = (props) => {
  let history = useHistory();
  const targetTime = new Date().getTime() + 180000;
  const { setUser, setAccessToken, setAccessList } = useAuthContext();
  const { updateIsLogin, setLoginModalVisible } = props;
  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const setChangeDashboardFlag = (value) => updateIsLogin("role", value);
  const [mobileNumber, setMobileNumber] = useState(-1);
  const [userID, setUserID] = useState(-1);
  const [isGmailLogin, setIsGmailLogin] = useState(false);
  const [otpform] = Form.useForm();
  const [otpverify] = Form.useForm();
  const [resendOtp, setResendOtp] = useState(false);
  const [deadline, setDeadline] = useState(targetTime);
  // const [visible, setVisible] = useState(false);
  const [visiblenew, setVisiblenew] = useState(false);
  const [visiblelone, setVisiblelone] = useState(false);
  const d1 = useRef(null);
  const d2 = useRef(null);
  const d3 = useRef(null);
  const d4 = useRef(null);

  const sendOtp = (data) => {
    data = {
      Mobile: data.mobile,
      Email: "",
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };
    if (isGmailLogin) data.userId = userID;

    ApiClient.post("admin/sendotp", data)
      .then((res) => {
        if (res.status == 200) {
          const { UserId } = res.data.userDetails;
          setUserID(UserId);
          otpform.resetFields();
          otpverify.resetFields();
          setVisiblenew(false);
          setVisiblelone(true);
          setMobileNumber(data.Mobile);
        } else if (res.status == 409) {
          message.error(res.message);
        } else {
          message.error("Error Sending Otp");
        }
      })
      .catch((e) => {});
  };

  const reSendOtp = () => {
    if (mobileNumber != -1) {
      let data = {
        Mobile: mobileNumber,
        Email: "",
        DeviceToken: "string",
        DeviceType: "Web",
        FirBaseToken: "string",
      };
      ApiClient.post("admin/sendotp", data).then((res) => {
        if (res.status == 200) {
          message.success("OTP has Been Sent Again");
          otpverify.resetFields();
          setDeadline(targetTime);
          // setDeadline(targetTime)
          // setDeadline(targetTime)
        } else {
          message.error("Error Sending Otp");
        }
      });
    }
  };

  const verifyOtp = (data) => {
    data = {
      UserID: userID,
      Otp: data.d1 + data.d2 + data.d3 + data.d4,
      OtpType: 2,
    };
    ApiClient.post("admin/verifyotp", data).then((res) => {
      if (res.status === 202) {
        setVisiblelone(false);
        setUser(res.data.userDetails);
        setAccessToken(res.accessToken);
        history.push("/");
        if (res.data.userDetails.Role === 1) {
          let screens = [];
          res.data.userDetails.Screens.forEach((element) => {
            screens.push(element.Name);
          });
          setAccessList(screens);
          updateDashboardFlag(true);
        } else {
          updateLoginFlag(true);
        }
        setLoginModalVisible(false);
      } else if (res.status === 401) {
        message.error(res.message);
        setVisiblelone(false);
      } else {
        message.error(res.message);
      }
    });
  };

  const updateLoginFlag = (val) => {
    setChangeLoginFlag(val);

    if (props.onClick) {
      props.onClick(false);
    }
  };

  const updateDashboardFlag = (val) => {
    setChangeDashboardFlag(val);
  };

  const handleOtpVal = (e, inputBox) => {
    let val = parseInt(e.target.value);
    if (val >= 0) inputBox.current.focus();
  };
  const handleBack = (e, inputBox) => {
    if (!e.target.value && (e.keyCode === 8 || e.keyCode === 46))
      inputBox.current.focus();
  };

  const validateInput = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
      return;
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }

    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const handelCountdown = () => {
    // Modal.warning({
    //   title: 'OTP Expired',
    //   content: 'Your OTP Expired Please Try Again',
    //   onOk() { setResendOtp(true) }
    // });
    message.error("Your OTP Expired Please Try Again");
    setResendOtp(true);
  };

  return (
    <Row gutter={12}>
      <Col md={12} xs={24} className="modal-section-left mobile-up-none">
        <div className="section-left">
          <h2 className="">Why Sign Up?</h2>
          <div className="d-flex align-items-baseline">
            <p className="icons">
              <TeamOutlined />
            </p>
            <p>Become a part of our community</p>
          </div>
          <div className="d-flex align-items-baseline">
            <p className="icons">
              <BookOutlined />
            </p>
            <p>
              Utilize the number of promotional codes to save on your bookings
            </p>
          </div>
          <div className="d-flex align-items-baseline">
            <p className="icons">
              <BlockOutlined />
            </p>
            <p>
              Get closer to a higher membership tier with each purchase and
              unlock more benefits
            </p>
          </div>
          <div className="d-flex align-items-baseline">
            <p className="icons">
              <PrinterOutlined />
            </p>
            <p>View, print, SMS/Email & Cancel bookings</p>
          </div>
        </div>
      </Col>

      <Col md={12} xs={24} className="modal-section-right">
        <div className="modal-form">
          {visiblelone ? (
            <Form form={otpverify} onFinish={verifyOtp}>
              <Row>
                <Col md={24} xs={24}>
                  <img className="otp-img" src={sendImg} alt="search-img" />
                </Col>
                <Col md={24} xs={24}>
                  <Countdown
                    className="countdown"
                    onFinish={handelCountdown}
                    value={deadline}
                    format="mm:ss"
                  />
                </Col>
                <Col md={24} xs={24}>
                  <div className="otp-shadow">
                    <p>Enter OTP sent to your mobile number</p>
                    <Row gutter={8}>
                      <Col className="otp-shadow-1" md={6} xs={6}>
                        <Form.Item
                          className="otp-margin-bottom"
                          name="d1"
                          autoFocus
                          rules={[
                            {
                              required: true,
                              message: " ",
                            },
                            {
                              pattern: "^[0-9]{1}$",
                              message: " ",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            autoFocus
                            ref={d1}
                            type="tel"
                            maxLength="1"
                            onChange={(e) => handleOtpVal(e, d2)}
                            onKeyPress={validateInput}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col className="otp-shadow-1" md={6} xs={6}>
                        <Form.Item
                          className="otp-margin-bottom"
                          name="d2"
                          rules={[
                            {
                              required: true,
                              message: " ",
                            },
                            {
                              pattern: "^[0-9]{1}$",
                              message: " ",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            ref={d2}
                            type="tel"
                            maxLength="1"
                            onChange={(e) => handleOtpVal(e, d3)}
                            onKeyDown={(e) => handleBack(e, d1)}
                            onKeyPress={validateInput}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col className="otp-shadow-1" md={6} xs={6}>
                        <Form.Item
                          className="otp-margin-bottom"
                          name="d3"
                          rules={[
                            {
                              required: true,
                              message: " ",
                            },
                            {
                              pattern: "^[0-9]{1}$",
                              message: " ",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            ref={d3}
                            type="tel"
                            maxLength="1"
                            onChange={(e) => handleOtpVal(e, d4)}
                            onKeyDown={(e) => handleBack(e, d2)}
                            onKeyPress={validateInput}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col className="otp-shadow-1" md={6} xs={6}>
                        <Form.Item
                          className="otp-margin-bottom"
                          name="d4"
                          rules={[
                            {
                              required: true,
                              message: " ",
                            },
                            {
                              pattern: "^[0-9]{1}$",
                              message: " ",
                            },
                          ]}
                        >
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            ref={d4}
                            type="tel"
                            maxLength="1"
                            autoComplete="off"
                            onKeyDown={(e) => handleBack(e, d3)}
                            onKeyPress={validateInput}
                            onPressEnter={() => otpverify.submit()}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {resendOtp == false ? (
                      <span className="login-person-1">Resend code</span>
                    ) : (
                      <span className="login-person-1" onClick={reSendOtp}>
                        Resend code
                      </span>
                    )}
                  </div>
                </Col>

                <Col md={24} xs={24}>
                  <Button className="google-btn" htmlType="submit">
                    Continue
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form form={otpform} name="topForm" onFinish={sendOtp}>
              <h4 className="login-person-1">Login With OTP</h4>
              <div className="d-flex justify-content-center">
                <img className="otp-img" src={otpMob} alt="search-img" />
              </div>
              <div className="otp-shadow text-center">
                <p>
                  Enter your mobile number
                  <br />
                  We will send you OTP to verify
                </p>

                <Form.Item
                  className="otp-margin-bottom form-input"
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Mobile Number Required",
                    },
                    {
                      minLength: 10,
                      maxLength: 10,
                      pattern: "^[0-9]{10}$",
                      message: "Must be 10 digits",
                    },
                  ]}
                >
                  <Input
                    autoComplete="newpassword"
                    autoComplete="off"
                    type="number"
                    className="number-specing"
                    placeholder="Enter Mobile number"
                    autoComplete="off"
                    onKeyPress={validateInput}
                    onPressEnter={() => otpform.submit()}
                    autoFocus
                  />
                </Form.Item>
              </div>
              <div className="d-flex justify-content-center">
                <Button className="google-btn mx-2" htmlType="submit">
                  Continue
                </Button>
                {/* <Button
                  className="back-btn mx-2"
                  onClick={() => {
                    setVisiblenew(false);
                  }}
                >
                  Back
                </Button> */}
              </div>
            </Form>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Login;
