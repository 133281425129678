import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import { exportExcel } from "../ExportExcel";
// import topAirports from "../../components/flight-search/FlightsTopCities.json";
// import AirlineData from "../../components/flight-search/AirlineName.json";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";

const FlightsCommissions = () => {
  const { Option } = ANTD.Select;
  const { Content } = ANTD.Layout;

  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(-1);
  // const [providersList, setProvidersList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  // const [airportLists, setAirportLists] = useState([]);
  // const [airlineLists, setAirlineLists] = useState([]);
  const [flightCommLists, setFlightCommLists] = useState([]);
  // const [providersList, setProvidersList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const [form] = ANTD.Form.useForm();
  const validateMessages = {
    required: "",
  };
  useEffect(() => {
    getFlightCommissionsList();
    getMembership();
    // getProviders();
    getOperators();
    // getAirportLists();
    // getAirlineLists();
  }, []);

  const getFlightCommissionsList = () => {
    APIClient.get("admin/flightCommissions")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              Markup: item.Markup,
              MarkupType: item.MarkupType,
              FlightCommissionID: item.FlightCommissionID,
              SNo: index + 1,
              RoleType: item.RoleType.RoleID,
              RoleTypeName: item.RoleType.Name,
              FareType: item.FareType,
              FareTypeName: getFareType(item.FareType),
              CabinType: item.CabinType,
              CabinTypeName: getCabinType(item.CabinType),
              OperatorID: item.OperatorID.OperatorServiceID,
              OperatorName: item.OperatorID.OperatorName,
            };
          });
          setFlightCommLists(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMembership = () => {
    APIClient.get("admin/role/default")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter((item) => item.RoleID != 1);
          setMembershipList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let filterOperators = res.data.filter(
            (item) => item.ServiceType === 1
          );
          setOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateFlightComm = (currentData) => {
    setIsEditMode(true);
    setId(currentData.FlightCommissionID);
    setAddMarkupModal(true);
    form.setFieldsValue({
      ...currentData,
    });
  };

  const deleteFlightComm = (id) => {
    APIClient.delete("admin/flightCommissions/" + id)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Deleted  successfully", 3);
          getFlightCommissionsList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const tableActions = (currentFlightComm) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateFlightComm(currentFlightComm)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              deleteFlightComm(currentFlightComm.FlightCommissionID)
            }
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const add = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    setAddMarkupModal(true);
  };

  const clear = () => {
    setAddMarkupModal(false);
  };

  const getFareType = (num) => {
    switch (num) {
      case 1:
        return "Publish";
      case 31:
        return "General";
      case 21:
        return "CouponFare";
      case 33:
        return "CorporateFare";
      case 34:
        return "SMEFare";
      case 0:
        return "All";
      default:
        return "Unknown";
    }
  };

  const getCabinType = (num) => {
    switch (num) {
      case 1:
        return "Economy Premium";
      case 2:
        return "Business";
      case 3:
        return "First Class";
      case 4:
        return "Economy";
      case 0:
        return "All";
      default:
        return "Unknown";
    }
  };

  const getSign = (num) => {
    if (num === 1) {
      return "%";
    }
    return "";
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "Role",
      dataIndex: "RoleTypeName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.RoleTypeName),
      render: (RoleTypeName) =>
        RoleTypeName === "Admin" ? "All" : RoleTypeName,
    },
    {
      title: "Operator",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.OperatorName),
    },
    {
      title: "Cabin Type",
      dataIndex: "CabinTypeName",
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData, index) => (
        <>{value + " " + getSign(currentData.MarkupType)}</>
      ),
    },
    {
      title: "Fare Type",
      dataIndex: "FareTypeName",
      sorter: (a, b) => a.FareTypeName.localeCompare(b.FareTypeName),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const submitForm = (value) => {
    let data = {
      DeviceOS: 3,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...value,
    };

    isEditMode ? submitUpdateMap(data) : submitMapDetails(data);
  };

  const submitMapDetails = (formMapData) => {
    APIClient.post("admin/flightCommissions", formMapData)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Details Added SuccessFully", 3);
          setAddMarkupModal(false);
          getFlightCommissionsList();
          form.resetFields();
        } else if (response.status == 400) {
          ANTD.message.error("Duplicate Entry Membership Type OR Provider", 3);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else if (response.status === 409) {
          ANTD.message.error(response.message, 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitUpdateMap = (formMapData) => {
    APIClient.put(`admin/flightCommissions/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Details Updated SuccessFully", 3);
          setAddMarkupModal(false);
          getFlightCommissionsList();
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else if (response.status === 409) {
          ANTD.message.error(response.message, 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = flightCommLists.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setFlightCommLists(searchResults);
    } else {
      getFlightCommissionsList();
    }
  };

  const handleExcel = () => {
    const excelData = flightCommLists.map((item) => {
      return {
        S_No: item.SNo,
        Role: item.RoleType,
        Fare_Type: item.FareTypeName,
        Operator: item.OperatorName,
        Origin: item.Origin,
        Destination: item.Destination,
        Origin_Exception: item.OriginException,
        Destination_Exception: item.DestinationException,
        From_Booking: item.FromBooking,
        To_Booking: item.ToBooking,
        From_Travel_Date: item.FromTravel,
        To_Travel_Date: item.ToTravel,

        Markup: item.Markup,
        Cabin_Type: item.CabinType,
        Class_Type: item.ClassType,
        Cancellation_Service_Charge: item.CancellationServiceCharge,
        GST_VAT_Amount: item.CancellationVATAmount,
        ReSchedule_Service_Charge: item.ReScheduleVATAmount,
      };
    });

    exportExcel(excelData, "flightcommissions");
  };

  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };
  function getDate(date) {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
    return;
  }
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <ANTD.Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Flights Markup</h5>
                      <p>{flightCommLists.length} rows found !</p>
                    </div>

                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          autoComplete="newpassword"
                          autoComplete="off"
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getFlightCommissionsList()}
                      />
                      <div onClick={add} className="add-deposit-icon">
                        <PlusCircleFilled />
                      </div>
                    </div>
                  </div>

                  <ANTD.Table
                    scroll={{ x: true }}
                    bordered
                    dataSource={flightCommLists}
                    columns={tableColumns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                    rowKey={keyGenerate}
                  />
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
      <ANTD.Modal
        width={"800px"}
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> {isEditMode ? "Update" : "Add"} Flight Markup</strong>
            </h6>
          </div>,
        ]}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={[
          <div>
            <ANTD.Button key="submit" type="danger" onClick={clear}>
              Clear
            </ANTD.Button>
            <ANTD.Button key="add" type="primary" onClick={form.submit}>
              {isEditMode ? "Update" : "Add"}
            </ANTD.Button>
          </div>,
        ]}
      >
        <ANTD.Form
          layout="vertical"
          form={form}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col className="gutter-row" md={8} xs={24}>
              <ANTD.Form.Item
                label="Membership Type"
                name="RoleType"
                rules={[{ required: true }]}
              >
                <ANTD.Select placeholder="Please Select">
                  <Option value={1}>All</Option>
                  {membershipList.map((item, index) => (
                    <Option key={"mem" + index} value={item.RoleID}>
                      {item.Name}
                    </Option>
                  ))}
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col className="gutter-row" md={8} xs={24}>
              <ANTD.Form.Item
                label="Fare Type"
                name="FareType"
                rules={[{ required: true }]}
              >
                <ANTD.Select placeholder="Select Fare Type">
                  {/* <Option value={1}>Publish</Option> */}
                  <Option value={0}>All</Option>
                  <Option value={31}>General</Option>
                  <Option value={21}>CouponFare</Option>
                  <Option value={33}>CorpoorateFare</Option>
                  <Option value={34}>SMEFare</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col className="gutter-row" md={8} xs={24}>
              <ANTD.Form.Item
                label="Select Cabin Type"
                name="CabinType"
                rules={[{ required: true }]}
              >
                <ANTD.Select placeholder="Select Cabin Type">
                  <Option value={0}>All</Option>
                  <Option value={1}>Economy Premium</Option>
                  <Option value={2}>Business</Option>
                  <Option value={3}>First Class</Option>
                  <Option value={4}>Economy</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <div className="seperation-line">
            <ANTD.Row gutter={16}>
              <ANTD.Col className="gutter-row" md={8} xs={24}>
                <ANTD.Form.Item
                  label="Operators"
                  name="OperatorID"
                  rules={[{ required: true }]}
                >
                  <ANTD.Select
                    showSearch
                    placeholder="Select Operator"
                    filterOption={(val, op) => selectCustomFilter(val, op)}
                  >
                    {operatorList.map((item, index) => (
                      <Option key={index + "op"} value={item.OperatorServiceID}>
                        {item.OperatorName}
                      </Option>
                    ))}
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>
              <ANTD.Col className="gutter-row" md={8} xs={24}>
                <ANTD.Form.Item
                  label="Markup Type"
                  name="MarkupType"
                  rules={[{ required: true }]}
                >
                  <ANTD.Select
                    placeholder="Please select"
                    onSelect={() => form.setFieldsValue({ Markup: "" })}
                  >
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>
              <ANTD.Col className="gutter-row" md={8} xs={24}>
                <ANTD.Form.Item
                  label="Markup"
                  name="Markup"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) => {
                        let MarkupType = form.getFieldValue("MarkupType");
                        if (MarkupType === 0) {
                          return Promise.resolve();
                        } else if (MarkupType === 1) {
                          if (value > 100) {
                            return Promise.reject("Max Limit 100");
                          } else {
                            return Promise.resolve();
                          }
                        } else {
                          return Promise.reject("Please Select Markup Type");
                        }
                      },
                    },
                  ]}
                >
                  <ANTD.InputNumber
                    autoComplete="newpassword"
                    autoComplete="off"
                    min={0}
                    type="number"
                    style={{ width: "100%" }}
                  />
                </ANTD.Form.Item>
              </ANTD.Col>
            </ANTD.Row>
          </div>
        </ANTD.Form>
      </ANTD.Modal>
    </ANTD.Layout>
  );
};

export default FlightsCommissions;
