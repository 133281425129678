import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import Banner from "../../../components/banner/Banner";
import Hotels from "../../../components/Hotels/Hotels";
import Subscribe from "../../../components/subscribe/Subscribe";
import * as ReactBoostrap from "react-bootstrap";
// import { Helmet } from "react-helmet";
import * as ANTD from "antd";
import queryString from "query-string";
import "../HotelSearch/HotelSearch.scss";
import ApiClient from "../../../helpers/ApiClient";
import moment from "moment";
import Deals from "../../../components/FlightHome/Deals";
// import { useSytContext } from "../../../common/providers/SytProvider";
import Offers from "../../../components/PromoOffers/Offers";

const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  // const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);

  const getCityHotelList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 2
          );
          let result = data.reduce(function (obj, key) {
            obj[`${key.CityName}, ${key.CountryName}`] =
              obj[`${key.CityName}, ${key.CountryName}`] || [];
            obj[`${key.CityName}, ${key.CountryName}`].push(key);
            return obj;
          }, {});

          setCityHotelData(result);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  useEffect(() => {
    getCityHotelList();
  }, []);

  const getHotelUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      // params.checkInDate = moment().add(1, "days").format("YYYY-MM-DD");
      // params.checkOutDate = moment().add(2, "days").format("YYYY-MM-DD");
      params.checkInDate = "2021-12-30";
      params.checkOutDate = "2021-12-31";
      params = queryString.stringify(params);
      return `/hotels-list?${params}`;
    } else {
      return "";
    }
  };

  return (
    <div className="hotels_search_container">
      <section className="hotel_banner background_banner">
        {/* <div>
          <Banner banner={hotelBanners} />
        </div> */}

        <div className="heading_text">
          <h2>Find Cheap Hotels Now</h2>
          <p className="delas-phone-v3">
            <i className="fa fa-hotel icon-mob-21" aria-hidden="true"></i>&nbsp;
            Get best deal on Hotels, Apartments, Bungalows &amp; Homestays
          </p>
        </div>
        <div className="hotel_search">
          <Hotels hotelSearch={props.hotelContext} />
        </div>
      </section>

      <div className="directly-flight-ofc-v3">
        <Row>
          <Col md={24} xs={24}>
            <Deals />
          </Col>
        </Row>
      </div>
      <div className="directly-flight-ofc-v3">
        <Row>
          <Col md={24} xs={24}>
            <Offers promoType={2} />
          </Col>
        </Row>
      </div>
      <div className="container">
        {/*--start----end--*/}

        <Row className="heading-hotel">
          <Col md={24} xs={24}>
            <h3>Only here! New, larger accommodation base</h3>
            <p className="mb-0">
              Hotels, apartments, bungalows &amp; homestays - just the way you
              like it
            </p>
          </Col>
        </Row>

        {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel, index) => (
              <div key={index}>
                <Row gutter={[12, 12]} className="mb-5">
                  <Col md={24} xs={24}>
                    <p className="text-center font-weight-bold mb-0">
                      {cityHotel}
                    </p>
                  </Col>
                  {cityHotelData[cityHotel].map((item, i) => (
                    <Col md={6} xs={24} key={i}>
                      <Link to={getHotelUrl(item.SearchURL)}>
                        <img
                          className="cart-shopping-img"
                          src={
                            item.HotelImage1
                              ? `${BASE}${item.HotelImage1.substring(1)}`
                              : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                  .default
                          }
                          alt="Hotel Image"
                        />
                      </Link>
                      <p className="text-center font-weight-bold">
                        {item.Title}
                      </p>
                    </Col>
                  ))}
                </Row>{" "}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default HotelSearch;
