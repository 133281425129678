import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Layout,
  Col,
  Input,
  Form,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import ApiClient from "../../helpers/ApiClient";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import {
  PlusCircleFilled,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./OperatorsService.scss";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
const { Content } = Layout;

const FlightOperatorsService = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [OperatorsForm] = Form.useForm();
  const [operators, setOperators] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const getAirlineName = async (code) => {
    if (code === "All") {
      return code;
    }
    return await ApiClient.get("flights/airlineNames/search/" + code)
      .then((res) => {
        if (res.statusCode === 200) {
          let data = "";
          data = res.data.filter((item) => item.codeIataAirline === code)[0]
            .nameAirline;

          return data;
        } else return "";
      })
      .catch((e) => {
        return "";
      });
  };

  const getOperators = () => {
    setOperators([]);
    setTableData([]);
    ApiClient.get("admin/operators")
      .then(async (res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) => item.ServiceType === 1);
          data = await Promise.all(
            data.map(async (item, index) => {
              let OperatorFullName = "";
              try {
                OperatorFullName = await getAirlineName(item.OperatorName);
              } catch (e) {}
              return {
                SrNo: index + 1,
                ...item,
                ServiceType: getServiceName(item.ServiceType),
                OperatorFullName: OperatorFullName,
              };
            })
          );

          console.log(data);
          setOperators(data);
          setTableData(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    getOperators();
  }, []);

  const AddOperators = async (values) => {
    let data = {
      ServiceType: 1,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };
    const response = await ApiClient.post("admin/operator", data);
    if (response.status === 200) {
      message.success("Operator added successfully !");
      getOperators();
      setModalVisible(false);
    } else {
      message.error("Something went wrong");
    }
  };

  const UpdateOperator = async (values) => {
    let data = {
      ServiceType: 1,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };
    const response = await ApiClient.put("admin/operator/" + currID, {}, data);
    if (response.status === 200) {
      message.success("Operator updated successfully !");
      getOperators();
      setModalVisible(false);
    } else {
      message.error("Something went wrong");
    }
  };

  const DeleteOperator = async (id) => {
    const response = await ApiClient.delete("admin/operator/" + id);
    if (response.status === 200) {
      message.success("Operator Deleted successfully !");
      getOperators();
    } else {
      message.error("Something went wrong");
    }
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 23 },
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const tableActions = (text, record) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setCurrID(text);
              setIsEdit(true);
              setModalVisible(true);
              OperatorsForm.setFieldsValue({
                ServiceType: record.ServiceType,
                OperatorName: record.OperatorName,
              });
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => DeleteOperator(record.OperatorServiceID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columnsserviceoper = [
    {
      title: "Sr. No.",
      dataIndex: "SrNo",
      sorter: (a, b) => a.SrNo - b.SrNo,
    },
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      sorter: (a, b) => a.ServiceType.localeCompare(b.ServiceType),
    },
    {
      title: "Operator Code",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },
    {
      title: "Operator Name",
      dataIndex: "OperatorFullName",
      sorter: (a, b) => a.OperatorFullName.localeCompare(b.OperatorFullName),
    },
    {
      title: "Actions",
      dataIndex: "OperatorServiceID",
      render: (text, record) => tableActions(text, record),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(operators);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    setTableData(operators);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const getServiceName = (id) => {
    switch (id) {
      case 3:
        return "Bus";

      case 1:
        return "Flight";

      default:
        break;
    }
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <div className="promo providerapi">
      <Layout>
        <div className="manage-markup-section">
          <Content className="admin-container">
            <Row>
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <h5>View Flight Operator</h5>
                        <p>{tableData.length} rows found !</p>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            autoComplete="newpassword"
                            autoComplete="off"
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => getOperators()}
                        />
                        <div onClick={showModal} className="add-deposit-icon">
                          <PlusCircleFilled />
                        </div>
                      </div>
                    </div>

                    <Table
                      bordered
                      scroll={{ x: true }}
                      dataSource={tableData}
                      columns={columnsserviceoper}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                      rowKey={keyGenerate}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
          </Content>
        </div>
      </Layout>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Add Flight Operator</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="clear"
              type="danger"
              onClick={() => OperatorsForm.resetFields()}
            >
              Clear
            </Button>
            <Button key="add" type="primary" onClick={OperatorsForm.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
          </div>,
        ]}
      >
        <Form
          {...formItemLayout}
          layout="vertical"
          form={OperatorsForm}
          onFinish={isEdit ? UpdateOperator : AddOperators}
        >
          <Row>
            <Col md={24} xs={24}>
              <AutoCompleteSelect
                formItemProps={{
                  label: "Airline Name",
                  name: "OperatorName",
                  rules: [{ required: true }],
                }}
                selectProps={{ placeholder: "" }}
                api={"flights/airlineNames/search/"}
                keytext="nameAirline"
                keyvalue="codeIataAirline"
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default FlightOperatorsService;
