import React from "react";
import * as ANTD from "antd";

const Error404 = () => {
  return (
    <div className="error-404">
      <ANTD.Result
        subTitle="Sorry, the page you visited does not exist."
        status="404"
        title="404"
        extra={<ANTD.Button type="primary">Back Home</ANTD.Button>}
      />
    </div>
  );
};

export default Error404;
