import React from "react";
import "../BusSearch/BusSearch.scss";
import Banner from "../../../components/banner/Banner";
import Buses from "../../../components/buses-search/Buses";
import * as ANTD from "antd";
import TopBuses from "../TopBuses/TopBuses";
// import { useSytContext } from "../../../common/providers/SytProvider";
import {
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  message,
} from "antd";
import Deals from "../../../components/FlightHome/Deals";
import Offers from "../../../components/PromoOffers/Offers";

function BusSearch() {
  // const { busBanners } = useSytContext();
  return (
    <div>
      <div className="bus-block background_banner_1">
        {/* <div className="bus-banner">
          <Banner banner={busBanners} />
        </div> */}
        <div className="bus-search-header ">
          <h2>Book bus tickets</h2>
          <p className="delas-phone-v3">
            <i className="fa fa-bus icon-mob-21" aria-hidden="true"></i>&nbsp;
            Grab best deals on your bus bookings!
          </p>
        </div>
        <div className="buses-search ">
          <Buses modifySearch={false} busesSearchAPI={() => {}} />
        </div>
      </div>

      <div className="directly-flight-ofc-v3">
        <Row>
          <Col md={24} xs={24}>
            <Deals />
          </Col>
        </Row>
      </div>
      <div className="directly-flight-ofc-v3">
        <Row>
          <Col md={24} xs={24}>
            <Offers promoType={3} />
          </Col>
        </Row>
      </div>
      <section className="top-route-bb new-bus-top">
        <TopBuses />
      </section>
    </div>
  );
}

export default BusSearch;
