import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Row, message } from "antd";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import ApiClient from "../../../helpers/ApiClient";
import HotelBookPayCard from "../HotelCheckout/HotelBookPayCard";
import { LoadingOutlined } from "@ant-design/icons";
import HotelFairBox, {
  getHotelGrandTotal,
} from "../HotelCheckout/HotelFairBox";
import "../HotelPreview/HotelPreview.scss";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import { useAuthContext } from "../../../common/providers/AuthProvider";

const HotelPreview = () => {
  const {
    state: { hotelCheckOutData },
  } = useHotelContext();

  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const globalContext = useContext(GlobalStatesContext);
  const { promoData, ConvFee } = globalContext.state.otherData;
  const [pgIsLoading, setPgIsLoading] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  let history = useHistory();
  useEffect(() => {
    if (Object.keys(hotelCheckOutData).length <= 0) {
      history.goBack();
    }
  }, [hotelCheckOutData]);

  const navigateToTicket = async () => {
    setPgIsLoading(true);
    let guestsList = hotelCheckOutData.guests.map((pax) => {
      return pax.paxInfoList.map((item) => {
        return item;
      });
    });
    let { totalAmount } = await getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData
    );

    let data = {
      traceId: hotelCheckOutData.hotelPriceData.traceId,
      guests: guestsList[0],
      addressInfo: hotelCheckOutData.addressInfo,
      flightInfo: {
        arrivingByFlight: "",
        flightName: "",
        flightNumber: "",
        hour: "",
        minutes: "",
        remarks: "",
        airlineName: "",
        airlinePnr: "",
      },
      docIdentification: {
        type: "",
        value: "",
      },
      creditCardInfo: {
        securityId: "",
        cardNumber: "",
        expirationMonth: "",
        expirationYear: "",
        firstName: "",
        lastName: "",
        billingAmount: "",
        billingCurrency: "",
        cardHolderAddress: {
          addressLine1: "",
          addressLine2: "strng",
          city: "",
          zipcode: "",
          countryCode: "",
        },
      },
      paymentModeType: "",
      // promoData: promoData,
      // convienenceData: ConvFee,
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      totalPrice: Number(totalAmount).toFixed(2),
    };
    // let data = {
    //   traceId: hotelCheckOutData.hotelPriceData.traceId,
    //   repriceId: hotelCheckOutData.hotelPriceData.repriceId,
    //   guests: guestsList[0],
    //   addressInfo: hotelCheckOutData.addressInfo,
    // };
    let paymentPaxInfo = {
      CustomerName: guestsList[0][0].firstName,
      CustomerEmail: hotelCheckOutData.addressInfo.email,
      CustomerPhone: hotelCheckOutData.addressInfo.phoneNo,
    };

    setIsLoading(true);

    ApiClient.post("hotels-v2/hotelblock", data)
      .then((result) => {
        return result;
      })
      .then((res) => {
        setPgIsLoading(false);
        if (res.status === 200) {
          // setPreBookingRespObj(res);
          processPayment(
            res.data.RefNumber,
            paymentPaxInfo,
            res.data.signature
          );

          // history.push(`pg_success?ref=${res.data.ref_id}`);
          // processPayment(res.data.ref_id, paymentPaxInfo);
        } else if (res.status !== 200) {
          console.log("fetching failed");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  const processPayment = async (oid, paymentPaxInfo, signature) => {
    let { totalAmount } = await getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData
    );

    let data = {
      OrderId: oid,
      OrderAmount: Number(totalAmount).toFixed(2),
      OrderCurrency: "INR",
      OrderNote: "HOTEL",
      ...paymentPaxInfo,
      TDS: 0,
      GST: 0,
      Commission: 0, // for agent only
      AgentMarkup: 0,
      msg: signature,
    };
    // history.push(`/pg_success?ref=${data.OrderId}`);
    // set PgData and Display
    setPgData(data);
    setPgDisplay(true);
  };

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyBookingOTP", {
      mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      dialingCode: hotelCheckOutData?.addressInfo?.areaCode,
      OTP: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);

          navigateToTicket();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendBookingOTP", {
      mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      dialingCode: hotelCheckOutData?.addressInfo?.areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = async () => {
    let { totalAmount } = await getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 2,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: totalAmount ? totalAmount : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          if (user) {
            navigateToTicket();
          } else {
            handleSendOTP();
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  return (
    <div className="hotel-preview-container">
      <div className="form-body ">
        <div className="form-body-container py-3">
          <Row gutter={[16, 16]}>
            <Col md={16} sm={24} xs={24}>
              <div className="form-body-left-block">
                <div className="form-body-left-content">
                  <span className="booking-summary-div">
                    <i className="fa fa-file-text" aria-hidden="true"></i>&nbsp;
                    Review Your Booking{" "}
                  </span>
                  <div className="hotel-review-room-details hotel-top-new">
                    <HotelDetailsBox
                      hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                      hotelSearchData={hotelCheckOutData.hotelSearchData}
                    />
                  </div>
                  <span className="booking-summary-div-gu">
                    <i className="fa fa-users" aria-hidden="true"></i> Guests
                    Details
                  </span>
                  <Card className="preview-pax-details">
                    <HotelPassengers
                      passengersInfo={hotelCheckOutData.guests}
                    />
                  </Card>
                </div>
              </div>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <div className="form-body-right-block">
                <div className="price-form-right">
                  <div className="hotel-review-fare-details">
                    <HotelFairBox
                      hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                      hotelSearchData={hotelCheckOutData.hotelSearchData}
                      promoVisible={true}
                    />
                  </div>
                  <HotelBookPayCard
                    isLoading={false}
                    pgIsLoading={pgIsLoading}
                    bookpaycardinfo={"hotel-review"}
                    navigateToTicket={ValidateBookingLimit}
                    pgDisplay={pgDisplay}
                    pgData={pgData}
                  />
                  {/* <div className="tnc-container">
                  <div className="form-body-contact-continue">
                    <div className="contact-internal-form">
                      <div className="contact-footer">
                        <div className="bookandpay-btn">
                          {!pgDisplay ? (
                            <button
                              onClick={() => {
                                navigateToTicket();
                              }}
                            >
                              {isLoading ? (
                                <Spin indicator={antIcon} />
                              ) : (
                                "Pay Now"
                              )}
                            </button>
                          ) : (
                            <PaymentGateway data={pgData} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={hotelCheckOutData?.addressInfo?.phoneNo}
        />
      )}
    </div>
  );
};

export default HotelPreview;
