import React from "react";
import { Card, Col, Row, Table } from "antd";

const Flightsalescount = ({ flightsData }) => {
  let key = 0;

  const columnsflightcount = [
    {
      title: "ReferenceID",
      dataIndex: "orderId",
      key: "Referenceid",
    },
    {
      title: "Source",
      dataIndex: "origin",
      key: "source",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Amount ",
      dataIndex: "amount",
      key: "amount",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
  ];
  const keyGenerate = () => {
    return key++;
  };

  return (
    <>
      <div className="order-count-cards">
        <Row>
          <Col xs={24} md={24}>
            <h4 className="flights-sales-card">Flight Sales</h4>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-1">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Booking Count</p>
                <h5>{Number(flightsData.booking_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-2">
                <i className="fa fa-ban" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Cancelled Count </p>
                <h5>{Number(flightsData.cancelled_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          {/* <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-3">
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Cancel Requests </p>
                <h5>{Number(flightsData.cancel_request).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-4">
                <i className="fa fa-retweet" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Reschedule Requests </p>
                <h5>{Number(flightsData.reschedule_request).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-5">
                <i className="fa fa-bolt" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Refund Count </p>
                <h5>{Number(flightsData.refund_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col> */}

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-10">
                <i className="fa fa-random" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Day Sales </p>
                <h5>{Number(flightsData.day_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-11">
                <i className="fa fa-calendar" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Yesterday Sales </p>
                <h5>{Number(flightsData.yesterday_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-12">
                <i className="fa fa-calendar" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>Week Sales</p>
                <h5>{Number(flightsData.week_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="ad-hom-box">
              <div className="ad-span-col bg-13">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </div>

              <div className="ad-hom-view-com">
                <p>month wise</p>
                <h5>{Number(flightsData.month_sales).toFixed(2)}</h5>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="order-count-cards">
        <Row
          gutter={[
            { xs: 0, md: 16 },
            { xs: 8, md: 16 },
          ]}
          className="charts-bars card-count-balance "
        >
          <Col xs={24} md={12}>
            <Card className="side-bar-graph-1">
              <p className="month-wise-cart">Upcoming Bookings</p>

              <Table
                scroll={{ x: true }}
                className=" flight-new-pagenation"
                bordered
                dataSource={flightsData.upcoming_bookings}
                columns={columnsflightcount}
                rowKey={keyGenerate}
              />
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card className="side-bar-graph-new-flight">
              <p className="month-wise-cart">Completed Trips</p>

              <Table
                scroll={{ x: true }}
                className=" flight-new-pagenation"
                bordered
                dataSource={flightsData.completed_bookings}
                columns={columnsflightcount}
                rowKey={keyGenerate}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Flightsalescount;
