import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../../../helpers/useStorage";

export const HotelContext = createContext();

export function useHotelContext() {
  return useContext(HotelContext);
}

const HotelProvider = ({ children }) => {
  const [hotelCheckOutData, setHotelCheckOutData] = useLocalStorage(
    "hotelCheckOutData",
    {}
  );

  const [selectedHotel, setSelectedHotel] = useLocalStorage(
    "selectedhotel",
    {}
  );

  return (
    <HotelContext.Provider
      value={{
        state: { hotelCheckOutData, selectedHotel },
        setHotelCheckOutData,
        setSelectedHotel,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};
export default HotelProvider;
