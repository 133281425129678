import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Layout,
  message,
  Select,
} from "antd";

import ApiClient from "../../helpers/ApiClient";
// import ReCAPTCHA from "react-google-recaptcha";
import "./Mytrips.scss";
import { useHistory } from "react-router-dom";
const { Content } = Layout;
const { Option } = Select;

const Mytrips = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const getType = (type) => {
    let url = "";
    switch (type) {
      case 1: {
        url = "flight_ticket";
        break;
      }
      case 3: {
        url = "bus_ticket";
        break;
      }
      case 2: {
        url = "hotel_ticket";
        break;
      }
    }
    return url;
  };

  const submitForm = (data) => {
    if (data.email && data.ref) {
      ApiClient.get(
        `admin/myBookings/details?travelType=${data.type}&id=${data.ref}&email=${data.email}`
      )
        .then((resp) => {
          if (resp.statusCode === 200) {
            let type = getType(data.type);
            let url = type + "?ref=" + data.ref + "&email=" + data.email;
            history.push(url);
          } else {
            if (resp?.message) {
              message.error(resp.message, 3);
            } else {
              message.error("No Such Ticket Found", 3);
            }
          }
        })
        .catch((e) => {
          message.error(e, 3);
        });
    }
  };

  return (
    <div>
      <div className="promo providerapi">
        <Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container cms-pages-width retrieve-linebar">
              <Row className="fst-book">
                <Col md={24} xs={24}></Col>
              </Row>

              <Form
                layout="vertical"
                form={form}
                onFinish={submitForm}
                initialValues={{ type: 1 }}
              >
                <Row>
                  <Col md={12} xs={24}>
                    <Card className="retrieve-booking">
                      <h4>Retrieve Your Booking</h4>
                      <p className="booking-email-p">
                        Enter your booking number and email address below and
                        we’ll find your ticket for you. We sent your booking
                        number to you in your confirmation email.
                      </p>
                      <Form.Item
                        label="Service Type"
                        name="type"
                        rules={[
                          { required: true, message: "Field is Required" },
                        ]}
                      >
                        <Select>
                          <Option value={1}>Flights</Option>
                          <Option value={2}>Hotels</Option>
                          <Option value={3}>Bus</Option>
                        </Select>
                      </Form.Item>

                      <Row className="booking-value-id">
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Reference Number"
                            name="ref"
                            rules={[
                              {
                                required: true,
                                message: "Reference No is Required",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Email address"
                            name="email"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "Email is not a valid email",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* <Row justify="center" className="mb-3">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                          onChange={handleCaptcha}
                        />
                      </Row> */}
                      <Row justify="center">
                        <Col md={12} xs={24}>
                          <Button
                            // disabled={buttonDisabled}
                            className="google-btn-booking"
                            htmlType="submit"
                          >
                            Retrieve Booking
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col md={12} xs={24}>
                    <img
                      className="email-from-book"
                      src={require("../../assets/images/email-tickets.png")}
                      alt="search-img"
                      srcSet=""
                    />
                  </Col>
                </Row>
              </Form>
            </Content>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Mytrips;
