import React from "react";
import { Route, Switch } from "react-router-dom";
import FlightTicket from "../../admin/FlightTicket/FlightTicket";
import Error404 from "../../components/ErrorPages/Error404";
import AdminRoute from "../../helpers/AdminProtection";
import Promocode from "../../admin/Promo/Promo";
import AccessPermissions from "../../admin/AccessPermissions/AccessPermissions";
import BusesCommissions from "../../admin/AdminMarkup/BusesCommissions";
import FlightsMarkup from "../../admin/AdminMarkup/FlightsCommissions";
import AdminUser from "../../admin/AdminUser/AdminUser";
import Banners from "../../admin/Banners/Banners";
import CMS from "../../admin/CMS/cms";
import ContactForm from "../../admin/ContactForms/ContactForm";
import Dashboard from "../../admin/Dashboard/Dashboard";
import FlightEnquires from "../../admin/FlightEnquires/FlightEnquires";
import APILogs from "../../admin/Logs/APIAccessLogs";
import DBLogs from "../../admin/Logs/DBLogs";
import PGLogs from "../../admin/Logs/PGLogs";
import BusOperatorsService from "../../admin/OperatorsService/BusOperatorsService";
import FlightOperatorsService from "../../admin/OperatorsService/FlightOperatorsServiceold";
import Admin from "../../admin/Permissions/Admin/Admin";
import Reports from "../../admin/Reports/Reports";
import Role from "../../admin/Role/Role";
import Settings from "../../admin/Settings/Settings";
import Siteusers from "../../admin/Siteusers/Siteusers";
import SuperBusTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperBusTicket";
import SuperFlightTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperFlightTicket";
import AddProvider from "../../admin/SupplierConfiguration/AddProvider";
import ProviderApi from "../../admin/SupplierConfiguration/ProviderApi";
import TravelCalendar from "../../admin/TravelCalendar/TravelCalendar";
import SubscriberReport from "../../admin/SubscriberReport/SubscriberReport";
import Unauthorized from "../../components/ErrorPages/Unauthorized";
import NotFound from "../../components/ErrorPages/NotFound";
import Createlocation from "../../admin/Mapping/Createlocation";
import Updatelocation from "../../admin/Mapping/Updatelocation";

import CancelRequest from "../../admin/CancelRequest/CancelRequest";
import { useAuthContext } from "../providers/AuthProvider";
import CityHotel from "../../admin/CityHotel/CityHotel";
import Deals from "../../admin/Deals/Deals";
import HotelsCommissions from "../../admin/AdminMarkup/HotelsCommissions";
import OperatorsService from "../../admin/OperatorsService/OperatorsService";
import SuperHotelTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperHotelTicket";
import BookingLimit from "../../admin/BookingLimit/BookingLimit";

const AdminNavigations = ({ accessList, accessToken }) => {
  return (
    <div className="mob-M-Top">
      <Switch>
        <Route exact path="/" component={Dashboard} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/dashboard" component={Dashboard} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/role" component={Role} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/user" component={AdminUser} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/accesspermissions" component={AccessPermissions} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/provider" component={AddProvider} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/apiprovider" component={ProviderApi} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/reports" component={Reports} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/promo" component={Promocode} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/subscriberreport" component={SubscriberReport} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/banners" component={Banners} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/travelCalendar" component={TravelCalendar} />

        {/* <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/busoperator" component={BusOperatorsService} /> */}

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/operators" component={OperatorsService} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/busmarkup" component={BusesCommissions} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/hotelmarkup" component={HotelsCommissions} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/flightmarkup" component={FlightsMarkup} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/Siteusers" component={Siteusers} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/cityhotel" component={CityHotel} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/deals" component={Deals} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/flightticket" render={() => <FlightTicket />} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/CMS" component={CMS} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/bookinglimit" component={BookingLimit} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/apilogs" component={APILogs} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/pglogs" component={PGLogs} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/dblogs" component={DBLogs} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/settings" component={Settings} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/createlocation" component={Createlocation} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/updatelocation" component={Updatelocation} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/admin" component={Admin} />

        <Route exact path="/admin/super_flight_ticket" component={SuperFlightTicket} />
        <Route exact path="/admin/super_bus_ticket" component={SuperBusTicket} />
        <Route exact path="/admin/super_hotel_ticket" component={SuperHotelTicket} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/contactforms" component={ContactForm} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/flightenquiries" component={FlightEnquires} />

        <Route exact path="/admin/cancelrequest" component={CancelRequest} />

        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/404" component={Error404} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AdminNavigations;
