import React, { useState, useEffect } from "react";
import "./BusFilter.scss";
import { Card, Checkbox, Collapse, Slider } from "antd";
// import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";

const timeFilter = [
  { id: "day", start: "06:00", end: "17:59", label: "Day", isChecked: false },
  {
    id: "night",
    start: "18:00",
    end: "05:59",
    label: "Night",
    isChecked: false,
  },
];

const { Panel } = Collapse;

const BusFilter = ({ busSearchResultObj, setBusResultsList }) => {
  let {
    data: { trips },
  } = busSearchResultObj;
  let count = trips?.length;
  const [resultCount, setResultCount] = useState(count);
  const [filters, setFilters] = useState({});
  const [priceRange, setPriceRange] = useState([]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    scrollToListTop();
  };
  const onChangeBusType = (event, filterType, filterId) => {
    let { checked } = event.target;
    let checkedList = checkedFilters("busType");
    if (checkedList.length > 1) {
      let AC = checkedList.filter((item) => item.id === "AC");
      let NonAc = checkedList.filter((item) => item.id === "NONA/C");
      if (AC.length > 0 && filterId === "SEATER") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            filt.id === "AC" || filt.id === "SEATER"
              ? { ...filt, isChecked: true }
              : { ...filt, isChecked: false }
          ),
        });
      }
      if (AC.length > 0 && filterId === "NONA/C") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) => {
            if (filt.id === "AC") return { ...filt, isChecked: false };
            if (filt.id === "NONA/C") return { ...filt, isChecked: true };
            else return filt;
          }),
        });
      }

      if (AC.length > 0 && filterId === "SLEEPER") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            filt.id === "AC" || filt.id === "SLEEPER"
              ? { ...filt, isChecked: true }
              : { ...filt, isChecked: false }
          ),
        });
      }
      if (NonAc.length > 0 && filterId === "AC") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) => {
            if (filt.id === "AC") return { ...filt, isChecked: true };
            if (filt.id === "NONA/C") return { ...filt, isChecked: false };
            else return filt;
          }),
        });
      }
      if (NonAc.length > 0 && filterId === "SEATER") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            filt.id === "NONA/C" || filt.id === "SEATER"
              ? { ...filt, isChecked: true }
              : { ...filt, isChecked: false }
          ),
        });
      }
      if (NonAc.length > 0 && filterId === "SLEEPER") {
        setFilters({
          ...filters,
          [filterType]: filters[filterType].map((filt) =>
            filt.id === "NONA/C" || filt.id === "SLEEPER"
              ? { ...filt, isChecked: true }
              : { ...filt, isChecked: false }
          ),
        });
      }
    } else {
      setFilters({
        ...filters,
        [filterType]: filters[filterType].map((filt) =>
          filt.id == filterId ? { ...filt, isChecked: checked } : filt
        ),
      });
    }

    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("card-results-block")[0].scrollIntoView({
      behavior: "smooth",
    });
  };
  const filtersObj = {
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    timings: [],
    travels: [],
    boardingPoints: [],
    droppingPoints: [],
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    for (let i = 0; i < trips.length; i++) {
      //Setting Price Range
      let val = trips[i].startingFare.split("/").sort((a, b) => {
        return Number(b) - Number(a);
      });

      let startingFare = Number(val[0]);

      if (i === 0) {
        filtersObj.price.minPrice = Number(val[val.length - 1]);
      }
      let minFare = Number(val[val.length - 1]);
      if (startingFare > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
          startingFare;
      }

      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;

      //Setting Timing Filter
      filtersObj.timings = timeFilter;
      filtersObj.busType = [
        { type: "AC", label: "AC", id: "AC", isChecked: false },
        {
          type: "Non AC",

          label: "Non AC",
          id: "NONA/C",
          isChecked: false,
        },
        { type: "Sleeper", label: "Sleeper", id: "SLEEPER", isChecked: false },
        { type: "Seater", label: "Seater", id: "SEATER", isChecked: false },
      ];
      //Setting Bus Types Filters
      // filtersObj.busType = filtersAPIData.busTypes.map((bType) => ({
      //   ...bType,
      //   label: bType.type,
      //   id: bType.type,
      // }));

      //Setting Travels Filters
      const { displayName } = trips[i];
      checkIfExist(filtersObj.travels, displayName) &&
        filtersObj.travels.push({
          id: displayName,
          label: displayName,
          isChecked: false,
        });

      //Setting Boarding Points Filters
      const { boardingPoints } = trips[i];
      boardingPoints.map((bPoint) => {
        checkIfExist(filtersObj.boardingPoints, bPoint.Location) &&
          filtersObj.boardingPoints.push({
            id: bPoint.Location,
            label: bPoint.Location,
            isChecked: false,
          });
      });

      //Setting Dropping Points Filters
      const { droppingPoints } = trips[i];
      droppingPoints.map((dPoint) => {
        checkIfExist(filtersObj.droppingPoints, dPoint.Location) &&
          filtersObj.droppingPoints.push({
            id: dPoint.Location,
            label: dPoint.Location,
            isChecked: false,
          });
      });
    }

    setFilters(filtersObj);
    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };
  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };
  const checkedBusTypeFilters = (filterType) => {
    return filters[filterType]
      .filter((filter) => filter.isChecked)
      .map((item) => item.id);
  };

  const applyFilters = () => {
    let visibleCount = 0;
    let filteredList = trips.map((trip) => {
      let isVisible = true;

      const startingFare = Number(trip.startingFare.split("/")[0]);
      if (
        !(
          startingFare >= filters.price.minPrice &&
          startingFare <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }
      const timingsChecked = checkedFilters("timings");
      if (timingsChecked.length === 1) {
        const { departureTime } = trip,
          { start, end, id } = timingsChecked[0];
        if (
          !(id === "night"
            ? (departureTime >= start && departureTime <= "23:59") ||
              (departureTime >= "00:00" && departureTime <= end)
            : departureTime >= start && departureTime <= end)
        ) {
          isVisible = false;
        }
      }

      const busTypeChecked = checkedFilters("busType");
      const busTypeFalseChecked = checkedBusTypeFilters("busType");

      if (busTypeChecked.length === 1) {
        if (
          busTypeChecked.filter((bType) => trip.busType.indexOf(bType.id) > -1)
            .length === 0
        ) {
          isVisible = false;
        } else if (
          busTypeFalseChecked.includes("AC") &&
          !busTypeFalseChecked.includes("NONA/C") &&
          trip.busType.includes("NONA/C")
        ) {
          isVisible = false;
        }
      } else {
        if (
          busTypeChecked.filter((bType) => bType.id === "AC").length > 0 &&
          busTypeChecked.filter((bType) => bType.id === "SEATER").length > 0
        ) {
          if (
            !trip.busType.includes("AC") ||
            trip.busType.includes("NONA/C") ||
            trip.busType.includes("SLEEPER")
          ) {
            isVisible = false;
          }
        }

        if (
          busTypeChecked.filter((bType) => bType.id === "AC").length > 0 &&
          busTypeChecked.filter((bType) => bType.id === "SLEEPER").length > 0
        ) {
          if (
            !trip.busType.includes("AC") ||
            trip.busType.includes("NONA/C") ||
            trip.busType.includes("SEATER")
          ) {
            isVisible = false;
          }
        }

        if (
          busTypeChecked.filter((bType) => bType.id === "NONA/C").length > 0 &&
          busTypeChecked.filter((bType) => bType.id === "SEATER").length > 0
        ) {
          if (trip.busType.includes("AC") || trip.busType.includes("SLEEPER")) {
            isVisible = false;
          }
        }

        if (
          busTypeChecked.filter((bType) => bType.id === "NONA/C").length > 0 &&
          busTypeChecked.filter((bType) => bType.id === "SLEEPER").length > 0
        ) {
          if (trip.busType.includes("AC") || trip.busType.includes("SEATER")) {
            isVisible = false;
          }
        }
      }

      const travelsChecked = checkedFilters("travels");
      if (
        travelsChecked.length &&
        !travelsChecked.map((travel) => travel.id).includes(trip.displayName)
      ) {
        isVisible = false;
      }

      const boardingPointsChecked = checkedFilters("boardingPoints");
      if (boardingPointsChecked.length) {
        const { boardingPoints } = trip;
        if (
          !boardingPointsChecked
            .map((bPoint) => bPoint.id)
            .some((busBoardPoint) =>
              boardingPoints
                .map((boardP) => boardP.Location)
                .includes(busBoardPoint)
            )
        ) {
          isVisible = false;
        }
      }

      const droppingPointsChecked = checkedFilters("droppingPoints");
      if (droppingPointsChecked.length) {
        const { droppingPoints } = trip;
        if (
          !droppingPointsChecked
            .map((dPoint) => dPoint.id)
            .some((busDropPoint) =>
              droppingPoints
                .map((dropP) => dropP.Location)
                .includes(busDropPoint)
            )
        ) {
          isVisible = false;
        }
      }

      isVisible && visibleCount++;

      return { ...trip, isVisible: isVisible };
    });

    filteredList = filteredList.filter((item) => item.isVisible);
    setBusResultsList({ ...busSearchResultObj.data, trips: filteredList });

    // updateBusSearchResultObj({busSearchResultObj}data);
    setResultCount(visibleCount);
  };

  const appendZero = (hrMn) => {
    return ("0" + hrMn.hours).slice(-2) + ":" + ("0" + hrMn.minutes).slice(-2);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  useEffect(() => {
    setDynamicFilters();
  }, []);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );

  return (
    <div className="side-bar">
      <Card>
        <div className="flight-filters slider-icon-1">
          <div className="flight-result-indicator">
            <p>
              Showing {resultCount} Of {count} Buses
            </p>
          </div>
          <div className="overall-filter-container">
            <div className="overall-filter-header">
              <p className="filter-text">Filters</p>
              <p className="clear-text" onClick={setDynamicFilters}>
                Clear all
              </p>
            </div>
            <div className="overall-filter-body">
              <div className="stops-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel header="Price" key="1" extra={extraPanel("price")}>
                    <div className="slider-label">
                      <span>RS {priceRange[0]}</span>
                      <span>RS {priceRange[1]}</span>
                    </div>
                    {filters.price && (
                      <Slider
                        range
                        step={10}
                        defaultValue={[
                          filters.price.minPriceRange,
                          filters.price.maxPriceRange,
                        ]}
                        value={priceRange}
                        min={filters.price.minPriceRange}
                        max={filters.price.maxPriceRange}
                        onChange={priceChangeHandler}
                        onAfterChange={priceChangeCompleteHandler}
                        tooltipVisible={false}
                      />
                    )}
                  </Panel>
                </Collapse>
              </div>
              <div className="stops-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel
                    header={"Bus Timings"}
                    key="1"
                    extra={extraPanel("timings")}
                  >
                    {filters.timings &&
                      filters.timings.map((time, i) => (
                        <p key={i}>
                          <Checkbox
                            checked={time.isChecked}
                            onChange={(e) => onChange(e, "timings", time.id)}
                          >
                            {time.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>

              <div className="stops-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel
                    header={"Bus Type"}
                    key="1"
                    extra={extraPanel("busType")}
                  >
                    {filters.busType &&
                      filters.busType.map((bType, i) => (
                        <p key={i}>
                          <Checkbox
                            checked={bType.isChecked}
                            onChange={(e) =>
                              onChangeBusType(e, "busType", bType.id)
                            }
                          >
                            {bType.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
              <div className="stops-filter">
                <Collapse
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                  className="customscroll"
                >
                  <Panel
                    header={"Travels"}
                    key="1"
                    extra={extraPanel("travels")}
                  >
                    {filters.travels &&
                      filters.travels.map((travel, i) => (
                        <p key={i}>
                          <Checkbox
                            checked={travel.isChecked}
                            onChange={(e) => onChange(e, "travels", travel.id)}
                          >
                            {travel.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
              <div className="stops-filter">
                <Collapse
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                  className="customscroll"
                >
                  <Panel
                    header={"Boarding Points"}
                    key="1"
                    extra={extraPanel("boardingPoints")}
                  >
                    {filters.boardingPoints &&
                      filters.boardingPoints.map((bPoint, i) => (
                        <p key={i}>
                          <Checkbox
                            checked={bPoint.isChecked}
                            onChange={(e) =>
                              onChange(e, "boardingPoints", bPoint.id)
                            }
                          >
                            {bPoint.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
              <div className="stops-filter">
                <Collapse
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                  className="customscroll"
                >
                  <Panel
                    header={"Dropping Points"}
                    key="1"
                    extra={extraPanel("droppingPoints")}
                  >
                    {filters.droppingPoints &&
                      filters.droppingPoints.map((dPoint, i) => (
                        <p key={i}>
                          <Checkbox
                            checked={dPoint.isChecked}
                            onChange={(e) =>
                              onChange(e, "droppingPoints", dPoint.id)
                            }
                          >
                            {dPoint.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default BusFilter;
