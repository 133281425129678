import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import ApiClient from "../../../helpers/ApiClient";
import { RightOutlined } from "@ant-design/icons";
// import { filter } from "@antv/util";
import queryString from "query-string";
import "./TopFlights.scss";

const TopFlights = () => {
  const [domRoutes, setdomRoutes] = useState([]);
  const [inRoutes, setInRoutes] = useState([]);
  let tomorrowDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    getRoutes();
  }, []);

  const getRoutes = async (type) => {
    await ApiClient.get("admin/deals", {})

      .then((res) => {
        if (res.status === 200) {
          let data = res.data
            .filter((item) => item.serviceType == 2)
            .map((deal) => ({
              ...deal,
              destination: queryString.parse(deal.destination),
              source: queryString.parse(deal.source),
            }));
          setdomRoutes(data);
        } else {
          console.log("error");
        }
      })
      .catch((e) => {
        setdomRoutes([]);
      });
  };
  return (
    <>
      {domRoutes.length > 0 ? (
        <div className="">
          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <h4 className="top-fl-route text-center">Top Flight Routes</h4>
            </ANTD.Col>
          </ANTD.Row>
          <div className="sec-bg-top">
            <ANTD.Row>
              {domRoutes.length > 0 ? (
                domRoutes.map((route, index) => {
                  return (
                    <ANTD.Col
                      md={12}
                      xs={24}
                      className="gap-cols-sec"
                      key={index}
                    >
                      <div className="tab-content current">
                        <ul>
                          <li className="top-flight-li">
                            <a className="hm_link">{`${route.FromAirportCode} to ${route.ToAirportCode}`}</a>
                            <Link
                              to={`/flightresults?adult=1&child=0&class=Economy&destination=${route.ToAirportCode}&from=${tomorrowDate}&infant=0&origin=${route.FromAirportCode}&to=&tripType=oneWay`}
                              className="srch_btn"
                            >
                              Search Flights
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </ANTD.Col>
                  );
                })
              ) : (
                <ANTD.Col md={24} xs={24} className="gap-cols-sec">
                  <div className="tab-content current text-center">
                    <h6 className="mb-0">No Top Flights Available</h6>
                  </div>
                </ANTD.Col>
              )}
            </ANTD.Row>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TopFlights;
