import React, { useState, useEffect, useRef } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import { useHistory } from "react-router-dom";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import CountryList from "../../common/CountryList";
import moment from "moment";
import queryString from "query-string";
import { SearchOutlined } from "@ant-design/icons";
import "../Hotels/Hotels.scss";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Hotels = (props) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";

  const city = useRef(null);
  const dateBox = useRef(null);
  const ccode = useRef(null);

  const defHotelPaxInfo = [
    {
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    },
  ];
  const [showDate, setShowDate] = useState(false);
  const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
  const paxInfo = [...hotelPaxInfo];
  const validateMessages = {
    required: "",
  };

  const [rooms, setRooms] = useState(false);
  const searchBtn = useRef();
  const toggleCount = () => {
    setRooms((prev) => !prev);
  };

  const addRoom = () => {
    paxInfo.push({
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    });

    setHotelPaxInfo(paxInfo);
  };

  let updatePaxInfoFromChild = (paxInfo) => {
    setHotelPaxInfo(paxInfo);
  };

  let getPaxCount = () => {
    return paxInfo.reduce(
      (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
      0
    );
  };

  const submitForm = (values) => {
    let formData = {
      checkInDate: moment(values.checkin_checkout[0]).format("YYYY-MM-DD"),
      checkOutDate: moment(values.checkin_checkout[1]).format("YYYY-MM-DD"),
      hotelCityCode: values.hotelCityCode,
      roomGuests: JSON.stringify(hotelPaxInfo),
      nationality: values.nationality,
      countryCode: values.nationality,
      currency: "INR",
      traceId: "string",
    };

    const query = queryString.stringify(formData);
    history.push("/hotels-list?" + query);

    if (props.modifySearch) {
      props.hotelSearchAPI();
    }
  };

  const disabledDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  useEffect(() => {
    if (props.modifySearch) {
      const hotelSearchParams = queryString.parse(window.location.search);
      let roomDetails = JSON.parse(hotelSearchParams.roomGuests);

      form.setFieldsValue({
        hotelCityCode: hotelSearchParams.hotelCityCode,
        checkin_checkout: [
          moment(hotelSearchParams.checkInDate, "YYYY-MM-DD"),
          moment(hotelSearchParams.checkOutDate, "YYYY-MM-DD"),
        ],
        nationality: hotelSearchParams.nationality,
      });
      setHotelPaxInfo(roomDetails);
    }
  }, []);

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };

  return (
    <div className="hotels_search_box">
      <div className="hotels-wrapper">
        <Row className="pdr-bus-bottom" gutter={8}>
          <Col md={24} lg={24} xs={24} className=""></Col>
        </Row>
        <Form
          form={form}
          initialValues={{
            nationality: "IN",
          }}
          className="hotel-search-form hotel-bg-panel-all"
          validateMessages={validateMessages}
          onFinish={submitForm}
        >
          <Row gutter={8} className="search-row" align="top">
            <Col
              md={12}
              lg={7}
              xs={24}
              className="from-to-inputs hotel-select-jun"
            >
              <div className="postive-v3-hotel">
                <div className="location-name-code">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <HotelAutoSelect
                  formItemProps={{
                    label: "City",
                    name: "hotelCityCode",
                    rules: [
                      { required: true, message: "Please Specify City Name" },
                    ],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Enter City Name",
                  }}
                  api={"hotels-v2/searchhotelcity/"}
                  refName={city}
                  focusRef={dateBox}
                  handleOnSubmit={handleOnSubmit}
                  modifySearch={props.modifySearch ? props.modifySearch : false}
                />
              </div>
            </Col>

            <Col
              md={12}
              lg={5}
              xs={24}
              className="from-to-inputs hotel-select-jun right-line-bdr"
            >
              <Form.Item
                className="checin-cheout-mob"
                label={"Check-In & Check-Out Date"}
                name="checkin_checkout"
                rules={[
                  {
                    required: true,
                    message: "Please Specify Dates",
                  },
                ]}
              >
                <RangePicker
                  style={{ width: "100%" }}
                  placeholder={["Check-In", "Check-Out"]}
                  size="large"
                  format={dateFormat}
                  disabledDate={disabledDate}
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => setShowDate((prev) => !prev)}
                  inputReadOnly={true}
                />
              </Form.Item>
            </Col>
            <Col
              md={12}
              lg={4}
              xs={24}
              className="from-to-inputs hotel-select-jun"
            >
              <Form.Item
                label="Nationality"
                name="nationality"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  ref={ccode}
                  showSearch
                  size="large"
                  placeholder="Nationality"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {CountryList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              md={12}
              lg={5}
              xs={24}
              className="from-to-inputs hotel-select-jun"
            >
              <div className="postive-v3-hotel">
                <Form.Item label="Rooms">
                  {/* <div className="location-name-code">
                    <i class="fa fa-bed" aria-hidden="true"></i>
                  </div> */}
                  <div onClick={toggleCount}>
                    <Input
                      className="hotel-room-res-v3"
                      value={
                        "Rooms: " +
                        paxInfo.length +
                        " ,guests: " +
                        getPaxCount()
                      }
                      prefix={<i className="fa fa-bed" />}
                      size="large"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="add-room-block">
                    <div
                      className="pax-modal"
                      id="pax-modal"
                      style={{
                        display: rooms ? "block" : "none",
                      }}
                    >
                      <div className="pax-modal-wrapper">
                        <div className="pax-modal-arrow"></div>
                        <ul className="first-item">
                          {paxInfo.map((pax, index) => (
                            <HotelPassengers
                              pax={pax}
                              key={index}
                              index={index}
                              updatePaxInfoFromChild={updatePaxInfoFromChild}
                              paxInfo={paxInfo}
                            />
                          ))}

                          <li className="add-room-btn" onClick={addRoom}>
                            Add room
                          </li>
                        </ul>

                        <div>
                          <Button
                            block
                            className="pax-ready-btn"
                            onClick={() => {
                              toggleCount();
                              if (rooms) searchBtn.current.focus();
                            }}
                          >
                            Ready
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </div>
            </Col>
            <Col md={12} lg={3} xs={24} className="hotel-select-jun">
              <Form.Item className="mr-none">
                <Button
                  size="large"
                  className="train-search-btn primary-btn mr-t tg-primary-btn-h3"
                  id="hotel-main-btn"
                  ref={searchBtn}
                  htmlType="submit"
                >
                  <SearchOutlined /> Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Hotels;
