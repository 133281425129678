import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { Button, Col, Layout, Menu, Row, Modal } from "antd";
import { TgContext } from "../common/providers/TgProvider";
import { AdminAccessContext } from "../common/providers/AdminAccessProvider";
import { Link, useHistory } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  BarChartOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  AuditOutlined,
  UnlockOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  SettingOutlined,
  FileSearchOutlined,
  ContainerOutlined,
  LayoutOutlined,
  BuildOutlined,
} from "@ant-design/icons";
import AdminNavigations from "../common/navbar/AdminNavigations";
import "./Adminstyles.scss";
import ProtectedMenu from "../helpers/ProtectedMenu";
import { useAuthContext } from "../common/providers/AuthProvider";
import moment from "moment";
import Logo from "../assets/images/logo-trip-v3.png";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const CompanyName = process.env.REACT_APP_COMPANY_NAME;
const Administration = ({ updateIsLogin }) => {
  const { accessList } = useAuthContext();
  let history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const { logo } = useContext(TgContext);
  const { accessToken } = useContext(AdminAccessContext);
  const [isTimeout, setIsTimeout] = useState(false);
  const [events, setEvents] = useState([
    "click",
    "load",
    "mousemove",
    "scroll",
    "keydown",
  ]);
  const [second, setSecond] = useState(0);
  let timeout = 10;

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    if (accessToken) {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
      // localStorage.setItem("experiedTime", timeStamp + timeout * 1000)
    } else {
      localStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
  }, [accessToken]);

  // start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 60000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 20; // Maximum ideal time given before logout
      const popTime = 19; // remaining time (notification) left to logout.

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();

      if (minPast === popTime) {
        setSecond(leftSecond);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        updateIsLogin("role", false);
        localStorage.clear();
        sessionStorage.clear();
        warning();
        history.push("/");
      }
    }, 1000);
  };

  const warning = () => {
    Modal.warning({
      title: "Session Expired",
      content: "your session is expired",
    });
  };
  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  // const startInterval = () => {
  //   updateExpireTime
  //   const interval = setInterval(() => {
  //     const experiedTime = parseInt(localStorage.getItem("experiedTime"), 10)
  //     if (experiedTime < Date.now()) {
  //       console.log("timeoutt")
  //     }
  //   }, 1000)
  // }
  const AdminLogout = () => {
    updateIsLogin("role", false);
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
  };
  const menuToggle = () => {
    setCollapsed((prev) => !prev);
  };

  const BASE = process.env.REACT_APP_BASE_URL;

  return (
    <div id="admin">
      <Layout>
        <Sider
          style={{ minHeight: "100vh" }}
          width={"220px"}
          breakpoint="lg"
          trigger={null}
          collapsible
          collapsed={!collapsed}
          defaultCollapsed={false}
          className="site-layout-background"
        >
          <div className="logo">
            <img src={Logo} alt="trippozo_logo" />
          </div>

          <Menu theme="dark" mode="inline">
            <Menu.Item key="/admin/dashboard" icon={<BarChartOutlined />}>
              <Link to="/admin/dashboard">
                {" "}
                <strong> Dashboard</strong>
              </Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<UserOutlined />} title="Masters">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/role"
                icon={<UserOutlined />}
              >
                <Link to="/admin/role"> Roles</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/user"
                icon={<TeamOutlined />}
              >
                <Link to="/admin/user"> Admin Users</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/Siteusers"
                icon={<UserOutlined />}
              >
                <Link to="/admin/Siteusers"> Siteusers</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/deals"
                icon={<LayoutOutlined />}
              >
                <Link to="/admin/deals"> Deals</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/cityhotel"
                icon={<ContainerOutlined />}
              >
                <Link to="/admin/cityhotel"> City Hotel</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/accesspermissions"
                icon={<UnlockOutlined />}
              >
                <Link to="/admin/accesspermissions"> Access Permissions</Link>
              </ProtectedMenu>

              {/* <ProtectedMenu
                accessList={accessList}
                key="/admin/busoperator"
                icon={
                  <>
                    <i className="fa fa-bus"></i>
                  </>
                }
              >
                <Link to="/admin/busoperator">Bus Operator</Link>
              </ProtectedMenu> */}
              <ProtectedMenu
                accessList={accessList}
                key="/admin/operators"
                icon={
                  <>
                    <i className="fa fa-plane"></i>
                  </>
                }
              >
                <Link to="/admin/operators"> Operators</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/banners"
                icon={
                  <>
                    <i className="fa fa-picture-o"></i>
                  </>
                }
              >
                <Link to="/admin/banners"> Banners</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/promo"
                icon={
                  <>
                    <i className="fa fa-file-code-o"></i>
                  </>
                }
              >
                <Link to="/admin/promo"> Promo Code</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/cms"
                icon={
                  <>
                    <i className="fa fa-desktop"></i>
                  </>
                }
              >
                <Link to="/admin/cms"> CMS Screens</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/bookinglimit"
                icon={
                  <>
                    <i className="fa fa-desktop"></i>
                  </>
                }
              >
                <Link to="/admin/bookinglimit"> Booking Limit</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub2" icon={<AuditOutlined />} title="Markups">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/flightmarkup"
                icon={
                  <>
                    <i className="fa fa-plane"></i>
                  </>
                }
              >
                <Link to="/admin/flightmarkup"> Flight Markup</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/busmarkup"
                icon={
                  <>
                    <i className="fa fa-bus"></i>
                  </>
                }
              >
                <Link to="/admin/busmarkup"> Bus Markup</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/hotelmarkup"
                icon={<BuildOutlined />}
              >
                <Link to="/admin/hotelmarkup"> Hotel Markup</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub4" icon={<FilePdfOutlined />} title="Ticketing">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/travelCalendar"
                icon={
                  <>
                    <i className="fa fa-calendar"></i>
                  </>
                }
              >
                <Link to="/admin/travelCalendar"> Travel Calendar</Link>
              </ProtectedMenu>

              <Menu.Item
                key="/admin/cancelrequest"
                icon={
                  <>
                    <i className="fa fa-ban"></i>
                  </>
                }
              >
                <Link to="/admin/cancelrequest"> Cancel Requests</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub5" icon={<FileSearchOutlined />} title="Reports">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/reports"
                icon={<FileTextOutlined />}
              >
                <Link to="/admin/reports"> All Reports</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/contactforms"
                icon={
                  <>
                    <i className="fa fa-list"></i>
                  </>
                }
              >
                <Link to="/admin/contactforms"> Contact Forms</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/flightenquiries"
                icon={
                  <>
                    <i className="fa fa-list"></i>
                  </>
                }
              >
                <Link to="/admin/flightenquiries"> Flight Enquiries</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/subscriberreport"
                icon={
                  <>
                    <i className="fa fa-users"></i>
                  </>
                }
              >
                <Link to="/admin/subscriberreport"> Subscriber Report</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub6" icon={<UserOutlined />} title="Logs">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/pglogs"
                icon={
                  <>
                    <i className="fa fa-credit-card-alt"></i>
                  </>
                }
              >
                <Link to="/admin/pglogs"> PG Log</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/apilogs"
                icon={
                  <>
                    <i className="fa fa-link"></i>
                  </>
                }
              >
                <Link to="/admin/apilogs"> API Log</Link>
              </ProtectedMenu>
            </SubMenu>

            {/* <SubMenu key="sub7" icon={<EnvironmentOutlined />} title="Mapping">
              <ProtectedMenu accessList={accessList} key="/admin/createlocation" icon={<EnvironmentOutlined />}>
                <Link to="/admin/createlocation">Create Location</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/updatelocation" icon={<EnvironmentOutlined />}>
                <Link to="/admin/updatelocation">Update Location</Link>
              </ProtectedMenu>
            </SubMenu> */}

            <ProtectedMenu
              accessList={accessList}
              key="/admin/settings"
              icon={<SettingOutlined />}
              className="individual-menu"
            >
              <Link to="/admin/settings"> Settings</Link>
            </ProtectedMenu>
          </Menu>
        </Sider>
        <Layout>
          <Header className="site-layout-background headerColor">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: menuToggle,
              }
            )}

            <Button type="primary" onClick={AdminLogout}>
              Logout
            </Button>
          </Header>
          <Content>
            <AdminNavigations
              accessList={accessList}
              accessToken={accessToken}
            />
          </Content>
          <Footer>
            <Row>
              <Col span={24}>
                <h6 className="adminFooter">
                  All Right Resereved @ {CompanyName}
                </h6>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default Administration;
