function getBusTicketPrice(ticketData) {
  let baseFare = 0;
  let taxes = 0;
  let serviceCharge = 0;
  let total = 0;
  let grandTotal = 0;
  let convamount = 0;
  if (ticketData?.passengerInfo?.length > 0) {
    baseFare = ticketData.passengerInfo.reduce((acc, cur) => acc + Number(cur.fares), 0);
    taxes = ticketData.passengerInfo.reduce((acc, cur) => acc + Number(cur.serviceTax), 0);
    serviceCharge = ticketData.passengerInfo.reduce((acc, cur) => acc + Number(cur.serviceCharge), 0);
  }

  total = Number(baseFare) + Number(taxes) + Number(serviceCharge);

  if (ticketData?.convienenceData?.amount) {
    if (ticketData.convienenceData.type === 0) {
      convamount = Number(ticketData.convienenceData.amount);
    } else {
      convamount = Number((Number(total) / 100) * Number(ticketData.convienenceData.amount ? ticketData.convienenceData.amount : 0));
    }
  }
  grandTotal = total + convamount;

  if (ticketData?.promoData?.Discount) {
    if (ticketData.promoData.tripType === "twoWay") {
      grandTotal -= Number(ticketData.promoData.Discount) / 2;
    } else {
      grandTotal -= Number(ticketData.promoData.Discount);
    }
  }

  return {
    baseFare: Number(baseFare).toFixed(2),
    taxes: Number(taxes).toFixed(2),
    serviceCharge: Number(serviceCharge).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    grandTotal: Number(grandTotal).toFixed(2),
  };
}

export default getBusTicketPrice;
