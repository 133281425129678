import React, { useState, useEffect } from "react";
import "../Dashboard/Dashboard.scss";
import { Card, Col, Row, Skeleton } from "antd";
import { Link } from "react-router-dom";
import Flightsalescount from "../../admin/Flightsalescount/Flightsalescount";
import Bussalescount from "../../admin/Bussalescount/Bussalescount";
import FlightSalesCountSkeleton from "../Flightsalescount/FlightSalesCountSkeleton";
import HotelSalesCountSkeleton from "../Hotelsalescount/HotelSalesCountSkeleton";
import HotelSalescount from "../../admin/Hotelsalescount/Hotelsalescount";
import BusSalesCountSkeleton from "../Bussalescount/BusSalesCountSkeleton";
import ApiClient from "../../helpers/ApiClient";
const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    buses: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    flights: {
      completed_bookings: [],
      upcoming_bookings: [],
    },

    userCounts: {},
  });
  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    ApiClient.get("admin/dashboard")
      .then((res) => {
        if (res && res.status == 200) {
          setDashboardData((prev) => ({ ...prev, ...res.data }));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {isLoading ? (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>Dashboard</h4>
            </div>

            <div className="dash-board-header-btn"></div>
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8} md={8} lg={6}>
              <Card>
                <Skeleton active paragraph={{ rows: 2 }} />
              </Card>
            </Col>

            <Col xs={24} sm={8} md={8} lg={6}>
              <Card>
                <Skeleton active paragraph={{ rows: 2 }} />
              </Card>
            </Col>

            <Col xs={24} sm={8} md={8} lg={6}>
              <Card>
                <Skeleton active paragraph={{ rows: 2 }} />
              </Card>
            </Col>
          </Row>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <FlightSalesCountSkeleton />
              </Col>
            </Row>
          </div>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <BusSalesCountSkeleton />
              </Col>
            </Row>
          </div>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <HotelSalesCountSkeleton />
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>Dashboard</h4>
            </div>

            <div className="dash-board-header-btn"></div>
          </div>
          <div className="order-count-cards">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} md={8} lg={6}>
                <Card className="order-card dashboard-card card-bg-1">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Users Count</h4>
                      <p className="count-dashboard">{dashboardData.userCounts.users_count}</p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i className="fa fa-desktop dash-board-icon-all" aria-hidden="true"></i>
                    </Col>
                  </Row>
                  <div className="bottom-dashboad-bdr">
                    <Link to="/admin/Siteusers">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={8} md={8} lg={6}>
                <Card className="order-card dashboard-card card-bg-2">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Subscribers </h4>
                      <p className="count-dashboard">{dashboardData.userCounts.subscribers}</p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i className="fa fa-clock-o dash-board-icon-all" aria-hidden="true"></i>
                    </Col>
                  </Row>
                  <div className="bottom-dashboad-bdr">
                    <Link to="/admin/subscriberreport">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>

              {/* <Col xs={24} sm={8} md={8} lg={6}>
                <Card className="order-card dashboard-card card-bg-3">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Customer Requests</h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.customer_requests}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-user dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>

                  <div className="bottom-dashboad-bdr">
                    <Link to="/admin/contactforms">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col> */}
            </Row>
          </div>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <Flightsalescount flightsData={dashboardData.flights} />
              </Col>
            </Row>
          </div>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <Bussalescount busData={dashboardData.buses} />
              </Col>
            </Row>
          </div>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <HotelSalescount hotelData={dashboardData.hotels} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
export default Dashboard;
