import React from "react";
import "./PassengerDetails.scss";
import * as ReactBootstrap from "react-bootstrap";

const PassengerDetails = ({ passengersInfo }) => {
  let passengersData = passengersInfo[0]?.passengerDetails;
  return (
    <div className="passenger-details-container">
      <div className="passenger-details-card">
        <div className="passenger-info">
          <ReactBootstrap.Table responsive>
            <thead>
              <tr>
                <th>Title</th>
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Seat No.</th>
                <th>Email</th>
                <th>Phone No.</th>
              </tr>
            </thead>
            <tbody>
              {passengersData
                ? passengersData.map((data) => (
                    <tr>
                      <td>{data.title ?? ""}</td>
                      <td>{data.name ?? ""}</td>
                      <td>{data.age ?? ""}</td>
                      <td>{data.gender === "M" ? "Male" : "Female"}</td>
                      <td>{data.seatNo ?? ""}</td>
                      <td>{passengersInfo[0]?.emailId ?? "-"}</td>
                      <td>{passengersInfo[0]?.mobileNo ?? "-"}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </ReactBootstrap.Table>
        </div>
      </div>
    </div>
  );
};
export default PassengerDetails;
