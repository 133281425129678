import React from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import bg from "../../../assets/images/bg/ticketbg.png";
import arrow from "../../../assets/images/bg/rightArrow.png";
import shield from "../../../assets/images/bg/shield.png";
import getBusTicketPrice from "../../AllTickets/busHelper";
import moment from "moment";

let dateFormat = "MMM, DD-YYYY";
const styles = StyleSheet.create({
  textDanger: {
    color: "#dc3545",
  },
  textGreen: {
    color: "#009966",
  },
  mb: {
    marginBottom: 10,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexGrow: 1,
  },

  wHalf: {
    flex: 0.5,
    marginBottom: 10,
  },
  tw: {
    flex: 0.2,
  },
  fourthHalf: {
    flex: 0.25,
    paddingRight: 5,
  },

  width32: {
    flex: 0.32,
  },
  trw: {
    flex: 0.3,
  },
  fw: {
    flex: 0.4,
  },
  sw: {
    flex: 0.6,
  },
  sew: {
    flex: 0.7,
  },

  mb8: {
    marginBottom: 8,
  },
  description: {
    paddingLeft: 5,
  },

  hr: {
    borderBottom: "1px solid #e2e2e2",
    flex: 1,
    margin: "10px 0",
  },

  page: {
    flexDirection: "row",
    padding: "15px 10px",
  },
  section: {
    flex: 1,
  },
  innerSection: {
    flex: 1,
    position: "relative",
  },
  pageBg: {
    position: "absolute",
    flex: 1,
    width: "100%",
    height: 100,
  },
  busWrapper: {
    padding: "25px 10px 10px 10px",
  },

  whiteText: { color: "#fff", fontSize: 12, marginBottom: 10, fontWeight: 600 },
  title: { fontSize: 12, fontWeight: 600, wordBreak: "break-all" },
  fadetitle: { color: "#a8a8a8", fontSize: 9 },
  smallText: { fontSize: 10 },
  xsmallText: {
    fontSize: 9,
    marginBottom: 3,
  },
  greytext: { color: "#666", fontSize: 9 },
  card: {
    padding: "10px 5px",
    marginBottom: 10,
    border: "1px solid #e2e2e2",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
  },

  insuranceCard: {
    border: "1px solid #02cb66",
    borderRadius: 6,
    textAlign: "center",
    padding: "10px 5px",
  },

  insurancetitle: {
    color: "#18a160",
    fontSize: 10,
  },

  dottedBorder: {
    borderBottom: "1px dotted #a8a8a8",
    width: "100%",
  },

  coverage: {
    color: "#294782",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px 0",
  },

  coveragetitle: {
    color: "#294782",
    fontSize: 9,
  },
});

const getBusBookingStatus = (status) => {
  switch (status) {
    case 2:
      return <Text style={{ color: "#FFA500" }}>BLOCKED</Text>;

    case 3:
      return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;

    case 5:
      return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;

    case 7:
      return <Text style={{ color: "#008000" }}>PARTIALLY CANCELLED</Text>;
    case "BLOCKED":
      return <Text style={{ color: "#FFA500" }}>BLOCKED</Text>;

    case "Booked":
      return <Text style={{ color: "#008000" }}>Booked</Text>;
    case "CONFIRMED":
      return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;
    case "CANCELLED":
      return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;

    case "PARTIALLY CANCELLED":
      return <Text style={{ color: "#008000" }}>PARTIALLY CANCELLED</Text>;

    default:
      return "";
  }
};

const CancellationPolicy = ({ data }) => {
  const policyStringArray = data.split(";");
  return (
    <View style={{ alignItems: "center", marginTop: 5 }}>
      <View style={{ width: "70%" }}>
        <Text style={{ ...styles.smallText, textAlign: "center" }}>
          Cancellation Policy
        </Text>
        <View style={{ marginTop: 5 }}>
          <View style={styles.row}>
            <View style={styles.wHalf}>
              <Text style={{ ...styles.smallText, textAlign: "left" }}>
                Cancellation time
              </Text>
            </View>

            <View style={styles.wHalf}>
              <Text style={{ ...styles.smallText, textAlign: "right" }}>
                Cancellation charges
              </Text>
            </View>
          </View>
          {policyStringArray.map((data, index) => {
            let val = data.split(":");
            if (index === 0) {
              return (
                <View style={styles.row} key={index + "with"}>
                  <View style={styles.wHalf}>
                    <Text style={{ ...styles.smallText, textAlign: "left" }}>
                      {`With in ${val[1]} Hrs`}
                    </Text>
                  </View>

                  <View style={styles.wHalf}>
                    <Text style={{ ...styles.smallText, textAlign: "right" }}>
                      {`${val[2]}%`}
                    </Text>
                  </View>
                </View>
              );
            } else if (index === policyStringArray.length - 1) {
              return (
                <View style={styles.row} key={index + "Above"}>
                  <View style={styles.wHalf}>
                    <Text style={{ ...styles.smallText, textAlign: "left" }}>
                      {`Above ${val[0]} Hrs`}
                    </Text>
                  </View>

                  <View style={styles.wHalf}>
                    <Text style={{ ...styles.smallText, textAlign: "right" }}>
                      {`${val[2]}%`}
                    </Text>
                  </View>
                </View>
              );
            } else {
              return (
                <View style={styles.row} key={index + "Between"}>
                  <View style={styles.wHalf}>
                    <Text style={{ ...styles.smallText, textAlign: "left" }}>
                      {`Between ${val[0]} to ${val[1]} Hrs`}
                    </Text>
                  </View>

                  <View style={styles.wHalf}>
                    <Text style={{ ...styles.smallText, textAlign: "right" }}>
                      {`${val[2]}%`}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </View>
      </View>
    </View>
  );
};

const BusDoc = ({ ticketData }) => {
  let { baseFare, taxes, serviceCharge, convamount, grandTotal, discount } =
    getBusTicketPrice(ticketData);

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        {ticketData?.bookingStatus ? (
          <View style={styles.section}>
            <View style={styles.innerSection}>
              <Image src={bg} style={styles.pageBg} />
              <View style={styles.busWrapper}>
                <Text style={styles.whiteText}>
                  {ticketData.sourceName} To {ticketData.destinationName}
                </Text>

                <View style={styles.card} wrap={false}>
                  <View style={styles.row}>
                    <View style={styles.fw}>
                      <View style={styles.row}>
                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.sourceName}
                          </Text>
                          <Text style={styles.fadetitle}>
                            {ticketData.operator}
                          </Text>
                        </View>
                        <View style={styles.tw}>
                          <Image
                            src={arrow}
                            style={{ width: 16, height: 10, marginTop: 1 }}
                          />
                        </View>

                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.destinationName}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.sw}>
                      <View style={styles.row}>
                        <View style={styles.trw}>
                          <Text style={styles.title}>
                            {moment(
                              ticketData.JourneyDate,
                              "DD-MM-YYYY"
                            ).format(dateFormat)}
                          </Text>
                          <Text style={styles.fadetitle}>Journey Date</Text>
                        </View>
                        <View style={styles.sew}>
                          <Text style={styles.title}>{ticketData.pnr}</Text>
                          <Text style={styles.fadetitle}>PNR/Booking No</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.hr}></View>

                  <View style={styles.row}>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>
                        {ticketData.bookingRefNo}
                      </Text>
                      <Text style={styles.fadetitle}>Ticket Ref Number</Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>
                        {getBusBookingStatus(ticketData.bookingStatus)}
                      </Text>
                      <Text style={styles.fadetitle}>Status</Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>{ticketData.noOfSeats}</Text>
                      <Text style={styles.fadetitle}>Seat(s)</Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>Paid</Text>
                      <Text style={styles.fadetitle}>Payment Status</Text>
                    </View>
                  </View>
                </View>

                {/* bus info */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Bus Details</Text>
                    <Text style={styles.fadetitle}>
                      Here are the details of your bus from{" "}
                      {ticketData.sourceName} to {ticketData.destinationName} on{" "}
                      {moment(ticketData.JourneyDate, "DD-MM-YYYY").format(
                        dateFormat
                      )}
                    </Text>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb }}>
                    <View style={styles.fw}>
                      <Text style={styles.smallText}>
                        {ticketData.operator}
                      </Text>
                      <Text style={styles.fadetitle}>Operator</Text>
                    </View>

                    <View style={styles.sw}>
                      <View style={{ ...styles.row, alignItems: "flex-start" }}>
                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.sourceName}
                          </Text>
                          <Text style={styles.fadetitle}>
                            {ticketData.departureTime}
                          </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            margin: "5px 2px 0 2px",
                            paddingRight: 5,
                          }}
                        >
                          <View style={styles.dottedBorder}></View>
                        </View>

                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.destinationName}
                          </Text>
                          <Text style={styles.fadetitle}>
                            {ticketData.ArrivalTime}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Bus Type</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textGreen }}
                      >
                        {ticketData.busTypeName}
                      </Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Departure Time</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textGreen }}
                      >
                        {ticketData.departureTime}
                      </Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Boarding Point</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textGreen }}
                      >
                        {ticketData.boardingPoint}
                      </Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Droping Point</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textGreen }}
                      >
                        {ticketData.droppingPoint}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Contact Details */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Contact Details</Text>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Name</Text>
                      <Text style={styles.smallText}>
                        {ticketData.passengerInfo[0].titles}{" "}
                        {ticketData.passengerInfo[0].names}
                      </Text>
                    </View>

                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Mobile Number</Text>
                      <Text style={styles.smallText}>
                        {ticketData.mobileNo}
                      </Text>
                    </View>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Email</Text>
                      <Text style={styles.smallText}>{ticketData.emailId}</Text>
                    </View>
                  </View>
                </View>

                {/* pax details */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Passengers Details</Text>
                  </View>
                  {ticketData.passengerInfo.length > 0
                    ? ticketData.passengerInfo.map((item, i) => (
                        <View style={styles.row} key={i + "pax"}>
                          <View style={styles.fourthHalf}>
                            <Text style={styles.fadetitle}>Name</Text>
                            <Text style={styles.smallText}>
                              {item.titles} {item.names}
                            </Text>
                          </View>

                          <View style={styles.fourthHalf}>
                            <Text style={styles.fadetitle}>Age</Text>
                            <Text style={styles.smallText}>{item.ages}</Text>
                          </View>
                          <View style={styles.fourthHalf}>
                            <Text style={styles.fadetitle}>Seat No.</Text>
                            <Text style={styles.smallText}>{item.seatNos}</Text>
                          </View>

                          <View style={styles.fourthHalf}>
                            <Text style={styles.fadetitle}>Gender</Text>
                            <Text style={styles.smallText}>
                              {item.genders === "M" ? "Male" : "Female"}
                            </Text>
                          </View>
                        </View>
                      ))
                    : null}
                </View>

                {/* insurance  */}

                {ticketData?.insuranceRequired === 1 &&
                ticketData.insuranceData &&
                ticketData?.insuranceData?.serviceType === 3 ? (
                  <View style={styles.card} wrap={false}>
                    <View style={styles.mb}>
                      <Text style={styles.title}>
                        THE SMART INSURANCE COVER
                      </Text>
                      <Text style={styles.greytext}>
                        EASY CLAIM PROCESS | NO QUESTIONS ASKED
                      </Text>
                    </View>

                    <View style={styles.row}>
                      <View style={styles.width32}>
                        <View style={styles.insuranceCard}>
                          <Text style={styles.insurancetitle}>
                            {ticketData?.insuranceData.description_box_1}
                          </Text>
                        </View>
                      </View>

                      <View style={styles.width32}>
                        <View style={styles.insuranceCard}>
                          <Text style={styles.insurancetitle}>
                            {ticketData?.insuranceData.description_box_2}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.width32}>
                        <View style={styles.insuranceCard}>
                          <Text style={styles.insurancetitle}>
                            {ticketData?.insuranceData.description_box_3}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.coverage}>
                      <Image
                        style={{ width: 10, height: 10, marginRight: 5 }}
                        src={shield}
                      />
                      <Text style={styles.coveragetitle}>
                        Insurance Coverage Amount :{" "}
                        {ticketData?.insuranceData.insuranceCoverage}
                      </Text>
                    </View>
                  </View>
                ) : null}

                {/* fare */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Fare Details</Text>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Base Fare</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {baseFare}</Text>
                    </View>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Taxes</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {taxes}</Text>
                    </View>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Service Charges</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {serviceCharge}</Text>
                    </View>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Convenience Fee</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {convamount}</Text>
                    </View>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Discount</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>
                        INR {discount ?? "0.00"}
                      </Text>
                    </View>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Total</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {grandTotal}</Text>
                    </View>
                  </View>

                  {ticketData?.bookingStatus === 5 ||
                  ticketData?.bookingStatus === "CANCELLED" ? (
                    <View style={{ ...styles.row, ...styles.mb8 }}>
                      <View style={styles.sw}>
                        <Text style={styles.smallText}>Refund Amount</Text>
                      </View>

                      <View style={styles.fourthHalf}>
                        <Text style={styles.smallText}>
                          INR {ticketData.RefundAmount}
                        </Text>
                      </View>
                    </View>
                  ) : null}
                </View>
                {/* terms */}
                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Terms and Conditions</Text>
                  </View>

                  <View style={styles.termCon}>
                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      1. Trippozo* is ONLY a bus ticket agent. It does not
                      operate bus services of its own. In order to provide a
                      comprehensive choice of bus operators, departure times and
                      prices to customers, it has tied up with many bus
                      operators. Trippozo advice to customers is to choose
                      bus operators they are aware of and whose service they are
                      comfortable with
                    </Text>
                    <View style={styles.description}>
                      <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                        Trippozo responsibilities include:
                      </Text>
                      <Text style={styles.xsmallText}>
                        (1) Issuing a valid ticket (a ticket that will be
                        accepted by the bus operator) for its network of bus
                        operators
                      </Text>

                      <Text style={styles.xsmallText}>
                        (2) Providing refund and support in the event of
                        cancellation
                      </Text>
                      <Text style={styles.xsmallText}>
                        (3) Providing customer support and information in case
                        of any delays / inconvenience
                      </Text>
                      <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                        Trippozo responsibilities do NOT include:
                      </Text>
                      <Text style={styles.xsmallText}>
                        (1) The bus operator's bus not departing / reaching on
                        time.
                      </Text>
                      <Text style={styles.xsmallText}>
                        (2) The bus operator's employees being rude.{" "}
                      </Text>
                      <Text style={styles.xsmallText}>
                        (3) The bus operator's bus seats etc not being up to the
                        customer's expectation.
                      </Text>
                      <Text style={styles.xsmallText}>
                        (4) The bus operator canceling the trip due to
                        unavoidable reasons.
                      </Text>
                      <Text style={styles.xsmallText}>
                        (5) The baggage of the customer getting lost / stolen /
                        damaged.
                      </Text>
                    </View>
                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      2. The departure time mentioned on the ticket are only
                      tentative timings. However the bus will not leave the
                      source before the time that is mentioned on the ticket.
                    </Text>
                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      3. Passengers are required to furnish the following at the
                      time of boarding the bus:
                    </Text>
                    <View style={styles.description}>
                      <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                        Passengers are required to furnish the following at the
                        time of boarding the bus:
                      </Text>

                      <Text style={styles.xsmallText}>
                        (1) A copy of the ticket (A print out of the ticket or
                        the print out of the ticket e-mail).
                      </Text>

                      <Text style={styles.xsmallText}>
                        (2) A valid identity proof
                      </Text>
                      <Text style={styles.xsmallText}>
                        Failing to do so, they may not be allowed to board the
                        bus.
                      </Text>
                    </View>
                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      4. In case one needs the refund to be credited back to
                      his/her bank account, please write your cash coupon
                      details to * The discount
                    </Text>

                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      5. Delivery charges (if any), will not be refunded in the
                      event of ticket cancellation.
                    </Text>

                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      6. In case a booking confirmation e-mail and sms gets
                      delayed or fails because of technical reasons or as a
                      result of incorrect e-mail ID / phone number provided by
                      the user etc, a ticket will be considered 'booked' as long
                      as the ticket shows up on the confirmation page of
                      Trippozo
                    </Text>
                    <Text style={{ ...styles.smallText, marginBottom: 3 }}>
                      7. Grievances and claims related to the bus journey should
                      be reported to Trippozo support team within 2 days of
                      your travel date.
                    </Text>
                    <CancellationPolicy data={ticketData.cancellationPolicy} />
                  </View>
                </View>

                {/*  */}
              </View>
            </View>
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default BusDoc;
