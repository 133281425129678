import React, { useState, useEffect } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import { message } from "antd";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";
import { useLocalStorage } from "../../helpers/useStorage";

const defBusSearch = {
  type: "oneWay",
  from: null,
  to: null,
  departureDate: new Date(),
  returnDate: null,
};

const defCombinedSearch = {
  tripType: "oneWay",
  classType: "Economy",
  origin: [],
  destination: [],
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: "",
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
};

const GlobalStatesProvider = (props) => {
  const [busPersist, setBusPersist] = useLocalStorage("busPersist", {
    busSearchObj: {
      from: null,
      to: null,
      departureDate: new Date(),
    },
    selectedBusData: [],
  });

  const validatePromoCode = (req) => {
    ApiClient.post("admin/validate/promo", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            promoData: { ...res.data, status: true },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => {});
  };

  const RemovePromo = () => {
    setOtherData({
      ...otherData,
      promoData: { status: false, Discount: 0 },
    });
  };

  const RemovePromoConvFee = () => {
    setOtherData({
      promoData: { status: false, Discount: 0 },
      ConvFee: { status: false, amount: 0 },
    });
  };

  const AddConvFee = (type) => {
    ApiClient.get("admin/convenienceFee").then((res) => {
      let convobj = { type: 1, amount: 0 };

      if (res.status !== 404) {
        res.data.forEach((i) => {
          if (i.ServiceType == type) {
            convobj.type = i.ConvenienceFeeType;
            convobj.amount = i.Value;
            convobj.id = i.ID;
          }
        });
      }
      setOtherData({ ...otherData, ConvFee: convobj });
    });
  };

  //Hydrating Bus Data from localstorage
  const busPersistJson = JSON.parse(localStorage.getItem("busPersist")) || {};
  let initBusSearch = busPersistJson.busData || defBusSearch;
  let initSelectedBusData = busPersistJson.selectedBusData || [];

  //Hydrating Combined Data from localstorage
  // const combinedPersistJson =
  //   JSON.parse(localStorage.getItem("combinedPersist")) || {};
  // let initCombinedSearch =
  //   combinedPersistJson.combineSearchData || defCombinedSearch;

  const loginCheck = JSON.parse(EncHelper.getItem("login")) || {
    flag: false,
    role: false,
  };
  const [isLogin, setLogin] = useState(loginCheck);
  const [airlineMatrixReset, setAirlineMatrixReset] = useState(false);

  const [busData, setBusData] = useState(initBusSearch);
  const [selectedBusData, setSelectedBusData] = useState(initSelectedBusData);
  const [busPassengerData, setBusPassengerData] = useState([]);
  const [busSearchResultObj, setBusSearchResultObj] = useState({});

  const [otherData, setOtherData] = useState({
    promoData: { status: false, Discount: 0 },
    ConvFee: { type: 1, amount: 0 },
  });

  // const [combineSearchData, setCombineSearchData] =
  //   useState(initCombinedSearch);

  useEffect(() => {
    //Bus Data in localstorage
    localStorage.setItem(
      "busPersist",
      JSON.stringify({
        busData: busData,
        selectedBusData: selectedBusData,
      })
    );
  }, [busData, selectedBusData]);

  // useEffect(() => {
  //   //Bus Data in localstorage
  //   localStorage.setItem(
  //     "combinedPersist",
  //     JSON.stringify({
  //       combineSearchData: combineSearchData,
  //     })
  //   );
  // }, [combineSearchData]);

  return (
    <GlobalStatesContext.Provider
      value={{
        state: {
          busData,
          busPersist,

          selectedBusData,
          busPassengerData,
          isLogin,
          // combineSearchData,
          busSearchResultObj,
          otherData,
          airlineMatrixReset,
        },
        ResetAirlineMatrix: (val) => {
          setAirlineMatrixReset(val);
        },

        RemovePromoConvFee: () => {
          RemovePromoConvFee();
        },
        AddConvFee: (v) => {
          AddConvFee(v);
        },
        updateOtherData: (val) => {
          setOtherData(val);
        },
        RemovePromo: () => {
          RemovePromo();
        },
        setBusSearchObj: (searchObj) => {
          setBusPersist((prev) => ({
            ...prev,
            busSearchObj: searchObj,
          }));
        },
        updateSelectedBusData: (selectedbusobj) => {
          setBusPersist((prev) => ({
            ...prev,
            selectedBusData: [selectedbusobj],
          }));
        },
        updateIsLogin: (attribute, value) => {
          let temp = {
            ...isLogin,
            [attribute]: value,
          };
          setLogin((prev) => ({ ...prev, [attribute]: value }));
          EncHelper.setItem("login", JSON.stringify(temp));
        },
        updateBusField: (attribute, value) => {
          setBusData({
            ...busData,
            [attribute]: value,
          });
        },

        updateBusPassengerData: (obj) => {
          setBusPassengerData(obj);
        },

        // updataCombineSearchObj: (attribute, value) => {
        //   setCombineSearchData((prev) => ({ ...prev, [attribute]: value }));
        // },
        updateEmptySelectedBusData: () => {
          setSelectedBusData([]);
        },
        updateBusSearchResultObj: (busSearchResp) => {
          setBusSearchResultObj(busSearchResp);
        },
        resetBusSearchObj: (busSearchObj) => {
          setBusData(busSearchObj);
        },
        validatePromoCode: (val) => {
          validatePromoCode(val);
        },
      }}
    >
      {props.children}
    </GlobalStatesContext.Provider>
  );
};
export default GlobalStatesProvider;
