import React, { useContext, useState } from "react";
import { Carousel } from "antd";
import { TgContext } from "../../common/providers/TgProvider";
import "../CombinedBanner/CombinedBanner.scss";
import "antd/dist/antd.css";
import { Col, Row, Drawer, Button } from "antd";
import Greatdeals from "../Greatdeals/Greatdeals";
const CombinedBanner = () => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { banners } = useContext(TgContext);
  const [state, setState] = useState({ visible: false });
  const showDrawer = () => {
    setState({
      visible: true,
    });
  };

  const onClose = () => {
    setState({
      visible: false,
    });
  };

  return (
    <div className="combined-banner bg-v3-ban">
      <Carousel
        className="carousel-images dnone-carousel"
        autoplay={true}
        dots={false}
      >
        {/* {banners.length > 0 ? (
          banners.map((item, i) => (
            <div key={item.BannerID + "banner" + i} className="image-container">
              <img src={BASE + item.path.substring(1)} alt="carousel-img" />
              {/* <h4>Search, Compare and Book Tickets</h4> */}
        {/* </div>
          ))
        ) : ( */}
        {/* <div className="image-container">
          <img
            src={require("../../assets/images/v3-banner-1.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets</h4>
        </div> */}
        {/* )} */}
        {/* <div className="image-container">
          <img
            src={require("../../assets/images/banner-trus-1.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets</h4>
        </div>
        <div className="image-container">
          <img
            src={require("../../assets/images/hero_image.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets </h4>
        </div>
        <div className="image-container">
          <img
            src={require("../../assets/images/banner-trus-1.jpg")}
            alt="carousel-img"
          />
          <h4>Search, Compare and Book Tickets </h4>
        </div> */}
      </Carousel>

      <div className="directly-flight-ofc">
        <Row>
          <Col md={24} xs={24}>
            <div className="air-cheap-v3">
              <h4 className="book-airline-v33">
                Compare and Book Cheap Flights on Over 600 Airlines
              </h4>
            </div>
          </Col>
        </Row>
      </div>

      <div className="">
        <div className="why-book-trip">
          <ul className="book-tree-v2" onClick={showDrawer}>
            <li>
              <img src={require("../../assets/images/fc-1.png")} alt="" />
            </li>
            <li>
              <img src={require("../../assets/images/fc-2.png")} alt="" />
            </li>
          </ul>
        </div>
      </div>

      <Drawer
        className="drawer-v3-pop"
        title=""
        width={500}
        onClose={onClose}
        visible={state.visible}
      >
        <Row>
          <Col md={12} xs={24}>
            <div className="drawer-pop-img">
              <img src={require("../../assets/images/fc-1.png")} alt="" />
              <p>Price Match Promise</p>
              <span>Find our lowest prices to destinations worldwide.</span>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="drawer-pop-img">
              <img src={require("../../assets/images/fc-2.png")} alt="" />
              <Button className="likes-fb">
                <i className="fa fa-thumbs-o-up" aria-hidden="true"></i>&nbsp;
                Like 600k
              </Button>
              <span>People love our offers, you will too!</span>
            </div>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};
export default CombinedBanner;
