import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  message,
  Modal,
  Spin,
  DatePicker,
  Input,
  Select,
} from "antd";
// import TicketFlight from "./TicketFlight";
import FlightTicket from "./FlightTicket";
import TicketSidebar from "../TicketSidebar";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
// import getFlightPrice from "./flightHelper";
import { OnlyFutureDateValidator } from "../../../helpers/CustomValidators";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const CheckboxGroup = Checkbox.Group;
const FlightTicketScreen = ({ mode }) => {
  const { Panel } = Collapse;
  const { Option } = Select;
  let dateFormat = "DD MMM YYYY";
  const [loading, setLoading] = useState(false);
  const [cancellationType, setCancellationType] = useState(1);
  const ticketSearchParams = queryString.parse(window.location.search);

  const [ticketData, setTicketData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [source, setSource] = useState("");
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [destination, setDestination] = useState("");

  // const [checkedList, setCheckedList] = useState([]);
  // const [indeterminate, setIndeterminate] = useState(false);
  // const [checkAll, setCheckAll] = useState(false);

  // const onChange = (list) => {
  //   setCheckedList(list);
  //   setIndeterminate(
  //     !!list.length && list.length < ticketData.passengers.length
  //   );
  //   setCheckAll(list.length === ticketData.passengers.length);
  // };

  // const onCheckAllChange = (e) => {
  //   setCheckedList(e.target.checked ? ticketData.passengers : []);
  //   form.setFieldsValue({
  //     Passangers: e.target.checked ? ticketData.passengers : [],
  //   });
  //   setIndeterminate(false);
  //   setCheckAll(e.target.checked);
  // };

  const [form] = Form.useForm();
  useEffect(() => {
    getTicketDetails();
  }, []);
  const [fareRulesResp, setFareRulesResp] = useState();

  const fetchFareRules = (traceId) => {
    APIClient.get(`flights/getRulesFromTraceId/${traceId}`)
      .then((resp) => {
        if (resp.statusCode === 200) {
          setFareRulesResp(resp.data);
        } else {
          let error_resp = {
            data: {
              fareRules: [
                {
                  ruleDetails: "Fare Rules not Available",
                },
              ],
            },
          };
          setFareRulesResp(error_resp);
        }
      })
      .catch((err) => console.log(err));
  };
  const getTicketDetails = () => {
    setLoadingTicket(true);
    APIClient.get("admin/myBookings/details", {
      travelType: "1",
      id: ticketSearchParams.ref,
      email: ticketSearchParams.email,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          fetchFareRules(res.data[0].traceId);
          setTicketData(res.data[0]);
          setDestination(
            res.data[0].oneWaySegment[res.data[0].oneWaySegment.length - 1]
              .destination
          );
          setSource(res.data[0].oneWaySegment[0].origin);
        } else {
          message.error(res.message, 3);
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setLoadingTicket(false);
        message.error(error, 3);
      });
  };

  const flightCancelTicket = () => {
    setCancellationType(1);
    setModalVisible(true);
    form.resetFields();
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const submitCancelForm = (val) => {
    let pnr = "";
    let returnPnr = "";

    if (ticketData.tripType === "OneWay") {
      pnr = ticketData.pnr;
    } else {
      let pnrArray = ticketData.pnr ? ticketData.pnr.split("~") : [];
      if (pnrArray.length > 0) {
        pnr = pnrArray[0];
        returnPnr = pnrArray[1];
      }
    }

    if (val.CancellationType === 1) {
      let passangers = ticketData.passengers.map((item) => ({
        title: item.title,
        firstName: item.firstName,
        lastName: item.lastName,
        passangerId: item.passangerId,
        ticketNumber: "string",
      }));

      let onwardSegments = ticketData.oneWaySegment.map((item) => ({
        ...item,
        Passangers: passangers,
      }));

      let returnSegments = ticketData.returnSegment.map((item) => ({
        ...item,
        Passangers: passangers,
      }));

      let req = {
        CancellationType: val.CancellationType,
        ReferenceNumber: ticketData.id,
        PNR: pnr,
        guestEmailId: ticketData.guestEmaiId,
        guestMobileNo: ticketData.guestMobileNo,
        ReturnPNR: returnPnr,
        Segments: [...onwardSegments, ...returnSegments],
        Remarks: val.Remarks,
      };

      cancelApiCall(req);
    } else {
      let passangers = val.Passangers.map((item) => ({
        title: item.title,
        firstName: item.firstName,
        lastName: item.lastName,
        passangerId: item.passangerId,
        ticketNumber: "string",
      }));

      let segments = val.Segments.map((item) => ({
        ...item,
        Passangers: passangers,
      }));
      if (val.CancellationType === 2) {
        let req = {
          ReferenceNumber: ticketData.id,
          CancellationType: val.CancellationType,
          ReferenceNumber: ticketData.id,
          PNR: pnr,
          guestEmailId: ticketData.guestEmaiId,
          guestMobileNo: ticketData.guestMobileNo,
          ReturnPNR: returnPnr,
          Segments: segments,
          Remarks: val.Remarks,
        };
      } else {
        let resheduleReq = {
          UserID: 0,
          PNR: pnr,
          ReturnPNR: returnPnr,
          guestEmailId: ticketData.guestEmaiId,
          guestMobileNo: ticketData.guestMobileNo,
          ReferenceNumber: ticketData.referenceNumber,
          RescheduleType: 1,
          RescheduleTripType: ticketData.tripType === "OneWay" ? 0 : 1,
          Segments: segments,
          RescheduleDate: val.ReSheduleDate,
          RescheduleStatus: 1,
          Remarks: val.Remarks,
          CreatedBy: 0,
          ModifiedBy: 0,
        };
        resheduleApiCall(resheduleReq);
      }
    }
  };

  const cancelApiCall = (req) => {
    setLoading(true);
    APIClient.post("admin/airCancelRequest", req)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
          if (!!res.message) message.success(res.message, 10);

          getTicketDetails(ticketSearchParams.ref);
          setModalVisible(false);

          form.resetFields();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const resheduleApiCall = (req) => {
    setLoading(true);

    APIClient.post("admin/airRescheduleRequest", req)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
          if (!!res.message) message.success(res.message, 10);
          getTicketDetails(ticketSearchParams.ref);
          setModalVisible(false);

          form.resetFields();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const disabledPrevDates = (currentDate) => {
    return currentDate < moment();
  };

  return (
    <div className="flight-ticket-collapse">
      <Card>
        {loadingTicket ? (
          <div className="fligh-ticket-container">
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          </div>
        ) : Object.keys(ticketData).length > 0 ? (
          <div className="fligh-ticket-container">
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum admin-ticket-block">
                  {/* <TicketFlight
                    ticketData={ticketData}
                    source={source}
                    destination={destination}
                    fareRulesResp={fareRulesResp}
                  /> */}
                  <FlightTicket
                    ticketData={ticketData}
                    source={source}
                    destination={destination}
                    fareRulesResp={fareRulesResp}
                  />
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Flight"
                      onCancelTicket={flightCancelTicket}
                      source={source}
                      destination={destination}
                      fareRulesResp={fareRulesResp}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Flight"
                          onCancelTicket={flightCancelTicket}
                          source={source}
                          destination={destination}
                          fareRulesResp={fareRulesResp}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="fligh-ticket-container">
            <div className="flight-ticket">
              <Row className="ticket-row">
                <Col md={24} className="ticket-coloum text-center">
                  <h5>No Ticket Found</h5>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            {ticketData.BookingStatus === "CANCELLED" &&
            Object.keys(ticketData).length > 0 ? null : (
              <Button
                key="add"
                type="primary"
                onClick={form.submit}
                loading={loading}
              >
                {cancellationType === 3 ? "Send Request" : "Cancel Ticket"}
              </Button>
            )}
          </div>,
        ]}
        width={"800px"}
      >
        <div className="wrapper">
          {ticketData.BookingStatus !== "CANCELLED" &&
          Object.keys(ticketData).length > 0 ? (
            <Form
              layout="vertical"
              initialValues={{
                CancellationType: 1,
              }}
              form={form}
              onFinish={submitCancelForm}
            >
              <Row gutter={12}>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Trip Cancellation Type"
                    name="CancellationType"
                    rules={[
                      { required: true, message: "Please Cancellation Type" },
                    ]}
                  >
                    <Select
                      placeholder="Select Cancellation Type"
                      onSelect={(val) => {
                        form.resetFields();
                        form.setFieldsValue({
                          CancellationType: val,
                        });
                        setCancellationType(val);
                      }}
                    >
                      <Option value={1}>Full Cancellation</Option>
                      <Option value={2}>Partial Cancellation</Option>
                      <Option value={3}>Change Itinerary / Reissue</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {cancellationType === 1 ? (
                  <>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item label="Please Select Refund Sector">
                        <Row>
                          <Col md={24} sm={24}>
                            <Checkbox checked={true}>All </Checkbox>
                          </Col>

                          {ticketData.oneWaySegment.length > 0
                            ? ticketData?.oneWaySegment?.map((item, i) => (
                                <Col md={24} sm={24} key={i + "fullonseg"}>
                                  <Checkbox checked={true} disabled>
                                    {item.origin} - {item.destination} (
                                    {moment(item.departureDateTime).format(
                                      "DD MMM YYYY"
                                    )}
                                    )
                                  </Checkbox>
                                </Col>
                              ))
                            : null}
                          {ticketData.returnSegment.length > 0
                            ? ticketData?.returnSegment?.map((item, i) => (
                                <Col md={24} sm={24} key={i + "retsegfull"}>
                                  <Checkbox checked={true} disabled>
                                    {item.origin} - {item.destination} (
                                    {moment(item.departureDateTime).format(
                                      "DD MMM YYYY"
                                    )}
                                    )
                                  </Checkbox>
                                </Col>
                              ))
                            : null}
                        </Row>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item label="Please Select Passanger">
                        <Row>
                          {ticketData?.passengers.map((item, i) => (
                            <Col md={24} sm={24} key={i + "modal"}>
                              <Checkbox checked={true} disabled>
                                <p className="mb-0">
                                  {i + 1}. {item.firstName} {item.lastName}
                                </p>
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Please Select Refund Sector"
                        name="Segments"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Segment",
                          },
                        ]}
                      >
                        <CheckboxGroup>
                          <Row>
                            <Col md={24} sm={24}>
                              <Checkbox checked={false} disabled>
                                All{" "}
                              </Checkbox>{" "}
                            </Col>

                            {ticketData.oneWaySegment.length > 0
                              ? ticketData?.oneWaySegment?.map((item, i) => (
                                  <Col md={24} sm={24} key={i + "onseg"}>
                                    <Checkbox value={item}>
                                      {item.origin} - {item.destination} (
                                      {moment(item.departureDateTime).format(
                                        "DD MMM YYYY"
                                      )}
                                      )
                                    </Checkbox>
                                  </Col>
                                ))
                              : null}
                            {ticketData.returnSegment.length > 0
                              ? ticketData?.returnSegment?.map((item, i) => (
                                  <Col md={24} sm={24} key={i + "retseg"}>
                                    <Checkbox value={item}>
                                      {item.origin} - {item.destination} (
                                      {moment(item.departureDateTime).format(
                                        "DD MMM YYYY"
                                      )}
                                      )
                                    </Checkbox>
                                  </Col>
                                ))
                              : null}
                          </Row>
                        </CheckboxGroup>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Please Select Passanger"
                        name="Passangers"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Passanger",
                          },
                        ]}
                      >
                        <CheckboxGroup>
                          <Row>
                            {ticketData?.passengers.map((item, i) => (
                              <Col md={24} sm={24} key={i + "modal"}>
                                <Checkbox value={item}>
                                  {i + 1}. {item.firstName} {item.lastName}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </CheckboxGroup>
                      </Form.Item>
                    </Col>
                  </>
                )}
                {cancellationType === 3 ? (
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Reschedule Date:"
                      name="ReSheduleDate"
                      rules={[
                        { required: true, message: " " },
                        {
                          validator: OnlyFutureDateValidator,
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={disabledPrevDates}
                      />
                    </Form.Item>
                  </Col>
                ) : null}

                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    label="Please  Enter Remarks"
                    name="Remarks"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : null}
          {cancellationType !== 3 ? (
            <div className="note-wrapper mt-3">
              <h6>Note: </h6>
              <ol className="pl-0">
                <li>1. Partial Refund will be processed offline.</li>
                <li>
                  2. In case of Infant booking, cancellation will be processed
                  offline.
                </li>
                <li>
                  3. In case of One sector to be cancel, please send the offline
                  request.
                </li>
                <li>
                  4. In case of Flight cancelation/ flight reschedule, please
                  select flight cancelled.
                </li>
                <li>
                  5. Cancellation Charges cannot be retrieved for Partial
                  Cancelled Booking
                </li>
              </ol>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default FlightTicketScreen;
