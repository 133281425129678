import React, { useState } from "react";
import { Button } from "antd";
import HotelsList from "./HotelsList/HotelsList";

const ActivitiesContainer = ({
  isFromPackage = false,
  listOfHotels,
  traceId,
  isHotelSearchLoad,
  showNetFare,
}) => {
  const [pagination, SetPagination] = useState(51);
  const [activeTab, setActiveTab] = useState(null);

  const showMore = () => {
    SetPagination((prev) => prev + 51);
  };

  return (
    <div className="list-of-items">
      {listOfHotels.map((availableHotel, itemIndex) => {
        return itemIndex < pagination ? (
          <HotelsList
            availableHotel={availableHotel}
            hotelTraceId={traceId}
            isFromPackage={isFromPackage}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isHotelSearchLoad={isHotelSearchLoad}
            showNetFare={showNetFare}
            key={itemIndex}
          />
        ) : itemIndex === pagination ? (
          <div className="showmore-wrapper" key="key">
            <Button onClick={showMore}>Show More</Button>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default ActivitiesContainer;
