import React, { useContext, useEffect } from "react";
import { message } from "antd";
import "./FlightTicket.scss";
import moment from "moment";
import { TgContext } from "../../providers/TgProvider";
import ReactHtmlParser from "react-html-parser";
import ApiClient from "../../../helpers/ApiClient";
import { getFlightPrice, calculateDuration } from "./flightHelper.js";
import Logo from "../../../assets/images/trippozo_bg_overlay.png";
import { useState } from "react";
const FlightTicket = ({ ticketData, source, destination, fareRulesResp }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const [address, setAddress] = useState([]);
  const { logo } = useContext(TgContext);
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    RefundAmount,
    grandTotal,
    insuranceTotal,
  } = getFlightPrice(ticketData);
  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}> {status}</p>;
      }
      case "CONFIRMED": {
        return (
          <p style={{ color: "#008000" }}>
            <b> {status}</b>
          </p>
        );
      }
      case "CANCELLED": {
        return (
          <p style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </p>
        );
      }

      case "PARTIALLY_CANCELLED": {
        return (
          <p style={{ color: "#008000" }}>
            <b> PARTIALLY CANCELLED</b>
          </p>
        );
      }
      case "CANCELLATIONREQUESTED": {
        return (
          <p style={{ color: "#008000" }}>
            <b>CANCELLATION REQUESTED</b>
          </p>
        );
      }

      default:
        return;
    }
  };
  useEffect(() => {
    getAddress();
  }, []);
  const getAddress = () => {
    ApiClient.get("admin/settings/" + 1)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setAddress(data);
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="flight_Ticket">
      {ticketData.id ? (
        <table
          className="Container"
          width="100%"
          cellspacing="0"
          cellpadding="0"
        >
          <tr>
            <td className="text-align" colspan="2">
              <img
                src={logo ? `${BASE + logo.replace("/", "")}` : Logo}
                width="150"
              />
            </td>
          </tr>

          <tr>
            <td width="60%">
              <table width="100%" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <table width="100%" cellspacing="0" cellpadding="0">
                      <tr>
                        <td className="font-family fW600 fS12p">Tripozo.com</td>
                      </tr>
                      <tr>
                        <td>
                          {address?.settings?.BusinessAddress[0]?.Address ??
                            null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {address?.settings?.BusinessAddress[0]?.PostalCode ??
                            null}
                        </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td width="40%" valign="top">
              <table width="100%" cellspacing="0" cellpadding="0">
                <tr>
                  <td className="font-family fW300 fS13p align-r"></td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">&nbsp;</td>
                </tr>
                <tr>
                  <td className="font-family fW300 fS13p align-r">
                    <b>Reference Number:</b>
                    {ticketData?.id}
                  </td>
                </tr>

                <tr>
                  <td className="font-family fW300 fS13p align-r">
                    <b>Issue Date :</b>
                    {ticketData.bookingDate
                      ? moment(ticketData.bookingDate).format("DD-MM-YYYY")
                      : null}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                className=" table-Border"
                cellspacing="0"
                cellpadding="0"
              >
                <tr className="background-color">
                  <td
                    colspan="4"
                    className="font-family fW600 fS11p align-c"
                    width="34%"
                    height="32"
                  >
                    {ticketData?.tripType.toUpperCase()}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="4"
                    className="font-family fW300 fS11p pd txtAl-r "
                    height="32"
                  >
                    <b>
                      PNR :
                      {ticketData.returnPnr !== null
                        ? `${ticketData.pnr}~${ticketData.returnPnr}`
                        : ticketData.pnr}
                    </b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                className="table-Border "
                cellspacing="0"
                cellpadding="0"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd whtColor align-l"
                    width="40%"
                    height="32"
                  >
                    Passenger Detail
                  </td>

                  <td
                    className="font-family fW600 fS11p pd whtColor align-l"
                    width="30%"
                  >
                    Ticket no
                  </td>

                  <td
                    className="font-family fW600 fS11p pd whtColor align-l"
                    width="30%"
                  >
                    Frequent flyer no
                  </td>
                </tr>
                {ticketData.passengers.length > 0
                  ? ticketData.passengers.map((passenger) => {
                      return (
                        <>
                          <tr>
                            <td
                              className="font-family fS11p pd align-l"
                              width="40%"
                              height="32"
                            >
                              <p className="fW600 mb0 mt0">
                                {`${passenger?.firstName}${" "}${
                                  passenger?.lastName
                                }`}
                              </p>
                              <p className=" mb0 mt0">{passenger?.mobile}</p>
                            </td>

                            <td
                              className="font-family fS11p pd align-l"
                              width="30%"
                              height="32"
                            >
                              <p className="mb0 mt0">
                                {passenger.ticketNumer
                                  ? passenger.ticketNumer
                                  : "-"}
                              </p>
                            </td>
                            <td
                              className="font-family fW600 fS11p pd align-l"
                              width="30%"
                            >
                              -
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : null}
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <b>Oneway Details</b>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              {ticketData?.oneWaySegment?.map((items) => (
                <table
                  width="100%"
                  className=" table-Border"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tr className="background-color">
                    <td
                      className="font-family fW600 fS11p pd border-top align-l"
                      width="30%"
                      height="32"
                    >
                      Flight
                    </td>

                    <td
                      className="font-family fW600 fS11p pd border-top align-l"
                      width="20%"
                    >
                      Departure
                    </td>

                    <td
                      className="font-family fW600 fS11p pd border-top align-l"
                      width="20%"
                    >
                      Arrival
                    </td>

                    <td
                      className="font-family fW600 fS11p pd align-l"
                      width="30%"
                    >
                      Status
                    </td>
                  </tr>

                  <tr>
                    <td
                      className="font-family fW300 fS11p pd align-l"
                      height="32"
                    >
                      <p className="mb0 mt0">{items?.flightCode}</p>
                      <p className="mb0 mt0">{items?.flightNumber}</p>
                      <p className="mt0">Cabin: Economy</p>
                    </td>
                    <td className="font-family fW300 fS11p pd align-l">
                      <p className="mb0 mt0">{items?.origin}</p>
                      <p>
                        {`${moment(items.departureDateTime).format(
                          "hh:mm A"
                        )} ,${moment(items.departureDateTime).format(
                          "ddd DD MMM YYYY"
                        )}`}
                      </p>
                    </td>
                    <td className="font-family fW300 fS11p pd align-l">
                      <p className="mb0 mt0">{items?.destination}</p>
                      <p className="mb0 mt0 blueColor">
                        Terminal: {items?.arrivalTerminal}
                      </p>
                      <p className="mb0 mt0">{`${moment(
                        items.arrivalDateTime
                      ).format("hh:mm A")}, ${moment(
                        items.arrivalDateTime
                      ).format("ddd DD MMM YYYY")}`}</p>
                    </td>
                    <td className="font-family fW300 fS11p pd align-l">
                      <p className="mb0 mt0 blueColor">
                        {getStatus(ticketData.BookingStatus)}
                      </p>
                      <p className="mb0 mt0">
                        Baggage(per person):{" "}
                        {ticketData.oneWayBaggageInfo.length > 0
                          ? ticketData.oneWayBaggageInfo[0]?.BaggageInfo
                          : null}
                      </p>
                      <p className="mb0 mt0">{`${
                        ticketData.oneWaySegment.length > 1
                          ? ticketData.oneWaySegment.length - 1
                          : "None"
                      }${"stop"}`}</p>
                    </td>
                  </tr>
                </table>
              ))}
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          {ticketData.returnSegment.length > 0 ? (
            <>
              {" "}
              <tr>
                <td colspan="2">
                  <b>Return Details</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
            </>
          ) : null}

          <tr>
            <td colspan="2">
              {ticketData.returnSegment.length > 0
                ? ticketData.returnSegment.map((items) => (
                    <table
                      width="100%"
                      className=" table-Border"
                      cellspacing="0"
                      cellpadding="0"
                    >
                      <tr className="background-color">
                        <td
                          className="font-family fW600 fS11p pd border-top align-l"
                          width="30%"
                          height="32"
                        >
                          Flight
                        </td>

                        <td
                          className="font-family fW600 fS11p pd border-top align-l"
                          width="20%"
                        >
                          Departure
                        </td>

                        <td
                          className="font-family fW600 fS11p pd border-top align-l"
                          width="20%"
                        >
                          Arrival
                        </td>

                        <td
                          className="font-family fW600 fS11p pd align-l"
                          width="30%"
                        >
                          Status
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="font-family fW300 fS11p pd align-l"
                          height="32"
                        >
                          <p className="mb0 mt0">{items?.flightCode}</p>
                          <p className="mb0 mt0">{items?.flightNumber}</p>
                          <p className="mt0">Cabin: Economy</p>
                        </td>
                        <td className="font-family fW300 fS11p pd align-l">
                          <p className="mb0 mt0">{items?.origin}</p>
                          <p>
                            {`${moment(items.departureDateTime).format(
                              "hh:mm A"
                            )} ,${moment(items.departureDateTime).format(
                              "ddd DD MMM YYYY"
                            )}`}
                          </p>
                        </td>
                        <td className="font-family fW300 fS11p pd align-l">
                          <p className="mb0 mt0">{items?.destination}</p>
                          <p className="mb0 mt0 blueColor">
                            Terminal: {items?.arrivalTerminal}
                          </p>
                          <p className="mb0 mt0">{`${moment(
                            items.arrivalDateTime
                          ).format("hh:mm A")}, ${moment(
                            items.arrivalDateTime
                          ).format("ddd DD MMM YYYY")}`}</p>
                        </td>
                        <td className="font-family fW300 fS11p pd align-l">
                          <p className="mb0 mt0 blueColor">
                            {getStatus(ticketData.BookingStatus)}
                          </p>
                          <p className="mb0 mt0">
                            Baggage(per person):{" "}
                            {ticketData.oneWayBaggageInfo.length > 0
                              ? ticketData.oneWayBaggageInfo[0]?.BaggageInfo
                              : null}
                          </p>
                          <p className="mb0 mt0">{`${
                            ticketData.oneWaySegment.length > 1
                              ? ticketData.oneWaySegment.length - 1
                              : "None"
                          }${"stop"}`}</p>
                        </td>
                      </tr>
                    </table>
                  ))
                : null}
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">
              <table
                width="100%"
                className=" table-Border"
                cellspacing="0"
                cellpadding="0"
              >
                <tr className="background-color">
                  <td
                    colspan="4"
                    className="font-family fW600 fS11p pd align-l"
                    width="34%"
                    height="32"
                  >
                    <p className="mb0 mt0">Payment Details</p>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    className="font-family fW300 fS11p pd align-l"
                    width="75%"
                    height="32"
                  >
                    {" "}
                    <p className="mb0 mt0">This is an electronic ticket.</p>
                    <p className="mb0 mt0">
                      Please carry a positive identification for check in.
                    </p>
                  </td>
                  <td
                    colspan="2"
                    className="font-family fW300 fS11p pd txtAl-r "
                    width="25%"
                    height="32"
                  >
                    <table
                      width="100%"
                      className=" border-left"
                      cellspacing="0"
                      cellpadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>

                        <tr>
                          <td className="font-family fW600 fS11p ">
                            <b>Base Amount:</b>
                          </td>
                          <td className="font-family fW300 fS11p ">
                            <b>{baseAmount}</b>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p ">
                            Surge and Taxes:
                          </td>
                          <td className="font-family fW300 fS11p ">
                            {taxAmount}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p pd ">
                            Conveneience Fee:
                          </td>
                          <td className="font-family fW300 fS11p pd ">
                            {convienenceFee}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-family fW300 fS11p pd border-bottom">
                            <b>Discount:</b>
                          </td>
                          <td className="font-family fW300 fS11p pd border-bottom">
                            <b>{discount}</b>
                          </td>
                        </tr>

                        <tr rowspan="2">
                          <td className="ptb6p fW600">
                            <b>Total Air Fare:</b>
                          </td>
                          <td className="ptb6p" colspan="2">
                            <b>Rs.{grandTotal}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                className="table-Border"
              >
                <tr className="background-color">
                  <td
                    className="font-family fW600 fS11p pd align-l border-top "
                    width="100%"
                    height="32"
                  >
                    Terms and Conditions
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    {!fareRulesResp ? (
                      <p>...</p>
                    ) : (
                      <div className="termCon_wrapper">
                        {ReactHtmlParser(
                          fareRulesResp?.data?.fareRules[0]?.ruleDetails
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>

          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
        </table>
      ) : null}
    </div>
  );
};
export default FlightTicket;
