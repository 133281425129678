export const getPromoDiscount = (promoData, total) => {
  if (promoData.DiscountType == 1) {
    return Number((Number(total) / 100) * Number(promoData.Discount)).toFixed(2);
  } else {
    return Number(promoData.Discount).toFixed(2);
  }
};
export const getConvieneceFeeValue = (ConvFee, total) => {
  let convamount = 0;
  if (ConvFee.type == 0) {
    convamount = Number(ConvFee.amount).toFixed(2);
  } else {
    convamount = (Number(total) / 100) * Number(ConvFee.amount ? ConvFee.amount : 0);
  }
  return Number(convamount).toFixed(2);
};

export const getFlightGrandTotalPrice = (airPriceResp, ConvFee, promoData, flightSearchObj) => {
  let adultCount = Number(flightSearchObj.adultCount);
  let childCount = Number(flightSearchObj.childCount);
  let infantCount = Number(flightSearchObj.infantCount);

  let totalPax = adultCount + childCount + infantCount;

  let totalBaseFare = 0;
  let totalAmount = 0;
  let otherCharges = 0;
  let convamount = 0;
  let discount = 0;
  let commission = 0;
  let markup = 0;

  let grandTotal = 0;

  airPriceResp.flightDetails.forEach((flight) => {
    flight.fareFamilies.forEach((fareFamily) => {
      fareFamily.flightFares.forEach((fare) => {
        if (fare.fareTag == "Base") {
          if (fare.paxType == "ADT") {
            totalBaseFare += adultCount * Number(fare.amount);
          } else if (fare.paxType == "CHD") {
            totalBaseFare += childCount * Number(fare.amount);
          } else {
            totalBaseFare += infantCount * Number(fare.amount);
          }
        } else if (fare.fareTag == "Tax") {
          if (fare.paxType == "ADT") {
            otherCharges += adultCount * Number(fare.amount);
          } else if (fare.paxType == "CHD") {
            otherCharges += childCount * Number(fare.amount);
          } else {
            otherCharges += infantCount * Number(fare.amount);
          }
        } else if (fare.fareTag === "commission") {
          commission += Number(fare.amount);
        } else if (fare.fareTag === "markup") {
          markup += Number(fare.amount);
        }
      });
    });
  });

  totalAmount = Number(totalBaseFare) + Number(otherCharges) + Number(commission) + Number(markup);

  convamount = getConvieneceFeeValue(ConvFee, totalAmount, totalPax);
  discount = getPromoDiscount(promoData, totalAmount);
  totalAmount -= discount;

  grandTotal = Number(totalAmount + Number(convamount)).toFixed(2);

  return {
    grandTotal,
    commission: Number(commission).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    discount: Number(discount).toFixed(2),
    markup,
    otherCharges: otherCharges,
  };
};

export const getFareTotal = (flightSearchObj, flightFares) => {
  let totalBaseFare = 0;
  // let markUp = 0;
  let tax = 0;
  let totalAmount = 0;
  let adultPrice = 0;
  let ChildPrice = 0;
  let infantPrice = 0;
  let adultCount = parseInt(flightSearchObj.adultCount);
  let childCount = parseInt(flightSearchObj.childCount);
  let infantCount = parseInt(flightSearchObj.infantCount);

  flightFares.forEach((fareObj) => {
    if (fareObj.fareTag === "Base") {
      if (fareObj.paxType === "ADT") {
        adultPrice += Number(fareObj.amount) * adultCount;
      } else if (fareObj.paxType === "CHD") {
        ChildPrice += Number(fareObj.amount) * childCount;
      } else {
        infantPrice += Number(fareObj.amount) * infantCount;
      }
    } else if (fareObj.fareTag == "Tax") {
      if (fareObj.paxType == "ADT") {
        tax += adultCount * Number(fareObj.amount);
      } else if (fareObj.paxType == "CHD") {
        tax += childCount * Number(fareObj.amount);
      } else {
        tax += infantCount * Number(fareObj.amount);
      }
    }
    // else if (fareObj.fareTag === "markup") {
    //   markUp += Number(fareObj.amount);
    // }
  });

  totalBaseFare = adultPrice + ChildPrice + infantPrice;

  totalAmount = totalBaseFare + tax;

  return {
    adultPrice: Number(adultPrice).toFixed(2),
    ChildPrice: Number(ChildPrice).toFixed(2),
    infantPrice: Number(infantPrice).toFixed(2),
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    tax: Number(tax).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
  };
};
