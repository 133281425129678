import moment from "moment";

export const createReportFromApiReponse = (bookings) => {
  let response = {
    flights: [],
    hotels: [],
    buses: [],
    activities: [],
  };
  bookings.forEach((booking) => {
    booking.month = moment(booking.bookingDate).month();
    booking.year = moment(booking.bookingDate).year();
    booking.formatedDate = moment(booking.bookingDate, "YYYY-MM-DD").format("DD-MM-YYYY");

    if (booking.type == "Hotel" && booking.BookingStatus == "CONFIRMED") {
      response.hotels.push(booking);
    } else if (booking.type == "Flight" && booking.BookingStatus == "CONFIRMED") {
      response.flights.push(booking);
    } else if (booking.type == "Bus" && booking.BookingStatus == "CONFIRMED") {
      response.buses.push(booking);
    } else if (booking.type == "Activities" && booking.BookingStatus == "CONFIRMED") {
      response.activities.push(booking);
    }
  });

  return response;
};
