import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Collapse,
} from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";
// import { downloadTicketPdf } from "../../helpers/TicketUtils";
import BusDoc from "../PdfDocuments/Bus/BusDoc";
import Invoice from "./Model/UserInvoice";
import HotelDoc from "../PdfDocuments/Hotel/HotelDoc";
import FlightDoc from "../PdfDocuments/Flight/FlightDoc";
import "./TicketSidebar.scss";

const pdfUrl = process.env.REACT_APP_PDF_URL;

const TicketSidebar = ({
  ticketData,
  type,
  onCancelTicket,
  ticketSearchParams,
  source,
  destination,
  fareRulesResp,
}) => {
  const { user } = useAuthContext();
  const [smsForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const { Panel } = Collapse;
  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });

  const [withFare, setWithFare] = useState(-1);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);

  const toggleEmailModal = () => {
    emailForm.resetFields();
    if (!emailModalVisible) loadpassengerData("Email");
    setEmailModalVisible((prev) => !prev);
  };

  const toggleSmsModal = () => {
    smsForm.resetFields();
    if (!smsModalVisible) loadpassengerData("SMS");
    setSmsModalVisible((prev) => !prev);
  };

  const loadpassengerData = (type) => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      getPassengerData(userID).then((data) => {
        if (data.status) {
          if (type === "SMS") smsForm.setFieldsValue({ Mobile: data.Mobile });
          else emailForm.setFieldsValue({ Email: data.Email });
        }
      });
    }
  };

  const sendEmailSmsETicket = (val) => {
    ApiClient.post("notification/commonUtility", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        message.error("Something Went Wrong");
      }
    });
  };

  const submitEmailForm = (val) => {
    if (limitCount.emailCount > 2) {
      message.error("Limit Exceeded");
      setEmailModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({
        ...prev,
        emailCount: limitCount.emailCount + 1,
      }));
    }
    if (type === "Bus") {
      let formData = {
        phoneNumber: "string",
        travelType: 3,
        trigger: 1,
        bookingReference: ticketData.bookingRefNo,
        email: val.Email,
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: "string",
        travelType: 1,
        trigger: 1,
        bookingReference: ticketData.id,
        email: val.Email,
        withFare: withFare,
      };

      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: "string",
        travelType: 2,
        trigger: 1,
        bookingReference: ticketData.RefNumber,
        email: val.Email,
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
  };

  const sendSms = (val) => {
    if (limitCount.smsCount > 2) {
      message.error("Limit Exceeded");
      setSmsModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({ ...prev, smsCount: limitCount.smsCount + 1 }));
      console.log(limitCount);
    }
    if (type === "Bus") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 3,
        trigger: 2,
        bookingReference: ticketData.bookingRefNo,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 1,
        trigger: 2,
        bookingReference: ticketData.id,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 2,
        trigger: 2,
        bookingReference: ticketData.RefNumber,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
  };

  const handleCancel = (cancelTicketType) => {
    onCancelTicket(cancelTicketType);
  };
  const printTicket = () => {
    window.print();
  };
  const downloadPDF = (serviceType) => {
    let obj = {
      serviceType: serviceType,
      withFare: 1,
      bookingRefNo: ticketSearchParams.ref,
      email: ticketSearchParams.email,
    };

    ApiClient.post("notification/bookingticketpdf", obj).then((res) => {
      if (res.status === 200 && res.data) {
        window.open(pdfUrl + res.data, "Download");
      } else {
        message.error("Something Went Wrong");
      }
    });
  };

  return (
    <div className="actionable-buttons">
      <div className="ticket-options">
        {type === "Flight" ? (
          <p onClick={() => downloadPDF(1)}>
            <DownloadOutlined /> Download E-Ticket
          </p>
        ) : null}
        {/* {type === "Flight" && ticketData.id ? (
          <PDFDownloadLink
            document={
              <FlightDoc
                ticketData={{ ...ticketData, source, destination }}
                fareRulesResp={fareRulesResp}
              />
            }
            fileName="flightTicket.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <p>Loading document... </p>
              ) : (
                <p>
                  {" "}
                  <DownloadOutlined /> Download E-Ticket
                </p>
              )
            }
          </PDFDownloadLink>
        ) : null} */}
        {/* {type === "Bus" ? (
          <PDFDownloadLink
            document={<BusDoc ticketData={ticketData} />}
            fileName="busTicket.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <p>Loading document... </p>
              ) : (
                <p>
                  {" "}
                  <DownloadOutlined /> Download E-Ticket
                </p>
              )
            }
          </PDFDownloadLink>
        ) : null} */}
        {type === "Bus" ? (
          <p onClick={() => downloadPDF(3)}>
            <DownloadOutlined /> Download E-Ticket
          </p>
        ) : null}
        {type === "Hotel" ? (
          <p onClick={() => downloadPDF(2)}>
            <DownloadOutlined /> Download E-Ticket
          </p>
        ) : null}

        {/* {type === "Hotel" ? (
          <PDFDownloadLink
            document={<HotelDoc ticketData={ticketData} />}
            fileName="hotelTicket.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <p>Loading document... </p>
              ) : (
                <p>
                  {" "}
                  <DownloadOutlined /> Download E-Ticket
                </p>
              )
            }
          </PDFDownloadLink>
        ) : null} */}
      </div>

      <div className="ticket-options">
        <p onClick={() => printTicket()}>
          <PrinterOutlined /> Print E-Ticket
        </p>
      </div>

      <div className="mb-3">
        <Collapse expandIcon={() => <MailOutlined />}>
          <Panel header={`Email Ticket`} key="1">
            <div className="trip-type-selector">
              <div className="trip-type-selector">
                <Button
                  block
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    toggleEmailModal();

                    setWithFare(1);
                  }}
                >
                  With Price
                </Button>

                <Button
                  block
                  onClick={() => {
                    toggleEmailModal();
                    setWithFare(0);
                  }}
                >
                  Without Price
                </Button>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>

      {ticketData.cancellable && (
        <div className="ticket-options">
          <p onClick={() => toggleSmsModal()}>
            <MessageOutlined /> Sms E-Ticket
          </p>
        </div>
      )}

      {type === "Bus"
        ? ticketData.bookingStatus != "Cancelled" &&
          ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => handleCancel(type)}>
                <FileExcelOutlined /> Cancel E-Ticket
              </p>
            </div>
          )
        : null}

      {type === "Flight" && user
        ? ticketData.BookingStatus !== "CANCELLED" &&
          ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => handleCancel("Flight")}>
                <FileExcelOutlined /> Cancel / Reschedule E-Ticket
              </p>
            </div>
          )
        : null}

      {type === "Hotel"
        ? ticketData.BookingStatus != 3 && (
            //   ticketData.cancellable && (
            <>
              <div className="ticket-options">
                <p onClick={() => toggleSmsModal()}>
                  <MessageOutlined /> Sms E-Ticket
                </p>
              </div>
              <div className="ticket-options">
                <p onClick={() => handleCancel(type)}>
                  <FileExcelOutlined /> Cancel E-Ticket
                </p>
              </div>
            </>
          )
        : // )
          null}

      <Modal
        title="User Invoice"
        visible={userInvoiceVisible}
        onOk={() => setUserinvoiceVisible(false)}
        onCancel={() => setUserinvoiceVisible(false)}
        width={"75%"}
      >
        <Invoice />
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Email E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={emailModalVisible}
        onOk={toggleEmailModal}
        onCancel={toggleEmailModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleEmailModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={emailForm.submit}
            >
              Send
            </Button>
          </div>,
        ]}
      >
        <Form form={emailForm} onFinish={submitEmailForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Email is not a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>SMS E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={smsModalVisible}
        onOk={toggleSmsModal}
        onCancel={toggleSmsModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleSmsModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={smsForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form form={smsForm} onFinish={sendSms}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Mobile No."
                name="Mobile"
                autoFocus
                rules={[
                  {
                    required: true,
                    message: "Mobile Number Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  className="number-specing"
                  placeholder="Enter Mobile number"
                  autoComplete="off"
                  autoFocus
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default TicketSidebar;
