import React, { useState, useContext, useEffect } from "react";
// import Slider from "react-slick";
import "./Deals.scss";
import { Card, Row, Col, Slider } from "antd";
import { Link } from "react-router-dom";
import { SwapOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { TgContext } from "../../common/providers/TgProvider";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";

function Deals(props) {
  const [deals, setDeals] = useState([]);
  // const { updataCombineSearchObj } = props;
  let history = useHistory();
  const { updateTabKey } = useContext(TgContext);

  const searchTopDeals = (origin, destination, tabKey) => {
    let tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    // updataCombineSearchObj("origin", origin);
    // updataCombineSearchObj("destination", destination);
    // updataCombineSearchObj("fromDate", tomorrow);
    // updataCombineSearchObj("toDate", "");

    // let query = {
    //   adult: 1,
    //   child: 0,
    //   class: "Economy",
    //   from: tomorrow,
    //   infant: 0,
    //   to: "",
    //   tripType: "oneWay",
    //   origin: `${origin}-${origin}`,
    //   destination: `${destination}-${destination}`,
    // };
    // updateTabKey(tabKey);

    // let queryParams = queryString.stringify(query);
    // history.push("/flightresults?" + queryParams);
    history.push(
      `/flightresults?adult=1&child=0&class=Economy&destination=${destination}&from=${tomorrow}&infant=0&origin=${origin}&to=&tripType=oneWay`
    );
  };

  useEffect(() => {
    getDeals();
  }, []);

  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const getDeals = async (type) => {
    await ApiClient.get("admin/deals", {})

      .then((res) => {
        if (res.status === 200) {
          let data = res.data
            .filter((item) => item.serviceType == 1)
            .map((deal) => ({
              ...deal,
              destination: queryString.parse(deal.destination),
              source: queryString.parse(deal.source),
            }));
          setDeals(data);
        } else {
          console.log("error");
        }
      })
      .catch((e) => {
        setDeals([]);
      });
  };

  return deals.length > 0 ? (
    <div className="deals-wrapper mb-4 ">
      <Row>
        <Col md={24}>
          <h5 className="explore-card">Explore Deals</h5>
        </Col>
        <Col md={24}>
          <Row gutter={24}>
            {/* <Slider {...MobileSlidersettings}> */}

            {deals.map((deal, index) => (
              <Col md={6} xs={24} key={index}>
                <Card
                  hoverable
                  bordered={false}
                  cover={
                    <img
                      alt="example"
                      src={`${
                        process.env.REACT_APP_BASE_URL
                      }${deal.Image.substring(1)}`}
                    />
                  }
                  // onClick={() =>
                  //   props.updataCombineSearchObj
                  //     ? searchTopDeals(
                  //         deal.FromAirportCode,
                  //         deal.ToAirportCode,
                  //         "1"
                  //       )
                  //     : null
                  // }
                  onClick={() =>
                    searchTopDeals(
                      deal.FromAirportCode,
                      deal.ToAirportCode,
                      "1"
                    )
                  }
                >
                  <div className="map-cityname" title="India">
                    {deal.ToAirportCode}, {deal.FromCountryID.Country}
                  </div>
                  <div className="rsdeals__item">
                    <Row gutter={8}>
                      <Col md={12}>
                        <div className="deal-rs__green">
                          <i className="fa fa-inr" /> {deal.FromAmount}
                        </div>
                      </Col>
                      <Col md={12} className="deals-details">
                        <p className="mb-0">
                          {deal.FromAirportCode} <SwapOutlined />{" "}
                          {deal.ToAirportCode}
                        </p>
                        <p className="mb-0">
                          {moment().add(1, "days").format("MMM, DD-YYYY")}
                        </p>
                      </Col>
                      <Col md={24}>
                        <div className="deals__range">
                          <div className="deals-rs__range deals-green"></div>
                          <div className="deals-rs__range deals-yellow"></div>
                          <div className="deals-rs__range deals-red"></div>
                        </div>
                      </Col>
                      <Col md={24} className="text-center">
                        <p className="mb-0 bottom-text">
                          <small>Fares are round trip</small>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}

            {/* </Slider> */}
          </Row>
        </Col>
      </Row>
    </div>
  ) : null;
}

export default Deals;
