import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import * as ANTD from "antd";
import "./unauth.scss";
const CustomNoResultFound = ({ title }) => {
  return (
    <div className="error-404">
      <img
        className="no-results-img"
        src={require("../../assets/images/no-results-v1.png")}
        alt="search-img"
        srcSet=""
      />
      <p className="text-results-found">{title}</p>
      <Link to="/">
        <ANTD.Button className="backto-homebtn" type="primary">
          Back Home
        </ANTD.Button>
      </Link>
      {/* <Result status="500" title={title} subTitle="" /> */}
    </div>
  );
};

export default CustomNoResultFound;
