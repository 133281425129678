import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Collapse,
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  Spin,
  Skeleton,
  message,
} from "antd";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { TgContext } from "../../common/providers/TgProvider";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import ApiClient from "../../helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import BookPayCard from "./Book-Pay-Card";
import FareSummaryCard from "./FareSummaryCard";
import FlightDetailsCard from "./Flight-Details-Card";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./FlightsCheckout.scss";
import { PaxAgeValidator } from "../../helpers/CustomValidators";
import { FlightGrandTotalCard } from "./FlightGrandTotalCard";
import { AirpriceReponse } from "./SampleAirpriceResponse";
import { getPassengerData } from "../../helpers/PassegerData";
import CountryList from "../../common/CountryList";

const { Option } = Select;
const { Panel } = Collapse;
let dateformat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const abbrevations = [
  "MR",
  "Mr",
  "mr",
  "mR",
  "MRS",
  "Mrs",
  "mrs",
  "mrS",
  "mRs",
  "MS",
  "ms",
  "Ms",
  "mS",
  "Mstr",
  "mstr",
  "Miss",
  "miss",
];

const FlightCheckout = () => {
  const { user } = useAuthContext();
  const { RemovePromoConvFee } = useContext(GlobalStatesContext);
  const {
    state: {
      flightPersist: { flightSearchObj, selectedFlight },
    },
    updateFlightAirPrice,
    updateAirBookState,
  } = useFlightContext();
  let history = useHistory();

  const [isGstRequired, setIsGstRequired] = useState(false);
  const validateMessages = {
    required: "",
  };

  const [airPriceResp, setAirPriceResp] = useState({ flightDetails: [] });

  const [totalPaxArr, setTotalPaxArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passengersArr, setPassengersArr] = useState([]);

  useEffect(() => {
    loadpassengerData();
  }, []);

  const loadpassengerData = () => {
    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }
    if (userID != 1) {
      getPassengerData(userID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            mobile: data.Mobile,
            email: data.Email,
          });
        }
      });
    }
  };

  const [contact_form] = Form.useForm();
  const [passenger_form] = Form.useForm();
  const [gst_form] = Form.useForm();

  const getAirPrice = () => {
    let airPriceReqObj = {
      traceId: flightSearchObj.traceId,
      flightIds:
        flightSearchObj.airTravelType === "oneWay"
          ? [selectedFlight[0].flightId]
          : selectedFlight.map((flightID) => flightID.flightId),
      airTravelType: flightSearchObj.airTravelType,
      mappingType: flightSearchObj.resultsType,
      itineraryViewType: "1",
      gstDetails: {
        gstAddressLine1: "",
        gstAddressLine2: "",
        gstCity: "",
        gstState: "",
        gstpinCode: "",
        gstEmailId: "",
        gstNumber: "",
        gstPhoneNo: "",
        gstCompanyName: "",
      },
    };

    ApiClient.post("flights/airPrice", airPriceReqObj)
      .then((resp) => {
        return resp;
      })
      .then((resp) => {
        setUpFromApiResponse(resp);
      })
      .catch((err) => console.log(err));
  };

  const setUpFromApiResponse = (resp) => {
    if (resp.statusCode === 200) {
      setIsGstRequired(
        resp.data.flightDetails[0].fareFamilies[0].isGstMandatory
      );
      setAirPriceResp(resp.data);
      paxInfoSections(resp.data);
      updateFlightAirPrice(resp.data);
      setIsLoading(true);
    } else if (resp.statusCode === 400) {
      if (resp?.errors) {
        let errorsArr = resp.errors.filter(
          (item) => item.errorCode === "Status204NoContent"
        );
        if (errorsArr?.length > 0) {
          message.error(errorsArr[0].errorDetail, 4);
        }
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAirPrice();
    RemovePromoConvFee();
  }, []);

  const paxInfoSections = (airPriceRespObj) => {
    let paxInfoArr = [];
    for (let i = 0; i < flightSearchObj.adultCount; i++) {
      let paxObj = {
        type: "ADT",
      };
      paxInfoArr.push(paxObj);
    }

    for (let i = 0; i < flightSearchObj.childCount; i++) {
      let paxObj = {
        type: "CHD",
      };
      paxInfoArr.push(paxObj);
    }

    for (let i = 0; i < flightSearchObj.infantCount; i++) {
      let paxObj = {
        type: "INF",
      };
      paxInfoArr.push(paxObj);
    }

    paxInfoArr.map((obj, index) => {
      let singlePaxObj = {
        title: "Mr",
        firstName: "",
        lastName: "",
        paxType: "",
        genderType: "",
        dob: "",
        id: index,
      };

      airPriceRespObj.passengerRequiredFields
        .filter((requiredFiledsObj) => requiredFiledsObj.paxType === obj.type)
        .map((paxReqFieldsObj, i) => {
          Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
            if (
              paxReqFieldsObj[paxReqKeys] === true &&
              paxReqKeys !== "address"
            ) {
              singlePaxObj[paxReqKeys] = "";
              if (
                paxReqKeys === "passengerNationality" ||
                paxReqKeys === "passportIssuedCountry"
              ) {
                singlePaxObj[paxReqKeys] = "India";
              }
            }
          });
          singlePaxObj["paxType"] = paxReqFieldsObj.paxType;
        });
      totalPaxArr.push(singlePaxObj);
    });

    setIsLoading(true);
    setTotalPaxArr(totalPaxArr);
  };

  const handlePaxField = (e, paxObjIndex, key) => {
    let paxObj = {};
    paxObj[key] = e;

    passengersArr.push(paxObj);

    totalPaxArr[paxObjIndex][key] = e;

    setPassengersArr(passengersArr);
  };

  const redirectToPreview = () => {
    contact_form
      .validateFields()
      .then((contact_data) => {
        passenger_form
          .validateFields()
          .then((passeger_data) => {
            gst_form
              .validateFields()
              .then((gstDetails) => {
                onFlicghtCheckoutValidated(contact_data, gstDetails);
              })
              .catch((e) => {
                if (e?.errorFields?.length > 0)
                  gst_form.scrollToField(e.errorFields[0].name);
              });
          })

          .catch((e) => {
            passenger_form.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        contact_form.scrollToField(e.errorFields[0].name);
      });
  };

  const onFlicghtCheckoutValidated = (contactDetails, gstDetails) => {
    contactDetails.areaCode = "+91";
    if (!contactDetails.hasOwnProperty("countryName")) {
      contactDetails.countryName = "";
    }

    totalPaxArr.map((passengersObj) => {
      passengersObj["email"] = contactDetails.email;
      passengersObj["mobile"] = contactDetails.mobile;
      passengersObj["areaCode"] = contactDetails.areaCode;
      passengersObj["address_CountryCode"] = contactDetails.areaCode;
      passengersObj["address1"] = "APML";
      passengersObj["city"] = "Delhi";
      passengersObj["countryName"] = "India";
      passengersObj["countryCode"] = "IN";
      passengersObj["state"] = contactDetails.state;
      passengersObj["postalCode"] = contactDetails.postalCode;
      passengersObj["additionalServicesIds"] = [""];
      delete passengersObj["gender"];
      //delete passengersObj["address"]; //remove this statement after resolve this key from BE
      delete passengersObj["id"];
      return passengersObj;
    });

    let userID = 1;
    if (user) {
      if (user.UserID) {
        userID = user.UserID;
      }
    }

    let airBookReqObj = {
      traceId: flightSearchObj.traceId,
      userId: userID,
      passengers: totalPaxArr,
      gstDetails: {
        gstAddressLine1: gstDetails.companyaddress ?? "",
        gstAddressLine2: "",
        gstCity: "",
        gstState: "",
        gstpinCode: "",
        gstEmailId: gstDetails.companyemail ?? "",
        gstNumber: gstDetails.gstnumber
          ? gstDetails.gstnumber.toUpperCase()
          : "",
        gstPhoneNo: gstDetails.contactnumber ?? "",
        gstCompanyName: gstDetails.companyName ?? "",
      },
    };
    updateAirBookState(airBookReqObj);
    history.push("/flight-review");
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };
  const { updateTabKey } = useContext(TgContext);

  const collapseHeader = (
    <div className="add-gst-text">
      <h4>Add Your GST Details</h4>
      <small>{isGstRequired == true ? "(Required)" : "(Optional)"}</small>
    </div>
  );
  function callback(key) {
    console.log(key);
  }

  const [travellersListResp, setTravellersListResp] = useState({
    Travellers: [],
  });
  useEffect(() => {
    fetchTravellerDetails();
  }, [user]);
  const fetchTravellerDetails = () => {
    if (!user) return;
    ApiClient.get("admin/user/travellers/" + user.UserID)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setTravellersListResp(resp.data);
        } else {
          console.log("Unable to fetch Travellers List");
        }
      })
      .catch((err) => err);
  };
  const handleTravelerDetails = (val, paxIndex) => {
    let selectedTraveler = travellersListResp.Travellers.find(
      (traveler) => traveler.TravellerID === val
    );

    let { passengers } = passenger_form.getFieldValue();

    let newObj = {
      ...passengers[paxIndex],
      title: selectedTraveler.Gender === 0 ? "MR" : "Ms",
      firstName: selectedTraveler.FirstName,
      lastName: selectedTraveler.LastName,
    };
    Object.keys(newObj).map((key) => {
      if (key === "dob") {
        newObj[key] = selectedTraveler.DOB ? moment(selectedTraveler.DOB) : "";
      }
      if (key === "passengerNationality") {
        newObj[key] = selectedTraveler.PassengerNationality ?? "";
      }
      if (key === "passportNumber") {
        newObj[key] = selectedTraveler.PassportNumber ?? "";
      }
      if (key === "passportDOI") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.passportDOI)
          : "";
      }
      if (key === "passportDOE") {
        newObj[key] = selectedTraveler.PassportDOE
          ? moment(selectedTraveler.PassportDOE)
          : "";
      }
      if (key === "genderType") {
        newObj[key] = selectedTraveler.Gender === 0 ? "m" : "f";
      }
    });
    Object.assign(passengers[paxIndex], newObj);

    passenger_form.setFieldsValue({ passengers });
  };

  return (
    <div className="checkout-section flight-wrapper farType-wrapper">
      <div className="flight-checkout">
        <div className="checkout-heading">
          <div
            className="goback"
            onClick={() => {
              updateTabKey("1");
              history.go(-1);
            }}
          >
            <ArrowLeftOutlined />
            <span>Go back and select another flight</span>
          </div>
          <h3>Fill out the form below and book your flight now!</h3>
        </div>

        <div className="checkout-container">
          <Row gutter={[16, 16]}>
            <Col md={16} sm={24} xs={24} className="checkout-bottom-part">
              <FlightDetailsCard
                selectedFlightData={selectedFlight}
                flightSearchData={flightSearchObj}
              />

              <div className="user-details contactwrapper">
                {/*    <p className="heading">Contact details</p> */}
                {!isLoading ? (
                  <>
                    <p className="heading">Contact Details</p>
                    <Card className="checkout-custom-card" bordered={false}>
                      <Skeleton active />
                    </Card>
                  </>
                ) : isLoading && totalPaxArr.length > 0 ? (
                  <div className="titlewrapper">
                    <p className="heading">Contact Details</p>
                    <Card className="flight-cards-details">
                      <Form
                        layout="vertical"
                        className="flight-checkout-contact-form"
                        name="contactForm"
                        form={contact_form}
                        scrollToFirstError={true}
                        initialValues={{
                          countryName: "India",
                        }}
                      >
                        <Row gutter={[16, 8]}>
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              label="Phone number"
                              name="mobile"
                              className="phno"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input
                                autoComplete="newpassword"
                                addonBefore="+91"
                                className="inputbg"
                              />
                            </Form.Item>
                          </Col>

                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              name="email"
                              label="Email"
                              rules={[
                                { required: true, message: "Required" },
                                { type: "email", message: "Invalid Email" },
                              ]}
                            >
                              <Input
                                autoComplete="newpassword"
                                type={"email"}
                                className="inputbg"
                              />
                            </Form.Item>{" "}
                          </Col>
                          <Col md={12} sm={12} xs={24}>
                            <Form.Item
                              name="address1"
                              label="Address"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                                { min: 3, message: "Invalid Address" },
                              ]}
                            >
                              <Input
                                autoComplete="newpassword"
                                className="inputbg"
                              />
                            </Form.Item>
                          </Col>
                          {totalPaxArr[0].city === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="city"
                                label="City"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
                                  autoComplete="newpassword"
                                  className="inputbg"
                                />
                              </Form.Item>{" "}
                            </Col>
                          ) : null}
                          {totalPaxArr[0].state === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="state"
                                label="State"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
                                  autoComplete="newpassword"
                                  className="inputbg"
                                />
                              </Form.Item>{" "}
                            </Col>
                          ) : null}
                          {totalPaxArr[0].countryName === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="countryName"
                                label="Country"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
                                  autoComplete="newpassword"
                                  className="inputbg"
                                />
                              </Form.Item>{" "}
                            </Col>
                          ) : null}
                          {totalPaxArr[0].postalCode === "" ? (
                            <Col md={12} sm={12} xs={24}>
                              <Form.Item
                                name="postalCode"
                                label="Postal Code"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Input
                                  autoComplete="newpassword"
                                  className="inputbg"
                                />
                              </Form.Item>
                            </Col>
                          ) : null}
                        </Row>
                      </Form>
                    </Card>
                  </div>
                ) : (
                  <p>{}</p>
                )}
              </div>

              <div className="paxInfowrapper">
                {!isLoading ? (
                  <>
                    <p className="heading">Passenger Details</p>
                    <Card className="checkout-custom-card" bordered={false}>
                      <Skeleton active />
                    </Card>
                  </>
                ) : isLoading && totalPaxArr.length > 0 ? (
                  <>
                    <p className="heading">Passenger Details</p>

                    <Form
                      layout="vertical"
                      className="passenger-form user-details "
                      validateMessages={validateMessages}
                      form={passenger_form}
                      initialValues={{ passengers: totalPaxArr }}
                    >
                      <Card className="checkout-custom-card">
                        <Form.List name="passengers">
                          {(fields) => {
                            return fields.map((field, index) => (
                              <div key={index}>
                                <Row gutter={[16, 8]}>
                                  <Col md={3} sm={8} xs={24}>
                                    <div className="card-adult-text">
                                      <div className="pax-heading">
                                        {totalPaxArr[index].paxType === "ADT"
                                          ? "Adult"
                                          : totalPaxArr[index].paxType === "CHD"
                                          ? "Child"
                                          : "Infant"}
                                      </div>
                                    </div>
                                  </Col>
                                  {user && (
                                    <>
                                      <Col md={13} sm={8} xs={24}>
                                        <div className="pax-head-hr">
                                          <hr />
                                        </div>
                                      </Col>

                                      <Col md={8} sm={8} xs={24}>
                                        <Select
                                          placeholder="Choose"
                                          onSelect={(val) => {
                                            handleTravelerDetails(val, index);
                                          }}
                                          style={{ width: "100%" }}
                                        >
                                          {travellersListResp.Travellers.map(
                                            (travelerName) => (
                                              <Option
                                                value={travelerName.TravellerID}
                                                key={travelerName.TravellerID}
                                              >
                                                {travelerName.FirstName +
                                                  " " +
                                                  travelerName.LastName}
                                              </Option>
                                            )
                                          )}
                                        </Select>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                                <Row gutter={[16, 8]}>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      {...field}
                                      name={[index, "title"]}
                                      label="Title"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Specify Title",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Choose"
                                        onChange={(e) =>
                                          handlePaxField(e, index, "title")
                                        }
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        {totalPaxArr[index].paxType ===
                                        "ADT" ? (
                                          <>
                                            <Select.Option value="MR">
                                              Mr.
                                            </Select.Option>
                                            <Select.Option value="MRS">
                                              Mrs.
                                            </Select.Option>
                                          </>
                                        ) : (
                                          <>
                                            <Select.Option value="MSTR">
                                              Mstr.
                                            </Select.Option>
                                            <Select.Option value="MISS">
                                              Miss.
                                            </Select.Option>
                                          </>
                                        )}

                                        <Select.Option value="MS">
                                          Ms.
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      {...field}
                                      name={[index, "firstName"]}
                                      label="First Name"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required",
                                        },
                                        {
                                          min: 2,
                                          message:
                                            "First Name must be minimum 2 characters.",
                                        },
                                        {
                                          pattern: "^[a-zA-Z ]*$",
                                          message: "Alphabet Characters Only",
                                        },
                                        {
                                          max: 32,
                                          message:
                                            "First Name must be maximum 32 characters.",
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (
                                              !value ||
                                              getFieldValue([
                                                "passengers",
                                                index,
                                                "lastName",
                                              ]) != value
                                            ) {
                                              if (
                                                !abbrevations.includes(value)
                                              ) {
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject(
                                                  new Error(
                                                    "Abbrevations are not accepted in beginning."
                                                  )
                                                );
                                              }
                                            }
                                            return Promise.reject(
                                              new Error(
                                                "The First and Last Name should not be same."
                                              )
                                            );
                                          },
                                        }),
                                      ]}
                                    >
                                      <Input
                                        className="inputbg"
                                        onChange={(e) =>
                                          handlePaxField(
                                            e.target.value,
                                            index,
                                            "firstName"
                                          )
                                        }
                                        key={`${Math.floor(
                                          Math.random() * 1000
                                        )}-min`}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      {...field}
                                      name={[index, "lastName"]}
                                      label="Last Name"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required",
                                        },
                                        {
                                          min: 2,
                                          message:
                                            "Last Name must be minimum 2 characters.",
                                        },
                                        {
                                          pattern: "^[a-zA-Z ]*$",
                                          message: "Alphabet Characters Only",
                                        },
                                        {
                                          max: 32,
                                          message:
                                            "Last Name must be maximum 32 characters.",
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (
                                              !value ||
                                              getFieldValue([
                                                "passengers",
                                                index,
                                                "firstName",
                                              ]) != value
                                            ) {
                                              if (
                                                !abbrevations.includes(value)
                                              ) {
                                                return Promise.resolve();
                                              } else {
                                                return Promise.reject(
                                                  new Error(
                                                    "Abbrevations are not accepted in beginning."
                                                  )
                                                );
                                              }
                                            }
                                            return Promise.reject(
                                              new Error(
                                                "The First and Last Name should not be same."
                                              )
                                            );
                                          },
                                        }),
                                      ]}
                                    >
                                      <Input
                                        className="inputbg"
                                        onChange={(e) =>
                                          handlePaxField(
                                            e.target.value,
                                            index,
                                            "lastName"
                                          )
                                        }
                                        key={`${Math.floor(
                                          Math.random() * 1000
                                        )}-min`}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={8} xs={24}>
                                    <Form.Item
                                      label="Gender"
                                      rules={[
                                        { required: true, message: "Required" },
                                      ]}
                                      name={[index, "genderType"]}
                                    >
                                      <Select
                                        autoComplete="newpassword"
                                        placeholder="Choose"
                                        onChange={(e) =>
                                          handlePaxField(e, index, "genderType")
                                        }
                                        key={`${Math.floor(
                                          Math.random() * 1000
                                        )}-min`}
                                      >
                                        <Select.Option value="m">
                                          Male
                                        </Select.Option>
                                        <Select.Option value="f">
                                          Female
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>

                                  {totalPaxArr[index].hasOwnProperty("dob") && (
                                    <Col md={8} sm={8} xs={24}>
                                      <Form.Item
                                        label="Date of birth"
                                        name={[index, "dob"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                          {
                                            validator: (_, v) =>
                                              PaxAgeValidator(
                                                totalPaxArr[index].paxType,
                                                v
                                              ),
                                          },
                                        ]}
                                      >
                                        <DatePicker
                                          className="inputbg"
                                          allowClear={false}
                                          format={dateformat}
                                          onChange={(date, dateString) =>
                                            handlePaxField(
                                              moment(
                                                dateString,
                                                dateformat
                                              ).format(oriDateFormat),
                                              index,
                                              "dob"
                                            )
                                          }
                                          key={`${Math.floor(
                                            Math.random() * 1000
                                          )}-min`}
                                          style={{ width: "100%" }}
                                          disabledDate={disabledFutureDate}
                                          inputReadOnly={true}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {totalPaxArr[index].hasOwnProperty(
                                    "passengerNationality"
                                  ) && (
                                    <Col md={8} sm={8} xs={24}>
                                      <Form.Item
                                        {...field}
                                        name={[index, "passengerNationality"]}
                                        label="Nationality"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                          {
                                            pattern: "^[a-zA-Z ]*$",
                                            message: "Alphabet Characters Only",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          placeholder="Nationality"
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          onChange={(e) =>
                                            handlePaxField(
                                              e,
                                              index,
                                              "passengerNationality"
                                            )
                                          }
                                          key={`${Math.floor(
                                            Math.random() * 1000
                                          )}-min`}
                                        >
                                          {CountryList.map((item) => (
                                            <Option
                                              key={item.name}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {totalPaxArr[index].hasOwnProperty(
                                    "passportNumber"
                                  ) && (
                                    <Col md={8} sm={8} xs={24}>
                                      <Form.Item
                                        name={[index, "passportNumber"]}
                                        label="Passport Number"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          className="inputbg"
                                          placeholder="Passport Number"
                                          onChange={(e) =>
                                            handlePaxField(
                                              e.target.value,
                                              index,
                                              "passportNumber"
                                            )
                                          }
                                          key={`${Math.floor(
                                            Math.random() * 1000
                                          )}-min`}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {totalPaxArr[index].hasOwnProperty(
                                    "passportDOI"
                                  ) && (
                                    <Col md={8} sm={8} xs={24}>
                                      <Form.Item
                                        name={[index, "passportDOI"]}
                                        label="Passport Issue Date"
                                        className="passport-dates"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <DatePicker
                                          className="inputbg"
                                          format={dateformat}
                                          onChange={(date, dateString) =>
                                            handlePaxField(
                                              moment(
                                                dateString,
                                                dateformat
                                              ).format(oriDateFormat),
                                              index,
                                              "passportDOI"
                                            )
                                          }
                                          key={`${Math.floor(
                                            Math.random() * 1000
                                          )}-min`}
                                          inputReadOnly={true}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {totalPaxArr[index].hasOwnProperty(
                                    "passportDOE"
                                  ) && (
                                    <Col md={8} sm={8} xs={24}>
                                      <Form.Item
                                        name={[index, "passportDOE"]}
                                        label="Passport Expiry Date"
                                        className="passport-dates"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <DatePicker
                                          className="inputbg"
                                          format={dateformat}
                                          onChange={(date, dateString) =>
                                            handlePaxField(
                                              moment(
                                                dateString,
                                                dateformat
                                              ).format(oriDateFormat),
                                              index,
                                              "passportDOE"
                                            )
                                          }
                                          key={`${Math.floor(
                                            Math.random() * 1000
                                          )}-min`}
                                          inputReadOnly={true}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  {totalPaxArr[index].hasOwnProperty(
                                    "passportIssuedCountry"
                                  ) && (
                                    <Col md={8} sm={8} xs={24}>
                                      <Form.Item
                                        name={[index, "passportIssuedCountry"]}
                                        label="Passport Issue Country"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          placeholder="Select Passport Issued Country"
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          onChange={(e) =>
                                            handlePaxField(
                                              e,
                                              index,
                                              "passportIssuedCountry"
                                            )
                                          }
                                          key={`${Math.floor(
                                            Math.random() * 1000
                                          )}-min`}
                                        >
                                          {CountryList.map((item) => (
                                            <Option
                                              key={item.name}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            ));
                          }}
                        </Form.List>
                      </Card>
                    </Form>
                  </>
                ) : (
                  <p>{}</p>
                )}
              </div>
              {isGstRequired ? (
                <div className="gst-details">
                  {/*   <p className="heading">GST Details</p> */}
                  <Card className="gst-custom-details flight-cards-details">
                    <p className="heading">GST Details</p>
                    <div className="add-gst-block">
                      <Collapse
                        onChange={callback}
                        bordered={false}
                        activeKey={[isGstRequired == true ? "1" : "0"]}
                      >
                        <Panel header={collapseHeader} key="1">
                          <Form
                            layout="vertical"
                            className="passenger-form"
                            validateMessages={validateMessages}
                            form={gst_form}
                          >
                            <Row gutter={[16, 8]}>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Company Name"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                  ]}
                                  name="companyName"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    className="contacts-value-de"
                                    // onChange={(e) => {
                                    //   getGstDetails(e.target.value, "company Name");
                                    // }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="GST Number"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                    {
                                      minLength: 15,
                                      maxLength: 15,
                                      pattern: "^[0-9A-Z ]{15}$",
                                      message:
                                        "Must be 15 digits AlphaNumeric with caps",
                                    },
                                  ]}
                                  name="gstnumber"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    className="contacts-value-de"
                                    placeholder="GST Number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Contact Number"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                    {
                                      minLength: 10,
                                      maxLength: 10,
                                      pattern: "^[0-9]{10}$",
                                      message: "Must be 10 digits",
                                    },
                                  ]}
                                  name="contactnumber"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    className="contacts-value-de"
                                    placeholder="Contact Number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Company Email"
                                  rules={[
                                    {
                                      required: isGstRequired,
                                      message: "Required",
                                    },
                                  ]}
                                  name="companyemail"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    className="contacts-value-de"
                                    placeholder="Company Email"
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Company Address"
                                  rules={[{ required: isGstRequired }]}
                                  name="companyaddress"
                                >
                                  <Input
                                    autoComplete="newpassword"
                                    className="contacts-value-de"
                                    placeholder="Company Address"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </Panel>
                      </Collapse>
                    </div>
                  </Card>
                </div>
              ) : null}
            </Col>
            <Col xs={24} md={8} className="checkout-top-part">
              <div className="checkout-sticky-part">
                {!isLoading ? (
                  <Card className="checkout-custom-card" bordered={false}>
                    <Skeleton active />
                  </Card>
                ) : (
                  airPriceResp && (
                    <>
                      {airPriceResp.flightDetails.map((flightDetObj, i) => (
                        <FareSummaryCard
                          flightSearchObj={flightSearchObj}
                          flightDetObj={flightDetObj}
                          currency={flightDetObj.fareFamilies[0].currency}
                          title={
                            i === 0 ? "Onward Trip Fare" : "Return Trip Fare"
                          }
                          key={i}
                        />
                      ))}
                      <div className="grand-total-card">
                        <FlightGrandTotalCard
                          airPriceResp={airPriceResp}
                          flightSearchObj={flightSearchObj}
                        />
                      </div>
                    </>
                  )
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={16}>
              <BookPayCard
                isLoading={isLoading}
                bookpaycardinfo={"flight-checkout"}
                redirectToPreview={redirectToPreview}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FlightCheckout;
