import React, { useContext, useState, useEffect } from "react";
import { Button, Row, Card, Col, Form, message, Spin } from "antd";

import { useHistory } from "react-router-dom";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import FareDetails, { getBusGrandTotal } from "../Checkout/FareDetails";
import BusDetails from "../Checkout/BusDetails";
import ApiClient from "../../../helpers/ApiClient";
import PaymentGateway from "../../../helpers/PaymentGateway";
import { LoadingOutlined } from "@ant-design/icons";
import "../Checkout/Checkout.scss";
import PassengerDetails from "./BusPassengerDetails/PassengerDetails";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";

const BusReview = () => {
  const { user } = useAuthContext();
  const {
    state: {
      busPersist: { selectedBusData },
      busPassengerData,
      otherData: { promoData, ConvFee },
    },
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  const [form] = Form.useForm();

  const [passengersData, setPassengersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);

  useEffect(() => {
    if (busPassengerData.length > 0) {
      busPassengerData.map((paxData) => {
        if (paxData.passengerDetails) {
          let buildDataForPassenger = paxData.passengerDetails.map((pData) => ({
            Title: pData.title,
            Age: pData.age,
            Name: pData.name,
            Gender: pData.gender,
            SeatNo: pData.seatNo,
            EmailId: busPassengerData.emailId,
            MobileNo: busPassengerData.mobileNo,
          }));
          setPassengersData([...passengersData, ...buildDataForPassenger]);
        }
      });
    } else {
      history.go(-1);
    }
  }, [busPassengerData]);

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyBookingOTP", {
      mobile: busPassengerData[0].mobileNo,
      dialingCode: "91",
      OTP: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          busBlocking(busPassengerData[0], "oneWay");
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendBookingOTP", {
      mobile: busPassengerData[0].mobileNo,
      dialingCode: "91",
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const busBlocking = (busPassengerDataObj, type) => {
    setIsLoading(true);

    const { totalFareCalculations } = getBusGrandTotal(
      selectedBusData,
      ConvFee,
      promoData
    );

    let data = {
      ...busPassengerDataObj,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      // promoData: {
      //   ...promoData,
      //   tripType: type,
      // },
      // convienenceData: ConvFee,
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      totalPrice: totalFareCalculations ?? 0,
    };

    ApiClient.post("buses/blockticket", data)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        setIsLoading(false);
        if (resp.statusCode === 200) {
          if (resp.data.BookingStatus === 1) {
            processPayment(resp.data.BookingReferenceNo, resp.data.signature);
          } else {
            message.error("Unable To Book Ticket: " + resp.data.Message);
          }
        } else {
          message.error("Server Error: " + resp.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const goTo = () => {
    form
      .validateFields()
      .then(() => {
        const { totalFareCalculations } = getBusGrandTotal(
          selectedBusData,
          ConvFee,
          promoData
        );

        ApiClient.post("admin/validatebookinglimit", {
          serviceType: 3,
          roleType: user?.Role?.RoleId ?? 4,
          bookingAmount: totalFareCalculations ? totalFareCalculations : 0,
        })
          .then((res) => {
            if (res?.status === 200 && res?.isValid) {
              if (user) {
                busBlocking(busPassengerData[0], "oneWay");
              } else {
                handleSendOTP();
              }
            } else {
              if (res?.message) message.error(res.message, 3);
              else message.error("Booking Failed", 3);
            }
          })
          .catch();
      })
      .catch((e) => {});
  };

  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});

  const processPayment = (oid, signature) => {
    let { totalFareCalculations, totalCommission, agentMarkup } =
      getBusGrandTotal(selectedBusData, ConvFee, promoData);
    let commissionAmount = 0;
    let agentMarkupAmount = 0;
    if (user?.Role?.RoleId === 5) {
      commissionAmount = Number(totalCommission);
      agentMarkupAmount = Number(agentMarkup);
    }

    let data = {
      OrderId: oid,
      OrderAmount: parseFloat(totalFareCalculations),
      OrderCurrency: "INR",
      OrderNote: "BUS",
      CustomerName: passengersData[0].Name,
      CustomerEmail: busPassengerData[0].emailId,
      CustomerPhone: busPassengerData[0].mobileNo,
      msg: signature,
      // TDS: 0,
      // GST: 0,
      // Commission: commissionAmount, // for agent only
      // AgentMarkup: agentMarkupAmount,
    };
    // history.push(`/pg_success?ref=${data.OrderId}`);
    setPgData(data);
    setPgDisplay(true);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <div className="buscheckout_bg">
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form form={form} layout="vertical">
              <h3 className="title">Bus Details</h3>
              <Row gutter={[16, 16]}>
                <Col md={17} sm={16} xs={24}>
                  <div className="left_wrapper">
                    <BusDetails selectedBusData={selectedBusData} />
                  </div>
                  <div className="paxinfo_wrapper">
                    <h3 className="title">Passenger Details</h3>

                    <Card>
                      <PassengerDetails passengersInfo={busPassengerData} />
                    </Card>
                  </div>
                </Col>

                <Col md={7} sm={8} xs={24}>
                  <div className="right_wrapper">
                    <FareDetails
                      selectedBusData={selectedBusData}
                      isPromoVisible={false}
                    />
                  </div>

                  <Card>
                    {pgDisplay == false ? (
                      <div className="btn_wrapper">
                        <Button
                          type="primary"
                          onClick={() => {
                            goTo();
                          }}
                        >
                          {isLoading ? <Spin indicator={antIcon} /> : "Pay Now"}
                        </Button>
                      </div>
                    ) : (
                      <PaymentGateway data={pgData} />
                    )}
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={busPassengerData[0].mobileNo}
        />
      )}
    </div>
  );
};
export default BusReview;
