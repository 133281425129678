import React, { useContext, useState, createContext } from "react";
import { useLocalStorage } from "../../../helpers/useLocalStorage";

//TODO - Move to static file
// const defFlightSearchObj = {
//   airTravelType: "oneWay",
//   oneWayType: "1",
//   traceId: "",
//   stopOver: "",
//   departureDateTime: moment(new Date()).format("YYYY-MM-DD"),
//   returnDateTime: "",
//   origin: "",
//   destination: "",
//   adultCount: 1,
//   childCount: 0,
//   infantCount: 0,
//   cabinClass: "Economy",
//   resultsType: "",
//   searchId: "",
// };
const FlightContext = createContext();

export function useFlightContext() {
  return useContext(FlightContext);
}

const FlightProvider = (props) => {
  //Hydrating Flight Data from localstorage

  const intitalFlightState = {
    flightSearchObj: {},
    selectedFlight: [],
  };

  const [flightPersist, setFlightPersist] = useLocalStorage(
    "flightPersist",
    intitalFlightState
  );

  const [flightFareRulesResp, setFlightFareRulesResp] = useState("");

  const [airBookStateObj, setAirBookStateObj] = useState({});

  const [flightBookingResp, setFlightBookingResp] = useState({});

  const [flightAirSearchResp, setFlightAirSearchResp] = useState({});

  const [flightAirPriceResp, setFlightAirPriceResp] = useState({});

  /* Update Flight Search obj  */
  const updateFlightSearchObj = (searchObj) => {
    setFlightPersist((prev) => ({ ...prev, flightSearchObj: searchObj }));
  };

  /* Update Flight fare Rules data */
  const updateFlightFares = (FareRulesResp) => {
    setFlightFareRulesResp(FareRulesResp);
  };

  /* Update selected flight Obj */
  const updateSelectedFlight = (flightsArr) => {
    setFlightPersist((prev) => ({ ...prev, selectedFlight: flightsArr }));
  };

  const resetFlightSelectedData = () => {
    setFlightPersist((prev) => ({ ...prev, selectedFlight: [] }));
  };

  const resetFlightPersist = () => {
    setFlightPersist(intitalFlightState);
  };

  /* Update airbook request Obj */
  const updateAirBookState = (airBookReq) => {
    setAirBookStateObj(airBookReq);
  };

  /* Update airbook resp Obj */
  const updateAirBookRespState = (airBookResp) => {
    setFlightBookingResp(airBookResp);
  };

  /* Update Flight Search obj  */
  const updateFlightAirSearchRespObj = (flightAirResponse) => {
    setFlightAirSearchResp(flightAirResponse);
  };

  /* Update Airprice resp Obj */
  const updateFlightAirPrice = (airPriceRespObj) => {
    setFlightAirPriceResp(airPriceRespObj);
  };

  const resetFlightSearchObj = () => {
    setFlightPersist((prev) => ({ ...prev, flightSearchObj: {} }));
  };

  return (
    <FlightContext.Provider
      value={{
        state: {
          flightPersist,
          flightFareRulesResp,
          airBookStateObj,
          flightBookingResp,
          flightAirPriceResp,
          flightAirSearchResp,
        },

        updateFlightSearchObj: updateFlightSearchObj,
        updateFlightFares: updateFlightFares,
        updateSelectedFlight: updateSelectedFlight,
        updateAirBookState: updateAirBookState,
        updateAirBookRespState: updateAirBookRespState,
        updateFlightAirSearchRespObj: updateFlightAirSearchRespObj,
        updateFlightAirPrice: updateFlightAirPrice,
        resetFlightSearchObj: resetFlightSearchObj,
        resetFlightSelectedData: resetFlightSelectedData,
        resetFlightPersist,
      }}
    >
      {props.children}
    </FlightContext.Provider>
  );
};
export default FlightProvider;
