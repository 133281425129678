import React, { useState, useEffect } from "react";
import "./FlightsList.scss";

import SeperatedView from "./SeperatedView/SeperatedView";
import CombinedView from "./CombinedView/CombinedView";
import { useHistory } from "react-router-dom";

const FlightsList = (props) => {
  const {
    setSelectedTripType,
    updateSelectedFlight,
    flightPersist,
    updateFlightFares,
    flightAirSearchResp,
    promoData,
  } = props;

  // const [airTravelType, setAirTravelType] = useState("roundTrip");
  const [airTravelType, setAirTravelType] = useState("oneWay");

  const [journeyType, setJourneyType] = useState("onward");
  const [airSearchRespForSeparate, setAirSearchRespForSeparate] = useState(
    flightAirSearchResp.flightDetails
  );
  const [combinedOnewayFlightList, setCombinedOneWayFlightList] = useState([]);
  const [combinedTwowayFlightList, setCombinedTwoWayFlightList] = useState([]);

  let history = useHistory();
  const changeFlightResultsView = (flightObj) => {
    if (flightObj.length === 0) {
      updateSelectedFlight([]);
      return;
    }
    updateSelectedFlight(flightObj);

    if (flightAirSearchResp.resultsType === "Separate") {
      // setJourneyType("return");
      if (journeyType === "onward") {
        changeReturnFlights();
        setReturnFlightResults(flightAirSearchResp.ibFlightDetails);
      }
      // else {
      //   history.push("/flight-checkout");
      // }
    } else {
      history.push("/flight-checkout");
    }
  };

  useEffect(() => {
    if (
      flightAirSearchResp.airTravelType === "oneWay" ||
      flightAirSearchResp.airTravelType === "multidestination" ||
      flightAirSearchResp.resultsType === "Separate"
    ) {
      if (journeyType == "onward") {
        setAirSearchRespForSeparate(flightAirSearchResp.flightDetails);
        let visibleData = flightAirSearchResp.flightDetails.filter(
          (item) => item.isVisible
        );
        // getMinFlightPrice(visibleData);

        setCombinedOneWayFlightList(visibleData);
      } else {
        setAirSearchRespForSeparate(flightAirSearchResp.ibFlightDetails);
        setSelectedTripType(2);

        let visibleData = flightAirSearchResp.ibFlightDetails.filter(
          (item) => item.isVisible
        );
        setCombinedOneWayFlightList(visibleData);
        // getMinFlightPrice(visibleData);
      }
    } else {
      let visibleData = flightAirSearchResp.flightDetails.filter(
        (item) => item.isVisible
      );
      setCombinedTwoWayFlightList(visibleData);
    }
  }, [flightAirSearchResp]);

  const setOnwardFlights = () => {
    // setAirSearchRespForSeparate(flightAirSearchResp.flightDetails);
    setSelectedTripType(1);
    setJourneyType("onward");
  };

  const changeReturnFlights = () => {
    setSelectedTripType(2);
    setJourneyType("return");
  };
  const setReturnFlightResults = (flights) => {
    setAirSearchRespForSeparate(flights);
  };

  return (
    <div className="result-bar">
      {airTravelType === "roundTrip" ? (
        <div>Selected journeyType : {journeyType}</div>
      ) : null}

      {flightAirSearchResp?.resultsType ? (
        flightAirSearchResp.resultsType === "Combined" ? (
          <div className="resultsScrollWrapper">
            <CombinedView
              changeFlightResultsView={changeFlightResultsView}
              selectedFlight={flightPersist.selectedFlight}
              flightSearchObj={flightPersist.flightSearchObj}
              combinedOnewayFlightList={combinedOnewayFlightList}
              combinedTwowayFlightList={combinedTwowayFlightList}
              setFlightFareRules={updateFlightFares}
              promoData={promoData}
            />
          </div>
        ) : (
          <SeperatedView
            flightSearchObj={flightPersist.flightSearchObj}
            selectedFlight={flightPersist.selectedFlight}
            flightDetails={airSearchRespForSeparate}
            changeFlightResultsView={changeFlightResultsView}
            setOnwardFlights={setOnwardFlights}
            journeyType={journeyType}
            promoData={promoData}
            changeReturnFlights={changeReturnFlights}
          />
        )
      ) : null}
    </div>
  );
};

export default FlightsList;
