import { CaretRightOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, DatePicker, Form, Input, Layout, Modal, Popconfirm, Row, Select, Spin, Table, Tooltip } from "antd";
import React, { useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";

import search from "../assets/vector-icons/search.png";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;

const APIAccessLogs = () => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [showRequestModal, setRequestModal] = useState(false);
  const [apiLogList, setApiLogList] = useState([]);
  const [busLogList, setBusLogList] = useState([]);
  const [hotelLogList, sethotelLogList] = useState([]);
  const [tabledataSource, setTableDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [travelType, setTravelType] = useState(-1);
  const [showAPIRequestModal, setAPIRequestModal] = useState(false);
  const [logRequest, setLogRequest] = useState("");
  const [logResponse, setLogResponse] = useState("");
  const [ApiRequest, setApiRequest] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const getApiLogs = (formData) => {
    setLoading(true);
    let query = `?fromDate=${formData.fromDate}&toDate=${formData.toDate}`;
    ApiClient.get(`flights/airLog/search${query}`)
      .then((res) => {
        if (res.statusCode === 200) {
          setApiLogList(res.data);
          let data = res.data.filter((_, index) => index % 2 === 0);
          data = data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setTableDataSource(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getBusApiLogs = (formData) => {
    setLoading(true);
    let query = `?fromDate=${formData.fromDate}&toDate=${formData.toDate}`;
    ApiClient.get(`buses/busLog/search${query}`)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setBusLogList(data);
          setTableDataSource(data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getHotelApiLogs = (formData) => {
    setLoading(true);
    let query = `?fromDate=${formData.fromDate}&toDate=${formData.toDate}`;
    ApiClient.get(`hotels-v2/hotelLog/search${query}`)
      .then((res) => {
        console.log(res, "data");
        setLoading(false);
        if (res.statusCode === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          sethotelLogList(data);
          setTableDataSource(data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const Request = (rec) => {
    return (
      <div className="eyewrapper ">
        <EyeOutlined
          onClick={(e) => {
            setLogRequest(JSON.stringify(rec.request, null, 4));
            setLogResponse(JSON.stringify(rec.response, null, 4));
            setRequestModal(true);
          }}
        />
      </div>
    );
  };

  const APIRequest = (rec) => {
    return (
      <div className="eyewrapper eyered">
        <EyeOutlined
          onClick={(e) => {
            let filter = apiLogList.filter((item) => (item.response ? item.logType === "1" && item.response.traceId === rec.response.traceId : ""));

            if (filter.length < 1) {
              setAPIRequestModal(true);
              setApiRequest("");
              setApiResponse("");
              return;
            }
            setApiRequest(JSON.stringify(filter[0].request, null, 4));
            setApiResponse(JSON.stringify(filter[0].response, null, 4));
            setAPIRequestModal(true);
          }}
        />
      </div>
    );
  };

  const busAPIRequest = (rec) => {
    return (
      <div className="eyewrapper">
        <EyeOutlined
          onClick={(e) => {
            setApiRequest(JSON.stringify(rec.apiRequest, null, 4));
            setApiResponse(JSON.stringify(rec.apiResponse, null, 4));
            setAPIRequestModal(true);
          }}
        />
      </div>
    );
  };

  const toDateDis = (currentDate) => {
    let momentA = moment(currentDate).startOf("day");
    let momentB = moment().startOf("day");
    if (momentA > momentB) {
      return true;
    } else if (momentA < momentB) {
      return true;
    } else {
      return false;
    }
  };

  const disabledFutureDate = (currentDate) => {
    let momentA = moment(currentDate).startOf("day");
    let momentB = moment().startOf("day");
    let momentC = moment().add(-1, "days").startOf("day");

    if (momentA > momentB) {
      return true;
    } else if (momentA < momentC) {
      return true;
    } else {
      return false;
    }
  };

  const flightColumns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Access Log Id",
      render: (_, rec) => (rec.response ? rec.response.traceId : ""),
    },
    {
      title: "Dated",
      dataIndex: "createdAt",
      render: (d) => moment(d).format("DD-MM-YYYY hh:mm"),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },

    {
      title: "Service",
      render: () => <>Flight</>,
    },
    {
      title: "Logs",
      render: (_, rec) => Request(rec),
    },
    {
      title: "API URL",
      dataIndex: "apiURL",
      sorter: (a, b) => a.apiURL.localeCompare(b.apiURL),
    },

    // {
    //   title: "API Logs",
    //   render: (_, rec) => APIRequest(rec),
    // },
  ];

  const hotelColumns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Access Log Id",
      render: (_, rec) => rec.traceId,
    },
    {
      title: "Dated",
      dataIndex: "createdAt",
      render: (d) => moment(d).format("DD-MM-YYYY hh:mm"),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },

    {
      title: "Service",
      render: () => <>Hotel</>,
    },
    {
      title: "Logs",
      render: (_, rec) => Request(rec),
    },
    {
      title: "API URL",
      dataIndex: "apiURL",
      sorter: (a, b) => a.apiURL.localeCompare(b.apiURL),
    },

    // {
    //   title: "API Logs",
    //   render: (_, rec) => APIRequest(rec),
    // },
  ];

  const handleTimeAndDate = (value) => {
    if (value === "1") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  const busColumns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "Service",
      render: () => <>Bus</>,
    },
    {
      title: "Dated",
      dataIndex: "createdAt",
      render: (d) => moment(d).format("DD-MM-YYYY hh:mm"),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "API Logs",
      render: (item, rec) => busAPIRequest(rec),
    },
    {
      title: "API URL",
      dataIndex: "apiURL",
      sorter: (a, b) => a.apiURL.localeCompare(b.apiURL),
    },
  ];

  const submitForm = (val) => {
    val.fromDate = val.fromDate.format("YYYY-MM-DD");
    val.toDate = val.toDate.format("YYYY-MM-DD");

    setTableDataSource([]);
    setTableHeaders(val.travelType);
    setTravelType(val.travelType);
    if (val.travelType === 1) {
      getApiLogs(val);
    } else if (val.travelType === 3) {
      getBusApiLogs(val);
    } else if (val.travelType === 2) {
      getHotelApiLogs(val);
    }
  };

  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 3:
        setColumns(busColumns);
      case 2:
        setColumns(hotelColumns);
        break;

      default:
        return;
    }
  };
  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  const closeSearch = () => {
    setShowSearchBox(false);
    if (travelType === 1) setTableDataSource(apiLogList);
    else if (travelType === 3) setTableDataSource(busLogList);
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tabledataSource.filter(function (item) {
        if (item !== undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setTableDataSource(searchResults);
    } else {
      if (travelType === 1) setTableDataSource(apiLogList);
      else if (travelType === 3) setTableDataSource(busLogList);
      else if (travelType === 2) setTableDataSource(hotelLogList);
    }
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  return (
    <Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                      <CaretRightOutlined rotate={isActive ? 90 : -90} />
                    </Tooltip>
                  )}
                  expandIconPosition="right"
                  className="panel_wrapper"
                  defaultActiveKey={["1"]}
                >
                  <Panel header="API Logs" key="1">
                    <Form form={form} layout="vertical" onFinish={submitForm}>
                      <Row gutter={16}>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item label="From Date" name="fromDate" rules={[{ required: true, message: "Field is required" }]}>
                            <DatePicker style={{ width: "100%" }} placeholder="Select From date" disabledDate={disabledFutureDate} />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item label="To Date" name="toDate" rules={[{ required: true, message: "Field is required" }]}>
                            <DatePicker style={{ width: "100%" }} placeholder="Select To date" disabledDate={toDateDis} />
                          </Form.Item>
                        </Col>

                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item label="Service" name="travelType" rules={[{ required: true, message: "Field is required" }]}>
                            <Select autoComplete="newpassword" autoComplete="off" placeholder="Please select">
                              <Option value={1}>Flights</Option>
                              <Option value={2}>Hotels</Option>

                              <Option value={3}>Buses</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="reports-btns">
                        <Button type="primary" onClick={(e) => setShowTable(true)} htmlType="submit">
                          Search
                        </Button>
                        <Button type="danger" onClick={(e) => setShowTable(false)}>
                          Reset
                        </Button>
                      </Row>
                    </Form>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {tabledataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <h5>View API Logs</h5>
                            <p>{tabledataSource.length} rows found !</p>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                autoComplete="newpassword"
                                autoComplete="off"
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img src={search} alt="search" onClick={(e) => searchData()} />
                          </div>
                        </div>

                        <Table
                          scroll={{ x: true }}
                          bordered
                          dataSource={tabledataSource}
                          columns={columns}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                          rowKey={keyGenerate}
                        />
                      </>
                    ) : loading ? (
                      <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
          <Modal
            width="900px"
            visible={showRequestModal}
            title="LOGS"
            onCancel={(e) => {
              setRequestModal(false);
            }}
            footer={false}
          >
            <>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() => copyToClipboard("Log Request: " + logRequest + ", Log Response :" + logResponse)}
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <h6>Request</h6>
              <pre>{logRequest}</pre>
              <br />
              <h6>Response</h6>
              <pre>{logResponse}</pre>
            </>
          </Modal>

          <Modal
            width="900px"
            visible={showAPIRequestModal}
            title="API LOGS"
            onCancel={(e) => {
              setAPIRequestModal(false);
            }}
            footer={false}
          >
            <>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() => copyToClipboard("API Request: " + ApiRequest + ", API Response :" + ApiResponse)}
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <h6>API Request</h6>
              <pre>{ApiRequest}</pre>
              <br />
              <h6>API Response</h6>
              <pre>{ApiResponse}</pre>
            </>
          </Modal>
        </Content>
      </div>
    </Layout>
  );
};

export default APIAccessLogs;
