import React from "react";
import "../Hotelsalescount/Hotelsalescount.scss";
import { Card, Col, Row, Table } from "antd";
// import { AntDesignOutlined, SettingFilled } from "@ant-design/icons";
// import { Link } from "react-router-dom";
// import Siteusers from "../../admin/Siteusers/Siteusers";
// import { Chart, LineAdvance } from "bizcharts";

const Hotelsalescount = ({ hotelData }) => {
  // const { TabPane } = Tabs;
  // const data = [
  //   {
  //     month: "Jan",
  //     city: "Tokyo",
  //     temperature: 7,
  //   },
  //   {
  //     month: "Jan",
  //     city: "London",
  //     temperature: 3.9,
  //   },

  // ];

  const columnshotelcount = [
    {
      title: "ReferenceID",
      dataIndex: "RefNumber",
    },
    {
      title: "Hotel Name",
      dataIndex: "HotelName",
    },

    {
      title: "Amount ",
      dataIndex: "Fare",
      render: (p) => Math.round(p),
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (type) => getStatus(type),
    },
  ];

  const getStatus = (type) => {
    switch (type) {
      case 1:
        return "Failed ";
      case 2:
        return "Confirmed ";
      case 3:
        return "Cancelled ";
      case 4:
        return "Pending ";
      case 5:
        return "Rejected  ";
      case 6:
        return "Hold ";
      case 7:
        return "CancellationRequest ";
      case 8:
        return "CancellationPending ";
      case 9:
        return "CancellationInProgress ";
      default:
        return "";
    }
  };

  return (
    <div>
      <div>
        <div className="dash-board-header"></div>
        <div className="order-count-cards order-line-bar-fhb"></div>

        {/* hotel--sales--open */}

        <div className="order-count-cards ">
          <Row>
            <Col xs={24} md={24}>
              <h4 className="flights-sales-card">Hotel Sales</h4>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={4}>
              <div className="ad-hom-box">
                <span className="ad-span-col bg-1">
                  <i className="fa fa-bookmark" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Booking Count</p>
                  <h5>{Number(hotelData.booking_count).toFixed(2)}</h5>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div className="ad-hom-box">
                <span className="ad-span-col bg-1">
                  <i className="fa fa-bookmark" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Cancelled Count</p>
                  <h5>{Number(hotelData.cancelled_count).toFixed(2)}</h5>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div className="ad-hom-box">
                <span className="ad-span-col bg-6">
                  <i className="fa fa-bar-chart"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Day Sales </p>
                  <h5>{Number(hotelData.day_sales).toFixed(2)}</h5>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div className="ad-hom-box">
                <span className="ad-span-col bg-7">
                  <i className="fa fa-bookmark" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Yesterday Sales </p>
                  <h5>{Number(hotelData.yesterday_sales).toFixed(2)}</h5>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div className="ad-hom-box">
                <span className="ad-span-col bg-8">
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>Week Sales </p>
                  <h5>{Number(hotelData.week_sales).toFixed(2)}</h5>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div className="ad-hom-box">
                <span className="ad-span-col bg-9">
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </span>

                <div className="ad-hom-view-com">
                  <p>month Sales </p>
                  <h5>{Number(hotelData.month_sales).toFixed(2)}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="order-count-cards ">
          <Row
            gutter={[
              { xs: 0, md: 16 },
              { xs: 8, md: 16 },
            ]}
            className="charts-bars card-count-balance"
          >
            <Col xs={24} md={12}>
              <Card className="side-bar-graph-1">
                <p className="month-wise-cart">Upcoming Bookings</p>
                {/* <p className="month-wise-cart">Month wise (Show in Graph)</p>
                <Chart
                  padding={[10, 20, 50, 40]}
                  autoFit
                  height={300}
                  data={data}
                >
                  <LineAdvance
                    shape="smooth"
                    point
                    area
                    position="month*temperature"
                    color="city"
                  />
                </Chart> */}
                <Table className="table-scroll-none flight-new-pagenation" bordered dataSource={hotelData.upcoming_bookings} columns={columnshotelcount} />
              </Card>
            </Col>

            <Col xs={24} md={12}>
              <div className="site-card-border-less-wrapper">
                <Card className="side-bar-graph-new-flight" /* title="Hotel recent 10 bookings" bordered={false} */>
                  <p className="month-wise-cart">Recent Bookings</p>
                  <Row className="boder-count">
                    <Col xs={24} md={24} className="total-cal-fl">
                      <Table className="table-scroll-none flight-new-pagenation" bordered dataSource={hotelData.recent_bookings} columns={columnshotelcount} />
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>
        </div>

        {/* charts--close */}
      </div>
    </div>
  );
};
export default Hotelsalescount;
