import React, { useState } from "react";
import hotelNoImg from "../../../assets/images/hotels/no_photo.png";
import RoomNoImg from "../../../assets/images/hotels/no-hotel-room.png";

const HotelCardImage = ({ hotelDetailsRespObj, hotelRoom, onHandleModal }) => {
  // const [showImage, setShowImage] = useState(true);
  const [showImage, setShowImage] = useState(
    hotelRoom?.roomImageList.length > 0 ?? false
  );
  return (
    <div className="hotel_image_">
      <img
        src={showImage ? hotelRoom?.roomImageList[0] : RoomNoImg}
        alt={hotelRoom.roomName}
        onError={() => {
          setShowImage(false);
        }}
      />
      {showImage && (
        <div className="gallery-icon">
          <i
            className="fa fa-picture-o"
            aria-hidden="true"
            onClick={() =>
              onHandleModal({
                roomName: hotelRoom.roomName,
                images: hotelRoom?.roomImageList,
              })
            }
          ></i>
        </div>
      )}
    </div>
  );
};

export default HotelCardImage;
