import React, { useContext, useEffect, useState } from "react";
import { Col, Collapse, Grid, Row, Modal, Button, Skeleton } from "antd";

import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import queryString from "query-string";
import BusSort from "../BusSort/BusSort";
import ApiClient from "../../../helpers/ApiClient";
import Buses from "../../../components/buses-search/Buses";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
import BusFilter from "./BusFilter/BusFilter";
import BusResultCards from "./BusResultCards/BusResultCards";
import BusSkeleton from "./Skeleton/BusSkeleton";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const BusResults = () => {
  const {
    state: { busSearchResultObj },
    updateBusSearchResultObj,
    updateSelectedBusData,
    // RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  // const { md } = useBreakpoint();

  const { user } = useAuthContext();

  let busInitial = {
    trips: [],
    origin: "",
    destination: "",
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [key, setKey] = useState([]);
  const { md } = useBreakpoint();
  const showModalbusfilters = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalbussort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };
  const [busResultsList, setBusResultsList] = useState(busInitial);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBusesAvailability();
    // RemovePromoConvFee();
  }, []);

  /* Buses Search Availability */
  const getBusesAvailability = () => {
    let param = queryString.parse(document.location.search);

    let formData = {
      tripType: 1,
      sourceId: param?.sourceId,
      destinationId: param?.destinationId,
      journeyDate: param?.journeyDate,
      returnDate: "",
      userId: user?.UserID ?? 1,
      roleType: user?.Role ?? 4,
      membership: user?.Membership ?? 1,
    };
    let origin = param?.sourceName;
    let destination = param?.destinationName;

    fetchBusAvailability(formData, origin, destination, 1);
  };

  const fetchBusAvailability = (formData, origin, destination, type) => {
    setIsLoading(true);
    updateBusSearchResultObj({});
    setBusResultsList(busInitial);
    ApiClient.post("buses/availability/price", formData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (resp.data.trips != {}) {
            resp = {
              ...resp,
              data: {
                ...resp.data,
                trips: resp.data.trips.map((trip) => ({
                  ...trip,
                  isVisible: true,
                })),
                origin: origin,
                destination: destination,
                journeyDate: formData.journeyDate,
              },
            };
            updateBusSearchResultObj({ ...resp, tripType: type });
            setBusResultsList(resp.data);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="combined-results-container">
        <section className="hotels_modify ">
          <div className="hotel-modify-container bus-v3-back hide-pdr-v3">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={
                  <span className="hotels-hide-search">Modify Search</span>
                }
                key="1"
              >
                <Buses
                  modifySearch={true}
                  busesSearchAPI={getBusesAvailability}
                />
              </Panel>
            </Collapse>
          </div>
        </section>

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container filters-number-tab">
            <Row
              gutter={16}
              className="margins-alignments"
              style={{ padding: "14px 14px" }}
            >
              {isLoading ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <FilterSkeleton />
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    <BusSkeleton />
                  </Col>
                </>
              ) : Object.keys(busSearchResultObj).length > 0 &&
                busSearchResultObj?.data &&
                busSearchResultObj?.data?.trips?.length > 0 ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <BusFilter
                      busSearchResultObj={busSearchResultObj}
                      setBusResultsList={setBusResultsList}
                    />
                  </Col>

                  <Col md={18} sm={24} xs={24}>
                    <BusResultCards
                      updateSelectedBusData={updateSelectedBusData}
                      trips={busResultsList.trips}
                      busSearchResultObj={busSearchResultObj}
                      setBusResultsList={setBusResultsList}
                      showNetFare={showNetFare}
                      setShowNetFare={setShowNetFare}
                    />
                  </Col>
                </>
              ) : (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Buses Available"} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
      {isLoading ? null : Object.keys(busSearchResultObj).length > 0 &&
        busSearchResultObj?.data &&
        busSearchResultObj?.data?.trips?.length > 0 ? (
        <Row className="fiters_wrapper">
          <Col md={12} xs={12} className="tc">
            <span className="title" onClick={showModalbusfilters}>
              {" "}
              <i className="fa fa-filter" aria-hidden="true"></i>
              &nbsp;Filters
            </span>
          </Col>
          <Col md={12} xs={12} className="tc">
            <span className="title" onClick={showModalbussort}>
              <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
              &nbsp;Sort by
            </span>
          </Col>
        </Row>
      ) : null}

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        {isLoading ? (
          <Skeleton active />
        ) : Object.keys(busSearchResultObj).length > 0 &&
          busSearchResultObj?.data &&
          busSearchResultObj?.data?.trips?.length > 0 ? (
          <BusFilter
            busSearchResultObj={busSearchResultObj}
            setBusResultsList={setBusResultsList}
          />
        ) : (
          <p>No Buses Available</p>
        )}
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header bus-modal-sort-v3"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisiblenew(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        {isLoading ? (
          <Skeleton active />
        ) : Object.keys(busSearchResultObj).length > 0 &&
          busSearchResultObj?.data &&
          busSearchResultObj?.data?.trips?.length > 0 ? (
          <BusSort
            busSearchResultObj={busSearchResultObj}
            setBusResultsList={setBusResultsList}
          />
        ) : (
          <p>No Buses Available</p>
        )}
      </Modal>
    </>
  );
};

export default BusResults;
