import React, { useState, useContext } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import { useEncLocalStorage } from "../../helpers/useLocalStorage";
import { useLocalStorage } from "../../helpers/useLocalStorage";

const AuthContext = React.createContext();
export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const { updateIsLogin } = useContext(GlobalStatesContext);
  const [user, setUser] = useLocalStorage("user", null);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  const [accessList, setAccessList] = useEncLocalStorage("a_s", null);
  const setChangeLoginFlag = (value) => updateIsLogin("flag", value);
  const setChangeDashboardFlag = (value) => updateIsLogin("role", value);
  const tryLocalSignin = () => {
    try {
      if (accessToken && user) {
        if (user?.Role.RoleId === 1 || user?.Role === 1) {
          let screens = [];
          user.Screens.forEach((element) => {
            screens.push(element.Name);
          });

          setAccessList(screens);
          setChangeDashboardFlag(true);
        } else {
          setChangeLoginFlag(true);
        }
      }
    } catch (error) {}
  };

  return (
    <AuthContext.Provider
      value={{
        tryLocalSignin,
        user,
        setUser,
        accessToken,
        setAccessToken,
        accessList,
        setAccessList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
