import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import ApiClient from "./ApiClient";

const PaymentGateway = ({ data }) => {
  // const [form] = Form.useForm();
  // const [formData, setFormData] = useState({
  //   // OrderId: "string",
  //   // OrderAmount: 0,
  //   // OrderCurrency: "string",
  //   // OrderNote: "string",
  //   // CustomerName: "string",
  //   // CustomerEmail: "string",
  //   // CustomerPhone: "string",
  //   msg: "string",
  // });
  const ProcessUsingCashfree = () => {
    let cashfree_form = document.getElementById("redirectform");
    cashfree_form.submit();
  };
  useEffect(() => {
    // form.submit();
    ProcessUsingCashfree();
  }, []);

  // const submitform = (formpgData) => {
  //   // data.PgType = formpgData.PgType;
  //   data.PgType = 3;
  //   data.DeviceOS = 3;
  //   data.OrderAmount = Number(data.OrderAmount).toFixed(2);
  //   // setFormData({ ...data });

  //   ProcessUsingCashfree();

  //   // ApiClient.post("admin/createPgSignature", data).then((res) => {
  //   //   if (res.status === 200) {
  //   //     setFormData({ ...data, msg: res.data });

  //   //     ProcessUsingCashfree();
  //   //   } else {
  //   //     alert("Error from Api");
  //   //   }
  //   // });
  // };

  return (
    <div>
      {/* <Form form={form} onFinish={submitform}>
        <ANTD.Form.Item
          name="PgType"
          label="Payment Gateway"
          rules={[{ required: true, message: "Select Payment Gateway" }]}
        >
          <ANTD.Radio.Group size="large">
            <ANTD.Radio value="2">Razorpay</ANTD.Radio>
            <ANTD.Radio value="1">Cashfree</ANTD.Radio>
          </ANTD.Radio.Group>
        </ANTD.Form.Item>
        <Button type="primary" htmlType="submit">
          Continue Payment
        </Button>
      </Form> */}

      <form
        id="redirectform"
        method="post"
        // action="https://test.cashfree.com/billpay/checkout/post/submit"
        action={process.env.REACT_APP_BILLDESK_URL}
      >
        <input type="hidden" name="msg" value={data.msg} />
        {/* <input
          type="hidden"
          name="appId"
          value={process.env.REACT_APP_PG_APPID}
        />
        <input type="hidden" name="orderId" value={formData.OrderId} />
        <input type="hidden" name="orderAmount" value={formData.OrderAmount} />
        <input
          type="hidden"
          name="orderCurrency"
          value={formData.OrderCurrency}
        />
        <input type="hidden" name="orderNote" value={formData.OrderNote} />
        <input
          type="hidden"
          name="customerName"
          value={formData.CustomerName}
        />
        <input
          type="hidden"
          name="customerEmail"
          value={formData.CustomerEmail}
        />
        <input
          type="hidden"
          name="customerPhone"
          value={formData.CustomerPhone}
        />
        <input
          type="hidden"
          name="returnUrl"
          // value={`http://101.53.145.46/api/v1/admin/response/pg/redirect`}
          value={`${process.env.REACT_APP_BASE_DOMAIN}/api/v1/admin/response/pg/redirect`}
          //value={`https://trusgo.com/api/v1/admin/response/pg/redirect`}
        />
        <input
          type="hidden"
          name="notifyUrl"
          value=""
          //value={`https://trusgo.com/api/v1/admin/response/pg/redirect`}
        />
        <input type="hidden" name="signature" value={formData.signature} /> */}
      </form>
    </div>
  );
};

export default PaymentGateway;
