import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Offers.scss";
import { Row, Col } from "antd";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";

const Offers = (props) => {
  const { promoType } = props;
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  const getPromoCodes = () => {
    setPromoCode([]);

    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          if (promoType === 0) {
            var data = res.data.map((item, index) => {
              return {
                SNo: index + 1,
                ...item,
                ValidFrom: moment(item.ValidFrom).format(dateFormat),
                ValidTill: moment(item.ValidTill).format(dateFormat),
              };
            });
          } else {
            var data = res.data
              .filter(
                (item) =>
                  item.ServiceType === promoType &&
                  moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
              )
              .map((item, index) => {
                return {
                  SNo: index + 1,
                  ...item,
                  ValidFrom: moment(item.ValidFrom).format(dateFormat),
                  ValidTill: moment(item.ValidTill).format(dateFormat),
                };
              });
          }

          setPromoCode(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    getPromoCodes();
  }, []);
  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {promoCode.length > 0 ? (
        <div className="offer-wrapper mb-4">
          <h3>Offers</h3>

          <Slider {...MobileSlidersettings}>
            {promoCode.map((item, index) => (
              <div className="card offer-card" key={index}>
                <div className="card-body offer-card-body">
                  <h5 className="card-title offer-card-title">
                    CashBack Alert
                  </h5>
                  <Row gutter={12}>
                    <Col md={20}>
                      <p className="card-text offer-card-text">
                        {item.Description}
                      </p>
                      <p className="mb-0">
                        <small>Expire: {item.ValidTill}</small>
                      </p>
                    </Col>
                    <Col md={4}>
                      <img
                        className="offer-card-img"
                        src={
                          item.Image
                            ? `${
                                process.env.REACT_APP_BASE_URL +
                                item.Image.replace("/", "")
                              }`
                            : require("../../assets/images/fbh-1.png")
                        }
                        alt="Card image cap"
                      ></img>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
    </>
  );
};

export default Offers;
