import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Switch,
  Tabs,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import passangerIcon from "../../assets/images/passanger-v1.png";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import ApiClient from "../../helpers/ApiClient";
import BusSearch from "../../pages/Bus/BusSearch/BusSearch";
import HotelSearch from "../../pages/Hotels/HotelSearch/HotelSearch";
import "./FlightSearch.scss";
import Buses from "../buses-search/Buses";
import Hotels from "../Hotels/Hotels";

const { TabPane } = Tabs;
const dateFormat = "DD-MM-YYYY";
const oriDateString = "YYYY-MM-DD";
const { Option } = Select;

const FlightSearch = (props) => {
  const origin = useRef();
  const destination = useRef();
  const fromDateBox = useRef(null);
  const [searchform] = Form.useForm();

  const [adults, setAdults] = useState(1);
  const [child, setChild] = useState(0);
  const [infants, setInfants] = useState(0);
  const [paxError, setPaxError] = useState({
    infantErr: false,
    totalErr: false,
  });

  const [togglePax, setTogglePax] = useState(false);
  const [travel_type, setTravelType] = useState("oneWay");
  const [classType, setClassType] = useState("Economy");
  const departureDate = moment();
  const [arrivalDate, setArrivalDate] = useState(moment());
  const [dataFlip, setDataFlip] = useState(false);

  useEffect(() => {
    if (props.modify) {
      const searchParams = queryString.parse(window.location.search);

      if (searchParams) {
        searchform.setFieldsValue({
          origin: searchParams.origin ? searchParams.origin : "",
          destination: searchParams.destination ? searchParams.destination : "",
          fromDate: searchParams.from
            ? moment(searchParams.from, oriDateString)
            : "",
          toDate: searchParams.to ? moment(searchParams.to, oriDateString) : "",
        });
        let adultCount = searchParams.adult ? Number(searchParams.adult) : 1;
        let childCount = searchParams.child ? Number(searchParams.child) : 0;
        let infantCount = searchParams.infant ? Number(searchParams.infant) : 0;
        setTravelType(searchParams.tripType ?? "oneWay");
        setClassType(searchParams.class ?? "Economy");

        setAdults(adultCount);
        setChild(childCount);
        setInfants(infantCount);
      }
    }
  }, [props.modify]);

  useEffect(() => {
    if (props.reset) {
      searchform.resetFields();
    }
  }, [props.reset]);

  const validateMessages = {
    required: "",
  };

  const handleSwap = () => {
    const origin = searchform.getFieldValue("origin");
    const destination = searchform.getFieldValue("destination");

    if (origin && destination) {
      searchform.setFieldsValue({
        origin: destination,
        destination: origin,
      });
      setDataFlip((prev) => !prev);
    }
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setArrivalDate(momentdate);
    const toDate = searchform.getFieldValue("arrival");
    if (travel_type === "roundTrip" && toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = moment(momentdate).add(2, "days");
        searchform.setFieldsValue({
          arrival: newTodate,
        });
      }
    }
  };

  const onChangePaxCount = (adultCountVal) => {
    if (Number(adults) + Number(child) + Number(infants) > 8) {
      if (
        adultCountVal == "adultInc" ||
        adultCountVal == "childInc" ||
        adultCountVal == "infantInc"
      ) {
        setPaxError((prev) => ({
          ...prev,
          totalErr: true,
        }));
        return;
      }
    } else {
      if (paxError.totalErr) {
        setPaxError((prev) => ({
          ...prev,
          totalErr: false,
        }));
      }
    }
    if (!(Number(infants) < Number(adults))) {
      if (adultCountVal == "infantInc") {
        setPaxError((prev) => ({
          ...prev,
          infantErr: true,
        }));
        return;
      }
    } else {
      if (paxError.infantErr) {
        setPaxError((prev) => ({
          ...prev,
          infantErr: false,
        }));
      }
    }

    switch (adultCountVal) {
      case "adultInc":
        setAdults(adults + 1);

        break;
      case "adultDec":
        if (adults > 1) {
          setAdults(adults - 1);
        } else return "";
        break;
      case "childInc":
        setChild(child + 1);

        break;
      case "childDec":
        if (child >= 1) {
          setChild(child - 1);
        } else return "";
        break;
      case "infantInc":
        setInfants(infants + 1);

        break;
      case "infantDec":
        if (infants >= 1) {
          setInfants(infants - 1);
        } else return "";
        break;
      default:
        break;
    }
  };

  /* Disable the previous dates */
  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(arrivalDate).startOf("day");
  };

  let history = useHistory();

  const onFormSubmit = (data) => {
    let checkCount = Number(adults) + Number(child) + Number(infants);
    if (checkCount > 9) {
      message.error(
        "Total Passengers Cannot Exceed 9. (Adults + Children + Infants) <= 9",
        3
      );
      return;
    }

    let query = {
      origin: data.origin,
      destination: data.destination,
      from: data.fromDate ? moment(data.fromDate).format(oriDateString) : "",
      tripType: travel_type,
      adult: adults,
      child: child,
      class: classType,
      infant: infants,
    };

    if (travel_type === "roundTrip") {
      query.to = data.toDate ? moment(data.toDate).format(oriDateString) : "";
    }

    let queryParams = queryString.stringify(query);

    if (props.modify) {
      history.replace("/flightresults?" + queryParams);
      props.onModify();
    } else {
      history.push("/flightresults?" + queryParams);
    }
  };

  const content = (
    <div className="pax-modal fliht-modal-left">
      <div className="pax-modal-wrapper" id="pax-flight-modal">
        <div className="pax-modal-arrow flight-arrow-v2"></div>
        <li>
          <div className="pax-label">
            <p>Adults</p>
            <span>over 12 years old</span>
          </div>
          <div className="pax-count">
            <i
              className="fa fa-minus"
              aria-hidden="true"
              onClick={(e) => onChangePaxCount("adultDec")}
            ></i>
            <span>{adults}</span>
            <i
              className="fa fa-plus"
              aria-hidden="true"
              onClick={(e) => onChangePaxCount("adultInc")}
            ></i>
          </div>
        </li>

        {adults > 0 ? (
          <li>
            <div className="pax-label">
              <p>Children</p>
              <span>2 - 12 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("childDec")}
              ></i>
              <span>{child}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("childInc")}
              ></i>
            </div>
          </li>
        ) : null}

        {adults > 0 ? (
          <li>
            <div className="pax-label">
              <p>Infants</p>
              <span>upto 2 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("infantDec")}
              ></i>
              <span>{infants}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => onChangePaxCount("infantInc")}
              ></i>
            </div>
          </li>
        ) : null}
        {paxError.infantErr ? (
          <li>
            <span
              style={{
                fontSize: "11px",
                color: "blue",
              }}
            >
              The number of infants cannot be grater than the number of adults.
            </span>
          </li>
        ) : (
          ""
        )}
        {paxError.totalErr ? (
          <li>
            <span
              style={{
                fontSize: "11px",
                color: "blue",
              }}
            >
              {
                "Total Passengers Cannot Exceed 9. (Adults + Children + Infants) <= 9"
              }
              .
            </span>
          </li>
        ) : (
          ""
        )}

        <li className="last-class-li">
          {" "}
          <span className="class-type">Class :</span>
          <Select
            className="top-line"
            autoComplete="newpassword"
            autoComplete="off"
            defaultValue="Economy"
            style={{ maxWidth: "150px", minWidth: "198px" }}
            onChange={handleClassType}
            // value={combineSearchData.classType}
          >
            <Option value="premiumEconomy">Premium Economy</Option>
            <Option value="Business">Business</Option>
            <Option value="premiumFirst">Premium First</Option>
            <Option value="Economy">Economy</Option>
          </Select>
        </li>
        <Button
          block
          className="pax-ready-btn"
          onClick={() => setTogglePax(false)}
        >
          Done
        </Button>
      </div>
    </div>
  );

  const handleClassType = (val) => {
    setClassType(val);
  };
  const handleOnSubmit = (ref) => {
    ref.current.focus();
  };
  const updateTravelType = (e) => {
    if (e.target) {
      if (e.target.value === "oneWay") {
        searchform.setFieldsValue({
          toDate: "",
        });
      }
      setTravelType(e.target.value);
    } else {
      setTravelType(e);
    }
  };
  return (
    <div className="combined-search-block">
      <Helmet>
        {/* <title>Book Best Flights</title> */}
        <meta
          name="description"
          content=" Get cheap flight tickets with a best tour & travel company of India- Trippozo"
        />
      </Helmet>
      <div className="search-block ">
        <div className="search-body">
          <Tabs defaultActiveKey="1" className="overall-tabs-v3" centered>
            <TabPane
              tab={
                <span>
                  <i className="fa fa-plane" aria-hidden="true"></i>&nbsp;
                  Flights
                </span>
              }
              key="1"
            >
              <Form
                name="search-form"
                form={searchform}
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                className="home-search-form"
                // initialValues={{ stopOver: true }}
              >
                <div className="class-container">
                  <Row>
                    <Col md={16} xs={24}>
                      <div>
                        <Radio.Group
                          onChange={updateTravelType}
                          value={travel_type}
                        >
                          <Radio
                            className={`bdr-icon-f ${
                              travel_type === "oneWay" ? "tripTypeActive" : ""
                            }`}
                            value={"oneWay"}
                          >
                            One way
                          </Radio>
                          <Radio
                            className={`bdr-icon-f ${
                              travel_type === "roundTrip"
                                ? "tripTypeActive"
                                : ""
                            }`}
                            value={"roundTrip"}
                          >
                            Round trip
                          </Radio>
                          {/* <Radio value={"multidestination"}>Multi-city</Radio> */}
                        </Radio.Group>
                      </div>
                    </Col>
                    <Col md={8} xs={24} className="class-v3-flight">
                      <span className="class-type">Class :</span>
                      <Select
                        className="top-line"
                        autoComplete="newpassword"
                        autoComplete="off"
                        defaultValue="Economy"
                        style={{ maxWidth: "150px", minWidth: "100px" }}
                        // onChange={onHandleClass}
                        onChange={handleClassType}
                      >
                        <Option value="premiumEconomy">Premium Economy</Option>
                        <Option value="Business">Business</Option>
                        <Option value="premiumFirst">Premium First</Option>
                        <Option value="Economy">Economy</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                <Row gutter={8} className="search-row row-from-background">
                  <Col lg={6} md={11} xs={24}>
                    <div className="from_wrapper">
                      <div className="icon_wrapper">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                      <AirportAutoComplete
                        formItemProps={{
                          name: "origin",

                          rules: [
                            {
                              required: true,
                              message: "Please Specify Source City",
                            },
                          ],
                        }}
                        selectProps={{
                          size: "large",
                          placeholder: "Select Source",
                          onSelect: (value) => {
                            // handleCityName(value, "FROM");
                            handleOnSubmit(destination);
                          },
                        }}
                        refName={origin}
                        focusRef={destination}
                        dataFlip={dataFlip}
                      />
                    </div>
                  </Col>

                  <div className="swap-v33-one">
                    <div className="swap-icon-v3" onClick={handleSwap}>
                      <img
                        className="v3-round-icon"
                        src={require("../../assets/images/icon-bar-v3.png")}
                      />
                    </div>
                  </div>

                  <Col lg={6} md={11} xs={24}>
                    <div className="from_wrapper">
                      <div className="icon_wrapper">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                      <AirportAutoComplete
                        formItemProps={{
                          name: "destination",
                          rules: [
                            {
                              required: true,
                              message: "Please Specify Destination City",
                            },
                          ],
                        }}
                        selectProps={{
                          size: "large",
                          placeholder: "Select Destination",
                          onSelect: (value) => {
                            // handleCityName(value, "TO");
                            handleOnSubmit(fromDateBox);
                          },
                        }}
                        refName={destination}
                        dataFlip={dataFlip}
                        focusRef={fromDateBox}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={12} xs={24}>
                    <Form.Item
                      name="fromDate"
                      rules={[
                        {
                          required: true,
                          message: "Please Specify Start Date",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        allowClear={false}
                        size="large"
                        className="search-inputs from-return-date"
                        placeholder="Journey Date"
                        ref={fromDateBox}
                        format={dateFormat}
                        disabledDate={disabledOriginDate}
                        onChange={(date, dateString) =>
                          onChangeOriginDate(date, dateString)
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={3} md={12} xs={24}>
                    <Form.Item
                      name="toDate"
                      rules={[
                        {
                          required: travel_type === "roundTrip" ? true : false,
                          message: "Please Specify Return Date",
                        },
                      ]}
                    >
                      <DatePicker
                        size="large"
                        className="search-inputs from-return-date"
                        placeholder="Return Date"
                        format={dateFormat}
                        disabledDate={disabledDestDate}
                        onChange={(date) => {
                          if (!date) {
                            searchform.setFieldsValue({
                              arrival: "",
                            });
                            setTravelType("oneWay");
                          }
                        }}
                        disabled={travel_type != "roundTrip" ? true : false}
                        onClick={() => {
                          if (travel_type != "roundTrip") {
                            setTravelType("roundTrip");
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={2} md={12} xs={24} className="from-to-inputs">
                    <Form.Item className="flight-passenger">
                      <Popover
                        className="paxmodal"
                        overlayClassName="paxpopup"
                        placement="bottomRight"
                        title={null}
                        content={content}
                        trigger="click"
                        visible={togglePax}
                        onVisibleChange={() => {
                          setTogglePax((prev) => !prev);
                        }}
                      >
                        <Input
                          autoComplete="newpassword"
                          autoComplete="off"
                          className="adult-age-per"
                          size="large"
                          prefix={
                            <img
                              onClick={() => {
                                setTogglePax((prev) => !prev);
                              }}
                              className="passangericon"
                              src={passangerIcon}
                              alt="img"
                            />
                          }
                          value={adults + child + infants}
                          readOnly
                          style={{ cursor: "pointer" }}
                        />
                      </Popover>
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={12} xs={24}>
                    <Button
                      size="large"
                      htmlType="submit"
                      className="search-inputs tg-primary-btn"
                      // onClick={navigateToResults}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
                {/* <Row className="pre-airline">
                  <Col lg={6} md={12} xs={24}>
                    <div className="v3-air-icon">
                      <div className="v3-place-icon">
                        <SearchOutlined />
                      </div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Search Preferred Airline",
                          },
                        ]}
                      >
                        <Input placeholder="Search Preferred Airline" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={6} md={12} xs={24} className="pdr-top-1">
                    <Form.Item name="stopOver" >
                      <Switch />
                      <span className="flv-switch">Direct Flights</span>
                    </Form.Item>
                  </Col>
                </Row> */}
              </Form>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fa fa-bed" aria-hidden="true"></i>
                  &nbsp;Hotel
                </span>
              }
              key="2"
            >
              <Row className="pre-airline">
                <Col lg={24} md={24} xs={24}>
                  <Hotels />
                </Col>
              </Row>

              <Row className="pre-airline">
                <Col lg={6} md={12} xs={24}>
                  <div className="v3-air-icon">
                    <div className="v3-place-icon">
                      <SearchOutlined />
                    </div>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Search Preferred Airline",
                        },
                      ]}
                    >
                      <Input placeholder="Hotel Name" />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  lg={6}
                  md={12}
                  xs={24}
                  className="pdr-top-1 start-hotel-vale"
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Search Preferred Airline",
                      },
                    ]}
                  >
                    <Select defaultValue="Hotel Rating" style={{ width: 108 }}>
                      <Option value="Hotel Rating">Hotel Rating</Option>
                      <Option value="1">1 Star</Option>
                      <Option value="1">2 Star</Option>
                      <Option value="1">3 Star</Option>
                      <Option value="1">4 Star</Option>
                      <Option value="1">5 Star</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={
                <span>
                  <i className="fa fa-bus" aria-hidden="true"></i>
                  &nbsp;Bus
                </span>
              }
              key="3"
            >
              <Buses modifySearch={false} busesSearchAPI={() => {}} />
            </TabPane>
          </Tabs>
        </div>
      </div>
      {/* --block---close--- */}
    </div>
  );
};

export default FlightSearch;
