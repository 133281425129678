import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
} from "antd";

// import TicketBus from "./TicketBus";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
import getBusTicketPrice from "./busHelper";
import TicketSidebar from "../TicketSidebar";
import { LoadingOutlined } from "@ant-design/icons";
import BusTicket from "./BusTicket";
import "./busTicketScreen.scss";

const { Panel } = Collapse;

const BusTicketScreen = () => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);

  const initialState = {
    ArrivalTime: "",
    JourneyDate: "",
    ProviderBookingId: "",
    boardingPoint: "",
    bookingRefNo: "",
    bookingStatus: "",
    busTypeName: "",
    cancellable: false,
    cancellationPolicy: "",
    collectedFare: 0,
    convienenceData: {},
    departureTime: "",
    destinationName: "",
    droppingPoint: "",
    emailId: "",
    markup: "0",
    mobileNo: "",
    noOfSeats: 0,
    operator: "",
    partialCancellationAllowed: "",
    passengerInfo: [],
    pnr: "",
    promoData: {},
    refundAmount: "",
    sourceName: "",
  };

  const [ticketData, setTicketData] = useState(initialState);
  const [loadingTicket, setLoadingTicket] = useState(true);
  let { grandTotal } = getBusTicketPrice(ticketData);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const getTicketDetails = () => {
    let ref = ticketSearchParams.ref;
    let email = ticketSearchParams.email;
    setLoadingTicket(true);

    APIClient.get("buses/ticketDetails/?refNo=" + ref + "&email=" + email)
      .then((res) => {
        if (res.statusCode == 200) {
          setTicketData(res.data);
        } else {
          message.error(res.message, 3);
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setLoadingTicket(false);
        console.error(error);
      });
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  const CancellationPolicy = (data) => {
    const policyStringArray = data.split(";");
    return (
      <div className="cancellation-wrapper">
        <ul>
          <li>
            <p>
              <b> Time before Departure </b>
            </p>
            <p>
              <b>Refund %</b>
            </p>
          </li>
          {policyStringArray.map((data, index) => {
            let val = data.split(":");
            if (index === 0) {
              return (
                <li key={index + "policy1"}>
                  <p>{`With in ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else if (index === policyStringArray.length - 1) {
              return (
                <li key={"policy2" + index}>
                  <p>{`Above ${val[0]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            } else {
              return (
                <li key={"pol3" + index}>
                  <p>{`Between ${val[0]} to ${val[1]} Hrs`} </p>
                  <p>{`${val[2]}%`}</p>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  };

  const submitCancelForm = (val) => {
    let selectedSeat = ticketData.passengerInfo.map((seat) => seat.seatNos);
    let canceledSeat = val.seatNo;
    setLoading(true);
    let isCancelled = false;
    ticketData.partialCancellationAllowed == "false"
      ? selectedSeat.length === canceledSeat.length
        ? (isCancelled = true)
        : message.error(
            "Partial Cancellation is not allowed for this Bus",
            3
          ) && setLoading(false)
      : (isCancelled = true);
    if (ticketData.emailId == "" && ticketData.bookingRefNo == "") {
      return;
    }
    let data = {
      email: ticketData.emailId,
      seatNos: val.seatNo.toString(),
      referenceNo: ticketData.bookingRefNo,
    };
    if (isCancelled) {
      APIClient.post("buses/cancelTicket/", data)
        .then((res) => {
          if (res.statusCode == 200) {
            message.success(
              `${res.data.Message}. Your total refund amount is Rs. ${res.data.TotalRefundAmount} `,
              10
            );

            getTicketDetails(ticketSearchParams.ref);
            setModalVisible(false);

            form.resetFields();
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  useEffect(() => {
    getTicketDetails();
  }, []);

  return (
    <div className="flight-ticket-collapse">
      <Card>
        {loadingTicket ? (
          <div className="fligh-ticket-container">
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          </div>
        ) : ticketData.bookingStatus === "Booked" ||
          ticketData.bookingStatus === "Cancelled" ||
          ticketData.bookingStatus === "PartiallyCancelled" ? (
          <div className="fligh-ticket-container">
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum">
                  {/* <Collapse
                    bordered={true}
                    className="collapsed-data"
                    defaultActiveKey={["1"]}
                    accordion={true}
                  >
                    <Panel key="1"> */}
                  <BusTicket ticketData={ticketData} />
                  {/* </Panel>
                  </Collapse> */}
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Bus"
                      onCancelTicket={cancelTicket}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Bus"
                          onCancelTicket={cancelTicket}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {ticketData.bookingStatus === "Booked" ||
        ticketData.bookingStatus === "Cancelled" ||
        ticketData.bookingStatus === "PartiallyCancelled" ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> : {ticketData.bookingRefNo}
            </p>
            <p>
              <b>Journey Details</b> : {ticketData.sourceName} <b>TO </b>
              {ticketData.destinationName} on {ticketData.JourneyDate} (
              {ticketData.operator} - {ticketData.busTypeName} )
            </p>
            <p>
              <b>Total Fare</b> : <i className="fa fa-inr" aria-hidden="true" />{" "}
              {grandTotal}
            </p>
            <p className="text-center">
              <b>Cancellation Policy</b>
            </p>
            {CancellationPolicy(ticketData.cancellationPolicy)}
            <p>
              <b>Select Seat No.</b>
            </p>
            <Form layout="inline" form={form} onFinish={submitCancelForm}>
              <Form.Item
                label=" "
                name="seatNo"
                rules={[{ required: true, message: "Please Select Seat No" }]}
              >
                <Checkbox.Group>
                  <Row>
                    {ticketData.passengerInfo.length > 0
                      ? ticketData.passengerInfo.map((item, i) => (
                          <Checkbox key={i} value={item.seatNos}>
                            {item.seatNos}
                          </Checkbox>
                        ))
                      : ""}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
export default BusTicketScreen;
