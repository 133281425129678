import React from "react";
import * as ANTD from "antd";

const PersonalInfo = () => {
  return (
    <ANTD.Row gutter="16">
      <ANTD.Col className="gutter-class" md={6} xs={24}>
        <ANTD.Form.Item
          label="First Name"
          name="FirstName"
          rules={[
            {
              required: true,
              message: "Required Field",
            },
          ]}
        >
          <ANTD.Input
            autoComplete="newpassword"
            autoComplete="off"
            placeholder="Enter First Name"
          />
        </ANTD.Form.Item>
      </ANTD.Col>
      <ANTD.Col className="gutter-class" md={6} xs={24}>
        <ANTD.Form.Item
          label="Last Name"
          name="LastName"
          rules={[
            {
              required: true,
              message: "Required Field",
            },
          ]}
        >
          <ANTD.Input
            autoComplete="newpassword"
            autoComplete="off"
            placeholder="Enter Last Name"
          />
        </ANTD.Form.Item>
      </ANTD.Col>
      <ANTD.Col className="gutter-class" md={6} xs={24}>
        <ANTD.Form.Item label="Phone Number" name="Phone">
          <ANTD.Input autoComplete="newpassword" autoComplete="off" disabled />
        </ANTD.Form.Item>
      </ANTD.Col>
      <ANTD.Col className="gutter-class" md={6} xs={24}>
        <ANTD.Form.Item label="Email" name="Email">
          <ANTD.Input autoComplete="newpassword" autoComplete="off" disabled />
        </ANTD.Form.Item>
      </ANTD.Col>
    </ANTD.Row>
  );
};

export default PersonalInfo;
