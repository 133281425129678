import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import { CloseOutlined } from "@ant-design/icons";
import { exportExcel } from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";

const { Content } = ANTD.Layout;

function FlightEnquires() {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [flightEnquires, setFlightEnquires] = useState([]);
  const [tableData, setTableData] = useState([]);

  const GetFlightEnquires = () => {
    setFlightEnquires([]);
    setTableData([]);
    ApiClient.get("admin/flightEnquiry")
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
              ReturnDate: item.ReturnDate
                ? moment(item.ReturnDate).format("DD-MM-YYYY")
                : null,
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setFlightEnquires(data);
          setTableData(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    GetFlightEnquires();
  }, []);

  const tableColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "Flight Enquiry ID",
      dataIndex: "FlightEnquiryID",
      sorter: (a, b) => a.FlightEnquiryID - b.FlightEnquiryID,
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
      sorter: (a, b) => a.ContactNumber.localeCompare(b.ContactNumber),
    },
    {
      title: "Trip Type",
      dataIndex: "TripType",
    },
    {
      title: "Source City",
      dataIndex: "SourceCity",
      sorter: (a, b) => a.SourceCity.localeCompare(b.SourceCity),
    },
    {
      title: "Destination City",
      dataIndex: "DestinationCity",
      sorter: (a, b) => a.DestinationCity.localeCompare(b.DestinationCity),
    },
    {
      title: "Prefered Class",
      dataIndex: "PreferredClass",
      sorter: (a, b) => a.PreferredClass.localeCompare(b.PreferredClass),
    },
    {
      title: "Departure Date",
      dataIndex: "DepartureDate",
      sorter: (a, b) => a.DepartureDate.localeCompare(b.DepartureDate),
    },

    {
      title: "Return Date",
      dataIndex: "ReturnDate",
      sorter: (a, b) =>
        a.ReturnDate && b.ReturnDate
          ? a.ReturnDate.localeCompare(b.ReturnDate)
          : "",
    },

    {
      title: "OnwardFlightNumber",
      dataIndex: "OnwardFlightNumber",
      sorter: (a, b) =>
        a.OnwardFlightNumber.localeCompare(b.OnwardFlightNumber),
    },
    {
      title: "OnwardFilghtName",
      dataIndex: "OnwardFilghtName",
      sorter: (a, b) => a.OnwardFilghtName.localeCompare(b.OnwardFilghtName),
    },
    {
      title: "ReturnFlightNumber",
      dataIndex: "ReturnFlightNumber",
      sorter: (a, b) =>
        a.ReturnFlightNumber && b.ReturnFlightNumber
          ? a.ReturnFlightNumber.localeCompare(b.ReturnFlightNumber)
          : "",
    },
    {
      title: "ReturnFilghtName",
      dataIndex: "ReturnFilghtName",
      sorter: (a, b) =>
        a.ReturnFilghtName && b.ReturnFilghtName
          ? a.ReturnFilghtName.localeCompare(b.ReturnFilghtName)
          : "",
    },
    {
      title: "NumberOfPassangers",
      dataIndex: "NumberOfPassengers",
      sorter: (a, b) => a.NumberOfPassengers - b.NumberOfPassengers,
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
      sorter: (a, b) => a.Remarks.localeCompare(b.Remarks),
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
      sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
    },
    {
      title: "ModifiedDate",
      dataIndex: "ModifiedDate",
      sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableData.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(flightEnquires);
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
    setTableData(flightEnquires);
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    const excelData = tableData;

    exportExcel(excelData, "FlightEnquiresDetails");
  };

  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  return (
    <ANTD.Layout>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Flight Enquires</h5>
                      <p>{tableData.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          autoComplete="newpassword"
                          autoComplete="off"
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => GetFlightEnquires()}
                      />
                    </div>
                  </div>

                  <ANTD.Table
                    scroll={{ x: true }}
                    bordered
                    dataSource={tableData}
                    columns={tableColumns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                    rowKey={keyGenerate}
                  />
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
    </ANTD.Layout>
  );
}

export default FlightEnquires;
