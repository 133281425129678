import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Row,
  Modal,
  message,
  Skeleton,
  Radio,
} from "antd";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import { CloseOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import { getFlightGrandTotalPrice } from "./flightPriceHelper";

export const getPromoDiscount = (promoData, total) => {
  if (promoData.DiscountType == 1) {
    return parseFloat((total / 100) * promoData.Discount);
  } else {
    return parseFloat(promoData.Discount);
  }
};

export const getConvieneceFeeValue = (ConvFee, total) => {
  let convamount = 0;
  if (ConvFee.type == 0) {
    convamount = parseFloat(ConvFee.amount);
  } else {
    convamount = parseFloat(
      (total / 100) * parseFloat(ConvFee.amount ? ConvFee.amount : 0)
    );
  }
  return convamount;
};

export const FlightGrandTotalCard = ({
  airPriceResp,
  flightSearchObj,
  PromoNotVisible,
}) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { user } = useAuthContext();
  const [promoform] = Form.useForm();
  const [radioForm] = Form.useForm();
  const globalContext = useContext(GlobalStatesContext);
  const { promoData } = globalContext.state.otherData;
  const { ConvFee } = globalContext.state.otherData;

  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  useEffect(() => {
    globalContext.AddConvFee(1);
    getpromo();
    globalContext.RemovePromo();
  }, []);

  let { convamount, discount, grandTotal } = getFlightGrandTotalPrice(
    airPriceResp,
    ConvFee,
    promoData,
    flightSearchObj
  );

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );

          if (data.length > 0) {
            let flightPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
              // &&
              // (promo.airlineCode ===
              //   airPriceResp.flightDetails[0].flightSegments[0]
              //     .marketingAirline ||
              //   promo.airlineCode === "ALL")
            );

            setPromoCode(flightPromo);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setPromoCode([]);
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleApply = (code) => {
    promoform.setFieldsValue({
      promo: code,
    });

    promoform.submit();
  };

  return (
    <>
      <Card className="flight-cards-details">
        <Row justify={"space-between"} className="grand_total_card_row">
          <Col>
            <p>Convenience Fee</p>
          </Col>
          <Col>
            <p>
              {ConvFee.type == 1
                ? parseFloat(ConvFee.amount).toFixed(2) + " %"
                : "₹ " + parseFloat(ConvFee.amount).toFixed(2)}
            </p>
          </Col>
        </Row>
        <Row justify={"space-between"} className="grand_total_card_row">
          <Col>
            <p>Discount</p>
          </Col>
          <Col>
            <p>
              {promoData.DiscountType == 1
                ? promoData.Discount + " %"
                : "₹ " + Number(promoData.Discount).toFixed(2)}
            </p>
          </Col>
        </Row>

        <div className="pax-total-price">
          <div className="pax-type">
            <p>Grand Total:</p>
          </div>
          <div className="total">
            <p>₹</p>
            &nbsp;
            <p className="amount">{grandTotal}</p>
          </div>
        </div>
      </Card>
      {!PromoNotVisible ? (
        <div className="promo-card apply-promo-fbt">
          {promoData.status == false ? (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p className="name">Apply Promo</p>
              </div>
              <div className="promo-input">
                <Form
                  name="customized_form_controls"
                  layout="inline"
                  form={promoform}
                  onFinish={(d) => {
                    globalContext.validatePromoCode({
                      ServiceType: 1,
                      Amount: Number(grandTotal),
                      PromoCode: d.promo,
                      UserId: user?.UserID ?? 1,
                    });
                  }}
                >
                  <Form.Item name="promo" className="promo-input-item">
                    <Input
                      autoComplete="newpassword"
                      autoComplete="off"
                      className="contacts-value-de"
                      placeholder="Enter Your Promo code"
                      // onClick={() => handleFocus()}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item className="promo-input-btn">
                    <Button
                      className="apply-promo-value-code"
                      type="primary"
                      htmlType="submit"
                    >
                      Apply
                    </Button>
                  </Form.Item>
                </Form>
              </div>

              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <div className="promo-cp-coupons" key={key}>
                      <div className="inline-cp-promo">
                        <Form>
                          <Form.Item>
                            <Radio
                              onClick={() => handleApply(item.Code)}
                            ></Radio>
                          </Form.Item>
                        </Form>
                        <p className="promo-key-cp">{item.Code}</p>
                        {item.DiscountType === 1 ? (
                          <p className="save-cp-offer">
                            Save {item.Discount + " %"}
                          </p>
                        ) : (
                          <p className="save-cp-offer">
                            Save <i className="fa fa-inr" />
                            &nbsp;
                            {item.Discount}
                          </p>
                        )}
                      </div>

                      <div className="promo-percentage-cp">
                        <p>{item.Description}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      globalContext.RemovePromo();
                      promoform.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p>
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
    </>
  );
};
