import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Layout,
  message,
  Select,
} from "antd";
import { Tooltip } from "antd";
import {
  StarOutlined,
  InfoCircleOutlined,
  StarTwoTone,
} from "@ant-design/icons";
import "../Greatdeals/Greatdeals.scss";
import { SwapOutlined } from "@ant-design/icons";
const Greatdeals = () => {
  const texticon = (
    <span>
      Basis of comparison is calculated by combining similar flights (same
      route, booking class, trip type and similar days to departure) previously
      searched on CheapOair (and related sites) for the previous month and the
      similar season last year.
    </span>
  );
  return (
    <div className="promo providerapi">
      <div className="directly-flight-ofc-deals">
        <Row>
          <Col md={24} xs={24}>
            <p className="trip-found-v3">
              Found these great deals for your next trip
            </p>
          </Col>
        </Row>

        <Row className="item-delas-v3">
          <Col md={8} xs={24} className="item-delas-cols">
            <div className="deals-v3-1">
              <div>
                <i className="fa fa-tag tags-v3-1" aria-hidden="true"></i>
              </div>
              <Row>
                <Col md={12} xs={12}>
                  <div className="deals-v3-card">
                    <p>$877.53</p>
                    <span>round trip</span>
                  </div>
                </Col>
                <Col md={12} xs={12}>
                  <div className="deals-v3-card-1">
                    <p>
                      HYD&nbsp;
                      <SwapOutlined />
                      &nbsp;NYC
                    </p>
                    <span>Jan 31 - Feb 07</span>
                  </div>
                  <div className="icon-post-v2">
                    <p>
                      <i
                        className="fa fa-chevron-circle-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={24} xs={24}>
                  <div className="deals-range-v1">
                    <div className="range-1 inline-v1"></div>
                    <div className="range-2 inline-v2"></div>
                    <div className="range-2 inline-v3"></div>
                    <div className="range-2 inline-v4"></div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={24} xs={24}>
                  <p className="calulate-p">
                    How we calculate this&nbsp;&nbsp;
                    <Tooltip title={texticon} className="tooltip-app1">
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={8} xs={24} className="item-delas-cols">
            <div className="deals-v3-1">
              <div>
                <i className="fa fa-tag tags-v3-1" aria-hidden="true"></i>
              </div>
              <Row>
                <Col md={12} xs={12}>
                  <div className="deals-v3-card">
                    <p>$877.53</p>
                    <span>round trip</span>
                  </div>
                </Col>
                <Col md={12} xs={12}>
                  <div className="deals-v3-card-1">
                    <p>
                      HYD&nbsp;
                      <SwapOutlined />
                      &nbsp;NYC
                    </p>
                    <span>Jan 31 - Feb 07</span>
                  </div>
                  <div className="icon-post-v2">
                    <p>
                      <i
                        className="fa fa-chevron-circle-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={24} xs={24}>
                  <div className="deals-range-v1">
                    <div className="range-1 inline-v1"></div>
                    <div className="range-2 inline-v2"></div>
                    <div className="range-2 inline-v3"></div>
                    <div className="range-2 inline-v4"></div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={24} xs={24}>
                  <p className="calulate-p">
                    How we calculate this&nbsp;&nbsp;
                    <Tooltip title={texticon} className="tooltip-app1">
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={8} xs={24} className="item-delas-cols">
            <div className="deals-v3-1">
              <div>
                <i className="fa fa-tag tags-v3-1" aria-hidden="true"></i>
              </div>
              <Row>
                <Col md={12} xs={12}>
                  <div className="deals-v3-card">
                    <p>$877.53</p>
                    <span>round trip</span>
                  </div>
                </Col>
                <Col md={12} xs={12}>
                  <div className="deals-v3-card-1">
                    <p>
                      HYD&nbsp;
                      <SwapOutlined />
                      &nbsp;NYC
                    </p>
                    <span>Jan 31 - Feb 07</span>
                  </div>
                  <div className="icon-post-v2">
                    <p>
                      <i
                        className="fa fa-chevron-circle-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={24} xs={24}>
                  <div className="deals-range-v1">
                    <div className="range-1 inline-v1"></div>
                    <div className="range-2 inline-v2"></div>
                    <div className="range-2 inline-v3"></div>
                    <div className="range-2 inline-v4"></div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={24} xs={24}>
                  <p className="calulate-p">
                    How we calculate this&nbsp;&nbsp;
                    <Tooltip title={texticon} className="tooltip-app1">
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Greatdeals;
