import React, { useState } from "react";
import hotelNoImage from "../../assets/images/hotels/no_img_v1.png";

const CustomImg = ({ src, className }) => {
  const [showImage, setShowImage] = useState(true);

  return (
    <img
      src={showImage ? src : hotelNoImage}
      style={{ height: "100%" }}
      alt="image"
      className={className}
      onError={() => setShowImage(false)}
    />
  );
};

export default CustomImg;
