//#region  new code
import React, { useEffect, useState } from "react";
import { Button, Form, message, Select, Tooltip } from "antd";
import * as ReactBootstrap from "react-bootstrap";
import CustomNoResultFound from "../../../../components/ErrorPages/CustomNoResultFound.jsx";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../common/providers/AuthProvider";
import ApiClient from "../../../../helpers/ApiClient";
import { MinutesToHours } from "./../../../../helpers/MinutesToHours";

import CancellationPolicy from "./CancellationPolicy";
import "./BusResultCards.scss";
const { Option } = Select;

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const BusCard = ({
  updateSelectedBusData,
  trips,
  searchId,
  otherData,
  showNetFare,
  journeyDate,
}) => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [cardsData, setCardsData] = useState([]);
  const { origin, destination } = otherData;
  const [selectedSeats, setSelectedSeats] = useState({});
  const [selectedPriceFilter, setSelectedPriceFilter] = useState("All");
  let history = useHistory();

  const setFilteredCardData = (data) => {
    if (data) {
      let FilteredData = data.filter((item) => item.isVisible);
      setCardsData(FilteredData);
    }
  };
  const bookingBusData = (selectionObj) => {
    try {
      let remainingDataObj = {
        seatsInfo: selectedSeats[selectionObj.busCardId],
        fareInfo: fareValues[selectionObj.busCardId],
      };
      updateSelectedBusData({
        ...selectionObj,
        ...remainingDataObj,
        searchId: searchId,
        origin: origin,
        destination: destination,
        journeyDate: journeyDate,
      });
      form.resetFields();
      sessionStorage.removeItem("BusPassengersDetails");
      sessionStorage.removeItem("BusPaxFormData");
    } catch (e) {
      console.log(e);
    }

    history.push("/bus-checkout");
  };
  const [fareValues, setFareValues] = useState({});
  const [activeTab, setActiveTab] = useState({});
  const [selectedBoardingPoint, setSelectedBoardingPoint] = useState({});
  const [selectedDroppingPoint, setSelectedDroppingPoint] = useState({});

  const [selectTrip, setSelectTrip] = useState({});

  const getSortedSeatNumbers = (busCardId) => {
    return selectedSeats[busCardId].length > 0
      ? selectedSeats[busCardId]
          .sort((a, b) => a.SeatNo - b.SeatNo)
          .map((obj) => obj.SeatNo)
          .join(", ")
      : "";
  };

  const calculateFares = () => {
    let fareObj = {};
    for (const property in selectedSeats) {
      let BaseFare = selectedSeats[property].reduce(
        (acc, value) => Number(value.NetFare) + Number(acc),
        0
      );

      /* Calculating total GST fare  */
      let OperatorServiceCharge = selectedSeats[property].reduce(
        (acc, value) => Number(value.OperatorServiceCharge) + Number(acc),
        0
      );

      let Servicetax = selectedSeats[property].reduce(
        (acc, value) => Number(value.Servicetax) + Number(acc),
        0
      );

      let commission = selectedSeats[property].reduce(
        (acc, value) => Number(value.commission) + Number(acc),
        0
      );

      let gst = Number(OperatorServiceCharge) + Number(Servicetax);

      /* Calculating both base and GST fares  */

      fareObj[property] = {
        baseFare: Number(BaseFare).toFixed(2),
        gst: Number(gst).toFixed(2),
        commission: Number(commission).toFixed(2),
        totalFare: Number(BaseFare + gst).toFixed(2),
      };
    }
    setFareValues(fareObj);
  };

  useEffect(() => {
    if (selectedSeats !== {}) calculateFares();
  }, [selectedSeats]);

  useEffect(() => {
    setFilteredCardData(trips);
    if (activeTab !== {}) {
      setActiveTab({});
      setSelectTrip({});
      setSelectedSeats({});
    }
  }, [trips]);

  const onHandleSeatLayout = (busId) => {
    setSelectTrip({});

    let seatLayoutParams = {
      searchId: searchId,
      tripId: busId,
      userId: user?.UserID ?? 1,
      roleType: user?.Role ?? 4,
      membership: user?.Membership ?? 1,
    };
    ApiClient.post("buses/tripAvailability", seatLayoutParams)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          const seatsArray = resp.data.seats;

          const lowerBerthArray = seatsArray.filter((Obj) => Obj.zIndex === 0);

          const upperBerthArray = seatsArray.filter((Obj) => Obj.zIndex === 1);
          setSelectTrip({ ...resp, lowerBerthArray, upperBerthArray });
        }
      })
      .catch((err) => {});
  };

  function groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];

      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const PriceFilters = () => {
    const seatsArray = selectTrip.data.seats;
    let seatsData = seatsArray.filter(
      (item) =>
        item.isAvailableSeat === "true" || item.isAvailableSeat === "True"
    );
    var objres = groupBy(seatsData, "fare");

    let groupByPrice = objres.map((item, i) => {
      if (item.length > 0) {
        let { fare } = item[0];
        return {
          fare,
          count: item.length,
        };
      }
    });

    return (
      <>
        {groupByPrice && Object.keys(groupByPrice).length > 1 ? (
          <div className="pricefilter">
            <Button
              className={`filterbtn ${
                selectedPriceFilter == "All" ? "active" : ""
              }`}
              onClick={() => setSelectedPriceFilter("All")}
            >
              All
              <span className="seatcount"> ({seatsData.length}) </span>
            </Button>

            {groupByPrice.map((item, i) => (
              <Button
                key={i}
                className={`filterbtn ${
                  selectedPriceFilter == item.fare ? "active" : ""
                }`}
                onClick={() => setSelectedPriceFilter(item.fare)}
              >
                ₹{item.fare} <span className="seatcount"> ({item.count})</span>
              </Button>
            ))}
          </div>
        ) : (
          <div className="emptyspace"></div>
        )}
      </>
    );
  };

  const SeatLayout = (data) => {
    const busCardId = data.busCardId;

    const lowerBerthArray = selectTrip.lowerBerthArray;
    const upperBerthArray = selectTrip.upperBerthArray;

    let uniqueseat = [...new Set(lowerBerthArray.map((col) => col.column))];

    let lowerLayoutObj = [];

    let maxRowValueLower = Math.max.apply(
      Math,
      lowerBerthArray.map((o) => {
        return o.row;
      })
    );
    let maxColValueLower = Math.max.apply(
      Math,
      lowerBerthArray.map((o) => {
        return o.column;
      })
    );
    // let maxRows = [];
    // for (let i = 0; i <= maxColValueLower; i++) {
    //   maxRows.push(i);
    // }

    let maxColumns = [];
    maxColumns = uniqueseat.sort((a, b) => a - b);

    for (let index = 0; index <= maxRowValueLower; index++) {
      lowerLayoutObj.push(lowerBerthArray.filter((obj) => obj.row === index));
    }

    let upperLayoutObj = [];
    let maxRowValueUpper = Math.max.apply(
      Math,
      upperBerthArray.map((o) => {
        return o.row;
      })
    );

    let maxColValueUpper = Math.max.apply(
      Math,
      upperBerthArray.map((o) => {
        return o.column;
      })
    );

    let uniqUperColumn = [...new Set(upperBerthArray.map((col) => col.column))];

    for (let index = 0; index <= maxRowValueUpper; index++) {
      upperLayoutObj.push(upperBerthArray.filter((obj) => obj.row === index));
    }
    let isSeatingRow = false;
    let isSeat = false;
    let isSleepingRow = false;
    let isVerticalRow = false;
    let seatClassName = "";
    // let maxUpRows = [];
    let maxUpColumns = [];
    maxUpColumns = uniqUperColumn.sort((a, b) => a - b);
    // for (let i = 0; i <= maxColValueUpper; i++) {
    //   maxUpRows.push(i);
    // }

    const getSeatClass = (
      busCardId,
      seatNo,
      IsAvailable,
      IsLadiesSeat,
      IsLadiesSeatReserv,
      fare,
      seatType
    ) => {
      let value = "";

      let key = getSeatStatus(
        busCardId,
        seatNo,
        IsAvailable,
        IsLadiesSeat,
        IsLadiesSeatReserv
      );
      switch (key) {
        case "booked":
          value =
            seatType === "seat"
              ? "Booked-seat-type-image no-pointer-events"
              : "booked-sleeper-type-image no-pointer-events";
          break;
        case "selected":
          value =
            seatType === "seat"
              ? "selected-seat-type-image"
              : "selected-sleeper-type-image";
          break;
        case "ladiesBooked":
          value =
            seatType === "seat"
              ? selectedPriceFilter == fare
                ? "ladies-reserved-seat-type-image no-pointer-events"
                : "ladies-reserved-seat-type-image no-pointer-events"
              : selectedPriceFilter == fare
              ? "reserved-ladies-sleeper-type-image no-pointer-events"
              : "reserved-ladies-sleeper-type-image no-pointer-events";

          break;
        case "ladiesRes":
          value =
            seatType === "seat"
              ? selectedPriceFilter == fare
                ? "ladiesRes-reserved-seat-type-image active"
                : "ladiesRes-reserved-seat-type-image"
              : selectedPriceFilter == fare
              ? "reserved-ladiesRes-sleeper-type-image active"
              : "reserved-ladiesRes-sleeper-type-image";

          break;
        case "default":
          value =
            seatType === "seat"
              ? selectedPriceFilter == fare
                ? "available-seat-type-image active"
                : "available-seat-type-image"
              : selectedPriceFilter == fare
              ? "sleeper-seat active"
              : "sleeper-seat";
          break;
        default:
          break;
      }
      return value;
    };

    const getSeatStatus = (
      busCardId,
      seatNo,
      IsAvailable,
      IsLadiesSeat,
      IsLadiesSeatReserv
    ) => {
      // if (IsAvailable !== "True" ) { for test keys
      if (IsAvailable === "false" || IsAvailable === "False") {
        if (IsLadiesSeat == "True" || IsLadiesSeat == "true")
          return "ladiesBooked";
        else return "booked";
      } else if (IsAvailable && isAlreadySelected(busCardId, seatNo)) {
        return "selected";
      } else if (
        IsLadiesSeat == "True" ||
        IsLadiesSeat == "true" ||
        IsLadiesSeatReserv
      ) {
        return "ladiesRes";
      } else {
        return "default";
      }
    };

    const isAlreadySelected = (busCardId, seatNumber) => {
      return selectedSeats[busCardId] &&
        selectedSeats[busCardId].find((seat) => seat.SeatNo === seatNumber)
        ? true
        : false;
    };

    const busAlreadySelected = (busCardId) =>
      selectedSeats.hasOwnProperty(busCardId) ? true : false;

    const addRemoveSeat = (busCardId, seatInfo) => {
      if (!busAlreadySelected(busCardId)) {
        setSelectedSeats({
          ...selectedSeats,
          [busCardId]: [seatInfo],
        });
      } else {
        if (isAlreadySelected(busCardId, seatInfo.SeatNo)) {
          setSelectedSeats({
            ...selectedSeats,
            [busCardId]: selectedSeats[busCardId].filter(
              (data) => data.SeatNo !== seatInfo.SeatNo
            ),
          });
        } else {
          if (selectedSeats) {
            if (
              selectedSeats[busCardId] &&
              selectedSeats[busCardId].length > 5
            ) {
              message.error("Can't Select More Than 6 Seats");
              return;
            }
          }

          setSelectedSeats({
            ...selectedSeats,
            [busCardId]: [...selectedSeats[busCardId], seatInfo],
          });
        }
      }
    };

    return (
      <div className="seats-layout-block">
        {upperBerthArray.length > 0 && (
          <div className="sleeper-layout-outer">
            <div className="seat-layout-title-block">
              <div className="seat-layout-title-block-inner-data">
                <div className="seat-layout-title-text">
                  <p>Upper</p>
                </div>
              </div>
            </div>
            <div className="seater-seating-arrangement">
              {upperLayoutObj.map((rowData, i) => {
                rowData = rowData.sort((a, b) => a.column - b.column);
                isSeatingRow =
                  rowData.filter(
                    (data) => data.length === 1 && data.width === 1
                  ).length > 0;
                isSleepingRow =
                  rowData.filter(
                    (data) => data.length === 2 && data.width === 1
                  ).length > 0;

                return (
                  <div
                    key={i}
                    className={
                      isSeatingRow
                        ? "inner-seating-container"
                        : isSleepingRow
                        ? "inner-sleeper-container"
                        : "inner-sleeper-container"
                    }
                  >
                    <div>
                      <ul
                        className={
                          isSeatingRow
                            ? "first-seater-row"
                            : isSleepingRow
                            ? "first-sleeper-row"
                            : ""
                        }
                      >
                        {maxUpColumns.map((ind, i) => {
                          let seatData = rowData.filter((i) => {
                            return i.column == ind;
                          });

                          if (seatData.length > 0) {
                            seatData = seatData[0];

                            isSeat =
                              seatData.length === 1 && seatData.width === 1;
                            isVerticalRow =
                              seatData.length === 1 && seatData.width === 2;
                            seatClassName = isSeat
                              ? getSeatClass(
                                  busCardId,
                                  seatData.number,
                                  seatData.isAvailableSeat,
                                  seatData.isLadiesSeat,
                                  seatData.isLadiesSeatReserv,
                                  seatData.fare,
                                  "seat"
                                )
                              : getSeatClass(
                                  busCardId,
                                  seatData.number,
                                  seatData.isAvailableSeat,
                                  seatData.isLadiesSeat,
                                  seatData.isLadiesSeatReserv,
                                  seatData.fare,
                                  "sleeper"
                                );
                          }

                          return rowData.length > 0 ? (
                            seatData.length > 0 ? (
                              <li
                                key={i}
                                className={isVerticalRow ? "vertical_seat" : ""}
                              >
                                <div
                                  id={seatData.number}
                                  className={seatClassName}
                                  id={seatData.number}
                                  onClick={() => {
                                    const clasName =
                                      typeof document !== "undefined"
                                        ? document.getElementById(
                                            seatData.number
                                          ).className
                                        : seatClassName;
                                    if (
                                      clasName.indexOf("no-pointer-events") ===
                                      -1
                                    ) {
                                      addRemoveSeat(busCardId, {
                                        SeatNo: seatData.number,
                                        NetFare: seatData.fare,
                                        markup: seatData.markup,
                                        Servicetax: seatData.serviceTax
                                          ? seatData.serviceTax
                                          : 0,
                                        OperatorServiceCharge:
                                          seatData.operatorServiceCharge
                                            ? seatData.operatorServiceCharge
                                            : 0,
                                        SeatCode: seatData.seatCode,
                                        commission: seatData.commission
                                          ? seatData.commission
                                          : 0,
                                      });
                                    }
                                  }}
                                >
                                  {seatData.number}
                                </div>
                              </li>
                            ) : rowData.length > 1 ? null : (
                              <li key={i}>
                                {/* <div className="available-seat-type-image empty_seat"></div> */}
                                <div
                                  className={
                                    isSeatingRow
                                      ? "available-seat-type-image empty_seat"
                                      : isSleepingRow
                                      ? "first-sleeper-row empty_seat_sleeper"
                                      : "first-sleeper-row empty_seat_sleeper"
                                  }
                                ></div>
                              </li>
                            )
                          ) : null;
                        })}
                      </ul>
                    </div>

                    {rowData
                      ? rowData.length === 0 && (
                          <>
                            <div key={i} className="empty-row"></div>
                            <div key={i + 1} className="empty-row"></div>
                          </>
                        )
                      : null}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* ------------ Lower Code--------- */}
        <div className="seat-layout-outer">
          <div className="seat-layout-title-block">
            <div className="seat-layout-title-block-inner-data">
              <div className="steering-icon">
                <i className="fa fa-circle"></i>
              </div>
              <div className="seat-layout-title-text">
                <p>Lower</p>
              </div>
            </div>
          </div>
          <div className="seater-seating-arrangement">
            {lowerLayoutObj.map((rowData, i) => {
              rowData = rowData.sort((a, b) => a.column - b.column);
              isSeatingRow =
                rowData.filter((data) => data.length === 1 && data.width === 1)
                  .length > 0;
              isSleepingRow =
                rowData.filter((data) => data.length === 2 && data.width === 1)
                  .length > 0;

              return (
                <div
                  key={i}
                  className={
                    isSeatingRow
                      ? "inner-seating-container"
                      : isSleepingRow
                      ? "inner-sleeper-container"
                      : ""
                  }
                >
                  <div className="seat-type-description">
                    <ul
                      className={
                        isSeatingRow
                          ? "first-seater-row"
                          : isSleepingRow
                          ? "first-sleeper-row"
                          : ""
                      }
                    >
                      {maxColumns.map((ind, i) => {
                        let seatData = rowData.filter((i) => {
                          return i.column == ind;
                        });
                        if (seatData.length > 0) {
                          seatData = seatData[0];
                          isSeat =
                            seatData.length === 1 && seatData.width === 1;
                          isVerticalRow =
                            seatData.length === 1 && seatData.width === 2;
                          seatClassName = isSeat
                            ? getSeatClass(
                                busCardId,
                                seatData.number,
                                seatData.isAvailableSeat,
                                seatData.isLadiesSeat,
                                seatData.isLadiesSeatReserv,
                                seatData.fare,
                                "seat"
                              )
                            : getSeatClass(
                                busCardId,
                                seatData.number,
                                seatData.isAvailableSeat,
                                seatData.isLadiesSeat,
                                seatData.isLadiesSeatReserv,
                                seatData.fare,
                                "sleeper"
                              );
                        }
                        return rowData.length > 0 ? (
                          seatData.length > 0 ? (
                            <li
                              key={i}
                              className={isVerticalRow ? "vertical_seat" : ""}
                            >
                              <div
                                // className={seatClassName}
                                id={seatData.number}
                                className={seatClassName}
                                key={seatData.number}
                                onClick={() => {
                                  // const clasName = seatClassName;
                                  const clasName =
                                    typeof document !== "undefined"
                                      ? document.getElementById(seatData.number)
                                          .className
                                      : seatClassName;
                                  if (
                                    clasName.indexOf("no-pointer-events") === -1
                                  ) {
                                    addRemoveSeat(busCardId, {
                                      SeatNo: seatData.number,
                                      NetFare: seatData.fare,
                                      markup: seatData.markup,
                                      Servicetax: seatData.serviceTax
                                        ? seatData.serviceTax
                                        : 0,
                                      OperatorServiceCharge:
                                        seatData.operatorServiceCharge
                                          ? seatData.operatorServiceCharge
                                          : 0,
                                      SeatCode: seatData.seatCode,
                                      commission: seatData.commission
                                        ? seatData.commission
                                        : 0,
                                    });
                                  }
                                }}
                              >
                                {seatData.number}
                              </div>
                            </li>
                          ) : rowData.length > 1 ? null : (
                            <li key={i}>
                              {/* <div className="available-seat-type-image empty_seat"></div> */}
                              <div
                                className={
                                  isSeatingRow
                                    ? "available-seat-type-image empty_seat"
                                    : isSleepingRow
                                    ? "first-sleeper-row empty_seat_sleeper"
                                    : "first-sleeper-row empty_seat_sleeper"
                                }
                              ></div>
                            </li>
                          )
                        ) : null;
                      })}
                    </ul>
                  </div>

                  {rowData
                    ? rowData.length === 0 && (
                        <>
                          <div key={i} className="empty-row"></div>
                          <div key={i + 1} className="empty-row"></div>
                        </>
                      )
                    : null}
                </div>
              );
            })}
          </div>
        </div>

        <hr />
        {/* ---------- Seat Type Description ------------ */}
        <div className="seat-type-description">
          <ul className="space_between">
            <li>
              <div className="available-seat-type-image"></div>
              <p>Available </p>
            </li>
            <li>
              <div className="selected-seat-type-image" />
              <p>Selected </p>
            </li>

            <li>
              <div className="ladies-reserved-seat-type-image" />
              <p>Booked by Ladies</p>
            </li>
            <li>
              <div className="ladiesRes-reserved-seat-type-image" />
              <p>Reserved for Ladies</p>
            </li>
            <li>
              <div className="Booked-seat-type-image" />
              <p>Booked </p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const [pagination, SetPagination] = useState(51);
  const showMore = () => {
    SetPagination((prev) => prev + 51);
  };
  return (
    <div className="card-results-block">
      {cardsData.length > 0 ? (
        <div className="resultsScrollWrapper">
          {cardsData.map((cardData, index) => {
            return index < pagination ? (
              cardData.isVisible && (
                <ReactBootstrap.Card
                  className="card-results"
                  key={"tab_" + index}
                >
                  <div className="card-results-travel-details">
                    <ReactBootstrap.Row className="card-results-row">
                      <ReactBootstrap.Col
                        md="4"
                        sm="12"
                        className="card-results-columns"
                      >
                        <div className="travels-name">
                          <h2>{cardData.displayName}</h2>
                        </div>
                        <div className="selected-bus-type">
                          <p>{cardData.busType}</p>
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col
                        md="5"
                        sm="12"
                        className="card-results-columns"
                      >
                        <div className="from-to-details">
                          <div className="bus-timings">
                            <ul>
                              <li> {tConvert(cardData.departureTime)}</li>
                              <li className="du-fast">
                                <span>{cardData.duration} </span>
                              </li>
                              <li> {tConvert(cardData.arrivalTime)}</li>
                            </ul>
                          </div>
                          <div className="address d-flex justify-content-between">
                            <div style={{ width: "48%" }}>
                              <p className="onboard-address">
                                {cardData.boardingPoints[0]
                                  ? cardData.boardingPoints[0].Address
                                  : ""}
                                {cardData.boardingPoints[0]
                                  ? cardData.boardingPoints[0].Landmark
                                  : ""}
                              </p>
                              <p className="available-boardingpoints">
                                {cardData.boardingPoints.length > 1
                                  ? `${
                                      cardData.boardingPoints.length - 1
                                    } More Boarding Point(s)`
                                  : null}
                              </p>
                            </div>
                            <div style={{ width: "48%" }}>
                              <p className="onboard-address text-right">
                                {cardData.boardingPoints[0]
                                  ? cardData.droppingPoints[0].Address
                                  : ""}
                                {cardData.boardingPoints[0]
                                  ? cardData.droppingPoints[0].Landmark
                                  : ""}
                              </p>
                              <p className="available-boardingpoints text-right">
                                {cardData.droppingPoints.length > 1
                                  ? `${
                                      cardData.droppingPoints.length - 1
                                    } More Dropping Point(s)`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col
                        md="3"
                        sm="12"
                        className="card-results-columns"
                      >
                        <div className="available-price-details">
                          {showNetFare ? (
                            <div className="starting-price">
                              {cardData.startingFare.split("/").length > 1 ? (
                                <p className="starting-price-text">
                                  Starting From
                                </p>
                              ) : null}
                              <p className="starting-price-number  text-line">
                                {cardData.startingFare
                                  .split("/")
                                  .map((item, i, a) => (
                                    <span key={i}>
                                      {i === 0 && "₹"} {Math.round(item)}
                                      {i < a.length - 1 && "/"}
                                    </span>
                                  ))}
                              </p>
                              <p className="starting-price-number">
                                {cardData.netFare
                                  .split("/")
                                  .map((item, i, a) => (
                                    <span key={i}>
                                      {i === 0 && "₹"} {Math.round(item)}
                                      {i < a.length - 1 && "/"}
                                    </span>
                                  ))}
                              </p>
                              <p className="starting-price-number">
                                {cardData.commission
                                  .split("/")
                                  .map((item, i, a) => (
                                    <span key={i} className="comm-text">
                                      {i === 0 && "  Comm: ₹ "}{" "}
                                      {Math.round(item)}
                                      {i < a.length - 1 && "/ "}
                                    </span>
                                  ))}
                              </p>
                            </div>
                          ) : (
                            <div className="starting-price">
                              {cardData.startingFare.split("/").length > 1 ? (
                                <p className="starting-price-text">
                                  Starting From
                                </p>
                              ) : null}
                              <p className="starting-price-number">
                                {cardData.startingFare
                                  .split("/")
                                  .map((item, i, a) => (
                                    <span key={i}>
                                      {i === 0 && "₹"} {Math.round(item)}
                                      {i < a.length - 1 && "/"}
                                    </span>
                                  ))}
                              </p>
                            </div>
                          )}

                          <div className="no-of-seats">
                            <span>{cardData.availableSeats} Seat(s)</span>
                          </div>
                        </div>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </div>
                  <div className="card-results-tabs-info  d-md-block">
                    <div className="cards-tabs-block">
                      <ReactBootstrap.Tabs
                        activeKey={activeTab[index] ? activeTab[index] : ""}
                        id="controlled-tab"
                        onSelect={(tabKey) => {
                          setActiveTab({
                            [index]: tabKey,
                          });
                          if (tabKey === "selectseats_" + index) {
                            onHandleSeatLayout(cardData.id);
                            setSelectedSeats({});
                            form.resetFields();
                            setSelectedPriceFilter("All");
                          }
                        }}
                      >
                        <ReactBootstrap.Tab
                          eventKey={"boardingpoints_" + index}
                          title="Boarding/Dropping points"
                        >
                          <div className="boarding-dropping-points-tab">
                            <div
                              className="close-btn"
                              onClick={() => {
                                setActiveTab({
                                  [index]: "",
                                });
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                            <div className="boarding-dropping-points-tab-container">
                              <div className="boarding-points-block">
                                <div className="boarding-points-block-header ">
                                  <h4>Boarding Points</h4>
                                </div>
                                <div className="boarding-points-block-body">
                                  <ul>
                                    {cardData.boardingPoints.map((data, i) => (
                                      <li key={i + data.Time}>
                                        <span className="dot"></span>
                                        <span>
                                          <Tooltip
                                            className="pointTooltip"
                                            overlayClassName="locTooltip"
                                            placement="topLeft"
                                            title={
                                              <span>
                                                {MinutesToHours(data.Time)}{" "}
                                                {data.Location}
                                              </span>
                                            }
                                          >
                                            {MinutesToHours(data.Time)}{" "}
                                            {data.Location}
                                          </Tooltip>
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="dropping-points-block">
                                <div className="dropping-points-block-header ">
                                  <h4>Dropping Points</h4>
                                </div>
                                <div className="dropping-points-block-body">
                                  <ul>
                                    {cardData.droppingPoints.map((data, i) => (
                                      <li key={data.Time + i}>
                                        <span className="dot"></span>
                                        <span>
                                          <Tooltip
                                            className="pointTooltip"
                                            overlayClassName="locTooltip"
                                            placement="topLeft"
                                            title={
                                              <span>
                                                {MinutesToHours(data.Time)}{" "}
                                                {data.Location}
                                              </span>
                                            }
                                          >
                                            {MinutesToHours(data.Time)}{" "}
                                            {data.Location}
                                          </Tooltip>
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ReactBootstrap.Tab>
                        <ReactBootstrap.Tab
                          eventKey={"cancellation_" + index}
                          title="Cancellation Policy"
                        >
                          <div className="cancllation-policy-tab">
                            <div
                              className="close-btn"
                              onClick={() => {
                                setActiveTab({
                                  [index]: "",
                                });
                                setSelectedSeats({});
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                            <div className="cancellation-policy-container">
                              <div className="cancellation-block">
                                <CancellationPolicy
                                  data={cardData.cancellationPolicy}
                                />
                              </div>
                            </div>
                          </div>
                        </ReactBootstrap.Tab>
                        <ReactBootstrap.Tab
                          eventKey={"selectseats_" + index}
                          title="Select Seats"
                          className="seat-selection-block"
                        >
                          <div className="pricefilter_wrapper">
                            {Object.keys(selectTrip).length === 0 ? (
                              <div className="emptyspace"></div>
                            ) : activeTab[index] ? (
                              activeTab[index] === "selectseats_" + index ? (
                                <PriceFilters />
                              ) : null
                            ) : null}

                            <div
                              className="close-btn"
                              onClick={() => {
                                setActiveTab({
                                  [index]: "",
                                });
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </div>
                          </div>

                          <div className="seats_container">
                            <div className="seats_wrapper">
                              {Object.keys(selectTrip).length === 0 ? (
                                "Loading Seat Layout...."
                              ) : activeTab[index] ? (
                                activeTab[index] === "selectseats_" + index ? (
                                  <SeatLayout busCardId={cardData.id} />
                                ) : null
                              ) : null}
                            </div>

                            <div className="boarding-dropping-point-block">
                              <div className="boarding-dropping-point-block-inner">
                                <div className="boarding-dropping-point-block-tabs">
                                  <Form
                                    name={"locSelect" + index}
                                    form={form}
                                    layout="vertical"
                                  >
                                    <Form.Item
                                      label="Boarding Point"
                                      name="boardingPoint"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Choose Boarding Point",
                                        },
                                      ]}
                                    >
                                      <Select
                                        getPopupContainer={(trigger) =>
                                          trigger.parentNode
                                        }
                                        overlayClassName="bdoverlay"
                                        virtual={false}
                                        onSelect={(index) =>
                                          setSelectedBoardingPoint({
                                            ...cardData.boardingPoints[index],
                                          })
                                        }
                                        placeholder="Choose"
                                      >
                                        {cardData.boardingPoints.map(
                                          (data, index) => (
                                            <Option
                                              key={`bp_${index}_${new Date()}`}
                                              value={index}
                                              className="bdpoint"
                                            >
                                              {" "}
                                              <Tooltip
                                                className="pointTooltip"
                                                overlayClassName="locTooltip"
                                                placement="topLeft"
                                                title={
                                                  <span>
                                                    {" "}
                                                    {MinutesToHours(
                                                      data.Time
                                                    )}{" "}
                                                    {data.Location}
                                                  </span>
                                                }
                                              >
                                                {MinutesToHours(data.Time)}{" "}
                                                {data.Location}
                                              </Tooltip>
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      label="Dropping Point"
                                      name="droppingPoint"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Choose Dropping Point",
                                        },
                                      ]}
                                    >
                                      <Select
                                        getPopupContainer={(trigger) =>
                                          trigger.parentNode
                                        }
                                        virtual={false}
                                        onSelect={(index) =>
                                          setSelectedDroppingPoint({
                                            ...cardData.droppingPoints[index],
                                          })
                                        }
                                        placeholder="Choose"
                                      >
                                        {cardData.droppingPoints.map(
                                          (data, index) => (
                                            <Option
                                              key={`dpp_${new Date()}_${index}`}
                                              value={index}
                                              className="bdpoint"
                                            >
                                              <Tooltip
                                                className="pointTooltip"
                                                overlayClassName="locTooltip"
                                                placement="topLeft"
                                                title={
                                                  <span>
                                                    {" "}
                                                    {MinutesToHours(
                                                      data.Time
                                                    )}{" "}
                                                    {data.Location}
                                                  </span>
                                                }
                                              >
                                                {MinutesToHours(data.Time)}{" "}
                                                {data.Location}
                                              </Tooltip>
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </Form>

                                  <div className="selected-seat-fare-details">
                                    <ul>
                                      <li>
                                        <p>Seat No.</p>
                                        <p>
                                          {selectedSeats[cardData.id] &&
                                          selectedSeats[cardData.id].length > 0
                                            ? getSortedSeatNumbers(cardData.id)
                                            : ""}
                                        </p>
                                      </li>
                                      <li>
                                        <p>Base Fare</p>
                                        <p>
                                          ₹{" "}
                                          {fareValues[cardData.id] &&
                                            fareValues[cardData.id].baseFare}
                                        </p>
                                      </li>
                                      <li>
                                        <p>GST</p>
                                        <p>
                                          ₹{" "}
                                          {fareValues[cardData.id] &&
                                            fareValues[cardData.id].gst}
                                        </p>
                                      </li>
                                      <li>
                                        <p>Total Fare</p>
                                        <p>
                                          ₹{" "}
                                          {fareValues[cardData.id] &&
                                            fareValues[cardData.id].totalFare}
                                        </p>
                                      </li>
                                    </ul>

                                    <div className="continue-booked-seat-btn">
                                      <Button
                                        disabled={
                                          selectedSeats[cardData.id] &&
                                          selectedSeats[cardData.id].length > 0
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          form
                                            .validateFields()
                                            .then((data) => {
                                              bookingBusData({
                                                busCardId: cardData.id,
                                                displayName:
                                                  cardData.displayName,
                                                busType: cardData.busType,
                                                droppingTimes:
                                                  selectedDroppingPoint,
                                                boardingTimes:
                                                  selectedBoardingPoint,
                                                arrivalTime:
                                                  cardData.arrivalTime,
                                                departureTime:
                                                  cardData.departureTime,
                                                duration: cardData.duration,
                                              });
                                            })
                                            .catch((e) => {});
                                        }}
                                      >
                                        Continue Booking
                                      </Button>
                                    </div>
                                  </div>
                                  {/* ) : (
                                  ""
                                )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </ReactBootstrap.Tab>
                      </ReactBootstrap.Tabs>
                    </div>
                  </div>
                </ReactBootstrap.Card>
              )
            ) : index === pagination ? (
              <div className="showmore-wrapper">
                <Button onClick={showMore}>Show More</Button>
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <CustomNoResultFound title={"No Buses Available"} />
      )}
    </div>
  );
};

export default BusCard;
